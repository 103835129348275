import { createSlice } from "@reduxjs/toolkit"
import { prop, propOr, uniq } from "ramda"

import {
	exportKeywordsComparison,
	getAllKeywordsComparison,
	getComparingArticle,
	getComparingArticles,
	getComparingCard,
	getComparingCategories,
	getComparingWarehouses,
	getKeywordsComparison,
	getTargetCard,
	getTargetWarehouses,
} from "./actions"

const red_bg = "#FDE4EB"
const green_bg = "#E2F4EC"
const articleProductId = JSON.parse(localStorage.getItem("article_product_id")) || ""
const articleProductIds = JSON.parse(localStorage.getItem("article_product_ids")) || []

const initialState = {
	articleLoading: false,
	article: [],
	articleError: null,

	articlesLoading: false,
	articles: [],
	articlesError: null,

	comparingCategoriesLoading: false,
	comparingCategories: [],
	comparingCategoriesError: null,

	targetWarehousesLoading: false,
	targetWarehouses: [],
	targetWarehousesError: null,

	comparingWarehousesLoading: false,
	comparingWarehouses: [],
	comparingWarehousesError: null,

	targetCardLoading: false,
	targetCard: {},
	targetCardError: null,

	comparingCardLoading: false,
	comparingCard: {},
	comparingCardError: null,

	articlesComparisonParams: {
		articleId: articleProductId,
		articleIds: articleProductIds,
	},

	keywordsComparisonLoading: false,
	keywordsComparison: {
		count: null,
		items: [],
	},
	keywordsComparisonError: null,

	allKeywordsComparisonLoading: false,
	allKeywordsComparison: [],
	allKeywordsComparisonError: null,

	exportKeywordsComparisonLoading: false,
	exportKeywordsComparisonSuccess: {},
	exportKeywordsComparisonError: null,
}

const articlesComparison = createSlice({
	name: "articlesComparison",
	initialState,
	reducers: {
		setArticleComparisonId(state, { payload }) {
			state.articlesComparisonParams.articleId = payload
			localStorage.setItem(
				"article_product_id",
				JSON.stringify(state.articlesComparisonParams.articleId)
			)
		},
		setArticleComparisonIds(state, { payload }) {
			if (typeof payload === "number" || typeof payload === "string") {
				state.articlesComparisonParams.articleIds = uniq([
					...state.articlesComparisonParams.articleIds,
					payload,
				])
			} else {
				state.articlesComparisonParams.articleIds = uniq([
					...state.articlesComparisonParams.articleIds,
					...payload,
				])
			}

			localStorage.setItem(
				"article_product_ids",
				JSON.stringify(state.articlesComparisonParams.articleIds)
			)
		},
		deleteArticleComparisonIds(state, { payload }) {
			state.articlesComparisonParams.articleIds =
				state.articlesComparisonParams.articleIds.filter(item => item !== payload)

			localStorage.setItem(
				"article_product_ids",
				JSON.stringify(state.articlesComparisonParams.articleIds)
			)
		},
		clearArticleParams(state) {
			state.articlesComparisonParams.articleId = ""
			state.articlesComparisonParams.articleIds = []

			localStorage.removeItem("article_product_id")
			localStorage.removeItem("article_product_ids")
		},
	},
	extraReducers: builder => {
		// main article
		builder.addCase(getComparingArticle.pending, state => {
			state.articleLoading = true
		})
		builder.addCase(getComparingArticle.fulfilled, (state, { payload }) => {
			state.articleLoading = false
			state.article = propOr([], "results", payload).map(
				({
					id,
					name,
					image,
					discount,
					reviews,
					proceeds,
					orders,
					subject,
					dynamic,
					rating,
					sales_speed,
					price,
				}) => ({
					id,
					image,
					subject,
					title: { name, image, id },
					count_top_100: Math.round(orders),
					proceeds,
					wb_count: dynamic,
					price,
					discount,
					reviews,
					rating,
					sales_speed,
				})
			)
		})
		builder.addCase(getComparingArticle.rejected, state => {
			state.articleLoading = false
			state.articleError = "Error getting comparing article..."
		})
		// comparing articles
		builder.addCase(getComparingArticles.pending, state => {
			state.articles = []
			state.articlesLoading = true
		})
		builder.addCase(getComparingArticles.fulfilled, (state, { payload }) => {

			state.articlesLoading = false
			state.articles = propOr([], "results", payload)
				.sort((a, b) => prop("proceeds", b) - prop("proceeds", a))
				.map(
					({
						id,
						name,
						image,
						discount,
						reviews,
						proceeds,
						orders,
						subject,
						dynamic,
						rating,
						sales_speed,
						price,
					}) => ({
						title: { name, image, id },
						id,
						image,
						subject,
						count_top_100: Math.round(orders),
						proceeds,
						wb_count: dynamic,
						price,
						discount,
						reviews,
						rating,
						sales_speed,
					})
				)
		})
		builder.addCase(getComparingArticles.rejected, state => {
			state.articlesLoading = false
			state.articlesError = "Error getting comparing articles..."
		})
		// comparing categories
		builder.addCase(getComparingCategories.pending, state => {
			state.comparingCategoriesLoading = true
		})
		builder.addCase(getComparingCategories.fulfilled, (state, { payload }) => {
			state.comparingCategoriesLoading = false
			state.comparingCategories = propOr([], "results", payload).map(item => ({
				...item,
				name: { title: item.name, id: item.id },
				common: {
					value: item.common,
					style: {
						backgroundColor: item.common ? green_bg : red_bg,
					},
				},
			}))
		})
		builder.addCase(getComparingCategories.rejected, state => {
			state.comparingCategoriesLoading = false
			state.comparingCategoriesError = "Error getting comparing categories..."
		})
		// target warehouses
		builder.addCase(getTargetWarehouses.pending, state => {
			state.targetWarehousesLoading = true
		})
		builder.addCase(getTargetWarehouses.fulfilled, (state, { payload }) => {
			state.targetWarehousesLoading = false
			state.targetWarehouses = payload
		})
		builder.addCase(getTargetWarehouses.rejected, state => {
			state.targetWarehousesLoading = false
			state.targetWarehousesError = "Error getting target warehouses..."
		})
		// comparing warehouses
		builder.addCase(getComparingWarehouses.pending, state => {
			state.comparingWarehousesLoading = true
		})
		builder.addCase(getComparingWarehouses.fulfilled, (state, { payload }) => {
			state.comparingWarehousesLoading = false
			state.comparingWarehouses = payload
		})
		builder.addCase(getComparingWarehouses.rejected, state => {
			state.comparingWarehousesLoading = false
			state.comparingWarehousesError = "Error getting comparing warehouses..."
		})
		// target card
		builder.addCase(getTargetCard.pending, state => {
			state.targetCardLoading = true
		})
		builder.addCase(getTargetCard.fulfilled, (state, { payload }) => {
			state.targetCardLoading = false
			state.targetCard = payload
		})
		builder.addCase(getTargetCard.rejected, state => {
			state.targetCardLoading = false
			state.targetCardError = "Error getting target card..."
		})
		// comparing card
		builder.addCase(getComparingCard.pending, state => {
			state.comparingCardLoading = true
		})
		builder.addCase(getComparingCard.fulfilled, (state, { payload }) => {
			state.comparingCardLoading = false
			state.comparingCard = payload
		})
		builder.addCase(getComparingCard.rejected, state => {
			state.comparingCardLoading = false
			state.comparingCardError = "Error getting comparing card..."
		})
		// keywords comparison
		builder.addCase(getKeywordsComparison.pending, state => {
			state.keywordsComparisonLoading = true
		})
		builder.addCase(getKeywordsComparison.fulfilled, (state, { payload }) => {
			state.keywordsComparisonLoading = false
			state.keywordsComparison.count = payload.count
			state.keywordsComparison.items = propOr([], "results", payload).map(item => ({
				...item,
				name: {
					value: item.name,
					id: item.id
				},
				common: {
					value: item.is_in,
					style: {
						backgroundColor: item.is_in ? green_bg : red_bg,
					},
				},
			}))
		})
		builder.addCase(getKeywordsComparison.rejected, state => {
			state.keywordsComparisonLoading = false
			state.keywordsComparisonError = "Error getting keywords comparison..."
		})
		// all keywords comparison
		builder.addCase(getAllKeywordsComparison.pending, state => {
			state.allKeywordsComparisonLoading = true
		})
		builder.addCase(getAllKeywordsComparison.fulfilled, (state, { payload }) => {
			state.allKeywordsComparisonLoading = false
			state.allKeywordsComparison = payload.results.map(item => item.id)
		})
		builder.addCase(getAllKeywordsComparison.rejected, state => {
			state.allKeywordsComparisonLoading = false
			state.allKeywordsComparisonError = "Error getting all comparison keywords..."
		})
		// keywords comparison download
		builder.addCase(exportKeywordsComparison.pending, state => {
			state.exportKeywordsComparisonLoading = true
		})
		builder.addCase(exportKeywordsComparison.fulfilled, (state, { payload }) => {
			state.exportKeywordsComparisonLoading = false
			state.exportKeywordsComparisonSuccess = payload
		})
		builder.addCase(exportKeywordsComparison.rejected, state => {
			state.exportKeywordsComparisonLoading = false
			state.exportKeywordsComparisonError = "Error downloading file..."
		})
	},
})

export const articlesComparisonActions = {
	...articlesComparison.actions,
	getComparingArticle,
	getComparingArticles,
	getComparingCategories,
	getTargetWarehouses,
	getComparingWarehouses,
	getTargetCard,
	getComparingCard,
	exportKeywordsComparison,
	getKeywordsComparison,
	getAllKeywordsComparison,
}

export default articlesComparison.reducer
