import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	loadFilterDisabled: {},
}

const loadFilter = createSlice({
	name: "loadFilterDisabled",
	initialState,
	reducers: {
		loadFilterData(state, {payload}) {
			state.loadFilterDisabled = payload.loadFilterDisabled
		},
	},
})

export const {loadFilterData} = loadFilter.actions

export default loadFilter.reducer
