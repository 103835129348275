import React, { useEffect, useMemo, useRef, useState } from "react"

import Grid from "@mui/material/Grid"
import Paper from "@mui/material/Paper"
import classnames from "classnames"
import { useSnackbar } from "notistack"
import { defaultTo, prop, propOr } from "ramda"
import { useLocation } from "react-router-dom"

// import Hidden from "@mui/material/Hidden"

import API from "root/js/api"

// import bubbleSrc from "./bubble.svg"
import useNavigationMemory from "root/hooks/useNavigationMemory"

import contactUsSrc from "./contact-us.jpg"
import staffSrc from "./staff.jpg"

import styles from "./index.module.scss"

const WBAPI = new API()

//! const paths = [
// 	{
// 		module: "signin",
// 		paths: ["/signin"],
// 	},
// 	{
// 		module: "signup",
// 		paths: ["/signup"],
// 	},

// 	{
// 		module: "analysis_brand",
// 		paths: ["/search/brand", "/brand/:id", "/home"],
// 	},
// 	{
// 		module: "analysis_seller",
// 		paths: ["/search/seller", "/seller/:id", "/home"],
// 	},
// 	{
// 		module: "analysis_category",
// 		paths: ["/search/category", "/category/:id", "/home"],
// 	},
// 	{
// 		module: "analysis_search",
// 		paths: ["/search-analytics"],
// 	},
// 	{
// 		module: "analysis_product",
// 		paths: ["/search/product", "/product/:id", "/home"],
// 	},
// 	{
// 		module: "filter_product",
// 		paths: ["/product-analyzer"],
// 	},
// 	{
// 		module: "filter_brand",
// 		paths: ["/brand-analyzer"],
// 	},
// 	{
// 		module: "filter_seller",
// 		paths: ["/seller-analyzer"],
// 	},
// 	{
// 		module: "filter_category",
// 		paths: ["/category-analyzer"],
// 	},
// 	{
// 		module: "seo_semantic",
// 		paths: ["/keys-finder"],
// 	},
// 	{
// 		module: "seo_search_position",
// 		paths: ["/position"],
// 	},
// 	{
// 		module: "seo_search_goods",
// 		paths: ["/top-find"],
// 	},
// 	// {
// 	// 	module: "seo_position_tracker",
// 	// 	paths: ["/search-analytics"],
// 	// },
// 	{
// 		module: "seo_top_suggest",
// 		paths: ["/keys-top-find"],
// 	},
// 	{
// 		module: "monitoring",
// 		paths: ["/monitoring"],
// 	},
// 	{
// 		module: "personal_analytics",
// 		paths: ["/my-warehouse", "/scoreboard", "/my-reults"],
// 	},
// 	{
// 		module: "profile",
// 		paths: ["/cabinet"],
// 	},
// 	{
// 		module: "advertising",
// 		paths: ["/advertising-optimization"],
// 	},
// 	{
// 		module: "optimization",
// 		paths: ["/advertising-optimization", "/product-optimization", "/category-optimization"],
// 	},
//! ]

//! const bannerShownModules = []

const defaultToObj = defaultTo({})

const Banner = props => {
	const {
		fullWidth,
		isDrawerOpen = false,
		// url,
		className,
		...rest
	} = props

	const { pathname } = useLocation()
	const { enqueueSnackbar } = useSnackbar()
	const snackbarShown = useRef(false)
	const { params } = useNavigationMemory()
	const [banners, setBanners] = useState({})

	// console.log("-->", banners["/dashboard" + pathname])

	//! const module = paths.find(item =>
	//! 	item.paths.includes(props.match.path.replace(/\/$/, ""))
	//! )?.module

	//! const matchRoute = Object.keys(banners).find(
	//! 	item => item === pathname || item === props.match.path
	//! )
	//! const currentBanner = banners[module] || banners[matchRoute] || banners.general
	const currentBanner = banners?.[pathname] || banners?.["/dashboard" + pathname] || banners?.["/dashboard/"]
	// console.log(currentBanner, banners, pathname, "/dashboard" + pathname)
	// const path = ("/dashboard" + pathname).split("/").filter(item => item !== "")
	// currentBanner.is_block = path.some(item => keys(banners)[0].split("/").includes(item))
	const path = ("/dashboard" + pathname).split("/").filter(item => item !== "")
	const obj = defaultToObj(
		propOr(
			// {},
			prop("/" + path.join("/") + "?" + `tab=${params.tab}`, banners),
			"/" + prop(0, path) + "/" + prop(1, path) + "/",
			banners
		)
	)

	//banners.find(banner => banner.module === pathname || banners.module === props.match.path)
	// const currentBanner = {
	// 	subject: "Проводятся технические работы",
	// 	description2: "С сайтом все в порядке! Какие-то нехорошие ребята пытаются нас ДДОСить: у них них ничего не получится. Мы с командой уже почти все решили!",
	// 	description: "Мы ведём технические работы и планируем закончить через X часов. Приносим извинения за доставленные неудобства. "
	// }

	const { subject, description, description2 } = currentBanner || obj
	// console.log(subject, description, description2)
	const isShown = useMemo(
		() => Boolean(prop("is_block", currentBanner) || prop("is_block", obj)),
		[currentBanner, banners, obj]
	)

	// const isShown = true
	useEffect(() => {
		isShown ? (document.body.style.overflow = "hidden") : (document.body.style.overflow = "")

		return () => (document.body.style.overflow = "")
	}, [isShown])

	useEffect(() => {
		snackbarShown.current = false
		const fetchData = async () => {

			try {
				const response = await WBAPI.getBanners()
				setBanners(Object.fromEntries(response.map(item => [item.module, item])))
			} catch (err) {
				console.error(err)
			}
		}
		fetchData()
	}, [pathname])

	useEffect(() => {
		if (
			snackbarShown.current ||
			!currentBanner ||
			currentBanner.is_block ||
			!obj ||
			obj.is_block
			// || bannerShownModules.includes(currentBanner.module)
		)
			return

		// bannerShownModules.push(currentBanner.module)
		snackbarShown.current = true
		// console.log(subject)
		if (subject) {
			enqueueSnackbar({
				title: subject,
				message: (
					<>
						{description}
						{description2 && (
							<>
								<br />
								<br />
								{description2}
							</>
						)}
					</>
				),
				variant: "error",
			})
		}
	}, [currentBanner, obj, enqueueSnackbar, pathname, subject, description, description2])

	return isShown ? (
		<div
			{...rest}
			className={classnames(styles.root, className)}
			data-drawer-open={isDrawerOpen}
			data-fullwidth={fullWidth}
		>
			<Grid
				container
				direction={"column"}
				className={styles.container}
			>
				<Grid
					item
					container
					xs={12}
					sm={12}
					md={12}
					lg={12}
					className={styles.left}
				>
					<Grid
						item
						xs={12}
						md={6}
						direction={"column"}
						style={{ paddingRight: "30px", marginBottom: "24px" }}
					>
						<h3 className={styles.subject}>{subject}</h3>
						<h6 className={styles.description}>{description}</h6>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						mb={2}
						style={{ marginBottom: "24px" }}
					>
						{description2 && (
							<div className={styles.description2}>
								<div className={styles.message}>{description2}</div>
								<div className={styles.staff}>
									<img
										src={staffSrc}
										alt=""
									/>
									<div className={styles.info}>
										<div className={styles.name}>Дмитрий</div>
										<div className={styles.job}>IT-директор Wildbox</div>
									</div>
								</div>
							</div>
						)}
					</Grid>
				</Grid>

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
				>
					<Paper
						elevation={0}
						className={styles.anyQuestions}
					>
						<h3
							className={styles.subject}
							style={{ marginBottom: "10px" }}
						>
							Остались вопросы?
						</h3>
						<h6 className={styles.description}>
							Напишите нам в чат поддержки на сайте
						</h6>
						<img
							src={contactUsSrc}
							alt=""
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	) : null
}

export default Banner
