import { createSlice } from "@reduxjs/toolkit"

import { checkBrand, checkBrands, setTabBrand } from "./actions"

const initialState = {
	brandsCheckBoxes: [],
	fullIds: [],
	tab: 0,
}

const brands = createSlice({
	name: "monitoring brands",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setTabBrand.fulfilled, (state, { payload }) => {
			state.tab = payload
		})
		builder.addCase(checkBrands.fulfilled, (state, { payload }) => {
			state.fullIds = payload
		})
		builder.addCase(checkBrand.fulfilled, (state, { payload }) => {
			state.brandsCheckBoxes = payload
		})

	},
})

export const brandsActions = {
	...brands.actions,
	checkBrand,
	checkBrands,
	setTabBrand,
}

export default brands.reducer
