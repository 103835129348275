import { createAsyncThunk } from "@reduxjs/toolkit"

export const checkBrand = createAsyncThunk(
	"/checkBrand",
	async (data) => data
)
export const checkBrands = createAsyncThunk(
	"/checkBrands",
	async (data) => data
)
export const setTabBrand = createAsyncThunk(
	"/setTabBrand",
	async (tab) => tab
)
