import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
		viewBox={"-3 -3 30 30"}
	>
		<path
			d="M19.1667 19.1667H2.83333V2.83333H11V0.5H2.83333C1.53833 0.5 0.5 1.55 0.5 2.83333V19.1667C0.5 20.45 1.53833 21.5 2.83333 21.5H19.1667C20.45 21.5 21.5 20.45 21.5 19.1667V11H19.1667V19.1667ZM13.3333 0.5V2.83333H17.5217L6.05333 14.3017L7.69833 15.9467L19.1667 4.47833V8.66667H21.5V0.5H13.3333Z"
			// fill="#CF3AA0"
		/>
	</Template>
))
