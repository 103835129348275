import React from "react"

const ExternalLinkPure = props => {
	let {children, style = {}, ...rest} = props
	return (
		<a
			{...rest}
			style={Object.assign({textDecoration: "none", color: "inherit"}, style)}
			rel="noopener noreferrer"
		>
			{children}
		</a>
	)
}

export default ExternalLinkPure
