import {defaultTo, keys, prop} from "ramda"

const defaultToArr = defaultTo([])

const initialState = {
	tabs: [],
	subjectsLoading: false,
	radioButtons: true,
	radioButtonsPosirion: {0: "all", 1: "all", 2: "all"},
	fatchDatas: {
		mainArticulesId: undefined,
	},
	tables: {
		0: {
			perPage: 50,
			page: 1,
			ordering: "-frequency",
			ids: {all: [], nons: [], yeses: []},
			chacked: {all: [], nons: [], yeses: []},
			all: [],
			nons: [],
			yeses: [],
			total: 0,
			nonesTotal: 0,
			yesesTotal: 0,
			loading: false,
		},
	},
}

export const keyWordTable = (state = initialState, action) => {
	switch (action.type) {
		case "FETCH_TABS":
			return {
				...state,
				tabs: action.payload,
			}
		case "SUBJECTS_LOADING":
			return {
				...state,
				subjectsLoading: action.payload,
			}
		case "FETCH_SUBJECTS":
			return {
				...state,
				tables: {
					...state.tables,
					...keys(state.tables).map(page => ({
						...state.tables[page],
						all: defaultToArr(state.tables[page].all).map(item => {
							return {
								...item,
								subject: prop(
									"subject",
									defaultToArr(action.payload).find(({id}) => id === item.id)
								),
							}
						}),
						nons: defaultToArr(state.tables[page].nons).map(item => {
							return {
								...item,
								subject: prop(
									"subject",
									defaultToArr(action.payload).find(({id}) => id === item.id)
								),
							}
						}),
						yeses: defaultToArr(state.tables[page].yeses).map(item => {
							return {
								...item,
								subject: prop(
									"subject",
									defaultToArr(action.payload).find(({id}) => id === item.id)
								),
							}
						}),
					})),
				},
			}
		case "CHANGE_RADIO":
			return {
				...state,
				radioButtonsPosirion: {
					...state.radioButtonsPosirion,
					[action.tab]: action.payload,
				},
			}

		case "FETCH_TABLE":
			return {
				...state,
				tables: {
					...state.tables,
					[action.index]: {
						...state.tables[action.index],
						perPage: action.payload.perPage,
						page: action.payload.page,
						ids: action.payload.ids,
						chacked: {all: [], nons: [], yeses: []},
						all: action.payload.all,
						nons: action.payload.nons,
						yeses: action.payload.yeses,
						total: action.payload.total,
						nonesTotal: action.payload.nonesTotal,
						yesesTotal: action.payload.yesesTotal,
						ordering: action.payload.ordering,
						loading: false,
					},
				},
			}
		case "NEW_TABLE":
			return {
				...state,
				tables: {
					...state.tables,
					[action.payload]: action.payload,
				},
			}
		case "FILL_CHACKBOXES":
			return {
				...state,
				tables: {
					...state.tables,
					[action.index]: {
						...state.tables[action.index],
						chacked: {
							...state.tables[action.index].chacked,
							[action.position]: action.payload,
						},
					},
				},
			}
		case "GET_MAIN_ARTICULE_ID":
			return {
				...state,
				fatchDatas: {...state.fatchDatas, mainArticulesId: action.payload},
			}
		case "LOADING":
			return {
				...state,
				tables: {
					...state.tables,
					[action.index]: {
						loading: true,
						perPage: 50,
						page: 1,
						// ids: [],
						ids: {all: [], nons: [], yeses: []},
						chacked: {all: [], nons: [], yeses: []},
						// chacked: [],
						ordering: "-frequency",
						all: [],
						nons: [],
						yeses: [],
						total: 0,
					},
				},
			}
		case "LOADED":
			return {
				...state,
				tables: {
					...state.tables,
					[action.index]: {
						...state.tables[action.index],
						loading: false,
					},
				},
			}
		case `TIPS_BY_WB_SEARCH_ID_${action.index}`:
			return {
				...state,
				fatchDatas: {...state.fatchDatas, [action.index]: action.payload},
			}

		default:
			return state
	}
}
