import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	productId: null,
}

const keysCheckProductsSlice = createSlice({
	name: "keysCheckProducts",
	initialState,
	reducers: {
		getKeysCheckProducts(state, {payload}) {
			state.productId = payload.productId
		},
	},
})

export const {getKeysCheckProducts} = keysCheckProductsSlice.actions

export default keysCheckProductsSlice.reducer
