import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M20.6291 7.98817C20.4217 7.21073 19.8137 6.59789 19.0427 6.38855C17.6341 6 11.9998 6 11.9998 6C11.9998 6 6.36567 6 4.95711 6.37382C4.20092 6.58294 3.5781 7.21084 3.37068 7.98817C3 9.40813 3 12.3529 3 12.3529C3 12.3529 3 15.3126 3.37068 16.7177C3.57832 17.495 4.18609 18.1079 4.95722 18.3172C6.3805 18.7059 12 18.7059 12 18.7059C12 18.7059 17.6341 18.7059 19.0427 18.3321C19.8138 18.1228 20.4217 17.51 20.6294 16.7327C20.9999 15.3126 20.9999 12.3679 20.9999 12.3679C20.9999 12.3679 21.0148 9.40813 20.6291 7.98817ZM10.2059 15.0735V9.63242L14.8912 12.3529L10.2059 15.0735Z" />
	</Template>
))
