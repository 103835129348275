import { createAsyncThunk } from "@reduxjs/toolkit";

import API from "root/js/api";

const WBAPI = new API();

export const Getaccounts = createAsyncThunk("get/account/ecconomic", async () => {
  try {
    const response = await WBAPI.getCompanyApiKey();
    return response;
  } catch (e) {
    console.log(e.message);
  }
});

export const GetTableEconomic = createAsyncThunk(
  "table/ecconomic",
  async ({ dateTo, dateFrom, keys, group_by, sort, q, limit, offset }) => {
    const joinKeys = keys.join()
    try {
      const response = await WBAPI.tableEconomic({ dateTo, dateFrom, joinKeys, group_by, sort, q, limit, offset });

      return response;
    } catch (e) {
      console.log(e.message);
    }
  }
);
