import { createSlice } from "@reduxjs/toolkit"
import { equals, length, prop, propOr } from "ramda"

import { getProductByPeriod } from "./actions"

import { defaultToArr } from "root/js/utils"

const initialState = {
	isLoading: false,
	data: {
		count: 0,
		results: [],
	},
	dynamics: [],
	selected: [],
	columns: [],
	dates: [],
	checkeds: [],
	error: null,
	extra_fields: "id,product,proceeds_dynamic,orders_dynamic,price_dynamic,auto_adv",
}

export const searchAnalyticsSlice = createSlice({
	name: "searchAnalyticsSlice",
	initialState,
	reducers: {},
	extraReducers: builder => {
		// !------------getPageChange
		builder.addCase(getProductByPeriod.pending, state => {
			state.isLoading = true
		})
		builder.addCase(getProductByPeriod.fulfilled, (state, { payload }) => {
			const dates = propOr([], "dynamic", prop(0, prop("results", payload))).sort(
				(a, b) => new Date(a) - new Date(b)
			)
			let arrayWithDynamics = []
			const array = propOr([], "results", payload)

			for (let i = 0; i < length(array); i++) {
				const { dynamic } = propOr({}, i, array)
				for (let z = 0; z < length(defaultToArr(dynamic)); z++) {
					const { date, price, proceeds, orders } = propOr({}, z, dynamic)

					const ordersDates = arrayWithDynamics.map(({ date }) => date)
					if (ordersDates.includes(date)) {
						arrayWithDynamics.map(item => {
							if (prop("date", item) && equals(date, prop("date", item))) {
								return {
									...item,
									orders: orders + item.orders,
									price: price + item.price,
									proceeds: proceeds + item.proceeds,
								}
							}

							return item
						})
					} else {
						arrayWithDynamics.push({ date, orders, proceeds, price })
					}
				}
			}

			state.columns = dates.map(({ date }) => date)
			state.dates = dates
			state.dynamics = arrayWithDynamics

			state.isLoading = false
		})
		builder.addCase(getProductByPeriod.rejected, state => {
			state.isLoading = false
			state.error = "Error"
		})
	},
})

export default searchAnalyticsSlice.reducer
