import {createAsyncThunk} from "@reduxjs/toolkit"

import API from "root/js/api"

const WBAPI = new API()

export const getGroupingWords = createAsyncThunk("/getGroupingWords", async (reqData, thunkAPI) => {
	try {
		const response = await WBAPI.getGrouping({
			object: reqData.body,
			limit: reqData.limit,
			offset: reqData.offset,
		})
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error getting grouping words...")
	}
})

export const getAllGroupingWords = createAsyncThunk(
	"/getAllGroupingWords",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getGroupingWordNames({
				body: reqData.body,
				limit: reqData.limit,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting all grouping words...")
		}
	}
)

export const getGroupingKeywords = createAsyncThunk(
	"/getGroupingKeywords",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getGroupingKeywords({
				body: reqData.body,
				limit: reqData.limit,
				offset: reqData.offset,
				extra_fields: reqData.extra_fields,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting grouping keywords...")
		}
	}
)

export const getAllGroupingKeywords = createAsyncThunk(
	"/getAllGroupingKeywords",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getGroupingKeywords({
				body: reqData.body,
				limit: reqData.limit,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting all grouping keywords...")
		}
	}
)

export const downloadGroupingExcel = createAsyncThunk(
	"/downloadGroupingExcel",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.exportGroupingExcel({
				object: reqData.body,
				// limit: reqData.limit,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error downloading file...")
		}
	}
)
