import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	formState: null,
	amount: 0,
	brand: {},
}

const brandAnalyzerSlice = createSlice({
	name: "brandAnalyzer",
	initialState,
	reducers: {
		setPrevForm(state, {payload}) {
			state.formState = payload.formState
			state.amount = payload.amount
			state.brand = payload.brand
		},
	},
})

export const {setPrevForm} = brandAnalyzerSlice.actions

export default brandAnalyzerSlice.reducer
