import { createSlice } from "@reduxjs/toolkit"
import { propOr } from "ramda"

import {
	createSPPList,
	deleteSPPItems,
	getSPPItemNotification,
	getSPPList,
	getSPPNotifications,
	setSPPList,
} from "./actions"

const initialState = {
	sppListIsLoading: false,
	sppList: [],
	sppListCount: null,
	sppListError: null,

	sppNotificationsIsLoading: false,
	sppNotifications: [],
	sppNotificationsError: null,

	sppItemNotificationIsLoading: false,
	sppItemNotification: [],
	sppItemNotificationError: null,

	setSppListInProcess: false,
	setSppListSuccess: false,
	setSppListError: null,

	deleteSPPItemsInProcess: false,
	deleteSPPItemsSuccess: false,
	deleteSPPItemsError: null,
}

const spp = createSlice({
	name: "spp",
	initialState,
	reducers: {},
	extraReducers: builder => {
		// get list
		builder.addCase(getSPPList.pending, state => {
			state.sppListIsLoading = true
		})
		builder.addCase(getSPPList.fulfilled, (state, { payload }) => {
			state.sppListIsLoading = false
			state.sppListCount = payload.count
			state.sppList = propOr([], "results", payload).map(item => ({
				...item,
				articule: {
					image: item.image,
					articule: item.product_id,
				},
				spp_dynamic: item.dynamic.map(i => ({
					y: i.spp,
					date: i.create_date,
					max: i.max,
				})),
				current_spp: {
					max: item.max,
					value: item.current_spp,
				},
			}))
			state.sppListError = null
		})
		builder.addCase(getSPPList.rejected, state => {
			state.sppListIsLoading = false
			state.sppListError = "Spp list error..."
		})
		// get all notifications
		builder.addCase(getSPPNotifications.pending, state => {
			state.sppNotificationsIsLoading = true
		})
		builder.addCase(getSPPNotifications.fulfilled, (state, { payload }) => {
			state.sppNotificationsIsLoading = false
			state.sppNotifications = payload.results
			state.sppNotificationsError = null
		})
		builder.addCase(getSPPNotifications.rejected, state => {
			state.sppNotificationsIsLoading = false
			state.sppNotificationsError = "Spp notifications error..."
		})
		// get item notification
		builder.addCase(getSPPItemNotification.pending, state => {
			state.sppItemNotificationIsLoading = true
		})
		builder.addCase(getSPPItemNotification.fulfilled, (state, { payload }) => {
			state.sppItemNotificationIsLoading = false
			state.sppItemNotification = payload.results[0]
			state.sppItemNotificationError = null
		})
		builder.addCase(getSPPItemNotification.rejected, state => {
			state.sppItemNotificationIsLoading = false
			state.sppItemNotificationError = "Spp item error..."
		})
		// set bulk
		builder.addCase(setSPPList.pending, state => {
			state.setSppListInProcess = true
			state.setSppListSuccess = false
		})
		builder.addCase(setSPPList.fulfilled, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = true
			state.setSppListError = null
		})
		builder.addCase(setSPPList.rejected, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = false
			state.setSppListError = "Set spp list error..."
		})
		// set default
		builder.addCase(createSPPList.pending, state => {
			state.setSppListInProcess = true
			state.setSppListSuccess = false
		})
		builder.addCase(createSPPList.fulfilled, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = true
			state.setSppListError = null
		})
		builder.addCase(createSPPList.rejected, state => {
			state.setSppListInProcess = false
			state.setSppListSuccess = false
			state.setSppListError = "Set spp list error..."
		})
		// delete
		builder.addCase(deleteSPPItems.pending, state => {
			state.deleteSPPItemsInProcess = true
			state.deleteSPPItemsSuccess = false
		})
		builder.addCase(deleteSPPItems.fulfilled, state => {
			state.deleteSPPItemsInProcess = false
			state.deleteSPPItemsSuccess = true
			state.deleteSPPItemsError = null
		})
		builder.addCase(deleteSPPItems.rejected, state => {
			state.deleteSPPItemsInProcess = false
			state.deleteSPPItemsSuccess = false
			state.deleteSPPItemsError = "Delete spp items error..."
		})
	},
})

export const sppActions = {
	...spp.actions,
	getSPPList,
	getSPPNotifications,
	getSPPItemNotification,
	setSPPList,
	createSPPList,
	deleteSPPItems,
}

export default spp.reducer
