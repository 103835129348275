import { createSlice } from "@reduxjs/toolkit";

import { GetCompanyWBCabinets } from "./action";

const initialState = {
  apiKeyName: [],
  cabinetId: 0
};

const settingsDebriefingSlice = createSlice({
  name: "settings/debriefing",
  initialState,
  reducers: {
    getCabinetId: (state, action) => {
        const {id} = action.payload
      
      state.cabinetId = id;
    }
  },
  extraReducers: bulider => {
    bulider.addCase(GetCompanyWBCabinets.fulfilled, (state, action) => {
      state.apiKeyName = action.payload;
    });
  }
});

export const { getCabinetId } = settingsDebriefingSlice.actions;
export const settingsDebriefingReducer = settingsDebriefingSlice.reducer;
