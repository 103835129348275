import {createAsyncThunk} from "@reduxjs/toolkit"

import API from "root/js/api"

const WBAPI = new API()

export const getMarks = createAsyncThunk(
	"/getMarks",
	async ({type, itemId, dateFrom, dateTo}, thunkAPI) => {
		try {
			const response = await WBAPI.getMarks({
				type,
				itemId,
				dateFrom,
				dateTo,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching marks...")
		}
	}
)

export const getSeasonalityMarks = createAsyncThunk(
	"/getSeasonalityMarks",
	async ({type, itemId, dateFrom, dateTo}, thunkAPI) => {
		try {
			const response = await WBAPI.getMarks({
				type,
				itemId,
				dateFrom,
				dateTo,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error fetching marks...")
		}
	}
)

export const setMark = createAsyncThunk("/setMark", async ({type, item}, thunkAPI) => {
	try {
		const response = await WBAPI.setMark({type, item})

		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error setting mark...")
	}
})

export const deleteMark = createAsyncThunk("/deleteMark", async ({type, deleteId}, thunkAPI) => {
	try {
		const response = await WBAPI.deleteMark({type, deleteId})

		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error deleting mark...")
	}
})
