import { createSlice } from "@reduxjs/toolkit"

import { getMyPartialPurchases, getPartialPurchases, setAverts } from "./actions"

const initialState = {
	partialPurchase: {},
	isLoading: false,
	error: null,
	myPartialPurchase: {
		data: {},
		isLoading: false,
		error: null,
	},


}

export const partialPurchaseSlice = createSlice({
	name: "partialPurchaseSlice",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getPartialPurchases.pending, state => {
			state.isLoading = true
		})
		builder.addCase(getPartialPurchases.fulfilled, (state, { payload }) => {
			state.partialPurchase = payload
			state.isLoading = false
		})
		builder.addCase(getPartialPurchases.rejected, state => {
			state.error = "Error"
			state.isLoading = false
		})

		builder.addCase(getMyPartialPurchases.pending, state => {
			state.myPartialPurchase.isLoading = true
		})
		builder.addCase(getMyPartialPurchases.fulfilled, (state, { payload }) => {
			state.myPartialPurchase.data = payload
			state.myPartialPurchase.isLoading = false
		})
		builder.addCase(getMyPartialPurchases.rejected, state => {
			state.myPartialPurchase.error = "Error"
			state.myPartialPurchase.isLoading = false
		})


	}
})

export default partialPurchaseSlice.reducer
