import React from "react"

import classnames from "classnames"
import {Link} from "react-router-dom"

import styles from "./index.module.scss"

const Link_ = props => {
	let {underline, children, className, to, ...rest} = props
	return to ? (
		<Link
			to={to}
			data-underline={underline}
			className={classnames(styles.root, className)}
			{...rest}
		>
			{children}
		</Link>
	) : (
		<span
			className={classnames(styles.root, className)}
			data-underline={underline}
			{...rest}
		>
			{children}
		</span>
	)
}

export default Link_
