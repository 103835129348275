export default {
	isAuth: "isAuth",
	getToken: "getToken",
	resetToken: "resetToken",
	setToken: "setToken",
	signIn: "signIn",
	signUp: "signUp",
	sendSms: "sendSms",
	refreshToken: "refreshToken",
	resendConfirmation: "resendConfirmation",
	socialSignUp: "socialSignUp",
	socialSignIn: "socialSignIn",
	resetPassword: "resetPassword",
	confirmResetPassword: "confirmResetPassword",
	getBrandInfo: "getBrandInfo",
	getBrandCategories: "getBrandCategories",
	getBrandGraph: "getBrandGraph",
	searchBrands: "searchBrands",
	searchCategories: "searchCategories",
	getBrandSaledByCategories: "getBrandSaledByCategories",
	getBrandSaledByOther: "getBrandSaledByOther",
	getBrandProducts: "getBrandProducts",
	getBrandStocks: "getBrandStocks",
	getProductInfo: "getProductInfo",
	getProductInfoByProduct_ids: "getProductInfoByProduct_ids",
	getProductGraph: "getProductGraph",
	getProductPositions: "getProductPositions",
	getProductSales: "getProductSales",
	getProductQuanity: "getProductQuanity",
	getRegions: "getRegions",
	exportReportFilter: "exportReportFilter",
	getFilteredItems: "getFilteredItems",
	getBrandsList: "getBrandsList",
	getSummary: "getSummary",
	getOrders: "getOrders",
	getSales: "getSales",
	getReturns: "getReturns",
	getItemsPopular: "getItemsPopular",
	getItemsOld: "getItemsOld",
	getItemsRansom: "getItemsRansom",
	getItemsLost: "getItemsLost",
	getExportPopularItems: "getExportPopularItems",
	getExportOldItems: "getExportOldItems",
	getExportRansomItems: "getExportRansomItems",
	getExportLostItems: "getExportLostItems",
	getParsersBrands: "getParsersBrands",
	getBrandsDynamic: "getBrandsDynamic",
	exportBrandsDynamic: "exportBrandsDynamic",
	exportBrandsDynamicById: "exportBrandsDynamicById",
	getGeneralCategoriesDynamic: "getGeneralCategoriesDynamic",
	exportGeneralCategoriesDynamic: "exportGeneralCategoriesDynamic",
	exportGeneralCategoriesDynamicById: "exportGeneralCategoriesDynamicById",
	getProducts: "getProducts",
	getProfile: "getProfile",
	updateProfile: "updateProfile",
	getApiKeys: "getApiKeys",
	createApiKey: "createApiKey",
	updateApiKey: "updateApiKey",
	deleteApiKey: "deleteApiKey",
	getCompanyUsers: "getCompanyUsers",
	createCompanyUser: "createCompanyUser",
	updateCompanyUser: "updateCompanyUser",
	deleteCompanyUser: "deleteCompanyUser",
	getOperationReport: "getOperationReport",
	getReportByDates: "getReportByDates",
	exportReportByDates: "exportReportByDates",
	exportReportByItems: "exportReportByItems",
	getOperationReportPaidIncome: "getOperationReportPaidIncome",
	getReportByItems: "getReportByItems",
	getCategories: "getCategories",
	getBrands: "getBrands",
	exportReportByCategory: "exportReportByCategory",
	getAnalyticsOrdersByCategory: "getAnalyticsOrdersByCategory",
	getExternalCategoriesTreeById: "getExternalCategoriesTreeById",
	getItemsFilter: "getItemsFilter",
	getAnalyticsSavedFilters: "getAnalyticsSavedFilters",
	exportReportOrdersBySize: "exportReportOrdersBySize",
	saveAnalyticsFilter: "saveAnalyticsFilter",
	renameAnalyticsSavedFilters: "renameAnalyticsSavedFilters",
	deleteAnalyticsSavedFilters: "deleteAnalyticsSavedFilters",
	getAnalyticsOrdersBySize: "getAnalyticsOrdersBySize",
	exportReportOrdersByWarehouse: "exportReportOrdersByWarehouse",
	getAnalyticsOrdersByWarehouse: "getAnalyticsOrdersByWarehouse",
	exportReportOrdersByRegion: "exportReportOrdersByRegion",
	getAnalyticsOrdersByRegion: "getAnalyticsOrdersByRegion",
	getAnalyticsOrdersBy_Warehouse: "getAnalyticsOrdersBy_Warehouse",
	getAnalyticsOrdersByColors: "getAnalyticsOrdersByColors",
	getAnalyticsOrdersBy_Region: "getAnalyticsOrdersBy_Region",
	exportReportOrdersByColors: "exportReportOrdersByColors",
	getColors: "getColors",
	getCatalogs: "getCatalogs",
	getProductById: "getProductById",
	getProductRelative: "getProductRelative",
	getProductColorDynamic: "getProductColorDynamic",
	exportProductColorDynamic: "exportProductColorDynamic",
	getSimilarProducts: "getSimilarProducts",
	getSimilarProductsScope: "getSimilarProductsScope",
	exportSimilarProducts: "exportSimilarProducts",
	getAlsoBuyProducts: "getAlsoBuyProducts",
	getAlsoBuyProductsScope: "getAlsoBuyProductsScope",
	exportAlsoBuyProducts: "exportAlsoBuyProducts",
	getSearchTagsProducts: "getSearchTagsProducts",
	getBrandCard: "getBrandCard",
	getBrandCategoriesSeo: "getBrandCategoriesSeo",
	getBrandWarehousesDynamic: "getBrandWarehousesDynamic",
	getParsersWarehouses: "getParsersWarehouses",
	getBrandWarehousesById: "getBrandWarehousesById",
	searchBrandsByName: "searchBrandsByName",
	getCategoriesDynamic: "getCategoriesDynamic",
	exportCategoriesDynamic: "exportCategoriesDynamic",
	exportCategoriesDynamicById: "exportCategoriesDynamicById",
	getCategoriesDynamicById: "getCategoriesDynamicById",
	getParsersCategories: "getParsersCategories",
	getParsersCategoriesExact: "getParsersCategoriesExact",
	getParsersCategoriesSearch: "getParsersCategoriesSearch",
	getProductPositionDynamic: "getProductPositionDynamic",
	getAnalyticsCollections: "getAnalyticsCollections",
	getAnalyticsExactCollection: "getAnalyticsExactCollection",
	createAnalyticsCollection: "createAnalyticsCollection",
	deleteAnalyticsCollection: "deleteAnalyticsCollection",
	removeProductsAnalyticsCollection: "removeProductsAnalyticsCollection",
	removeAllProductsAnalyticsCollection: "removeAllProductsAnalyticsCollection",
	addProductsAnalyticsCollection: "addProductsAnalyticsCollection",
	updateAnalyticsCollection: "updateAnalyticsCollection",
	getProductsColorSizeDynamic: "getProductsColorSizeDynamic",
	exportProductsColorSizeDynamic: "exportProductsColorSizeDynamic",
	getProductsColorWarehouseDynamic: "getProductsColorWarehouseDynamic",
	exportProductsColorWarehouseDynamic: "exportProductsColorWarehouseDynamic",
	getWarehousesDynamic: "getWarehousesDynamic",
	getProductsDynamic: "getProductsDynamic",
	exportProductsDynamic: "exportProductsDynamic",
	exportProductsDynamicById: "exportProductsDynamicById",
	getParsersCollections: "getParsersCollections",
	createParsersCollection: "createParsersCollection",
	deleteParsersCollection: "deleteParsersCollection",
	addProductsToParsersCollection: "addProductsToParsersCollection",
	getBrandsSummary: "getBrandsSummary",
	exportBrandsSummary: "exportBrandsSummary",
	getProductsSummary: "getProductsSummary",
	getUserData: "getUserData",
	getUserDataById: "getUserDataById",
	saveUserData: "saveUserData",
	patchUserData: "patchUserData",
	deleteUserData: "deleteUserData",
	getBrandColorGroupDynamic: "getBrandColorGroupDynamic",
	getBrandSizeDynamic: "getBrandSizeDynamic",
	exportBrandColorGroupDynamic: "exportBrandColorGroupDynamic",
	exportBrandSizeDynamic: "exportBrandSizeDynamic",
	exportWarehouseDynamic: "exportWarehouseDynamic",
	getParsersSellers: "getParsersSellers",
	getSellerById: "getSellerById",
	getSellersSummary: "getSellersSummary",
	getSellerColorGroupDynamic: "getSellerColorGroupDynamic",
	getCategoriesSummary: "getCategoriesSummary",
	getSellersSummaryScope: "getSellersSummaryScope",
	getExactSellersSummary: "getExactSellersSummary",
	exportSellersSummary: "exportSellersSummary",
	exportSellerDynamic: "exportSellerDynamic",
	getSellersDynamic: "getSellersDynamic",
	exportSellersDynamic: "exportSellersDynamic",
	exportSellerDynamicById: "exportSellerDynamicById",
	getSellerCategoriesDynamic: "getSellerCategoriesDynamic",
	getProductsDynamicScope: "getProductsDynamicScope",
	getBrandDynamicScope: "getBrandDynamicScope",
	getProductDynamicScope: "getProductDynamicScope",
	getCategoriesDynamicScope: "getCategoriesDynamicScope",
	getBrandColorGroupDynamicScope: "getBrandColorGroupDynamicScope",
	getBrandSizeDynamicScope: "getBrandSizeDynamicScope",
	getProductHistoryDynamic: "getProductHistoryDynamic",
	getProductColorDynamicScope: "getProductColorDynamicScope",
	getProductsColorSizeDynamicScope: "getProductsColorSizeDynamicScope",
	getProductsColorWarehouseDynamicScope: "getProductsColorWarehouseDynamicScope",
	getProductPositionDynamicScope: "getProductPositionDynamicScope",
	searchProductById: "searchProductById",
	SearchBrandByAlias: "SearchBrandByAlias",
	searchSellersByName: "searchSellersByName",
	getProductWarehouseDynamic: "getProductWarehouseDynamic",
	getProductWarehouseDynamicScope: "getProductWarehouseDynamicScope",
	exportSellerCategoriesDynamic: "exportSellerCategoriesDynamic",
	getSellersDynamicScope: "getSellersDynamicScope",
	getKeywords: "getKeywords",
	exportKeywords: "exportKeywords",
	getPoritions: "getPoritions",
	getBanners: "getBanners",
	getCompanyTariff: "getCompanyTariff",
	getProductKeywords: "getProductKeywords",
	getDinamicKeywordsFrazes: "getDinamicKeywordsFrazes",
	getProductKeywordsStat: "getProductKeywordsStat",
	getTariffs: "getTariffs",
	getTransactions: "getTransactions",
	getTransactionsById: "getTransactionsById",
	getPaymentFormData: "getPaymentFormData",
	buyTariff: "buyTariff",
	getProductKeywordsByProductId: "getProductKeywordsByProductId",
	getProductFavouritesDynamic: "getProductFavouritesDynamic",
	getProductKeywordGeolocationsByArticule: "getProductKeywordGeolocationsByArticule",
	getProductFavouritesDynamicTop: "getProductFavouritesDynamicTop",
	deleteKeywordsToProduct: "deleteKeywordsToProduct",
	deleteProductKeywordPositions: "deleteProductKeywordPositions",
	addKeywordsToProduct: "addKeywordsToProduct",
	getTopKeywordsDynamic: "getTopKeywordsDynamic",
	getSizesBySizeLocations: "getSizesBySizeLocations",
	getGeolocations: "getProductKeywordGeolocations",
	getProductFrequencySubjectIds: "getProductFrequencySubjectIds",
	getProductFrequencyBrands: "getProductFrequencyBrands",
	getProductFrequencySellers: "getProductFrequencySellers",
	getSubjectIds: "getSubjectIds",
	getSupplierArticleAdvertsUsageHistory: "getSupplierArticleAdvertsUsageHistory",
	getCompanyWB: "getCompanyWB",
	getSppAnalyticsPrices: "getSppAnalyticsPrices",
	getAnalyticsPricesSubjects: "getAnalyticsPricesSubjects",
	getGroupsKeywordsCluster: "getGroupsKeywordsCluster",
	getKeywordsStatSubjects: "getKeywordsStatSubjects",
	getKeywordsSummarySubjects: "getKeywordsSummarySubjects",
	getProductsDynamicAnalysis: "getProductsDynamicAnalysis",
	getGroupedProductDynamic: "getGroupedProductDynamic",
	getPriceSteps: "getPriceSteps",
	getProductsTrendGrouped: "getProductsTrendGrouped",
	getWareHouseLogistic: "getWareHouseLogistic",
	getProductsTrend: "getProductsTrend",
	getParsersCollectionsProducts: "getParsersCollectionsProducts",
	getBrandsTrend: "getBrandsTrend",
	getGroupedBrandsTrend: "getGroupedBrandsTrend",
	getExactParsersCollectionsKeywords: "getExactParsersCollectionsKeywords",
	getSellersTrend: "getSellersTrend",
	getCategoriesTrend: "getCategoriesTrend",
  getKeywordStatByPeriod: "getKeywordStatByPeriod",
  getKeywordsStatByIdDaily: "getKeywordsStatByIdDaily",
	getMonitoringTraffics: "getMonitoringTraffics",
	getMonitoringTrafficsPublicationDynamic: "getMonitoringTrafficsPublicationDynamic",
	getMonitoringTrafficsLimits: "getMonitoringTrafficsLimits",
	getTrafficsTariffPlan: "getTrafficsTariffPlan",
}
