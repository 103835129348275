import { equals } from "ramda";

// import Loading from "root/pages/current-search-analytics/components/FormedList/Loading"

const initialState = {
  tab: 0,
  tabs: [
    { tabType: "nicheOverview", title: "Обзор ниши", tab: 0 },
    { tabType: "bydays", title: "По дням", tab: 1 },
    { tabType: "products", title: "Товары", tab: 2 },
    { tabType: "brands", title: "Бренды", tab: 3 },
    { tabType: "sellers", title: "Поставщики", tab: 4 },
    { tabType: "category", title: "Категории", tab: 5 },
    { tabType: "semantic", title: "Ценовая сегментация", tab: 6 },
    { tabType: "warehouse", title: "По складам", tab: 7 }
    // {tabType: "productsWithPeriod", title: "Продукты с периодом", tab: 6},
  ],
  // item: {
  // 	component: Loading,
  // },
  nicheOverviewGraficDynamic: {
    data: [],
    status: "success"
  },
  nicheOverview: {
    commonData: {
      title: "Общие данные",
      // requestItems: {
      //   title: "Артикулов по запросу",
      //   value: "0 шт",
      //   loading: true
      // },
      monopoly: {
        title: "Монопольность",
        value: "0%",
        loading: true
      }
    },
    dataTop_100: {
      // title: "Данные по топ-100 за 30 дней:",
      title: "Данные по топ-100 товарам за период:",
      // apk: {
      //   title: "Доля APK",
      //   value: 0,
      //   loading: true
      // },
      typeB: {
        title: `Реклама "Бустер"`,
        value: 0,
        loading: true
      },
      typeC: {
        title: `Реклама "Поиск"`,
        value: 0,
        loading: true
      },
      news: {
        title: "Новинок",
        value: 0,
        loading: true
      },
      brands: {
        title: "Брендов",
        value: 0,
        loading: true
      },
      sellers: {
        title: "Поставщиков",
        value: 0,
        loading: true
      },
      revenue: {
        title: "Выручка",
        value: 0,
        loading: true
      },
      books: {
        title: "Заказов",
        value: 0,
        loading: true
      },
      lostRevenue: {
        title: "Упущенная выручка",
        value: 0,
        loading: true
      },
      averageRevenuePerArticle: {
        title: "Средняя выручка на артикул",
        value: 0,
        loading: true
      },
      // averageBuybackPercent: {
      // 	title: "Средний % выкупа",
      // 	value: 0,
      // 	loading: true,
      // },
      topAveragePrice: {
        title: "Средняя цена топа",
        value: "",
        loading: true
      }
    },
    revenueTop_5: {
      title: "Выручка у топ-5 брендов:",
      data: [],
      loading: true
    },
    top_1: {
      title: "Топ 1 артикул по выручке",
      articule: {
        title: "Артикул",
        value: "",
        loading: true
      },
      brand: {
        title: "Бренд",
        value: "",
        loading: true
      },
      seller: {
        title: "Поставщик",
        value: "",
        loading: true
      },
      revenue: {
        title: "Выручка за период",
        value: 0,
        loading: true
      }
    },
    top_20: {
      title: "Топ 20 артикул по выручке",
      articule: {
        title: "Артикул",
        value: "",
        loading: true
      },
      brand: {
        title: "Бренд",
        value: "",
        loading: true
      },
      seller: {
        title: "Поставщик",
        value: "",
        loading: true
      },
      revenue: {
        title: "Выручка за период",
        value: 0,
        loading: true
      }
    },
    top_50: {
      title: "Топ 50 артикул по выручке",
      articule: {
        title: "Артикул",
        value: "",
        loading: true
      },
      brand: {
        title: "Бренд",
        value: "",
        loading: true
      },
      seller: {
        title: "Поставщик",
        value: "",
        loading: true
      },
      revenue: {
        title: "Выручка за период",
        value: 0,
        loading: true
      }
    }
  },
  products: {
    table: [],
    count: 0,
    selecred: [],
    extrafilds: [],
    ordering: "product_ids",
    loading: false,
    isExporting: false,
    showingColumns: [
      "id",
      "product",
      "auto_adv",
      "cpm",
      "brand",
      "seller",
      "create_date",
      "price",
      "discount",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "auto_adv",
      // "sales_percent",
      "lost_proceeds",
      "lost_orders",
      "proceeds_percentage"
      // "subject"
    ]
  },
  brands: {
    table: [],
    count: 0,
    selecred: [],
    extrafilds: [],
    ordering: "-proceeds",
    loading: false,
    isExporting: false,
    showingColumns: [
      "id",
      "brand",
      "sellers",
      "price",
      "discount",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      // "sales_proceeds",
      "quantity",
      "lost_proceeds",
      "proceeds_percentage"
    ]
  },
  sellers: {
    table: [],
    count: 0,
    selecred: [],
    extrafilds: [],
    ordering: "-proceeds",
    loading: false,
    isExporting: false,
    showingColumns: [
      "id",
      "seller",
      "orders_share",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      // "sales_percent",
      "vendor_codes",
      "brands",
      "price",
      "discount",
      "lost_proceeds",
      "proceeds_percentage"
    ]
  },
  category: {
    table: [],
    count: 0,
    selecred: [],
    extrafilds: [],
    ordering: "-proceeds",
    loading: false,
    isExporting: false,
    showingColumns: [
      "id",
      "category_name",
      "vendor_codes",
      "product_with_orders",
      "sellers_count",
      "sellers_count_dynamic",
      "price",
      "discount",
      // "sales_percent",
      "proceeds",
      "orders_share",
      "proceeds_dynamic",
      "orders",
      "quantity",
      "lost_proceeds",
      "brands_count",
      "proceeds_percentage"
    ]
  }
};

export const searchAnalyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "EXPORTING_PRODUCT":
      return {
        ...state,
        products: {
          ...state.products,
          isExporting: true
        }
      };
    case "EXPORTING_PRODUCT_SUCCESS":
      return {
        ...state,
        products: {
          ...state.products,
          isExporting: false
        }
      };
    case "EXPORTING_PRODUCT_FAILUR":
      return {
        ...state,
        products: {
          ...state.products,
          isExporting: false
        }
      };
    case "EXPORTING_BRANDS":
      return {
        ...state,
        brands: {
          ...state.brands,
          isExporting: true
        }
      };
    case "EXPORTING_BRANDS_SUCCESS":
      return {
        ...state,
        brands: {
          ...state.brands,
          isExporting: false
        }
      };
    case "EXPORTING_BRANDS_FAILUR":
      return {
        ...state,
        brands: {
          ...state.brands,
          isExporting: false
        }
      };
    case "EXPORTING_SELLERS":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          isExporting: true
        }
      };
    case "EXPORTING_SELLERS_SUCCESS":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          isExporting: false
        }
      };
    case "EXPORTING_SELLERS_FAILUR":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          isExporting: false
        }
      };
    case "EXPORTING_CATEGORY":
      return {
        ...state,
        category: {
          ...state.category,
          isExporting: true
        }
      };
    case "EXPORTING_CATEGORY_SUCCESS":
      return {
        ...state,
        category: {
          ...state.category,
          isExporting: false
        }
      };
    case "EXPORTING_CATEGORY_FAILUR":
      return {
        ...state,
        category: {
          ...state.category,
          isExporting: false
        }
      };
    case "GET_COMMON_DATA_REQUEST":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          commonData: {
            ...state.nicheOverview.commonData
            // requestItems: {
            //   ...state.nicheOverview.commonData.requestItems,
            //   loading: true
            // }
          }
        }
      };
    case "PRODUCTS_COLUMN_SETTINGS":
      return {
        ...state,
        products: {
          ...state.products,
          showingColumns: action.payload
        }
      };
    case "CATEGORY_COLUMN_SETTINGS":
      return {
        ...state,
        category: {
          ...state.category,
          showingColumns: action.payload
        }
      };
    case "CATEGORY_ORDERING":
      return {
        ...state,
        category: {
          ...state.category,
          ordering: action.payload
        }
      };
    case "PRODUCTS_ORDERING":
      return {
        ...state,
        products: {
          ...state.products,
          ordering: action.payload
        }
      };
    case "BRANDS_ORDERING":
      return {
        ...state,
        brands: {
          ...state.brands,
          ordering: action.payload
        }
      };
    case "SELLERS_ORDERING":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          ordering: action.payload
        }
      };
    case "BRANDS_COLUMN_SETTINGS":
      return {
        ...state,
        brands: {
          ...state.brands,
          showingColumns: action.payload
        }
      };
    case "SELLERS_COLUMN_SETTINGS":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          showingColumns: action.payload
        }
      };
    case "PRODUCTS_ADD_MONITORING":
      return {
        ...state,
        products: {
          ...state.products,
          selecred: state.products.selecred.includes(action.payload)
            ? state.products.selecred.filter(item => item !== action.payload)
            : [...state.products.selecred, action.payload]
        }
      };
    case "CATEGORY_ADD_MONITORING":
      return {
        ...state,
        category: {
          ...state.category,
          selecred: state.category.selecred.includes(action.payload)
            ? state.category.selecred.filter(item => item !== action.payload)
            : [...state.category.selecred, action.payload]
        }
      };
    case "BRANDS_ADD_MONITORING":
      return {
        ...state,
        brands: {
          ...state.brands,
          selecred: state.brands.selecred.includes(action.payload)
            ? state.brands.selecred.filter(item => item !== action.payload)
            : [...state.brands.selecred, action.payload]
        }
      };
    case "PRODUCTS_ADD_ALL_MONITORING":
      return {
        ...state,
        products: {
          ...state.products,
          selecred: equals(state.products.selecred, action.payload) ? [] : action.payload
        }
      };
    case "CATEGORY_ADD_ALL_MONITORING":
      return {
        ...state,
        category: {
          ...state.category,
          selecred: equals(state.category.selecred, action.payload) ? [] : action.payload
        }
      };
    case "SELLERS_ADD_MONITORING":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          selecred: state.sellers.selecred.includes(action.payload)
            ? state.sellers.selecred.filter(item => item !== action.payload)
            : [...state.sellers.selecred, action.payload]
        }
      };
    case "SELLERS_ADD_ALL_MONITORING":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          selecred: equals(state.sellers.selecred, action.payload) ? [] : action.payload
        }
      };
    case "PRODUCTS_MONITORING_CLEAR":
      return {
        ...state,
        products: {
          ...state.products,
          selecred: []
        }
      };
    case "CATEGORY_MONITORING_CLEAR":
      return {
        ...state,
        category: {
          ...state.category,
          selecred: []
        }
      };
    case "BRANDS_ADD_ALL_MONITORING":
      return {
        ...state,
        brands: {
          ...state.brands,
          selecred: equals(state.brands.selecred, action.payload) ? [] : action.payload
        }
      };
    case "BRANDS_MONITORING_CLEAR":
      return {
        ...state,
        brands: {
          ...state.brands,
          selecred: []
        }
      };
    case "SELLERS_MONITORING_CLEAR":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          selecred: []
        }
      };
    case "GET_REVENUE_TOP_5":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          revenueTop_5: {
            ...state.nicheOverview.revenueTop_5,
            data: action.data,
            title: action.title,
            loading: false
          }
        }
      };
    case "REVENUE_TOP_5_LOADING":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          revenueTop_5: {
            ...state.nicheOverview.revenueTop_5,
            loading: true
          }
        }
      };

    case "GET_COMMON_DATA_VALUE":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          commonData: {
            ...state.nicheOverview.commonData
            // requestItems: {
            //   ...state.nicheOverview.commonData.requestItems,
            //   value: action.payload,
            //   loading: false
            // }
          }
        }
      };
    case "GET_MONOPOLY_REQUEST":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          commonData: {
            ...state.nicheOverview.commonData,
            monopoly: {
              ...state.nicheOverview.commonData.monopoly,
              loading: true
            }
          }
        }
      };
    case "GET_MONOPOLY_VALUE":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          commonData: {
            ...state.nicheOverview.commonData,
            monopoly: {
              ...state.nicheOverview.commonData.monopoly,
              value: action.payload,
              loading: false
            }
          }
        }
      };

    case "GET_DATA_TOP_100":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          dataTop_100: {
            ...state.nicheOverview.dataTop_100,
            news: {
              ...state.nicheOverview.dataTop_100.news,
              value: action.summary_is_new,
              loading: false
            },
            brands: {
              ...state.nicheOverview.dataTop_100.brands,
              value: action.summary_brands,
              loading: false
            },
            sellers: {
              ...state.nicheOverview.dataTop_100.sellers,
              value: action.summary_sellers,
              loading: false
            },
            revenue: {
              ...state.nicheOverview.dataTop_100.revenue,
              value: action.summary_proceeds,
              loading: false
            },
            books: {
              ...state.nicheOverview.dataTop_100.books,
              value: action.summary_orders,
              loading: false
            },
            lostRevenue: {
              ...state.nicheOverview.dataTop_100.lostRevenue,
              value: action.summary_lost_proceeds,
              loading: false
            },
            averageRevenuePerArticle: {
              ...state.nicheOverview.dataTop_100.averageRevenuePerArticle,
              value: action.avg_proceeds,
              loading: false
            },
            // averageBuybackPercent: {
            // 	...state.nicheOverview.dataTop_100.averageBuybackPercent,
            // 	value: action.avg_sales_percent,
            // 	loading: false,
            // },
            topAveragePrice: {
              ...state.nicheOverview.dataTop_100.topAveragePrice,
              value: action.topsHalfPrice,
              loading: false
            }
          }
        }
      };
    case "PREPEIR_DATA":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          dataTop_100: {
            ...state.nicheOverview.dataTop_100,
            news: {
              ...state.nicheOverview.dataTop_100.news,
              loading: true
            },
            brands: {
              ...state.nicheOverview.dataTop_100.brands,
              loading: true
            },
            sellers: {
              ...state.nicheOverview.dataTop_100.sellers,
              loading: true
            },
            revenue: {
              ...state.nicheOverview.dataTop_100.revenue,
              loading: true
            },
            books: {
              ...state.nicheOverview.dataTop_100.books,
              loading: true
            },
            lostRevenue: {
              ...state.nicheOverview.dataTop_100.lostRevenue,
              loading: true
            },
            averageRevenuePerArticle: {
              ...state.nicheOverview.dataTop_100.averageRevenuePerArticle,
              loading: true
            },
            // averageBuybackPercent: {
            // 	...state.nicheOverview.dataTop_100.averageBuybackPercent,
            // 	loading: true,
            // },
            topAveragePrice: {
              ...state.nicheOverview.dataTop_100.topAveragePrice,
              loading: true
            }
          }
        }
      };
    case "CHANGE_TAB":
      return {
        ...state,
        tab: action.payload
        // item: action.component,
      };
    case "PRODUCTS_REQUEST":
      return {
        ...state,
        products: { ...state.products, table: [], loading: true }
      };
    case "GET_PRODUCT":
      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          count: action.count,
          table: action.payload
        }
      };
    case "BRANDS_REQUEST":
      return {
        ...state,
        brands: { ...state.brands, table: [], loading: true }
      };
    case "BRANDS_REQUEST_ERROR":
      return {
        ...state,
        brands: { ...state.brands, loading: false }
      };
    case "GET_BRANDS":
      return {
        ...state,
        brands: {
          ...state.brands,
          loading: false,
          count: action.count,
          table: action.payload
        }
      };

    case "SELLERS_REQUEST":
      return {
        ...state,
        sellers: { ...state.sellers, table: [], loading: true }
      };
    case "GET_SELLERS":
      return {
        ...state,
        sellers: {
          ...state.sellers,
          loading: false,
          count: action.count,
          table: action.payload
        }
      };
    case "CATEGORY_REQUEST":
      return {
        ...state,
        category: { ...state.category, table: [], loading: true }
      };
    case "GET_CATEGORY":
      return {
        ...state,
        category: {
          ...state.category,
          loading: false,
          count: action.count,
          table: action.payload
        }
      };
    case "SET_APK_PROCENT":
      return {
        ...state,
        nicheOverview: {
          ...state.nicheOverview,
          dataTop_100: {
            ...state.nicheOverview.dataTop_100,
            // apk: {
            //   ...state.nicheOverview.dataTop_100.apk,
            //   value: action.payload,
            //   loading: false
            // },
            typeB: {
              ...state.nicheOverview.dataTop_100.typeB,
              value: action.payload.typeB,
              loading: false
            },
            typeC: {
              ...state.nicheOverview.dataTop_100.typeC,
              value: action.payload.typeC,
              loading: false
            },
          }
        }
      };
    case "SET_GRAFIC_DYNAMIC":
      return {
        ...state,
        nicheOverviewGraficDynamic: {
          data: action.payload,
          status: "success"
        }
      };
    case "SET_GRAFIC_DYNAMIC_STATUS":
      return {
        ...state,
        nicheOverviewGraficDynamic: {
          ...state.nicheOverviewGraficDynamic,
          status: action.payload
        }
      };

    default:
      return state;
  }
};
