import { createSlice } from "@reduxjs/toolkit";

import { Getaccounts, GetTableEconomic } from "./action";



const economicLogicSlice = createSlice({
    name: "economic/logic",
    initialState: {
        apiKeys: [],
        keys: [],
        table: [],
        isLoading: false,
        currencyName: "руб",
    },
    reducers: {
        addKeys: (state, action) => {
            state.keys = action.payload

            const currency = state.apiKeys.filter(item => state.keys.includes(item.id))

            let elem = currency.map(elem => elem.currency_name)

            state.currencyName = elem[elem.length - 1]
        },
    },
    extraReducers: (bulider) => {


        bulider.addCase(Getaccounts.fulfilled, (state, action) => {
            state.apiKeys = action.payload

        })
            .addCase(GetTableEconomic.pending, state => {
                state.isLoading = true
            })
            .addCase(GetTableEconomic.fulfilled, (state, action) => {
                const { results, count } = action.payload
                state.tableCount = count
                state.table = results
                state.isLoading = false
            })
        addKeys(GetTableEconomic.rejected, state => {
            state.isLoading = false
        })

    }
})
export const { addKeys } = economicLogicSlice.actions
export const economicLogicSliceReducer = economicLogicSlice.reducer