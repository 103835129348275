import { createAsyncThunk } from "@reduxjs/toolkit";
import { path, prop } from "ramda";

import API from "root/js/api";

const WBAPI = new API();

// const openInNewTab = url => {
//     console.log(url)
//     window.open(url, '_blank', 'noopener,noreferrer');
// };

export const getPartialPurchases = createAsyncThunk(
  "/getPartialPurchases",
  async ({ token }, thunkAPI) => {
    try {
      const response = await WBAPI.getPartialPurchase({ token });
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }
      console.log(error);
      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }
      if (errorStatus === 401) {
        // openInNewTab(`${window.location.origin}/dashboard/signin`)
        WBAPI.resetToken();
      }
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getMyPartialPurchases = createAsyncThunk(
  "/getMyPartialPurchases",
  async (_, thunkAPI) => {
    try {
      const response = await WBAPI.getMyPartialPurchase();
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }
      // console.log(error);
      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }
      if (errorStatus === 401) {
        window.location = `${window.location.origin}/dashboard/signin`;
        WBAPI.resetToken();
      }
      return thunkAPI.rejectWithValue();
    }
  }
);
export const payPartialPurchase = createAsyncThunk(
  "/payPartialPurchase",
  async ({ url_key }, thunkAPI) => {
    try {
      const response = await WBAPI.payPartialPurchase({ url_key });
      window.location.href = prop("url", prop("data", response));
      return response;
    } catch (error) {
      const errorStatus = path(["response", "status"], error);
      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }

      if (errorStatus === 400 || errorStatus === 404) {
        console.log(error);
      }
      if (errorStatus === 401) {
        // openInNewTab(`${window.location.origin}/dashboard/signin`)
        WBAPI.resetToken();
      }
      return thunkAPI.rejectWithValue("newPage");
    }
  }
);
