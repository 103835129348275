import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

import API from "root/js/api"

const WBAPI = new API()

const initialState = {
	data: [],
}

export const getTarrifs = createAsyncThunk("tariffs/getTariffs", async (promocode) => {
	try {
		const {results} = await WBAPI.getTariffs({promo_code: promocode})
		return results
	} catch (error) {
		console.log(error)
	}
})

const tariffsSlice = createSlice({
	name: "tariffs",
	initialState,
	extraReducers: {
		[getTarrifs.fulfilled]: (state, {payload}) => {
			state.data = payload
		},
	},
})

export const {setUserTariff} = tariffsSlice.actions

export default tariffsSlice.reducer
