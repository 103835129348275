import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M13.4491 3C9.27905 3 5.89823 6.32027 5.89823 10.4156C5.89823 12.08 6.46356 13.6115 7.40643 14.8489L3 19.1764L4.85686 21L9.31779 16.619C10.5056 17.3831 11.9232 17.8313 13.4491 17.8313C17.6192 17.8313 21 14.511 21 10.4156C21 6.32027 17.6192 3 13.4491 3ZM13.4491 15.8968C10.3716 15.8968 7.86802 13.438 7.86802 10.4156C7.86802 7.39328 10.3716 4.93451 13.4491 4.93451C16.5266 4.93451 19.0302 7.39328 19.0302 10.4156C19.0302 13.438 16.5266 15.8968 13.4491 15.8968Z" />
		<path d="M11.8077 9.12596H9.83786V13.6398H11.8077V9.12596Z" />
		<path d="M14.4341 6.54661H12.4643V13.6398H14.4341V6.54661Z" />
		<path d="M17.0604 7.83628H15.0907V13.6398H17.0604V7.83628Z" />
	</Template>
))
