import { createSlice } from "@reduxjs/toolkit"

import { checkCategories, checkCategory, setTabCategory } from "./actions"

const initialState = {
	categoryCheckBoxes: [],
	fullIds: [],
	tab: 0,
}

const categories = createSlice({
	name: "monitoring categories",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setTabCategory.fulfilled, (state, { payload }) => {
			state.tab = payload
		})
		builder.addCase(checkCategories.fulfilled, (state, { payload }) => {
			state.fullIds = payload
		})
		builder.addCase(checkCategory.fulfilled, (state, { payload }) => {
			state.categoryCheckBoxes = payload
		})

	},
})

export const categoriesActions = {
	...categories.actions,
	checkCategory,
	checkCategories,
	setTabCategory,
}

export default categories.reducer
