import { createSlice } from "@reduxjs/toolkit"

import { checkSeller, checkSellers, setTabSeller } from "./actions"

const initialState = {
	sellersCheckBoxes: [],
	fullIds: [],
	tab: 0,
}

const sellers = createSlice({
	name: "monitoring sellers",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setTabSeller.fulfilled, (state, { payload }) => {
			state.tab = payload
		})
		builder.addCase(checkSellers.fulfilled, (state, { payload }) => {
			state.fullIds = payload
		})
		builder.addCase(checkSeller.fulfilled, (state, { payload }) => {
			state.sellersCheckBoxes = payload
		})

	},
})

export const sellersActions = {
	...sellers.actions,
	checkSeller,
	checkSellers,
	setTabSeller,
}

export default sellers.reducer
