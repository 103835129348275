import React from "react"

import Slide from "@mui/material/Slide"
import {SnackbarProvider} from "notistack"


import SnackbarMessage from "../SnackbarMessage"
import "./index.scss"

const SnackbarProvider_ = props => {
	return (
		<SnackbarProvider
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			autoHideDuration={10000}
			TransitionComponent={Slide}
			Components={{
				default: SnackbarMessage,
				success: SnackbarMessage,
				error: SnackbarMessage,
				warning: SnackbarMessage
			}}
			{...props}
		/>
	)
}

export default SnackbarProvider_
