import {equals} from "ramda"

const initialState = {
	chat: [],
}

export const chatGpt = (state = initialState, action) => {
	switch (action.type) {
		case `CHAT_GPT`:
			// eslint-disable-next-line no-case-declarations
			const params = {
				id: action.id,
				isLoading: action.isLoading,
				textInfo: action.textInfo,
			}
			// eslint-disable-next-line no-case-declarations
			const includes = state.chat.some(({id}) => id === action.id)
			if (includes) {
				return {
					...state,
					chat: state.chat.map(item => {
						if (equals(item.id, action.id)) {
							return params
						}
						return item
					}),
				}
			} else {
				return {
					...state,
					chat: [...state.chat, params],
				}
			}

		// eslint-disable-next-line no-fallthrough
		default:
			return state
	}
}
