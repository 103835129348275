import { createSlice } from "@reduxjs/toolkit";
import { prop } from "ramda";

import { CommisionDiagrams, CommisionTable, CommisionWidgets, GetBarcodesSearch, GetFinancialLogicBrand, GetFinancialLogicCategories, GetFinancialLogicKey, GetKeyIndicatorsWidgets, GetProductSearch, GetSubjects, GetSupplierArticles, KeyIndicatorsDynamic, keyIndicatorsOtherWbStructure, KeyIndicatorsServiceStructrueWB, KeyIndicatorsTable, PenaltyAdditionalLineDiagram, PenaltyAdditionalsLinesDynamic, PenaltyAdditionalsPenaltyTypes, PenaltyAdditionalsWidgets, PenaltyDiagramProducts, PenaltyOffices, PenaltyTables, PenaltyTypes, RiteReturnDiagrama, RiteReturnsWidgets, RiterReturnDynamicProduct, RiterReturnOffices, RiterReturnTables } from "./action"

const initialState = {
	apiKeys: [],
	keys: [],
	currencyName: "",
	isSelectValuetDisabled: false,
	date: [],
	mounth: [],
	datePicker: {},
	tableKey: "",

	dateFrom: {},
	dateTo: {},
	financialLogicBrand: [],
	brandIds: [],
	transferValue: 0,
	wbServiceWithCharges: 0,

	financialLogicCategory: [],
	categoryId: [],

	financialLogicProductSearch: [],
	productId: [],

	financialLogicBarcodeSearch: [],
	barcodeIds: [],

	tableArticule: [],
	tableSubject: [],
	tableMonth: [],
	tableBrand: [],
	tableProduct: [],
	tableBarcode: [],
	tableShk: [],

	financialTable: [],

	isLoadingFinancialTable: false,

	financialLogicArticle: [],
	articulIds: [],

	subjects: [],
	subjectsId: [],

	keyIndicatorsWidgets: {},
	isLoadingKeyIndicatorsWidgets: false,

	keyWBServicesStructure: [],
	keyServiceStructureCircle: {},

	dynamicRevenue: [],

	commisionWidgets: {},
	isLoadingCommisionWidgets: false,

	commisionCircle: [],

	commisionDiagram: [],
	isLoadingCommisionDiagrams: false,

	commisionTables: [],
	isLoadingCommisionTable: false,

	riteReturnsWidgets: {},
	isLoadingReturnWidgets: false,
	riteReturnsDiagram: [],
	isLoadingReturnsDiagram: false,
	riterReturnOffices: [],
	isLoadingReturnOffices: false,
	riterReturnTable: [],
	isLoadingReturnTable: false,
	riterReturnDynamicProduct: [],
	isLoadingRiterReturnDynamicProduct: false,

	penaltyWidgets: {},
	isLoadingPenaltyWidgets: false,

	penaltyDiagramLine: [],
	isLoadingPenaltyDiagramLine: false,

	penaltyDynamic: [],
	isLoadingPenaltyDynamic: false,

	penaltyCicrle: [],
	isLoadingPenaltyCicrle: false,

	penaltyTypes: [],

	penaltyDiagramProducts: [],
	isLoadingPenaltyDiagramProducts: false,

	penaltyTable: [],
	isLoadingPenaltyTable: false,

	penaltyReasoneImg: [],
	penaltyTypeName: [],
	singlePenaltyType: [],
	typeNames: [],
}

const _setDate = (state, dateFrom, dateTo) => {
	state.dateFrom = dateFrom
	state.dateTo = new Date(dateTo.getTime() + 1000 * 60 * 60 * 24)
}

const financialLogicSlice = createSlice({
	name: "financial/logic",
	initialState,
	reducers: {
		addKeysFinancialLogic: (state, action) => {
			state.keys = action.payload

			const currency = state.apiKeys.filter(item => state.keys.includes(item.id))

			let elem = currency.map(elem => elem.currency_name)

			state.currencyName = elem[elem.length - 1]
		},
		disabledCheckbox: (state, action) => {
			if (action.payload) {
				state.isSelectValuetDisabled = false
			} else {
				state.isSelectValuetDisabled = true
			}
		},

		getIdCategory: (state, action) => {
			state.categoryId = action.payload
		},
		getSubjectId: (state, action) => {
			state.subjectsId = action.payload
		},
		getIdBrand: (state, action) => {
			state.brandIds = action.payload
		},
		getIdArticul: (state, action) => {
			state.articulIds = action.payload
		},
		getIdBarcod: (state, action) => {
			state.barcodeIds = action.payload
		},
		getProductId: (state, action) => {
			state.productId = action.payload
		},
		getMonthPeriod: (state, action) => {
			function getEnglishMonthName(russianMonth) {
				const russianMonths = [
					"Январь",
					"Февраль",
					"Март",
					"Апрель",
					"Май",
					"Июнь",
					"Июль",
					"Август",
					"Сентябрь",
					"Октябрь",
					"Ноябрь",
					"Декабрь",
				]

				const englishMonths = [
					"January",
					"February",
					"March",
					"April",
					"May",
					"June",
					"July",
					"August",
					"September",
					"October",
					"November",
					"December",
				]

				const index = russianMonths.indexOf(russianMonth)
				if (index !== -1) {
					return englishMonths[index]
				} else {
					return null
				}
			}
			const russianMonth = action.payload
			const englishMonth = getEnglishMonthName(russianMonth)

			if (englishMonth) {
				const startDate = new Date(`1 ${englishMonth} 2023`)
				const finishDate = new Date(startDate)
				finishDate.setMonth(startDate.getMonth() + 1)

				state.dateFrom = startDate
				state.dateTo = finishDate
			} else {
				console.error("Unknown month name:", russianMonth)
			}
		},

		getDatePicker: (state, action) => {
			_setDate(state, action.payload[0], action.payload[1])

			state.mounth.length = 0
			const monthsInRussian = [
				"Январь",
				"Февраль",
				"Март",
				"Апрель",
				"Май",
				"Июнь",
				"Июль",
				"Август",
				"Сентябрь",
				"Октябрь",
				"Ноябрь",
				"Декабрь",
			]

			let currentDate = new Date(state.dateFrom)

			while (currentDate <= state.dateTo) {
				const monthName = monthsInRussian[currentDate.getMonth()]
				state.mounth.push(monthName)
				currentDate.setMonth(currentDate.getMonth() + 1)
			}
		},

		prevLastMonth: (state, action) => {
			const { firstDayOfLastMonth, lastDayOfLastMonth } = action.payload

			_setDate(state, firstDayOfLastMonth, lastDayOfLastMonth)
			state.mounth.length = 0
		},
		prevLastWeek: (state, action) => {
			const { start, end } = action.payload
			_setDate(state, start, end)
			state.mounth.length = 0
		},

		clearSelectors: state => {
			state.articulIds = []
			state.brandIds = []
			state.mounth = []
			state.productId = []
			state.barcodeIds = []
			state.categoryId = []
			state.subjectsId = []
		},
		addCheckboxTypeName: (state, action) => {
			state.penaltyTypeName = action.payload
		},
		singlePenalty: (state, action) => {
			state.singlePenaltyType = action.payload
		}
	},
	extraReducers: bulider => {
		bulider

			.addCase(GetFinancialLogicKey.fulfilled, (state, action) => {
				state.apiKeys = action.payload
				state.date = action.payload.map(elem => elem.create_date)
			})

			.addCase(GetFinancialLogicBrand.fulfilled, (state, action) => {
				if (action.payload) {
					state.financialLogicBrand = action.payload
				}
			})
			.addCase(keyIndicatorsOtherWbStructure.fulfilled, (state, action) => {
				const { transfer_value, wb_service_with_charges } = action.payload

				state.transferValue = transfer_value
				state.wbServiceWithCharges = wb_service_with_charges
			})

			.addCase(GetFinancialLogicCategories.fulfilled, (state, action) => {
				const collator = new Intl.Collator("ru", { sensitivity: "base" })
				if (action.payload) {
					state.financialLogicCategory = action.payload
				}
			})
			.addCase(GetSubjects.fulfilled, (state, action) => {
				state.subjects = action.payload
			})

			.addCase(GetProductSearch.fulfilled, (state, action) => {
				if (action.payload) {
					state.financialLogicProductSearch = action.payload
						.slice()
						.sort((a, b) => a.product_id - b.product_id)
				}
			})

			.addCase(GetBarcodesSearch.fulfilled, (state, action) => {
				if (action.payload) {
					state.financialLogicBarcodeSearch = action.payload.slice().sort((a, b) => {
						return ("" + a.barcode).localeCompare(b.barcode)
					})
				}
			})
			.addCase(GetSupplierArticles.fulfilled, (state, action) => {
				if (action.payload) {
					state.financialLogicArticle = action.payload.slice().sort((a, b) => {
						const supplierA = a.supplier_article.toLowerCase()
						const supplierB = b.supplier_article.toLowerCase()

						if (supplierA < supplierB) {
							return -1
						}
						if (supplierA > supplierB) {
							return 1
						}
						return 0
					})
				}
			})

			.addCase(GetKeyIndicatorsWidgets.pending, state => {
				state.isLoadingKeyIndicatorsWidgets = true
			})

			.addCase(GetKeyIndicatorsWidgets.fulfilled, (state, action) => {
				state.keyIndicatorsWidgets = action.payload
				state.isLoadingKeyIndicatorsWidgets = false
			})

			.addCase(GetKeyIndicatorsWidgets.rejected, state => {
				state.isLoadingKeyIndicatorsWidgets = false
			})

			.addCase(KeyIndicatorsTable.pending, state => {
				state.isLoadingFinancialTable = true
			})

			.addCase(KeyIndicatorsTable.fulfilled, (state, action) => {
				// const regex = /group_by=([^&]+)/
				// const url = action.payload.next
				// const match = url.match(regex);
				// if(match && match[1]){
				// 	state.tableKey =match[1]
				// }
				//   if(state.tableKey === "supplier_article"){
				// 	state.tableArticule = action.payload.results
				//   }
				//   else if(state.tableKey === "subject_id"){
				// 	state.tableSubject = action.payload.results
				//   }

				//   else if(state.tableKey === "month" ){
				// 	state.tableMonth = action.payload.results
				//   }

				//   else if(state.tableKey === "brand_id"){
				// 	state.tableBrand = action.payload.results
				//   }
				//   else if(state.tableKey === "product_id"){
				// 	state.tableProduct = action.payload.results
				//   }
				//   else if(state.tableKey === "barcode"){
				// 	state.tableBarcode = action.payload.results
				//   }
				//   else if(state.tableKey === "shk_id"){
				// 	state.tableShk = action.payload.results
				//   }

				state.financialTable = action.payload.results
				state.finciaclTableCount = action.payload.count
				state.isLoadingFinancialTable = false
			})

			.addCase(KeyIndicatorsTable.rejected, state => {
				state.isLoadingFinancialTable = false
			})

			.addCase(KeyIndicatorsDynamic.fulfilled, (state, action) => {
				state.dynamicRevenue = action.payload
			})

			.addCase(KeyIndicatorsServiceStructrueWB.fulfilled, (state, action) => {
				state.keyServiceStructureCircle = action.payload
			})

			.addCase(CommisionWidgets.pending, state => {
				state.isLoadingCommisionWidgets = true
			})

			.addCase(CommisionWidgets.fulfilled, (state, action) => {
				state.commisionWidgets = action.payload

				const sum =
					prop("reward", prop("current", state.commisionWidgets)) +
					prop("ppvz_reward", prop("current", state.commisionWidgets)) +
					prop("acquiring_fee", prop("current", state.commisionWidgets)) +
					prop("transportation", prop("current", state.commisionWidgets)) +
					prop("nds", prop("current", state.commisionWidgets))

				state.commisionCircle = [
					{
						office_name: "Вознаграждение",
						value: prop("reward", prop("current", state.commisionWidgets)),
						precent:
							(prop("reward", prop("current", state.commisionWidgets)) / sum) * 100,
					},
					{
						office_name: "Поверенный",
						value: prop("ppvz_reward", prop("current", state.commisionWidgets)),
						precent:
							(prop("ppvz_reward", prop("current", state.commisionWidgets)) / sum) *
							100,
					},
					{
						office_name: "Эквайринг",
						value: prop("acquiring_fee", prop("current", state.commisionWidgets)),
						precent:
							(prop("acquiring_fee", prop("current", state.commisionWidgets)) / sum) *
							100,
					},
					{
						office_name: "Перевозка",
						value: prop("transportation", prop("current", state.commisionWidgets)),
						precent:
							(prop("transportation", prop("current", state.commisionWidgets)) /
								sum) *
							100,
					},
					{
						office_name: "НДС",
						value: prop("nds", prop("current", state.commisionWidgets)),
						precent: (prop("nds", prop("current", state.commisionWidgets)) / sum) * 100,
					},
				]

				state.isLoadingCommisionWidgets = false
			})

			.addCase(CommisionWidgets.rejected, state => {
				state.isLoadingCommisionWidgets = false
			})

			.addCase(CommisionDiagrams.pending, state => {
				state.isLoadingCommisionDiagrams = true
			})
			.addCase(CommisionDiagrams.fulfilled, (state, action) => {
				state.commisionDiagram = action.payload
				state.isLoadingCommisionDiagrams = false
			})
			.addCase(CommisionDiagrams.rejected, state => {
				state.isLoadingCommisionDiagrams = false
			})
			.addCase(CommisionTable.pending, state => {
				state.isLoadingCommisionTable = true
			})
			.addCase(CommisionTable.fulfilled, (state, action) => {
				state.commisionTables = action.payload.results
				state.commisionCount = action.payload.count
				state.isLoadingCommisionTable = false
			})
			.addCase(CommisionTable.rejected, state => {
				state.isLoadingCommisionTable = false
			})
			.addCase(RiteReturnsWidgets.pending, state => {
				state.isLoadingReturnWidgets = true
			})
			.addCase(RiteReturnsWidgets.fulfilled, (state, action) => {
				state.riteReturnsWidgets = action.payload
				state.isLoadingReturnWidgets = false
			})
			.addCase(RiteReturnsWidgets.rejected, state => {
				state.isLoadingReturnWidgets = false
			})
			.addCase(RiteReturnDiagrama.pending, state => {
				state.isLoadingReturnsDiagram = true
			})

			.addCase(RiteReturnDiagrama.fulfilled, (state, action) => {
				state.riteReturnsDiagram = action.payload
				state.isLoadingReturnsDiagram = false
			})

			.addCase(RiteReturnDiagrama.rejected, state => {
				state.isLoadingReturnsDiagram = false
			})

			.addCase(RiterReturnOffices.pending, state => {
				state.isLoadingReturnOffices = true
			})

			.addCase(RiterReturnOffices.fulfilled, (state, action) => {
				const REDUCE_OFFICES_TO_LENGTH = 7
				const OTHER_OFFICES_TITLE = "Остальные склады"

				state.riterReturnOffices = action.payload.reduce(
					(acc, { office_name, value }, index) => {
						if (index < REDUCE_OFFICES_TO_LENGTH) {
							acc.push({ office_name, value })
							return acc
						}
						const otherOfficesItem = acc.at(-1)
						otherOfficesItem.office_name = OTHER_OFFICES_TITLE
						otherOfficesItem.value += value
						return acc
					},
					[]
				)

				let sum = 0
				for (let i = 0; i < state.riterReturnOffices.length; i++) {
					sum += state.riterReturnOffices[i].value
				}

				state.riterReturnOffices = state.riterReturnOffices.map(elem => {
					return {
						office_name: elem.office_name,
						value: elem.value,
						precent: (elem.value / sum) * 100,
					}
				})

				state.isLoadingReturnOffices = false
			})

			.addCase(RiterReturnOffices.rejected, state => {
				state.isLoadingReturnOffices = false
			})
			.addCase(RiterReturnTables.pending, state => {
				state.isLoadingReturnTable = true
			})
			.addCase(RiterReturnTables.fulfilled, (state, action) => {
				state.isLoadingReturnTable = false
				state.riterReturnTable = action.payload.results
				state.riterCount = action.payload.count
			})
			.addCase(RiterReturnTables.rejected, state => {
				state.isLoadingReturnTable = false
			})

			.addCase(RiterReturnDynamicProduct.pending, state => {
				state.isLoadingRiterReturnDynamicProduct = true
			})
			.addCase(RiterReturnDynamicProduct.fulfilled, (state, action) => {
				state.riterReturnDynamicProduct = action.payload
				state.isLoadingRiterReturnDynamicProduct = false
			})
			.addCase(RiterReturnDynamicProduct.rejected, state => {
				state.isLoadingRiterReturnDynamicProduct = false
			})

			.addCase(PenaltyAdditionalsWidgets.pending, state => {
				state.isLoadingPenaltyWidgets = true
			})
			.addCase(PenaltyAdditionalsWidgets.fulfilled, (state, action) => {
				state.penaltyWidgets = action.payload
				state.isLoadingPenaltyWidgets = false
			})

			.addCase(PenaltyAdditionalsWidgets.rejected, state => {
				state.isLoadingPenaltyWidgets = false
			})

			.addCase(PenaltyAdditionalLineDiagram.pending, state => {
				state.isLoadingPenaltyDiagramLine = true
			})
			.addCase(PenaltyAdditionalLineDiagram.fulfilled, (state, action) => {
				state.penaltyDiagramLine = action.payload
				state.isLoadingPenaltyDiagramLine = false
			})

			.addCase(PenaltyAdditionalLineDiagram.rejected, state => {
				state.isLoadingPenaltyDiagramLine = false
			})
			.addCase(PenaltyAdditionalsPenaltyTypes.fulfilled, (state, action) => {
				const data = action.payload


				state.penaltyReasoneImg = state.penaltyDiagramLine.map((elem, index) => {
					return {
						type_name: elem.type_name,
						info: data[index]
					}
				})


			})



			.addCase(PenaltyAdditionalsLinesDynamic.pending, state => {
				state.isLoadingPenaltyDynamic = true
			})

			.addCase(PenaltyAdditionalsLinesDynamic.fulfilled, (state, action) => {
				const { response, type_name } = action.payload
				const selectedPenaltyType = state.penaltyTypes.find(
					item => item.type_name === type_name
				)
				if (!selectedPenaltyType) {
					console.error(`${type_name} not found`)
					return
				}
				selectedPenaltyType.dynamic = response
				state.isLoadingPenaltyDynamic = false
			})

			.addCase(PenaltyAdditionalsLinesDynamic.rejected, state => {
				state.isLoadingPenaltyDynamic = false
			})

			.addCase(PenaltyTypes.fulfilled, (state, action) => {
				state.penaltyDiagramLine = action.payload
				const data = action.payload
				state.typeNames = data.map(elem => elem.type_name)
				state.penaltyTypes = action.payload
			})
			.addCase(PenaltyOffices.pending, state => {
				state.isLoadingPenaltyCicrle = true
			})

			.addCase(PenaltyOffices.fulfilled, (state, action) => {
				const REDUCE_OFFICES_TO_LENGTH = 7
				const OTHER_OFFICES_TITLE = "Остальные склады"

				state.penaltyCicrle = action.payload.reduce((acc, { office_name, value }, index) => {
					if (index < REDUCE_OFFICES_TO_LENGTH) {
						acc.push({ office_name, value })
						return acc
					}
					const otherOfficesItem = acc.at(-1)
					otherOfficesItem.office_name = OTHER_OFFICES_TITLE
					otherOfficesItem.value += value
					return acc
				}, [])

				let sum = 0
				for (let i = 0; i < state.penaltyCicrle.length; i++) {
					sum += state.penaltyCicrle[i].value
				}

				state.penaltyCicrle = state.penaltyCicrle.map(elem => {
					return {
						office_name: elem.office_name,
						value: elem.value,
						precent: (elem.value / sum) * 100,
					}
				})
				state.isLoadingPenaltyCicrle = false
			})
			.addCase(PenaltyOffices.rejected, state => {
				state.isLoadingPenaltyCicrle = false
			})

			.addCase(PenaltyDiagramProducts.pending, state => {
				state.isLoadingPenaltyDiagramProducts = true
			})
			.addCase(PenaltyDiagramProducts.fulfilled, (state, action) => {
				state.penaltyDiagramProducts = action.payload
				state.isLoadingPenaltyDiagramProducts = false
			})
			.addCase(PenaltyDiagramProducts.rejected, state => {
				state.isLoadingPenaltyDiagramProducts = false
			})
			.addCase(PenaltyTables.pending, state => {
				state.isLoadingPenaltyTable = true
			})
			.addCase(PenaltyTables.fulfilled, (state, action) => {
				state.penaltyTable = action.payload.results
				state.penaltyCount = action.payload.count
				state.isLoadingPenaltyTable = false
			})
			.addCase(PenaltyTables.rejected, state => {
				state.isLoadingPenaltyTable = false
			})
	},
})

export const {
	addKeysFinancialLogic,
	getIdCategory,
	getIdBrand,
	getIdArticul,
	getIdBarcod,
	getProductId,
	getDatePicker,
	prevLastMonth,
	prevLastWeek,
	getSubjectId,
	getMonthPeriod,
	clearSelectors,
	disabledCheckbox,
	addCheckboxTypeName,
	singlePenalty
} = financialLogicSlice.actions
export const financialLogicSliceReducer = financialLogicSlice.reducer
