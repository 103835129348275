const initialState = {
	params: {
		current: "",
		compositions: [""],
	},

	articuleDates: {
		data: [],
		isLoading: false,
	},
}

export const articuleCompasitionReducer = (state = initialState, action) => {
	switch (action.type) {
		case "POST_PARAMS":
			return {
				...state,
				params: {
					...state.params,
					current: action.current,
					compositions: action.compositions,
				},
			}
		case "LOADING_ARTICULE":
			return {
				...state,
				articuleDates: {
					...state.articuleDates,
					isLoading: action.payload,
				},
			}
		case "FETCH_ARTICULE_DATES":
			return {
				...state,
				articuleDates: {
					...state.articuleDates,
					data: action.articuleDates,
					isLoading: false,
				},
			}

		default:
			return state
	}
}
