import axios from "axios";

export const postFile = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30000
});

const request = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 50000
});

request.interceptors.response.use(
  res => res,
  err => {
    if (
      err.response.headers["content-type"] === "text/html" &&
      err.response.request.status === 403
    ) {
      location.reload();
    }
    throw err
  }
);

export default request;
