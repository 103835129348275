const initialState = {
	activeLabel: [],
	openDrower: false,
	short_price_sigment: false,
	vendor_codes: 0
}

export const priceSegmentation = (state = initialState, action) => {
	switch (action.type) {
		case "CHANGE_SIGMANTATION":
			return {
				...state,
				activeLabel: action.activeLabel,
				vendor_codes: action.vendor_codes,
				openDrower: action.drower,
			}
		case "CHANGE_SHORT_PRICE_SIGMENT":
			return {
				...state,
				short_price_sigment: action.short_price_sigment,
			}
		case "CLOSE_PRICE_SIGMENT":
			return {
				activeLabel: [],
				openDrower: false,
				vendor_codes: 0,
				short_price_sigment: false,
			}

		default:
			return state
	}
}
