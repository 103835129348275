import React from "react"

import Tooltip from "@mui/material/Tooltip"

import styles from "./index.module.scss"

const Tooltip_ = props => {
	return props.title ? (
		<Tooltip
			arrow
			enterDelay={100}
			enterNextDelay={100}
			classes={{
				tooltip: `${styles.root} ${props.dark ? styles.dark : ""}`,
				arrow: `${styles.arrow} ${props.dark ? styles.dark : ""}`,
			}}
			{...props}
			placement={props.placement ?? "top"}
		/>
	) : (
		props.children
	)
}

export default Tooltip_
