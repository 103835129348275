import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M9.54518 13.125C9.34568 13.125 9.15369 13.0455 9.01269 12.903L6.96746 10.8405C6.67571 10.5465 6.67796 10.0717 6.97196 9.78C7.26595 9.489 7.74145 9.4905 8.03245 9.7845L9.50843 11.2733L12.5579 7.75875C12.8286 7.44525 13.3034 7.41225 13.6161 7.68375C13.9289 7.95525 13.9626 8.42925 13.6911 8.742L10.1114 12.867C9.97493 13.0245 9.77843 13.1175 9.56993 13.125C9.56168 13.125 9.55343 13.125 9.54518 13.125Z" />
		<path d="M20.2498 21C20.0578 21 19.8658 20.9265 19.7196 20.7803L14.4846 15.5452C14.1914 15.252 14.1914 14.778 14.4846 14.4847C14.7779 14.1915 15.2519 14.1915 15.5451 14.4847L20.7801 19.7197C21.0733 20.013 21.0733 20.487 20.7801 20.7803C20.6338 20.9265 20.4418 21 20.2498 21Z" />
		<path d="M10.3124 17.625C6.28047 17.625 3 14.3445 3 10.3125C3 6.2805 6.28047 3 10.3124 3C14.3444 3 17.6248 6.2805 17.6248 10.3125C17.6248 14.3445 14.3444 17.625 10.3124 17.625ZM10.3124 4.5C7.10771 4.5 4.49998 7.10775 4.49998 10.3125C4.49998 13.5173 7.10771 16.125 10.3124 16.125C13.5171 16.125 16.1249 13.5173 16.1249 10.3125C16.1249 7.10775 13.5171 4.5 10.3124 4.5Z" />
	</Template>
))
