import React from "react"

import Box from "@mui/material/Box"

import WarningAmber from "../../icons/WarningAmber"

import styles from "./index.module.scss"

const TooManyRequestsDialog = () => {
	return (
		<div className={styles.root}>
			<div className={styles.box}>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					mb={4}
				>
					<WarningAmber className={styles.warningIcon} />
					<div className={styles.title}>Вы превысили лимит запросов</div>
				</Box>

				<div className={styles.subtitle}>
					Вы так активно пользовались нашим сервисом, что превысили допустимый лимит
					запросов. Попробуйте зайти в сервис немного позже.
				</div>
				<div className={styles.subtitle}>
					<span className={styles.highlightedText}> Остались вопросы? </span>Напишите в
					окно поддержки в правом нижнем углу.
				</div>
			</div>
		</div>
	)
}

export default TooManyRequestsDialog
