import {createSlice} from "@reduxjs/toolkit"

import {getPaymentHistory} from "./actions"

const initialState = {
	paymentHistoryLoading: false,
	paymentHistory: [],
	paymentHistoryError: null,
}

const paymentHistory = createSlice({
	name: "payment_history",
	initialState,
	extraReducers: builder => {
		builder.addCase(getPaymentHistory.pending, state => {
			state.paymentHistoryLoading = true
		})
		builder.addCase(getPaymentHistory.fulfilled, (state, {payload}) => {
			state.paymentHistoryLoading = false
			state.paymentHistory = payload
		})
		builder.addCase(getPaymentHistory.rejected, state => {
			state.paymentHistoryLoading = false
			state.paymentHistoryError = "Error getting payment history..."
		})
	},
})

export const paymentHistoryActions = {
	...paymentHistory.actions,
	getPaymentHistory,
}

export default paymentHistory.reducer
