import React, {Fragment} from "react"

import CloseIcon from "@mui/icons-material/Close"
import Divider from "@mui/material/Divider"
import Drawer from "@mui/material/Drawer"
import classnames from "classnames"


import InlineButton from "../InlineButton"

import styles from "./index.module.scss"

const notifications = [
	{
		date: "Сегодня в 12:33",
		text: "Ваш отчет по рекомендациям сформирован.",
		report: true,
		isRead: false,
	},
	{
		date: "Вчера в 22:11",
		text: "Ваш тарифный план истечет через 7 дней.",
		report: true,
		isRead: false,
	},
	{
		date: "10 февраля в 03:51",
		text: "Просим Вас принять к сведению, что в воскресенье, 15 февраля 2021 года, платформа Wildbox, а также Wildbox.Hunter не будут функционировать с 04:00 до 06:00 (GMT) по причине проведения регламентных работ.",
		report: false,
		isRead: true,
	},
	{
		date: "13 января в 12:33",
		text: "Ваш отчет по рекомендациям сформирован.",
		report: true,
		isRead: true,
	},
]

const Notification = props => {
	const {date, text, report, isRead} = props

	return (
		<div
			className={styles.notification}
			data-is-read={isRead}
		>
			<div className={styles.date}>{date}</div>
			<div className={styles.text}>{text}</div>
			{report && (
				<InlineButton
					underlined
					variant="primary"
					className={styles.button}
				>
					Перейти к отчету
				</InlineButton>
			)}
		</div>
	)
}

const Notifications = props => {
	const {open, onClose, className, classes = {}, ...rest} = props

	return (
		<Drawer
			open={open}
			anchor="right"
			onClose={onClose}
			PaperProps={{
				classes: {
					root: classnames(styles.paper, classes.paper),
				},
			}}
		>
			<div
				className={styles.closeIconWrapper}
				onClick={onClose}
			>
				<CloseIcon className={styles.closeIcon} />
			</div>

			<div
				className={classnames(styles.root, className, classes.root)}
				{...rest}
			>
				<div className={styles.header}>
					<div className={styles.title}>Уведомления</div>
					<div className={styles.readAll}>Отметить все уведомления как прочитанные</div>
				</div>
				<Divider />
				<div className={styles.notifications}>
					{notifications.map((props, index) => (
						<Fragment key={index}>
							<Notification {...props} />
							<Divider />
						</Fragment>
					))}
				</div>
			</div>
		</Drawer>
	)
}

export default Notifications
