import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M9.13008 3H3.7658C3.34608 3 3 3.34601 3 3.76564V9.16564C3 9.58528 3.34608 9.93129 3.7658 9.93129H9.13008C9.5498 9.93129 9.85906 9.58528 9.89588 9.20245V3.76564C9.89588 3.34601 9.5498 3 9.13008 3ZM8.36428 8.43681H4.49478V4.49448H8.36428V8.43681Z" />
		<path d="M13.0732 5.26012H20.2342C20.6539 5.26012 21 4.91411 21 4.49448C21 4.07485 20.6539 3.72883 20.2342 3.72883H13.0732C12.6535 3.72883 12.3074 4.07485 12.3074 4.49448C12.3074 4.91411 12.6535 5.26012 13.0732 5.26012Z" />
		<path d="M20.2379 7.63436H13.0732C12.6535 7.63436 12.3074 7.98037 12.3074 8.4C12.3074 8.81963 12.6535 9.16564 13.0732 9.16564H20.2342C20.6539 9.16564 21 8.81963 21 8.4C21.0037 7.98037 20.6576 7.63436 20.2379 7.63436Z" />
		<path d="M9.13008 14.0687H3.7658C3.34608 14.0687 3 14.4147 3 14.8344V20.2344C3 20.654 3.34608 21 3.7658 21H9.13008C9.5498 21 9.85906 20.654 9.89588 20.2712V14.8344C9.89588 14.411 9.5498 14.0687 9.13008 14.0687ZM8.36428 19.5055H4.49478V15.5632H8.36428V19.5055Z" />
		<path d="M20.2379 14.7939H13.0732C12.6535 14.7939 12.3074 15.1399 12.3074 15.5595C12.3074 15.9791 12.6535 16.3252 13.0732 16.3252H20.2342C20.6539 16.3252 21 15.9791 21 15.5595C21.0037 15.1399 20.6576 14.7939 20.2379 14.7939Z" />
		<path d="M20.2379 18.7031H13.0732C12.6535 18.7031 12.3074 19.0491 12.3074 19.4687C12.3074 19.8883 12.6535 20.2344 13.0732 20.2344H20.2342C20.6539 20.2344 21 19.8883 21 19.4687C21.0037 19.0454 20.6576 18.7031 20.2379 18.7031Z" />
	</Template>
))
