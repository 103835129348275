import React, { useLayoutEffect, useState } from "react"

import Tabs from "@mui/material/Tabs"

import styles from "./index.module.scss"

const Tabs_ = props => {
	const [key, setKey] = useState(0)

	useLayoutEffect(() => {
		window.addEventListener("load", () => {
			setKey(1)
		})
	}, [])



	return (
		<Tabs
			key={key}
			{...props}
			classes={{
				root: styles.root,
				indicator: styles.indicator,
			}}
			variant="scrollable"
			TabScrollButtonProps={{
				classes: {
					disabled: styles.disabled,
				},
			}}
		/>
	)
}

export default Tabs_
