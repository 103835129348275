import { createSlice } from "@reduxjs/toolkit"
import { defaultTo, uniq } from "ramda"

import {
	downloadGroupingExcel,
	getAllGroupingKeywords,
	getAllGroupingWords,
	getGroupingKeywords,
	getGroupingWords,
} from "./actions"

const defaultToStringObj = defaultTo("[]")

const groupingKeywords = JSON.parse(defaultToStringObj(localStorage.getItem("grouping_keywords")))
const groupingStopWords = JSON.parse(
	defaultToStringObj(localStorage.getItem("grouping_stop_words"))
)
const groupingStopKeywords = JSON.parse(
	defaultToStringObj(localStorage.getItem("grouping_stop_keywords"))
)

const initialState = {
	groupingWordsLoading: false,
	groupingWords: {
		count: 0,
		words: {
			items: [],
			count: 0,
		},
		keywords: {
			items: [],
			count: 0,
		},
	},
	groupingWordsError: null,

	// all words
	groupingAllWordsLoading: false,
	groupingAllWords: [],
	groupingAllWordsError: null,

	groupingKeywordsLoading: false,
	groupingKeywords: {
		items: [],
		count: 0,
	},
	groupingKeyWordsError: null,

	// all keywords
	groupingAllKeywordsLoading: false,
	groupingAllKeywords: [],
	groupingAllKeywordsError: null,

	groupingParams: {
		keywords: groupingKeywords || [],
		stop_words: groupingStopWords || [],
		stop_keywords: groupingStopKeywords || [],
	},

	// download
	groupingExcelLoading: false,
	groupingExcelSuccess: [],
	groupingExcelError: null,
}

const grouping = createSlice({
	name: "grouping",
	initialState,
	reducers: {
		// params
		setGroupingParamsKeywords(state, { payload }) {
			if (typeof payload === "string") {
				state.groupingParams.keywords.push(payload)
			} else {
				state.groupingParams.keywords = uniq([...state.groupingParams.keywords, ...payload])
			}

			localStorage.setItem("grouping_keywords", JSON.stringify(state.groupingParams.keywords))
		},
		setGroupingParamsStopWords(state, { payload }) {
			state.groupingParams.stop_words = payload
			localStorage.setItem("grouping_stop_words", JSON.stringify(payload))
		},
		setGroupingParamsStopKeywords(state, { payload }) {
			state.groupingParams.stop_keywords = payload
			localStorage.setItem("grouping_stop_keywords", JSON.stringify(payload))
		},

		// add stop word
		addGroupingParamsStopWords(state, { payload }) {
			state.groupingParams.stop_words = uniq([...state.groupingParams.stop_words, ...payload])
			localStorage.setItem(
				"grouping_stop_words",
				JSON.stringify(state.groupingParams.stop_words)
			)
		},
		// add stop keyword
		addGroupingParamsStopKeywords(state, { payload }) {
			state.groupingParams.stop_keywords = uniq([
				...state.groupingParams.stop_keywords,
				...payload,
			])
			localStorage.setItem(
				"grouping_stop_keywords",
				JSON.stringify(state.groupingParams.stop_keywords)
			)
		},

		deleteParamsKeyword(state, { payload }) {
			state.groupingParams.keywords = state.groupingParams.keywords.filter(
				item => item !== payload
			)

			localStorage.setItem("grouping_keywords", JSON.stringify(state.groupingParams.keywords))
		},
		clearStopWords(state) {
			state.groupingParams.stop_words = []
			localStorage.setItem("grouping_stop_words", JSON.stringify([]))
		},
		clearStopKeywords(state) {
			state.groupingParams.stop_keywords = []
			localStorage.setItem("grouping_stop_keywords", JSON.stringify([]))
		},
		clearGroupingParams(state) {
			state.groupingParams.keywords = []
			state.groupingParams.stop_words = []
			state.groupingParams.stop_keywords = []

			localStorage.removeItem("grouping_keywords")
			localStorage.removeItem("grouping_stop_words")
			localStorage.removeItem("grouping_stop_keywords")
		},
		// clear all words
		clearAllGroupingWords(state) {
			state.groupingAllWords = []
		},
		// clear all keywords
		clearAllGroupingKeywords(state) {
			state.groupingAllKeywords = []
		},
	},
	extraReducers: builder => {
		// words
		builder.addCase(getGroupingWords.pending, state => {
			state.groupingWordsLoading = true
		})
		builder.addCase(getGroupingWords.fulfilled, (state, { payload }) => {
			state.groupingWordsLoading = false
			state.groupingWords.count = payload.count
			state.groupingWords.words = {
				count: payload.results.words.count,
				items: payload.results.words.items.map(item => ({
					...item,
					id: {
						id: item.id,
						name: item.name,
					},
					count_keywords: {
						id: item.id,
						name: item.name,
						count_keywords: item.count_keywords,
					},
				})),
			}
			state.groupingWords.keywords = {
				count: payload.results.keywords.count,
				items: payload.results.keywords.items.map(item => ({
					...item,
					id: {
						id: item.id,
						name: item.name,
					},
				})),
			}
		})
		builder.addCase(getGroupingWords.rejected, state => {
			state.groupingWordsLoading = false
			state.groupingWordsError = "Error getting grouping words..."
		})
		// all words
		builder.addCase(getAllGroupingWords.pending, state => {
			state.groupingAllWordsLoading = true
		})
		builder.addCase(getAllGroupingWords.fulfilled, (state, { payload }) => {
			state.groupingAllWordsLoading = false
			state.groupingAllWords = payload.results.map(item => item.name)
		})
		builder.addCase(getAllGroupingWords.rejected, state => {
			state.groupingAllWordsLoading = false
			state.groupingAllWordsError = "Error getting all grouping words..."
		})
		// keywords
		builder.addCase(getGroupingKeywords.pending, state => {
			state.groupingKeywordsLoading = true
		})
		builder.addCase(getGroupingKeywords.fulfilled, (state, { payload }) => {
			state.groupingKeywordsLoading = false
			state.groupingKeywords = {
				count: payload.count,
				items: payload.results.map(item => ({
					...item,
					id: {
						id: item.id,
						name: item.name,
					},
					name: {
						id: item.id,
						value: item.name,
					}
				})),
			}
		})
		builder.addCase(getGroupingKeywords.rejected, state => {
			state.groupingKeywordsLoading = false
			state.groupingWordsError = "Error getting grouping keywords..."
		})
		// all keywords
		builder.addCase(getAllGroupingKeywords.pending, state => {
			state.groupingAllKeywordsLoading = true
		})
		builder.addCase(getAllGroupingKeywords.fulfilled, (state, { payload }) => {
			state.groupingAllKeywordsLoading = false
			state.groupingAllKeywords = payload.results
		})
		builder.addCase(getAllGroupingKeywords.rejected, state => {
			state.groupingAllKeywordsLoading = false
			state.groupingAllKeywordsError = "Error getting all grouping keywords..."
		})

		// download
		builder.addCase(downloadGroupingExcel.pending, state => {
			state.groupingExcelLoading = true
		})
		builder.addCase(downloadGroupingExcel.fulfilled, (state, { payload }) => {
			state.groupingExcelLoading = false
			state.groupingExcelSuccess = payload
		})
		builder.addCase(downloadGroupingExcel.rejected, state => {
			state.groupingExcelLoading = false
			state.groupingExcelError = "Error downloading file..."
		})
	},
})

export const groupingActions = {
	...grouping.actions,
	getGroupingWords,
	getGroupingKeywords,
	getAllGroupingWords,
	getAllGroupingKeywords,
	downloadGroupingExcel,
}

export default grouping.reducer
