import { length, propOr, sum } from "ramda"

const initialState = {
	sellerTitle: "",
	selectedPeriodTotal: {
		avrProceedsOnProductWithOrders: 0,
		avrProductWithOrders: 0,
		loading: false,
		results: [],
		percent_sales: 0,
	},
}

export const sellerCardReducer = (state = initialState, action) => {

	const percent_sales = propOr([], "results", action).map(({ percent_sales }) => percent_sales)

	switch (action.type) {
		case "SELLER_TITLE":
			return {
				...state,
				sellerTitle: action.payload,
			}
		case "SELLER_SELECTED_PERIOD_TOTAL_LOADING":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: true,
				},
			}
		case "SELLER_SELECTED_PERIOD_TOTAL":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: false,
					avrProceedsOnProductWithOrders: action.avrProceedsOnProductWithOrders,
					avrProductWithOrders: action.avrProductWithOrders,
					results: propOr([], "results", action),
					percent_sales: sum(percent_sales) / length(percent_sales)
				},
			}

		default:
			return state
	}
}
