import {equals, propOr, uniq} from "ramda"

import {paginate} from "root/js/utils"

const initialState = {
	tabs: [
		{
			title: "Группы",
			words: "группы",
			page: "words",
		},
		{
			title: "Запросы",
			words: "слова",
			page: "keywords",
		},
	],
	params: {
		keywords: [],
		stop_keywords: [],
		collection_ids: [],
		stop_words: [],
	},
	keywords: {
		data: [],
		fullData: [],
		count: 0,
		page: 1,
		perPage: 50,
		ids: [],
		stop: [],
		checkeds: [],
	},
	words: {
		data: [],
		fullData: [],
		popap: [],
		count: 0,
		page: 1,
		perPage: 50,
		ids: [],
		stop: [],
		checkeds: [],
	},
	loading: false,
	exporting: false,
}

export const grouping = (state = initialState, action) => {
	switch (action.type) {
		case `CLEAR_CHECKEDS`:
			return {
				...state,
				words: {
					...state.words,
					checkeds: [],
				},
				keywords: {
					...state.keywords,
					checkeds: [],
				},
			}
		case `GROUPING_POPAP_CHECK_ALL`:
			return {
				...state,
				words: {
					...state.words,
					checkeds: action.payload,
				},
			}
		case `GROUPING_PAGE_${action.pageTitle}`:
			return {
				...state,
				[action.pageTitle]: {
					...state[action.pageTitle],
					page: action.payload,
				},
			}
		case `SUBMIT_STOP_KEYWORDS`:
			return {
				...state,
				params: {
					...state.params,
					stop_keywords: uniq([
						...state.params.stop_keywords,
						...propOr([], "stop_keywords", action),
					]),
				},
				keywords: {
					...state.keywords,
					stop: uniq([...state.keywords.stop, ...action.stop_keywords]),
				},
			}
		case `SUBMIT_STOP_WORDS`:
			return {
				...state,
				params: {
					...state.params,
					stop_words: uniq([...state.params.stop_words, ...action.stop_words]),
				},
				words: {
					...state.words,
					stop: uniq([...state.words.stop, ...action.stop_words]),
				},
			}

		case `GROUPING_CHECK_${action.pageTitle}`:
			return {
				...state,
				[action.pageTitle]: {
					...state[action.pageTitle],
					checkeds: state[action.pageTitle].checkeds.includes(action.payload)
						? state[action.pageTitle].checkeds.filter(item => item !== action.payload)
						: [...state[action.pageTitle].checkeds, action.payload],
				},
			}
		case `GROUPING_CHECK_ALL_${action.pageTitle}`:
			return {
				...state,
				[action.pageTitle]: {
					...state[action.pageTitle],
					checkeds: equals(state[action.pageTitle].checkeds, state[action.pageTitle].ids)
						? []
						: state[action.pageTitle].ids,
				},
			}
		case "GROUPING_LOADING":
			return {
				...state,
				loading: action.payload,
			}
		case "GROUPING_EXPORT":
			return {
				...state,
				exporting: action.payload,
			}
		case "GET_GROUPING_KEYWORDS_&_WORDS":
			return {
				...state,
				params: {
					...state.params,
					keywords: action.keywords,
					stop_keywords: action.stop_keywords,
					stop_words: action.stop_words,
				},
				keywords: {
					...state.keywords,
					stop: action.stop_keywords,
					fullData: action.payload.results.keywords.items,
					data: paginate(action.payload.results.keywords.items, state.keywords.perPage),
					count: action.payload.results.keywords.count,
					ids: action.payload.results.keywords.items.map(({id}) => id),
				},
				words: {
					...state.words,
					stop: action.stop_words,
					popap: action.payload.results.words.items.map(
						({word, count_keywords, keywords_ids}) => {
							return {
								popapPage: 1,
								popapCount: count_keywords,
								[word]: paginate(
									action.payload.results.keywords.items.filter(({id}) =>
										keywords_ids.includes(id)
									),
									state.words.perPage
								),
							}
						}
					),
					fullData: action.payload.results.words.items,
					data: paginate(
						action.payload.results.words.items.map(item => ({
							...item,
							keywords_count: {
								count: item.keywords_ids.length,
								word: item.word,
							},
							word_forms: {word_forms: item.word_forms, name: item.word},
							id: item.word,
							name: item.word,
						})),
						state.words.perPage
					),

					count: action.payload.results.words.count,
					ids: action.payload.results.words.items.map(({word}) => word),
				},
			}
		case "GET_GROUPING_DATA":
			return {
				...state,
				keywords: {
					...state.keywords,
					data: action.payload.results.keywords.items,
					ids: action.payload.results.keywords.items.map(({id}) => id),
					count: action.payload.results.keywords.count,
				},
				words: {
					...state.words,
					data: action.payload.results.words.items,
					ids: action.payload.results.keywords.items.map(({id}) => id),
					count: action.payload.results.words.count,
				},
				loading: false,
			}
		case "REQUEST_PARAMS":
			return {
				...state,
				params: {
					...state.params,
					keywords: uniq([...state.params.keywords, ...action.payload.keywords]),
					stop_keywords: action.payload.stop_keywords,
					stop_words: action.payload.stop_words,
				},
				keywords: {
					...state.keywords,
					stop: action.payload.stop_keywords,
				},
				words: {
					...state.words,
					stop: action.payload.stop_words,
				},
			}
		case "KEYWORDS_PARAMS_STRING":
			return {
				...state,
				params: {
					...state.params,
					keywords: uniq([...state.params.keywords, action.payload.keywords]),
					stop_keywords: action.payload.stop_keywords,
					stop_words: action.payload.stop_words,
				},
				keywords: {
					...state.keywords,
					stop: action.payload.stop_keywords,
				},
				words: {
					...state.words,
					stop: action.payload.stop_words,
				},
			}
		case "DELETE_KEYWORDS": {
			return {
				...state,
				params: {
					...state.params,
					keywords: state.params.keywords.filter(item => item !== action.payload),
				},
			}
		}
		case "CLEAR_GROUPING_INIT_DATAS":
			return {
				...state,
				params: {
					keywords: [],
					stop_keywords: [],
					stop_words: [],
				},
			}

		default:
			return state
	}
}
