import React from "react"

import classnames from "classnames"

import styles from "./index.module.scss"

const PageTitle = props => {
	const {children, className, ...rest} = props

	return (
		<h2
			className={classnames(styles.root, className)}
			{...rest}
		>
			{children}
		</h2>
	)
}

export default PageTitle
