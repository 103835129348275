import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M19.7644 4.23568C18.1168 2.58811 15.4457 2.58811 13.7981 4.23564L10.2722 7.76161C8.56797 9.46588 8.72575 12.1813 10.2722 13.7278C10.5311 13.9868 10.8177 14.1979 11.1202 14.3714L11.7638 13.7278C12.1858 13.3057 12.0373 12.8122 12.0297 12.4561C11.937 12.3904 11.8469 12.3194 11.7638 12.2363C10.9702 11.4427 10.9345 10.0824 11.7638 9.25315C11.8869 9.12999 15.2149 5.80199 15.2896 5.72725C16.1123 4.90463 17.4501 4.90463 18.2728 5.72725C19.0954 6.54987 19.0954 7.88775 18.2728 8.71037L15.9421 11.041C16.0095 11.4139 16.414 12.2972 16.206 13.7544C16.2161 13.7444 16.2283 13.738 16.2384 13.7279L19.7644 10.2019C21.4119 8.55434 21.4119 5.88324 19.7644 4.23568Z" />
		<path d="M14.001 9.99895C13.7421 9.73999 13.4555 9.52884 13.1531 9.35542L12.5095 9.99895C12.0874 10.421 12.2359 10.9145 12.2435 11.2706C12.3362 11.3363 12.4263 11.4073 12.5095 11.4905C13.3031 12.284 13.3388 13.6443 12.5095 14.4736C12.3861 14.597 8.78242 18.2007 8.71032 18.2728C7.8877 19.0954 6.54982 19.0954 5.7272 18.2728C4.90458 17.4501 4.90458 16.1123 5.7272 15.2897L8.33112 12.6857C8.26373 12.3128 7.85922 11.4295 8.06731 9.97234C8.05715 9.98232 8.04491 9.98883 8.03479 9.99892L4.23562 13.7981C2.58813 15.4457 2.58813 18.1168 4.23562 19.7644C5.88319 21.4119 8.55426 21.4119 10.2018 19.7644L14.001 15.9652C15.6736 14.2926 15.5822 11.5801 14.001 9.99895Z" />
	</Template>
))
