
import { createAsyncThunk } from "@reduxjs/toolkit";
import { ca } from "react-date-range/dist/locale";

import API from "root/js/api"
const WBAPI = new API()
export const GetCompanyWBCabinets = createAsyncThunk(
    "get/company/WB",
    async()=>{
        const response = await  WBAPI.getCompanyWB()

      return response

    }
)

export const CreateCabinetsWB = createAsyncThunk(
  "create/cabinets/WN",
  async({name,is_shared})=>{
   const response =  await WBAPI.createWBCabinets({name,is_shared})
   return response
  }
)

// export const GetWBCabintet =createAsyncThunk(
//   "get/WB/cabinter",
//   async({CABINET_ID})=>{
//     const response =  await WBAPI.getWBcabinet({CABINET_ID})
 
//   }
// )

export const DeleteWBCabinet = createAsyncThunk(
  "delete/WB/cabinet",
  async({ID})=>{

   const response = await WBAPI.deleteWbCabinet({ID})
   return response
  }
)

export const UpdateWBCabinet = createAsyncThunk(
  "update/wb/cabinet",
  async({ID,name,is_shared})=>{

    const res =  await WBAPI.updateWBCabinet({ID,name,is_shared})
      return res

  }
)


export const CreateWBTokens = createAsyncThunk(
  "create/wb/cabinet",
  async({CABINET_ID,token,type})=>{
    try{
      const response =  await WBAPI.createWBtokens({CABINET_ID,token,type})
      return response
    }
    catch(e){
      console.log(e.message)
    }

  }
)