import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	formState: null,
	amount: 0,
	savedFormData: null,
}

const productAnalyzerSlice = createSlice({
	name: "productAnalyzer",
	initialState,
	reducers: {
		setPrevForm(state, {payload}) {
			state.formState = payload.formState
			state.amount = payload.amount
			state.savedFormData = payload.amount
		},
	},
})

export const {setPrevForm} = productAnalyzerSlice.actions

export default productAnalyzerSlice.reducer
