import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<g clipPath="url(#clip0_438_550)">
			<path d="M21.6084 3.63861C21.2866 3.3438 20.8293 3.24556 20.4148 3.38216L2.82934 9.17908C2.35009 9.33705 2.02509 9.76486 2.00138 10.2689C1.9777 10.773 2.2611 11.2294 2.72337 11.4317L7.07239 13.3345C7.0936 13.433 8.34208 19.2234 8.38454 19.4203C8.43903 19.6731 8.56735 19.8983 8.83918 19.9675C9.11415 20.0376 9.3084 19.8873 9.51356 19.7337C9.62774 19.6482 12.7409 17.3171 12.7409 17.3171L16.5139 20.4046C16.7337 20.5845 17.0023 20.6783 17.2761 20.6783C17.4065 20.6783 17.5382 20.657 17.6662 20.6136C18.0633 20.4789 18.3577 20.1548 18.4537 19.7467L21.968 4.80517C22.0678 4.38037 21.9301 3.93341 21.6084 3.63861ZM9.79469 14.4249C9.79254 14.4299 9.79043 14.4357 9.78844 14.4426L9.06094 16.9843L8.24907 13.2189L13.8318 10.1171L9.9286 14.1983C9.86711 14.2626 9.82188 14.3403 9.79469 14.4249ZM10.0256 17.8829L10.356 16.7284L10.6721 15.6241L11.8045 16.5508L10.0256 17.8829ZM20.8245 4.53619L17.3103 19.4777C17.3085 19.4851 17.3062 19.4953 17.289 19.5012C17.272 19.5069 17.2637 19.5003 17.2578 19.4955L13.1293 16.1171C13.1291 16.117 13.1291 16.1169 13.1289 16.1168L11.2161 14.5515L17.3615 8.12576C17.5622 7.91588 17.5794 7.59084 17.402 7.36096C17.2244 7.13103 16.9056 7.06537 16.6518 7.20646L7.55215 12.2623L3.19423 10.3555C3.18095 10.3497 3.17364 10.3465 3.1747 10.324C3.17575 10.3017 3.18333 10.2991 3.19708 10.2946L20.7825 4.49771C20.7911 4.49494 20.8007 4.49174 20.8148 4.50463C20.8289 4.51759 20.8266 4.52744 20.8245 4.53619Z" />
		</g>
		<defs>
			<clipPath id="clip0_438_550">
				<rect
					width="20"
					height="20"
					transform="translate(2 2)"
				/>
			</clipPath>
		</defs>
	</Template>
))
