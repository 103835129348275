const initialState = {
	selectedPeriodTotal: {
		avrProceedsOnProductWithOrders: 0,
		avrProductWithOrders: 0,
		loading: false,
	},
}

export const brandCardReducer = (state = initialState, action) => {
	switch (action.type) {
		case "BRAND_SELECTED_PERIOD_TOTAL_LOADING":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: true,
				},
			}
		case "BRAND_SELECTED_PERIOD_TOTAL":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: false,
					avrProceedsOnProductWithOrders: action.avrProceedsOnProductWithOrders,
					avrProductWithOrders: action.avrProductWithOrders,
				},
			}

		default:
			return state
	}
}
