import { prop, propOr } from "ramda"

const initialState = {
	1: {
		sections: [],
		ids: [],
		checkBoxSelect: [],
		subjectIsLoading: false,
		urlParams: {},
		isAll: false,
		count: 0,
		sort: "-frequency",
		page: 1,
		perPage: 50,
		product_ids: [],
		stop_product_ids: [],
		loading: false,
		isExporting: false,
		emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
		extra_fields: "",
	},
	2: {
		sections: [],
		ids: [],
		isAll: false,
		urlParams: {},
		product_ids: [],
		stop_product_ids: [],
		checkBoxSelect: [],
		subjectIsLoading: false,
		count: 0,
		sort: "-frequency",
		page: 1,
		perPage: 50,
		loading: false,
		isExporting: false,
		emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
		extra_fields: "",
	},
	3: {
		sections: [],
		ids: [],
		isAll: false,
		product_ids: [],
		stop_product_ids: [],
		urlParams: {},
		checkBoxSelect: [],
		subjectIsLoading: false,
		count: 0,
		sort: "-frequency",
		page: 1,
		perPage: 50,
		loading: false,
		isExporting: false,
		emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
		extra_fields: "",
	},
	4: {
		sections: [],
		ids: [],
		isAll: false,
		urlParams: {},
		product_ids: [],
		stop_product_ids: [],
		checkBoxSelect: [],
		subjectIsLoading: false,
		count: 0,
		sort: "-frequency",
		page: 1,
		perPage: 50,
		loading: false,
		isExporting: false,
		emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
		extra_fields: "",
	},
	5: {
		sections: [],
		ids: [],
		isAll: false,
		urlParams: {},
		product_ids: [],
		stop_product_ids: [],
		checkBoxSelect: [],
		subjectIsLoading: false,
		count: 0,
		sort: "-frequency",
		page: 1,
		perPage: 50,
		loading: false,
		isExporting: false,
		emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
		extra_fields: "",
	},
}

export const keyCompasitionReducer = (state = initialState, action) => {
	switch (action.type) {
		case `CLEAR_KEYS_COMPARISON`:
			return {
				1: {
					sections: [],
					ids: [],
					checkBoxSelect: [],
					subjectIsLoading: false,
					urlParams: {},
					isAll: false,
					count: 0,
					sort: "-frequency",
					page: 1,
					perPage: 50,
					product_ids: [],
					stop_product_ids: [],
					loading: false,
					isExporting: false,
					emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
					extra_fields: "",
				},
				2: {
					sections: [],
					ids: [],
					isAll: false,
					urlParams: {},
					product_ids: [],
					stop_product_ids: [],
					checkBoxSelect: [],
					subjectIsLoading: false,
					count: 0,
					sort: "-frequency",
					page: 1,
					perPage: 50,
					loading: false,
					isExporting: false,
					emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
					extra_fields: "",
				},
				3: {
					sections: [],
					ids: [],
					isAll: false,
					product_ids: [],
					stop_product_ids: [],
					urlParams: {},
					checkBoxSelect: [],
					subjectIsLoading: false,
					count: 0,
					sort: "-frequency",
					page: 1,
					perPage: 50,
					loading: false,
					isExporting: false,
					emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
					extra_fields: "",
				},
				4: {
					sections: [],
					ids: [],
					isAll: false,
					urlParams: {},
					product_ids: [],
					stop_product_ids: [],
					checkBoxSelect: [],
					subjectIsLoading: false,
					count: 0,
					sort: "-frequency",
					page: 1,
					perPage: 50,
					loading: false,
					isExporting: false,
					emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
					extra_fields: "",
				},
				5: {
					sections: [],
					ids: [],
					isAll: false,
					urlParams: {},
					product_ids: [],
					stop_product_ids: [],
					checkBoxSelect: [],
					subjectIsLoading: false,
					count: 0,
					sort: "-frequency",
					page: 1,
					perPage: 50,
					loading: false,
					isExporting: false,
					emptyMessage: "Нет запросов, которые удовлетворяют данным критериям",
					extra_fields: "",
				},
			}
		case `SAVE_PRODUCT_IDS_${action.proptype}`:
			return {
				...state,
				[action.proptype]: {
					...state[action.proptype],
					stop_product_ids: action.stop_product_ids,
					product_ids: action.product_ids,
					products__gte: action.products__gte,
					products__lte: action.products__lte,
					frequency__gte: action.frequency__gte,
					frequency__lte: action.frequency__lte,
					percent_products__gte: action.percent_products__gte,
					percent_products__lte: action.percent_products__lte,
				},
			}

		case `CHECKBOX_${action.index}`:
			return {
				...state,
				[action.index]: {
					...state[action.index],
					isAll: false,
					checkBoxSelect: state[action.index].checkBoxSelect.includes(action.payload)
						? state[action.index].checkBoxSelect.filter(item => item !== action.payload)
						: [...state[action.index].checkBoxSelect, action.payload],
				},
			}
		case `SUBJECTS_LOADING_${action.proptype}`:
			return {
				...state,
				[action.proptype]: {
					...state[action.proptype],
					subjectIsLoading: action.subjectIsLoadingStatus,
				},
			}
		case `SET_SUBJECTS_DATA_${action.proptype}`:
			return {
				...state,
				[action.proptype]: {
					...state[action.proptype],
					sections: propOr([], "sections", state[action.proptype]).map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
						subject: prop(
							"subject",
							propOr([], "payload", action).find(({ id }) => id === item.id)
						),
					})),
				},
			}
		case "CHECKBOX_ALL_1":
			return {
				...state,
				1: {
					...state[1],
					isAll: true,
					extra_fields: action.extra_fields,
					urlParams: action.urlParams,
					checkBoxSelect: action.payload,
				},
			}

		case "CHECKBOX_ALL_2":
			return {
				...state,
				2: {
					...state[2],
					isAll: true,
					extra_fields: action.extra_fields,
					checkBoxSelect: action.payload,
				},
			}

		case "CHECKBOX_ALL_3":
			return {
				...state,
				3: {
					...state[3],
					isAll: true,
					extra_fields: action.extra_fields,
					checkBoxSelect: action.payload,
				},
			}

		case "CHECKBOX_ALL_4":
			return {
				...state,
				4: {
					...state[4],
					isAll: true,
					extra_fields: action.extra_fields,
					checkBoxSelect: action.payload,
				},
			}

		case "CHECKBOX_ALL_5":
			return {
				...state,
				5: {
					...state[5],
					isAll: true,
					extra_fields: action.extra_fields,
					checkBoxSelect: action.payload,
				},
			}

		// !-------------------------
		case "EXPORT":
			return {
				...state,
				[action.payload]: {
					...state[action.payload],
					isExporting: true,
				},
			}
		case "EXPORTED":
			return {
				...state,
				[action.payload]: {
					...state[action.payload],
					isExporting: false,
				},
			}
		case "EXPORTING_FAILUR":
			return {
				...state,
				[action.payload]: {
					...state[action.payload],
					isExporting: false,
				},
			}
		case "GET_SECTIONS_ALL_REQUEST":
			return {
				...state,
				1: {
					...state[1],
					loading: true,
				},
			}
		case "GET_SECTIONS_ALL_SUCCESS":
			return {
				...state,
				1: {
					...state[1],
					ids: action.payload.map(({ id }) => id),
					sections: action.payload.map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
					})),
					loading: false,
				},
			}
		case "GET_SECTIONS_ALL_COUNT":
			return {
				...state,
				1: {
					...state[1],
					count: action.payload,
				},
			}
		case "GET_SECTIONS_ALL_SORT":
			return {
				...state,
				1: {
					...state[1],
					sort: action.payload,
				},
			}
		case "GET_SECTIONS_ALL_ERROR":
			return {
				...state,
				1: {
					...state[1],
					loading: false,
				},
			}
		case "GET_SECTIONS_ALL_PAGE":
			return {
				...state,
				1: {
					...state[1],
					page: action.payload,
				},
			}
		case "GET_SECTIONS_ALL_PERPAGE":
			return {
				...state,
				1: {
					...state[1],
					perPage: action.payload,
				},
			}
		// !--------------------------
		case "GET_SECTIONS_A_WITHOUT_B_REQUEST":
			return {
				...state,
				2: {
					...state[2],
					loading: true,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_SUCCESS":
			return {
				...state,
				2: {
					...state[2],
					sections: action.payload.map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
					})),
					ids: action.payload.map(({ id }) => id),
					loading: false,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_SORT":
			return {
				...state,
				2: {
					...state[2],
					sort: action.payload,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_COUNT":
			return {
				...state,
				2: {
					...state[2],
					count: action.payload,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_ERROR":
			return {
				...state,
				2: {
					...state[2],
					loading: false,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_PAGE":
			return {
				...state,
				2: {
					...state[2],
					page: action.payload,
				},
			}
		case "GET_SECTIONS_A_WITHOUT_B_PERPAGE":
			return {
				...state,
				2: {
					...state[2],
					perPage: action.payload,
				},
			}
		// !--------------------------
		case "GET_SECTIONS_B_WITHOUT_A_REQUEST":
			return {
				...state,
				3: {
					...state[3],
					loading: true,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_SUCCESS":
			return {
				...state,
				3: {
					...state[3],
					ids: action.payload.map(({ id }) => id),
					sections: action.payload.map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
					})),
					loading: false,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_COUNT":
			return {
				...state,
				3: {
					...state[3],
					count: action.payload,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_SORT":
			return {
				...state,
				3: {
					...state[3],
					sort: action.payload,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_ERROR":
			return {
				...state,
				3: {
					...state[3],
					loading: false,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_PAGE":
			return {
				...state,
				3: {
					...state[3],
					page: action.payload,
				},
			}
		case "GET_SECTIONS_B_WITHOUT_A_PERPAGE":
			return {
				...state,
				3: {
					...state[3],
					perPage: action.payload,
				},
			}
		// !---------------------------
		case "GET_SECTIONS_A_REQUEST":
			return {
				...state,
				4: {
					...state[4],
					loading: true,
				},
			}
		case "GET_SECTIONS_A_SUCCESS":
			return {
				...state,
				4: {
					...state[4],
					ids: action.payload.map(({ id }) => id),
					sections: action.payload.map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
					})),
					loading: false,
				},
			}
		case "GET_SECTIONS_A_COUNT":
			return {
				...state,
				4: {
					...state[4],
					count: action.payload,
				},
			}
		case "GET_SECTIONS_A_SORT":
			return {
				...state,
				4: {
					...state[4],
					sort: action.payload,
				},
			}
		case "GET_SECTIONS_A_ERROR":
			return {
				...state,
				4: {
					...state[4],
					loading: false,
				},
			}
		case "GET_SECTIONS_A_PAGE":
			return {
				...state,
				4: {
					...state[4],
					page: action.payload,
				},
			}
		case "GET_SECTIONS_A_PERPAGE":
			return {
				...state,
				4: {
					...state[4],
					perPage: action.payload,
				},
			}
		// !---------------------------
		case "GET_SECTIONS_B_REQUEST":
			return {
				...state,
				5: {
					...state[5],
					loading: true,
				},
			}
		case "GET_SECTIONS_B_SUCCESS":
			return {
				...state,
				5: {
					...state[5],
					ids: action.payload.map(({ id }) => id),
					sections: action.payload.map(item => ({
						...item,
						query: {
							id: item.id,
							value: item.query,
						},
					})),
					loading: false,
				},
			}
		case "GET_SECTIONS_B_COUNT":
			return {
				...state,
				5: {
					...state[5],
					count: action.payload,
				},
			}
		case "GET_SECTIONS_B_SORT":
			return {
				...state,
				5: {
					...state[5],
					sort: action.payload,
				},
			}
		case "GET_SECTIONS_B_ERROR":
			return {
				...state,
				5: {
					...state[5],
					loading: false,
				},
			}
		case "GET_SECTIONS_B_PAGE":
			return {
				...state,
				5: {
					...state[5],
					page: action.payload,
				},
			}
		case "GET_SECTIONS_B_PERPAGE":
			return {
				...state,
				5: {
					...state[5],
					perPage: action.payload,
				},
			}
		default:
			return state
	}
}
