import React from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from 'react-router-scroll-top';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import App from 'root/App/index.js';
import SnackbarProvider from 'root/components/SnackbarProvider';

import ErrorBoundary from './hoc/ErrorBoundary';
import { GlobalCssPriority } from './root/App/GlobalCssPriority';
import { PlainCssPriority } from './root/App/PlainCssPriority';
import { setupStore } from './root/store';
import * as serviceWorker from './serviceWorker';

import { palette } from 'root/js/utils';
// import App from "./root/App/index-temp.js";

process.env.NODE_ENV === 'development' &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });

const theme = createTheme({
  palette: {
    primary: {
      main: palette.pink
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  shape: {
    borderRadius: 3
  },
  breakpoints: {
    values: {
      xl: 1920,
      lg: 1280,
      md: 960,
      sm: 600,
      xs: 0
    }
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 16,
          fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`
        },
        h4: {
          color: palette.gunPowderColor,
          fontSize: '24px',
          fontWeight: '500'
        },
        h5: {
          color: palette.gunPowderColor,
          fontSize: '18px',
          fontWeight: '500'
        },
        h6: {
          color: palette.gunPowderColor,
          fontSize: '16px',
          fontWeight: '500'
        },
        subtitle1: {
          color: palette.lightSlateGray,
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '16px'
        },
        subtitle2: {
          color: palette.lightSlateGray,
          fontSize: '16px',
          fontWeight: '400'
        }
      }
    },
    MuiAccordionSummary: {
      content: {
        '&.Mui-expanded': {
          margin: 0
        }
      }
    }
  }
});
const generateClassName = createGenerateClassName();
const store = setupStore();
let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <StylesProvider injectFirst generateClassName={generateClassName}>
        <GlobalCssPriority>
          <PlainCssPriority>
            <ThemeProvider theme={theme}>
              <Router basename={process.env.REACT_APP_ROUTER_BASE_PATH} disableScrollToTop={true}>
                <ScrollToTop>
                  <Provider store={store}>
                    <PersistGate persistor={persistor}>
                      <SnackbarProvider>
                        <App />
                      </SnackbarProvider>
                    </PersistGate>
                  </Provider>
                </ScrollToTop>
              </Router>
            </ThemeProvider>
          </PlainCssPriority>
        </GlobalCssPriority>
      </StylesProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
