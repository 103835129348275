import React from "react"

import Button from "@mui/material/Button"
import classnames from "classnames"


import styles from "./index.module.scss"

const InlineButton = props => {
	let {
		underlined,
		inline,
		whitespace = "nowrap",
		bigger,
		fullWidth,
		children,
		iconAfter: IconAfter,
		iconBefore: IconBefore,
		variant,
		isLoading,
		className,
		...rest
	} = props

	return (
		<Button
			{...rest}
			data-variant={variant}
			className={classnames(styles.root, className)}
			data-fullwidth={fullWidth}
			data-bigger={bigger}
			disableRipple
			data-whitespace={whitespace}
			data-inline={inline}
		>
			{IconBefore && <IconBefore className={styles.iconBefore} />}
			<span
				className={styles.label}
				data-underlined={underlined}
			>
				{children}
				{isLoading && "..."}
			</span>
			{IconAfter && <IconAfter className={styles.iconAfter} />}
		</Button>
	)
}

export default InlineButton
