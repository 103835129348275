//здесь находятся функции для работы с метрикой

//логирует действие, только для прода
// const log = (...d) => process.env.NODE_ENV === "development" && console.log(...d)
const log = (...d) => process.env.NODE_ENV === "development" && console.log(...d)

//ф-я запроса к метрике
const ymAPI = (method, data) => {
	if (window.ym) {
		window.ym(process.env.REACT_APP_YANDEX_METRIKA_ID, method, data)
	}
}

//достижение цели
const reachGoal = goal => {
	ymAPI("reachGoal", goal)
	log(`Достижение цели: ${goal}`)
}

//устновка параметров юзера
const userParams = data => {
	ymAPI("setUserID", data.UserID)
	setTimeout(() => ymAPI("userParams", data), 1000)
	// log("Передача параметра посетителя:", data)
}

//электронная коммерция
//покупка товара
const buyProduct = ({id, product}) => {
	window.dataLayer = window.dataLayer || []
	//прочитайте доку к ecommerce для я.метрики
	window.dataLayer.push({
		ecommerce: {
			currencyCode: "RUB",
			purchase: {
				actionField: {
					id,
					goal_id: 233369861,
				},
				products: [product],
			},
		},
	})
	log("Покупка товара:", {id, product})
}

export {buyProduct, userParams, reachGoal}
