import { createSlice } from "@reduxjs/toolkit"

import { checkKeyword, checkKeywords, setTabKeyword } from "./actions"

const initialState = {
	keywordsCheckBoxes: [],
	keywords: [],
	fullIds: [],
	fullKeywords: [],
	tab: 0,
}

const keywords = createSlice({
	name: "monitoring keywords",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setTabKeyword.fulfilled, (state, { payload }) => {
			state.tab = payload
		})
		builder.addCase(checkKeywords.fulfilled, (state, { payload }) => {
			state.fullIds = payload[0]
			state.fullKeywords = payload[1]
		})
		builder.addCase(checkKeyword.fulfilled, (state, { payload }) => {
			state.keywordsCheckBoxes = payload[0]
			state.keywords = payload[1]
		})

	},
})

export const keywordsActions = {
	...keywords.actions,
	checkKeyword,
	checkKeywords,
	setTabKeyword,
}

export default keywords.reducer
