import { createAsyncThunk } from "@reduxjs/toolkit"

export const checkKeyword = createAsyncThunk(
	"/checkKeyword",
	async (data) => data
)
export const checkKeywords = createAsyncThunk(
	"/checkKeywords",
	async (data) => data
)
export const setTabKeyword = createAsyncThunk(
	"/setTabKeyword",
	async (tab) => tab
)
