import React, {forwardRef} from "react"

import CloseIcon from "@mui/icons-material/Close"
import classnames from "classnames"
import {SnackbarContent, useSnackbar} from "notistack"


import styles from "./index.module.scss"

const SnackbarMessage = forwardRef((props, ref) => {
	const {closeSnackbar} = useSnackbar()
	const {
		id,
		title = "Уведомление от Wildbox",
		content,
		message,
		variant = "default",
		className,
		classes = {},
		...rest
	} = props

	return (
		<SnackbarContent
			ref={ref}
			className={classnames(styles.root, className, classes.root)}
			{...rest}
		>
			<CloseIcon
				className={styles.closeIcon}
				onClick={() => closeSnackbar(id)}
			/>
			{title && <div className={styles.title}>{title}</div>}
			{message && <div className={styles.message}>{message}</div>}
			{content && content}
			{variant && (
				<div
					className={styles.indicator}
					data-type={variant}
				/>
			)}
		</SnackbarContent>
	)
})

export default SnackbarMessage
