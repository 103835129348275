import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M12.0002 2C6.47687 2 2 6.4776 2 12C1.99951 17.5242 6.47711 22 12.0002 22C17.5216 22 22.0005 17.5242 22.0005 12C22.0005 6.4776 17.5219 2 12.0002 2ZM12.0002 3.66654C15.0823 3.66654 17.7674 5.34486 19.2087 7.83315H12.0002C10.2797 7.83242 8.80515 8.87566 8.1689 10.3627L5.86557 6.37413C7.38837 4.71408 9.56943 3.66654 12.0002 3.66654ZM14.5003 12C14.5003 13.381 13.3806 14.5001 12.0002 14.5001C10.6192 14.5001 9.50019 13.381 9.50019 12C9.50019 10.6197 10.6192 9.49994 12.0002 9.49994C13.3806 9.49994 14.5003 10.6197 14.5003 12ZM10.1917 20.1314C6.45988 19.3047 3.6663 15.9821 3.66679 12C3.66679 10.4832 4.07857 9.06492 4.78654 7.83989L8.39161 14.0833C9.25116 15.5733 10.8924 16.3294 12.498 16.1366L10.1917 20.1314ZM12.0002 20.3335L15.6086 14.0833C16.4691 12.5933 16.3031 10.795 15.3328 9.49994L19.9502 9.49921C20.1987 10.289 20.3335 11.1285 20.3335 12C20.3335 16.6036 16.6013 20.3335 12.0002 20.3335Z" />
	</Template>
))
