import { createAsyncThunk, createSlice /*, rejectWithValue*/ } from "@reduxjs/toolkit"
import { pathOr, prop, propOr } from "ramda"

import API from "root/js/api"
// import * as R from "ramda"
// import {checkTariffExpired, setError} from "../user/userSlice"
// import {fetchProductDynamic} from "../productDynamics/productDynamicsSlice"

const WBAPI = new API()

const initialState = {
	isLoading: true,
	error: "",
	id: null,
	tariffExpiration: null,
	name: "",
	sum: 0,
	expireDays: 0,
	requestsPerDay: 0,
	paused_tariffs: [],
}

export const getUserTariff = createAsyncThunk(
	"userTariff/getUserTariff",
	async (obj, { rejectWithValue }) => {
		try {
			const result = await WBAPI.getCompanyTariff()
			return {
				id: pathOr(null, ["tariff_plan", "id"], result),
				// id: 2,
				name: pathOr("Демо", ["tariff_plan", "name"], result),
				tariffExpiration: propOr(null, "tariff_expiration", result),
				sum: pathOr("", ["tariff_plan", "sum"], result),
				expireDays: pathOr("", ["tariff_plan", "expire_days"], result),
				canUpgrade: pathOr("", ["tariff_plan", "can_upgrade"], result),
				requestsPerDay: pathOr("", ["tariff_plan", "requests_per_day"], result),
				paused_tariffs: propOr([], "paused_tariffs", result),
			}
		} catch (error) {
			console.log(error)
			return rejectWithValue({
				error: "Не удалось получить информацию о тарифе. Обновите вкладку",
			})
		}
	}
)

const userTariffSlice = createSlice({
	name: "userTariff",
	initialState,
	reducers: {
		setUserTariff(state, { payload }) {
			state.id = payload.id
			state.name = payload.name
			state.sum = payload.sum
			state.tariffExpiration = payload.tariffExpiration
			state.expireDays = payload.expireDays
			state.canUpgrade = payload.canUpgrade
			state.requestsPerDay = payload.requestsPerDay
			state.paused_tariffs = payload.paused_tariffs
		},
	},
	extraReducers: {
		[getUserTariff.pending]: state => {
			state.isLoading = true
			state.error = ""
		},
		[getUserTariff.fulfilled]: (state, { payload }) => {
			state.isLoading = false
			state.error = ""
			state.id = payload.id
			state.name = payload.name
			state.sum = payload.sum
			state.tariffExpiration = payload.tariffExpiration
			state.expireDays = payload.expireDays
			state.canUpgrade = payload.canUpgrade
			state.requestsPerDay = payload.requestsPerDay
			state.paused_tariffs = payload.paused_tariffs
		},
		[getUserTariff.rejected]: (state, { payload }) => {
			state.isLoading = false
			state.error = payload.error
		},
	},
})

export const { setUserTariff } = userTariffSlice.actions

export default userTariffSlice.reducer
