import {articlesComparisonActions} from "./articlesComparison/articlesComparisonSlice"
import {groupingActions} from "./grouping/groupingSlice"
import {marksActions} from "./marks/marksSlice"
import {sppActions} from "./monitoring/spp/sppSlice"
import {paymentHistoryActions} from "./paymentHistory/paymentHistorySlice"

export default {
	...sppActions,
	...marksActions,
	...groupingActions,
	...paymentHistoryActions,
	...articlesComparisonActions,
}
