import { createSlice } from "@reduxjs/toolkit"

const initialState = {
	data: [],
	ids: [],
	supplier_articles: [],
	count: 0,
	isLoading: false,
	page: 1,
	perPage: 25,
	checkBox: {}
}


const monitoringTrafficsSlice = createSlice({
	name: "monitoringTraffics",
	initialState,
	reducers: {
		setTraffics(state, { payload }) {
			state.supplier_articles = payload.map(({ supplier_article }) => supplier_article)
			state.ids = payload.map(({ id }) => id)
			state.data = payload
		},
		setTrafficsIsloading(state, { payload }) {
			state.isLoading = payload
		},
		setTrafficsDataCount(state, { payload = 0 }) {
			state.count = payload
		},
		handleTablePage(state, { payload = 1 }) {
			state.page = payload
		},
		clickCheckBox(state, { payload }) {
			state.checkBox[payload.id] = payload.bool
		}
	}

})

export const {
	setTraffics,
	setTrafficsIsloading,
	setTrafficsDataCount,
	handleTablePage,
	clickCheckBox
} = monitoringTrafficsSlice.actions

export default monitoringTrafficsSlice.reducer
