import React, { Fragment, Suspense, useEffect, useMemo, useState } from "react";

import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { enqueueSnackbar } from "notistack";
import { isEmpty, isNil, length, propOr } from "ramda";
import Helmet from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from "react-router-dom";
import { userParams } from "root/js/ym";
import { setProfile } from "root/store/profile/actions";

import API from "root/js/api";

import Banner from "root/components/Banner";
import PromoAccess from "root/components/promoAccessModal";

import Drawer from "../components/Drawer";
import Header from "../components/Header";
import LinkPure from "../components/LinkPure";
import Loading from "../components/Loading";
import NoTariffDialog from "../components/NoTariffDialog";
import Notifications from "../components/Notifications";
import TooManyRequestsDialog from "../components/TooManyRequestsDialog";
// import Debriefing from "../icons/Debriefing";
import ErrorPage from "../pages/error/components/ErrorPage";
import { removeEmptyEnqueueSnackbar, removeErrorEnqueueSnackbar, removeSuccessEnqueueSnackbar, setLimits, startFetchingNewData } from "../store/bloggersSearchSlice/actions";
import { setError } from "../store/user/userSlice";
import { getUserTariff } from "../store/userTariff/userTariff";

import styles from "./index.module.scss";

import ChromeIcon from "root/icons/Chrome";
import HunterTelegramIcon from "root/icons/HunterTelegram";
import LinkToWBIcon from "root/icons/LinkToWB"
import LogOutIcon from "root/icons/LogOut";
import MonitoringHomePageIcon from "root/icons/MonitoringHomePage";
import MonitoringMenuIcon from "root/icons/MonitoringMenu";
import MySalesMenuIcon from "root/icons/MySalesMenu";
import NicheMenuIcon from "root/icons/NicheMenu";
import Optimization from "root/icons/Optimization";
import Partner from "root/icons/Partner";
// import PinIcon from "root/icons/Pin"
import YouTubeIcon from "root/icons/YouTube";
import СompetitorsMenuIcon from "root/icons/СompetitorsMenu";

import "root/css/global.scss";

const WBAPI = new API();
// const urlAlways = ["/decoding-detail"]
const otherPages = [
  {
    url: "/signin",
    component: React.lazy(() => import("../pages/sign/App"))
  },
  // {
  // 	url: "/signup",
  // 	component: React.lazy(() => import("../pages/sign/App")),
  // },
  {
    url: "/restore-password",
    component: React.lazy(() => import("../pages/sign/App"))
  },
  {
    url: "/set-password",
    component: React.lazy(() => import("../pages/sign/App"))
  },
  {
    url: "/change-password",
    component: React.lazy(() => import("../pages/sign/App"))
  },
  {
    url: "/callback",
    component: React.lazy(() => import("../pages/sign/App"))
  },
  {
    url: "/expired",
    component: React.lazy(() => import("../pages/expired/App"))
  },
  {
    url: "/error/:code",
    component: React.lazy(() => import("../pages/error/App"))
  },
  {
    url: "/transaction",
    component: React.lazy(() => import("../pages/cabinet/components/Transaction"))
  },
  {
    url: "/iframe/product-chart/:id/:token",
    component: React.lazy(() => import("../pages/iframe-product-chart/App"))
  },
  {
    url: "/iframe/keywords-chart/:id/:token",
    component: React.lazy(() => import("../pages/iframe-keywords-chart/App"))
  },
  {
    url: "/iframe/keywords-table/:id/:token",
    component: React.lazy(() => import("../pages/iframe-keywords-table/App"))
  },
  {
    url: "/iframe/request-analyzer/:keyword/:token",
    component: React.lazy(() => import("../pages/iframe-request-analyzer/App"))
  },
  {
    url: "/iframe/wordstat/:keyword/:token",
    component: React.lazy(() => import("../pages/keystat/App"))
  },
  {
    url: "/decoding-detail",
    component: React.lazy(() => import("../pages/decoding-detail/App"))
  },
  {
    url: "/setting",
    component: React.lazy(() => import("../pages/setting/App"))
  },
  {
    url: "/connections",
    component: React.lazy(() => import("../pages/connections/App"))
  },
  {
    url: "/iframe/clusterer/:keyword/:token",
    component: React.lazy(() => import("../pages/clusterer/App"))
  },
  {
    url: "/iframe/geo-size-location/:product/:token",
    component: React.lazy(() => import("../pages/geo-location/components/FormedList"))
  },

  {
    // title: "Анализ источников трафика",
    url: "/bloggers-data",
    component: React.lazy(() => import("../pages/bloggers/App")),
    // tariffIds: [4, 14],
    // exact: false
  },
  {
    url: "/iframe/keys-finder/:keyword/:token",
    component: React.lazy(() => import("../pages/keys-finder/components/FormedList"))
  },
  {
    url: "/iframe/position/:product_id/:phrase/:token",
    component: React.lazy(() => import("../pages/position/components/FormedList"))
  },
  {
    url: "/partial-purchase/:token",
    component: React.lazy(() => import("../pages/partial-purchase/components/FormedList")),
  },

];

const App = (/*props*/) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  let { token } = useParams();
  const [userProfile, setUserProfile] = useState({});

  const getVisableDrawerPage = (dP) => {
    if (userProfile.company?.is_admin && process.env.NODE_ENV === "development") return dP
    return dP.map(s => {
      if (s.type === "section") return ({ ...s, children: s.children.filter(item => !item.onlyAdminInDev) })
      return s
    })
  }

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  //const [userName, setUserName] = useState("Кабинет")
  const [isAuth, setIsAuth] = useState(true);

  const { error, expiredTariff } = useSelector(state => state.user);
  const { id: tariffId, name: tariffName, isLoading } = useSelector(state => state.userTariff);
  const { white_label } = useSelector(state => state.profile);

  useEffect(() => {
    if (location.pathname.includes("/iframe")) {
      localStorage.setItem(
        "token",
        // location.pathname.split("/").at(-1)
        propOr("", length(location.pathname.split("/")) - 1, location.pathname.split("/"))
      );
    }
  }, [token]);

  useEffect(() => {
    if (location.pathname.includes("/iframe")) return;

    dispatch(getUserTariff());
    //dispatch(checkTariffExpired())
  }, []);

  useEffect(() => {
    dispatch(setError(""));
  }, [location]);

  const drawerPages = useMemo(
    () =>
      [
        {
          type: "section",
          title: "Анализ конкурентов",
          icon: MonitoringHomePageIcon,
          expanded: true,
          children: [
            {
              url: "/search/product",
              component: React.lazy(() => import("../pages/search/components/Product")),
              title: "Анализ артикула",
              exact: false
            },
            {
              url: "/search/brand",
              component: React.lazy(() => import("../pages/search/components/Brand")),
              title: "Анализ бренда",
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              exact: false
            },
            {
              url: "/search/seller",
              component: React.lazy(() => import("../pages/search/components/Seller")),
              title: "Анализ поставщика",
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              exact: false
            },
            {
              url: "/search/category",
              component: React.lazy(() => import("../pages/search/components/Category")),
              title: "Анализ категорий",
              exact: false
            },
            {
              url: "/analysis-by-subject",
              component: React.lazy(() => import("../pages/analysis-by-subject/App")),
              title: "Анализ предметов",
              tariffIds: [1, 3, 4, 5, 14, 15, 16],
              exact: false,
            },
            {
              url: "/analysis-by-characteristics",
              component: React.lazy(() => import("../pages/analysis-by-characteristics/App")),
              title: "Анализ характеристик",
              tariffIds: [1, 4, 5, 14, 15, 16],
              exact: false,
            },
            // {
            //   url: "/search-analytics",
            //   component: React.lazy(() => import("../pages/current-search-analytics/App")),
            //   title: "Анализ топов поиска",
            //   exact: false
            // },
            {
              url: "/search-tops-analysis",
              component: React.lazy(() => import("../pages/search-tops-analysis/App")),
              title: "Анализ топов поиска",
              exact: false
            },
            {
              title: "Сравнительный анализ",
              url: "/articles-comparison",
              component: React.lazy(() => import("../pages/articles-comparison/App")),
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              url: "/geo-size-location",
              component: React.lazy(() => import("../pages/geo-location/App")),
              title: "ГЕО Видимость",
              exact: false
              // tariffIds: [1, 2, 3, 4, 5, 14, 15],
            },
            {
              url: "/brands-rating",
              component: React.lazy(() => import("../pages/brands-rating/App")),
              title: "Рейтинг брендов",
              onlyAdminInDev: true,
              exact: false
            },
            {
              url: "/sellers-rating",
              component: React.lazy(() => import("../pages/sellers_rating/App")),
              title: "Рейтинг поставщиков",
              onlyAdminInDev: true,
              exact: false
            },
          ]
        },
        {
          type: "section",
          // title: "Умные фильтры",
          title: "Ниши и тренды",
          icon: СompetitorsMenuIcon,
          children: [
            {
              url: "/trend-requests",
              component: React.lazy(() => import("../pages/trend-requests/App")),
              title: "Трендовые запросы",
              exact: false,
              tariffIds: [1, 3, 5, 4, 14, 15, 16]
            },
            {
              url: "/product-analyzer",
              component: React.lazy(() => import("../pages/product-analyzer/App")),
              title: "По товарам",
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              url: "/brand-analyzer",
              component: React.lazy(() => import("../pages/brand-analyzer/App")),
              title: "По брендам",
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16]
            },
            {
              url: "/seller-analyzer",
              component: React.lazy(() => import("../pages/seller-analyzer/App")),
              title: "По поставщикам",
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16]
            },
            {
              url: "/category-analyzer",
              component: React.lazy(() => import("../pages/category-analyzer/App")),
              title: "По категориям",
              exact: false
            },
            {
              url: "/request-analyzer",
              component: React.lazy(() => import("../pages/request-analyzer/App")),
              title: "По запросам",
              exact: false,
              tariffIds: [1, 2, 3, 5, 4, 14, 15, 16]
            }
          ]
        },
        {
          type: "section",
          title: "продвижение",
          icon: Optimization,
          children: [
            // {
            //   title: "Подключения РК",
            //   url: "/cluster-connection",
            //   component: React.lazy(() => import("../pages/connections/App")),
            //   exact: false,
            //   tariffIds: [1, 5, 3, 4, 14, 15],
            //   openIt: true
            // },
            {
              title: "Поиск трафика",
              url: "/bloggers-search",
              component: React.lazy(() => import("../pages/bloggers-search/App")),
              tariffIds: [4, 14, 16],
              exact: false
            },

            {
              // title: "Оптимизация рекламы",
              title: "Автореклама (Бустер)",
              url: "/auto-advertising",
              component: React.lazy(() => import("../pages/auto-advertising/App")),
              exact: false
              // tariffIds: [1, 5, 4],
            },
            {
              // title: "Оптимизация рекламы",
              title: "Реклама в поиске",
              url: "/advertising-optimization",
              component: React.lazy(() => import("../pages/advertising-optimization/App")),
              exact: false
              // tariffIds: [1, 5, 4],
            },
            {
              // title: "Оптимизация рекламы",
              title: "Реклама в карточке",
              url: "/product-optimization",
              component: React.lazy(() => import("../pages/product-optimization/App")),
              exact: false
            },
            {
              // title: "Оптимизация рекламы",
              title: "Реклама в каталоге",
              url: "/category-optimization",
              component: React.lazy(() => import("../pages/category-optimization/App")),
              exact: false
            },
            {
              title: "Рекомендации",
              url: "/top-find",
              component: React.lazy(() => import("../pages/top-find/App")),
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              title: "Проверка кластера",
              url: "/clusterer",
              component: React.lazy(() => import("../pages/clusterer/App")),
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              openIt: true
            },

            {
              title: "Кластеризатор",
              url: "/groupClusterer",
              component: React.lazy(() => import("../pages/group_clusterer/App")),
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              openIt: true
            },
            {
              title: "UTM-метки",
              url: "/utm-links",
              component: React.lazy(() => import("../pages/utm-generator/App")),
              exact: false,
            },
          ]
        },
        // {
        // 	type: "section",
        // 	title: "Аналитика продаж",
        // 	icon: MySalesHomePage,
        // 	children: [
        // 		{
        // 			url: "/sales-orders",
        // 			component: React.lazy(() => import("../pages/position/App")),
        // 			title: "Продажи и заказы",
        // 			exact: false,
        // 			// disabled: true,
        // 		},
        // 	],
        // },
        {
          type: "section",
          title: "SEO",
          icon: NicheMenuIcon,
          children: [
            {
              // title: "ChatGPT",
              title: "SEO-тексты",
              url: "/chat-gpt",
              component: React.lazy(() => import("../pages/chatGPT/App")),
              exact: false,
              tariffIds: [1, 2, 3, 5, 4, 14, 15, 16]
            },
            {
              url: "/position",
              component: React.lazy(() => import("../pages/position/App")),
              title: "Позиции в поиске",
              exact: false
            },
            {
              url: "/grouping",
              component: React.lazy(() => import("../pages/groupingNew/App")),
              title: "Группировка запросов",
              exact: false
            },
            // {
            // 	url: "/wordstat",
            // 	component: React.lazy(() => import("../pages/keystat/App")),
            // 	title: "Популярность запросов",
            // 	exact: false,
            // },
            {
              url: "/wordstat",
              component: React.lazy(() => import("../pages/keystat/App")),
              title: "Популярность запросов",
              exact: false
            },
            {
              url: "/keys-finder",
              component: React.lazy(() => import("../pages/keys-finder/App")),
              title: "Поисковые подсказки",
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16]
            },
            {
              title: "Запросы у списков",
              url: "/articles-keys",
              component: React.lazy(() => import("../pages/articles-keys/App")),
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16]
            },
            {
              title: " Запросы у топов поиска",
              url: "/keys-top-find",
              component: React.lazy(() => import("../pages/keys-top-find/App")),
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              title: "Пылесос запросов",
              url: "/sellers-keys",
              component: React.lazy(() => import("../pages/sellers-keys/App")),
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              title: "Сравнение списков",
              url: "/keys-comparison",
              component: React.lazy(() => import("../pages/keys-comparison/App")),
              exact: false,
              tariffIds: [1, 5, 4, 14, 15, 16]
            },
            {
              url: "/check-products-by-keys/product",
              component: React.lazy(() =>
                import("../pages/check-products-by-keys/input/components/Product")
              ),
              title: "Вхождение запросов",
              // disabled: true,
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16]
            },
            {
              url: "/seo-ai",
              component: React.lazy(() =>
                import("../pages/seo-ai/App")
              ),
              title: "SEO-AI",
              // disabled: true,
              exact: false,
            },
            // {
            // 	title: "Оптимизация рекламы",
            // 	url: "/advertising-optimization",
            // 	component: React.lazy(() =>
            // 		import("../pages/advertising-optimization/App")
            // 	),
            // 	exact: false,
            // },

            //? {
            //? 	title: "Трекер позиций",
            //? 	disabled: true,
            //? },
          ]
        },
        {
          type: "section",
          title: "Мониторинг",
          icon: MonitoringMenuIcon,
          children: [
            {
              url: "/monitoring?tab=0",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Товаров",
              exact: false
            },
            {
              url: "/monitoring?tab=1",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Брендов",
              exact: false
            },
            {
              url: "/monitoring?tab=2",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Поставщиков",
              exact: false
            },
            {
              url: "/monitoring?tab=3",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Категорий",
              exact: false
            },
            {
              url: "/monitoring?tab=4",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Запросов",
              exact: false
            },
            {
              url: "/monitoring?tab=5",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "СПП",
              exact: false
            },
            {
              url: "/monitoring?tab=6",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Позиций в поиске",
              exact: false
            },
            {
              url: "/monitoring?tab=7",
              component: React.lazy(() => import("../pages/monitoring/App")),
              title: "Внешнего трафика",
              exact: false,
              tariffIds: [2, 3, 4, 14]
            }
          ]
        },

        // {
        //   type: "section",
        //   title: "Разбор полётов",
        //   disabled: false,
        //   icon: Debriefing,
        //   children: [
        //     {
        //       url: "/settingsdebriefing",
        //       component: React.lazy(() => import("../pages/settings-debriefing/App")),
        //       title: "Настройки",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/businessanalytics",
        //       component: React.lazy(() => import("../pages/business-analytics/App")),
        //       title: "Бизнес-аналитика",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {

        //       url: "/financelogic",
        //       component: React.lazy(() => import("../pages/financial-logic/App")),
        //       title: "Финансовая логика",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,

        //     },
        //     {
        //       url: "/economiclogic",
        //       component: React.lazy(() => import("../pages/economic-logic/App")),
        //       title: "Экономическая логика",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/inventorumanagement",
        //       component: React.lazy(() =>
        //         import("../pages/inventory-management/App")
        //       ),
        //       title: "Управление запасами",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/inventorumanagement",
        //       component: React.lazy(() =>
        //         import("../pages/inventory-management/App")
        //       ),
        //       title: "Управление ценами",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/adverstingmanagment",
        //       component: React.lazy(() =>
        //         import("../pages/advertising-managment/App")
        //       ),
        //       title: "Управление рекламой",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "solutionfbs",
        //       component: React.lazy(() => import("../pages/solutions-for-fbs/App")),
        //       title: "Решения для FBS",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/inventorumanagement",
        //       component: React.lazy(() => import("../pages/support-services/App")),
        //       title: "Инструменты нейросетей",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/inventorumanagement",
        //       component: React.lazy(() => import("../pages/support-services/App")),
        //       title: "Тематические калькуляторы",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //     {
        //       url: "/business-economic",
        //       component: React.lazy(() => import("../pages/business-economic/App")),
        //       title: "Экономика бизнеса",
        //       tariffIds: [1, 5, 3, 4, 14, 15],
        //       openIt: true,
        //       exact: false,
        //     },
        //   ],
        // },
        {
          type: "section",
          title: "Внутренняя аналитика",
          icon: MySalesMenuIcon,
          disabled: false,
          children: [


            // {
            //   url: "/connections",
            //   component: React.lazy(() => import("../pages/connections/App")),
            //   title: "Подключения",
            //   exact: false,
            //   tariffIds: [1, 5, 3, 4, 14, 15],
            //   openIt: true
            // },
            {
              url: "/setting",
              component: React.lazy(() => import("../pages/setting/App")),
              title: "Настройки",
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              openIt: true,
              exact: false
            },
            {
              url: "/decoding-detail?tab=decoding-detail",
              component: React.lazy(() => import("../pages/decoding-detail/App")),
              title: "Расшифровка детализаций",
              exact: false,
              tariffIds: [1, 5, 3, 4, 14, 15, 16],
              openIt: true
            },
            {
              url: "/decoding-detail?tab=abc_analysis",
              component: React.lazy(() => import("../pages/decoding-detail/App")),
              title: "АВС-анализ",
              // disabled: true,
              exact: false
              // tariffIds: [1, 5, 3, 4],
              // openIt: true,
            },
            {
              url: "/decoding-detail?tab=unit_economy",
              component: React.lazy(() => import("../pages/decoding-detail/App")),
              title: "Юнит-экономика",
              // disabled: true,
              exact: false
              // tariffIds: [1, 5, 3, 4],
              // openIt: true,
            },
            {
              url: "/decoding-detail?tab=supply_plan",
              component: React.lazy(() => import("../pages/decoding-detail/App")),
              title: "План поставок",
              // disabled: true,
              exact: false
              // tariffIds: [1, 5, 3, 4],
              // openIt: true,
            }
            // !-------
            // {
            // 	url: "/warehouse-layout",
            // 	component: React.lazy(() => import("../pages/warehouse-layout/App")),
            // 	title: "Раскладка по складам",
            // 	// disabled: true,
            // 	exact: false,
            // 	// tariffIds: [1, 5, 3, 4],
            // 	// openIt: true,
            // },
            // !-------
            // {
            // 	url: "/product-analytics",
            // 	component: React.lazy(() => import("../pages/product-analytics/App")),
            // 	title: "Товары",
            // 	// disabled: true,
            // 	exact: false,
            // 	// tariffIds: [1, 5, 3, 4],
            // 	// openIt: true,
            // },
            // {
            // 	url: "/sales-and-orders",
            // 	component: React.lazy(() => import("../pages/sales-and-orders/App")),
            // 	title: "Продажи и заказы",
            // 	// disabled: true,
            // 	exact: false,
            // },
            // !-------
            // {
            // 	url: "/sales-and-orders",
            // 	component: React.lazy(() => import("../pages/sales-and-orders/App")),
            // 	title: "Продажи и заказы",
            // 	// disabled: true,
            // 	exact: false,
            // },
            // ,
            // {
            // 	url: "/orders-geo",
            // 	component: React.lazy(() => import("../pages/geo-orders/App")),
            // 	title: "География заказов",
            // 	// disabled: true,
            // 	exact: false,
            // },
          ]
        },
        {
          type: "sectionItem",
          // title: "Wildbox.Bot",
          title: "Подключения",
          icon: LinkToWBIcon,
          url: "/wb-cabinets",
          component: React.lazy(() => import("../pages/connections/App")),
          exact: false
        },
        {
          type: "sectionItem",
          // title: "Wildbox.Hunter",
          title: "Браузерное расширение",
          icon: ChromeIcon,
          url: "/cabinet?tab=2",
          component: React.lazy(() => import("../pages/cabinet/App")),
          exact: false
        },
        {
          type: "sectionItem",
          // title: "Wildbox.Bot",
          title: "Телеграм бот",
          icon: HunterTelegramIcon,
          url: "/cabinet?tab=3",
          component: React.lazy(() => import("../pages/cabinet/App")),
          exact: false
        },
        {
          type: "sectionItem",
          title: "Мой профиль",
          icon: AccountCircleOutlinedIcon,
          url: "/cabinet?tab=0",
          component: React.lazy(() => import("../pages/cabinet/App"))
        },
        {
          type: "sectionItem",
          title: "Оплата сервиса",
          icon: ErrorOutlineIcon,
          url: "/tariff",
          component: white_label === "megaphone" ? React.lazy(() => import("../pages/white-label")) : React.lazy(() => import("../pages/tariff/App"))
        },
        // {
        // 	type: "sectionItem",
        // 	title: "Внутренняя аналитика",
        // 	icon: MySalesMenuIcon,
        // 	disabled: true,
        // },
        // {
        // 	type: "section",
        // 	title: "Внутренняя аналитика",
        // 	icon: MySalesMenuIcon,
        // 	// icon: ErrorOutlineIcon,
        // 	children: [
        // 		{
        // 			url: "/my-reults",
        // 			component: React.lazy(() => import("../pages/my-results/App")),
        // 			title: "Оперативный отчет",
        // 			exact: false,
        // 		},
        // 		{
        // 			url: "/scoreboard",
        // 			component: React.lazy(() => import("../pages/scoreboard/App")),
        // 			title: "Онлайн табло",
        // 			exact: false,
        // 		},
        // 		{
        // 			url: "/my-warehouse",
        // 			component: React.lazy(() => import("../pages/my-warehouse/App")),
        // 			title: "Мой склад",
        // 			exact: false,
        // 		},
        // 	],
        // },
        {
          type: "sectionItem",
          title: "Партнерская программа",
          icon: Partner,
          url: "/partner",
          component: React.lazy(() => import("../pages/partner/App"))
        },
        {
          type: "sectionItem",
          title: "Полезные материалы",
          icon: YouTubeIcon,
          url: "/useful",
          component: React.lazy(() => import("../pages/useful/App"))
        },
        {
          type: "sectionItem",
          title: "Выход",
          icon: LogOutIcon,
          url: "/logout",
          component: () => {
            WBAPI.resetToken();
            localStorage.clear();
            sessionStorage.clear();
            window.localStorage.clear();
            window.sessionStorage.clear();
            history.push("/signin");
            return <></>;
          },
          exact: false
        }
      ].concat(
        process.env.NODE_ENV === "development"
          ? {
            type: "sectionItem",
            title: "Иконки",
            icon: WidgetsOutlinedIcon,
            url: "/icons",
            component: React.lazy(() => import("../icons/utils/preview")),
            exact: true
          }
          : []
      ),
    [/*userName, */ history, white_label]
  );

  const servicePages = useMemo(
    () => [
      {
        url: process.env.REACT_APP_HOME_PAGE,
        showInDrawer: false,
        component: React.lazy(() => import("../pages/home/App")),
        exact: true
      },
      {
        url: "/product/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/product/App")),
        exact: true
      },
      {
        url: "/check-product-id/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/check-products-by-keys/product")),
        exact: false
      },
      {
        url: "/brand/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/brand/App")),
        exact: true
      },
      {
        url: "/category/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/category/App")),
        exact: true
      },
      {
        url: "/seller/:id",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/seller/App")),
        exact: true
      },
      ...drawerPages.reduce(
        (acc, item) => {
          if (item.type === "section") {
            return acc.concat(item.children.filter(item => !item.disabled)).map(item => {
              if ((!tariffId || expiredTariff) && !propOr(false, "openIt", item)) {
                // item.onClickHandlerDisabled = true
              } else if (
                !isLoading &&
                item.tariffIds?.length &&
                !item.tariffIds.find(id => id === tariffId) &&
                !item.openIt
                // &&equals(item.url, urlAlways)
              ) {
                item.disabled = true;
                item.onClickMessageDisabled = true;
                item.onClickHandlerDisabled = true;
                item.hoverEffect = true;
                item.hoverText = "Для данного тарифа недоступно";
              } else {
                item.onClickHandlerDisabled = false;
              }
              return {
                ...item,
                url: item.url.replace(/\?.+$/, "")
              };
            });
          } else {
            return item.disabled ? acc : acc.concat(item);
          }
        }, []),
      {
        url: "/cabinet",
        showInDrawer: false,
        component: React.lazy(() => import("../pages/cabinet/App")),
        exact: true
      }
    ],
    [drawerPages, white_label, tariffId, isLoading, expiredTariff]
  );

  // const cabinetPage = {
  // 	url: "/cabinet",
  // 	component: React.lazy(() => import("../pages/cabinet/App")),
  // 	exact: true,
  // }

  const otherPagesPaths = useMemo(() => otherPages.map(item => item.url), [otherPages]);

  const servicePaths = useMemo(
    () => [].concat(servicePages.map(page => page.url)),
    // .concat(cabinetPage.url)
    [
      // cabinetPage,
      servicePages
    ]
  );

  useEffect(() => {
    // let timer
    if (location.pathname.includes("/iframe")) return;
    (async function loop() {
      try {
        const isAuth_ = await WBAPI.isAuth();
        setIsAuth(isAuth_);
        const user = await WBAPI.getProfile();
        localStorage.setItem("user", JSON.stringify({
          id: user?.id,
          companyId: user?.company?.id
        }));
        dispatch(setProfile({ data: user }));
        setUserProfile(user);
        localStorage.setItem("user", JSON.stringify({
          id: user?.id,
          companyId: user?.company?.id
        }));


      } catch (error) {
        console.log(error);
      }
      /*const {name} = await WBAPI.getProfile()

        setUserName(name)*/
      // timer = setTimeout(loop, 20000)
    })();

    // return () => clearTimeout(timer)
  }, []);

  useEffect(() => {
    //каждые 12 сек проверяем авторизацию
    if (!isEmpty(userProfile)) {
      let timer;
      (async function loop() {
        try {
          if (tariffId && isAuth) {
            window.uid = userProfile.id; //это для ErrorMessageBody
            //устанавливаем данные юзера для метрики
            !timer &&
              setTimeout(
                () =>
                  userParams({
                    UserID: String(userProfile.id),
                    email: userProfile.email,
                    tariff: { name: tariffName, id: tariffId }
                  }),
                3000
              );
          }
        } catch (err) {
          console.error(err);
        }
        timer = setTimeout(loop, 12000);
      })();

      return () => clearTimeout(timer);
    }
  }, [tariffId, isAuth, userProfile]);

  // const supplierArticle = useSelector(store => store.bloggersSearchSlice.supplier_article)
  const dataObjects = useSelector(store => store.bloggersSearchSlice.data.objects)
  const { emptyEnqueueSnackbar = [], errorEnqueueSnackbar = [], successEnqueueSnackbar = [] } = useSelector(store => store.bloggersSearchSlice.data)
  const intervalId = useSelector(store => store.bloggersSearchSlice.intervalId)
  const limits = useSelector(store => store.bloggersSearchSlice.limits)


  useEffect(() => {
    if (!isEmpty(successEnqueueSnackbar)) {
      successEnqueueSnackbar.forEach(({ supplier_article: article }) => {
        enqueueSnackbar({
          title: `Отчет по источникам трафика для артикула ${article} сформирован.`,
          message: location.pathname === "/bloggers-search/bloggers-data" ?
            <Fragment>
              Если у вас артикула {article} в статусе «В процессе». Нажмите на кнопку «Обновить статусы загрузки», чтобы скачать отчет по источникам трафика для артикула {article}
            </Fragment>
            : (<Fragment>
              Скачать отчет можно на странице «Поиск трафика»{" -> "}<LinkPure
                to={`/bloggers-search/bloggers-data`}
                className={styles.link}
                title={"Нажмите для перехода к «История анализа источников трафика»"}
              >
                «История анализа источников трафика»
              </LinkPure>.
            </Fragment>),
          variant: "success",
        })
        dispatch(setLimits())
        dispatch(removeSuccessEnqueueSnackbar(article))
      })
    }

    if (!isEmpty(errorEnqueueSnackbar)) {
      errorEnqueueSnackbar.forEach(({ supplier_article: article }) => {
        enqueueSnackbar({
          title: `Не удалось собрать информацию по источникам трафика для артикула ${article}.`,
          message: "",
          variant: "error",
        })
        dispatch(setLimits())
        dispatch(removeErrorEnqueueSnackbar(article))
      })
    }

    if (!isEmpty(emptyEnqueueSnackbar)) {
      emptyEnqueueSnackbar.forEach(({ supplier_article: article }) => {
        enqueueSnackbar({
          title: `По данному артикулу ${article} не найдено источников трафика. Возможно публикации были размещены на ресурсах, которые невозможно собрать автоматическим путем.`,
          message: "",
          variant: "error",
        })

        dispatch(setLimits())
        dispatch(removeEmptyEnqueueSnackbar(article))
      })
    }
  }, [errorEnqueueSnackbar, emptyEnqueueSnackbar, successEnqueueSnackbar])


  useEffect(() => {
    clearInterval(intervalId);
    const startFetching = async () => {
      dispatch(startFetchingNewData({
        supplier_article_ids: dataObjects.map(({ supplier_article }) => String(supplier_article)).join(","),
        dataObjects,
        dispatch,
      }));
    }

    if (!isEmpty(dataObjects)) {
      startFetching()
    }
  }, [dataObjects])


  return (
    <>
      <CssBaseline />
      <Route
        path={servicePaths}
        render={
          isAuth || location.pathname.includes("/iframe")
            ? null
            : () => (
              <Redirect
                to={`/signin?redirect_url=${encodeURIComponent(
                  `${location.pathname}${location.search}`
                )}`}
              />
            )
        }
      />

      <Switch>
        <Route
          path={servicePaths}
          render={props => (
            <>
              <Helmet>
                <meta name="description" content="Бесплатная аналитика брендов Wildberries" />
                <title>Wildbox</title>
              </Helmet>

              <Notifications
                open={isNotificationsOpen}
                onClose={() => setIsNotificationsOpen(false)}
              />
              {/* <PromoAccess long={isDrawerOpen} /> */}
              <Drawer
                items={getVisableDrawerPage(drawerPages)}
                drawerToggle={() => setIsDrawerOpen(value => !value)}
                setIsDrawerOpen={setIsDrawerOpen}
                onClose={() => setIsDrawerOpen(false)}
                open={isDrawerOpen}
              />
              <Box className={styles.root} data-drawer-open={isDrawerOpen}>

                <Box className={styles.emptyPlace}></Box>
                <Banner isDrawerOpen={isDrawerOpen} match={props.match} />

                <Box className={styles.content}>

                  <Header
                    isDrawerOpen={isDrawerOpen}
                    drawerToggle={() => setIsDrawerOpen(value => !value)}
                    onOpenNotifications={() => setIsNotificationsOpen(true)}
                  />

                  <Switch>
                    {servicePages.map(page => (
                      <Route
                        key={page.url}
                        exact={page.exact}
                        path={page.url}
                        render={props => {
                          if (error === "throttled") {
                            return <TooManyRequestsDialog />;
                          }
                          if (isLoading) return null;

                          if (
                            expiredTariff &&
                            props.match.path === "/decoding-detail" &&
                            !props.location.search.includes("?tab=decoding-detail")
                          ) {
                            history.push("/tariff");
                            return <NoTariffDialog />;
                          }
                          if (
                            expiredTariff &&
                            props.match.path !== "/tariff" &&
                            props.match.path !== "/partner" &&
                            props.match.path !== "/cabinet" &&
                            props.match.path !== "/useful" &&
                            props.match.path !== "/logout" &&
                            props.match.path !== "/wb-cabinets" &&

                            // props.match.path !==
                            // 	"/decoding-detail" &&
                            !otherPages.find(otherPage =>
                              otherPage.url === props.match.path
                            )
                          ) {
                            history.push("/tariff");
                            return <NoTariffDialog />;
                          }

                          if (error === "internal_server_error") {
                            return <ErrorPage code={500} />;
                          }

                          if (error === "not_found") {
                            return <ErrorPage code={404} />;
                          }

                          if (page.disabled) {
                            history.push("/home");
                          }

                          return (
                            <Suspense fallback={<Loading />}>
                              <page.component isDrawerOpen={isDrawerOpen} />
                            </Suspense>
                          );
                        }}
                      />
                    ))}
                    {/* <Route
											key={cabinetPage.url}
											exact={cabinetPage.exact}
											path={cabinetPage.url}
											render={props => {
												if (error === "throttled") {
													return <TooManyRequestsDialog />
												}
												return (
													<Suspense fallback={<Loading />}>
														<cabinetPage.component />
													</Suspense>
												)
												// return <TooManyRequestsDialog />
											}}
										/> */}

                    <Route
                      exact
                      path="*"
                      render={props => {
                        if (error === "throttled") {
                          return <TooManyRequestsDialog />;
                        }
                        return <Redirect to={process.env.REACT_APP_HOME_PAGE} />;
                      }}
                    />
                  </Switch>
                </Box>
              </Box>
            </>
          )}
        />

        <Route
          path={otherPagesPaths}
          render={props => (
            <>
              <Banner fullWidth match={props.match} />
              {otherPages.map(page => (
                <Route
                  key={page.url}
                  path={page.url}
                  render={props => {
                    if (error === "throttled") {
                      return <TooManyRequestsDialog />;
                    }
                    return (
                      <Suspense fallback={<Loading />}>
                        <page.component />
                      </Suspense>
                    );
                  }}
                />
              ))}
            </>
          )}
        />

        {otherPages.map(page => (
          <Route
            key={page.url}
            path={page.url}
            render={() => {
              if (error === "throttled") {
                return <TooManyRequestsDialog />;
              }
              return (
                <Suspense fallback={<Loading />}>
                  <page.component />
                </Suspense>
              );
            }}
          />
        ))}

        {/*<Route exact path="*" render={props => <Redirect to="/" />} />*/}
        <Route exact path="*" render={() => <Redirect to={process.env.REACT_APP_HOME_PAGE} />} />
      </Switch>
    </>
  );
};

export default App;




