import {createAsyncThunk} from "@reduxjs/toolkit"
import {length} from "ramda"

import API from "root/js/api"

const WBAPI = new API()

export const getProductByPeriod = createAsyncThunk(
	"/getProductByPeriod",
	async (
		{
			ids,
			params,
			extra_fields,
			// period = 30,
			date_from,
			date_to,
			ordering = "product_ids",
		},
		thunkAPI
	) => {
		try {
			const {results} = await WBAPI.getProductsDynamic({
				...params,
				city: undefined,
				limit: length(ids),
				product_ids: String(ids),
				// period,
				date_from,
				date_to,
				ordering,
				extra_fields,
			})
			return {results, count: length(ids)}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)
