const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  keywordsTab: {
    data: [],
    checkedItems: [],
    isAllChecked: false
  }
};

const seoAISlice = createSlice({
  name: "seoAI",
  initialState,
  reducers: {
    setKeywordsData(state, { payload: data }) {
      state.keywordsTab.data = data;
      state.keywordsTab.isAllChecked = false;
    },
    toggleCheckItem(state, { payload: { id, tab } }) {
      if (state[tab].checkedItems.includes(id))
        state[tab].checkedItems = state[tab].checkedItems.filter(i => i !== id);
      else state[tab].checkedItems.push(id);
    },
    toggleCheckAll(state, { payload: tab }) {
      if (state[tab].isAllChecked) state[tab].checkedItems = [];
      else state[tab].checkedItems = state[tab].data.map(item => item.id);
      state[tab].isAllChecked = !state[tab].isAllChecked;
    },
    clearCheckedItems(state) {
      state.checkedItems = []
      state.isAllChecked = false
    }
  }
});

export const { setKeywordsData, toggleCheckItem, toggleCheckAll, clearCheckedItems } = seoAISlice.actions;
export default seoAISlice.reducer;
