import React, { Component } from "react";

class ErrorBoundaryComponent extends Component {
  constructor(props) {
    super(props);

    // Используем useState в конструкторе классового компонента
    this.state = {
      hasError: false,
      errorInfo: null
    };
  }

  // state = {
  //     hasError: false,
  //     errorInfo: null,
  // };

  static getDerivedStateFromError() {
    return { hasError: true, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", errorInfo);
    this.setState({ errorInfo });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    const errorLines = () => {
      if (this.state.errorInfo) {
        return this.state.errorInfo.componentStack.trim().split("\n");
      }
      return [];
    };

    if (hasError && process.env.NODE_ENV === "development") {
      return (
        <div>
          {errorLines().map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </div>
      );
    }
    if (hasError) {
      return null;
    }

    return children;
  }
}

export default ErrorBoundaryComponent;
