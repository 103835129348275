import { createAsyncThunk } from '@reduxjs/toolkit';
import { nanoid } from 'nanoid';
import { defaultTo, equals, isNil, length, path, prop, propOr, replace, trim } from 'ramda';

import API from 'root/js/api';

import { defaultToArr, defaultToStr } from 'root/js/utils';

const WBAPI = new API();
const regex = /^(?:[0-9]+(?:[,.][0-9]*)?|[0-9]*[,.][0-9]+)$/;
const defaultToZero = defaultTo(0);
const rowGreenCollor = {
  background: '#E2F4EC'
};
const errorArray = [
  // "not_found",
  'invalid_identifier_value',
  'negative_cost',
  'invalid_cost_value',
  'duplicate_identifier',
  'invalid_element_count',
  'cost_null_value'
];
const rowRedCollor = {
  background: 'rgb(253, 228, 235)'
};
const rowDefaultCollor = {
  background: 'white'
};

function formatNumber(numberString) {
  if (numberString[0] === ',') {
    return '0' + numberString;
  }
  let formattedString = numberString.replace(/^0(?![.,])/, '');

  if (formattedString === '') {
    return '0';
  }

  // Проверяем, что десятичный разделитель встречается только один раз
  if (formattedString.match(/[.,]/g)?.length > 1) {
    return 0;
  }

  // Если последний символ - запятая, ничего не делаем
  if (formattedString.endsWith(',')) {
    return numberString;
  }

  // Заменяем точку на запятую (если она есть)
  formattedString = formattedString.replace('.', ',');

  return formattedString;
}

// ! Barcode
export const getBarcodeData = createAsyncThunk(
  '/getBarcodeData',
  async ({ wb_api_key_id, report_id, dispatch }, thunkAPI) => {
    try {
      const barcode = await WBAPI.getAnalyticsProductCost({
        identifier: 'barcode',
        wb_api_key_ids: wb_api_key_id,
        report_id
      });
      const barcodeData = barcode
        .sort((a, b) => a.product_id - b.product_id)
        .map(item => ({
          ...item,
          style: {
            background: 'white'
          },
          cost: {
            cost: isNil(item.cost) ? 0 : replace('.', ',', String(item.cost)),
            index: nanoid(),
            type: 'barcode'
          },
          product: {
            image: item.image,
            name: item.name,
            product_id: item.product_id
          }
        }));
      return barcodeData;
    } catch (error) {
      if (error?.response?.data?.non_field_errors?.[0]?.code === 'wb_parse_error') {
        dispatch(mackWbParseError());
        return thunkAPI.rejectWithValue(
          error?.response?.data?.non_field_errors?.[0]?.code ?? 'Error'
        );
      }
      return thunkAPI.rejectWithValue(
        error?.response?.data?.non_field_errors?.[0]?.code ?? 'Error'
      );
    }
  }
);
export const saveBarcodeData = createAsyncThunk(
  '/saveBarcodeData',
  async ({ wb_api_key_id, data, dispatch, report_id }, thunkAPI) => {
    try {
      const patchData = await WBAPI.postAnalyticsProductCost({
        wb_api_key_id: wb_api_key_id,
        report_id,
        identifier: 'barcode',
        data: data
          .filter(({ code }) => (code ? code === 'success' : true))
          .map(({ identifier, cost }) => ({
            identifier,
            cost: parseFloat(replace(',', '.', String(cost.cost)))
            // cost: cost.cost,
          }))
      });
      dispatch(getArticuleData({ wb_api_key_id, report_id }));
      const getPatchStatuses = propOr([], 'data', patchData).map(item => ({
        code: item.code,
        identifier: item.data.identifier
      }));
      const barcodeData = data.map(item => {
        const { code } = getPatchStatuses.find(({ identifier }) => item.identifier === identifier);

        if (code === 'success') {
          return {
            ...item,
            style: rowGreenCollor
          };
        }
        if (errorArray.includes(code)) {
          return {
            ...item,
            style: rowRedCollor
          };
        }

        return {
          ...item,
          style: rowDefaultCollor
        };
      });

      if (!patchData.success) {
        return thunkAPI.rejectWithValue(barcodeData);
      } else {
        dispatch(
          getSaccess({
            title: 'Данные были успешно сохранены!'
          })
        );
        return barcodeData;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);

export const postSearchBarcode = createAsyncThunk(
  '/postSearchBarcode',
  async ({ text, data }, thunkAPI) => {
    try {
      if (text === '' || regex.test(text)) {
        const newData = defaultToArr(data).filter(item =>
          String(item.identifier).includes(String(defaultToStr(text).trim()))
        );

        return { text, data: newData };
      }
      return { text: '', data: [] };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
export const postSearchArticule = createAsyncThunk(
  '/postSearchArticule',
  async ({ text, data }, thunkAPI) => {
    try {
      if (text === '' || regex.test(text)) {
        const newData = defaultToArr(data).filter(item =>
          String(item.product_id).includes(String(defaultToStr(text).trim()))
        );

        return { text, data: newData };
      }
      return { text: '', data: [] };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
export const searchByTitle = createAsyncThunk(
  '/searchByTitle',
  async ({ text, data }, thunkAPI) => {
    try {
      if (trim(text) !== '') {
        const newData = defaultToArr(data).filter(item => {
          return String(item.name)
            .toUpperCase()
            .includes(String(defaultToStr(text.toUpperCase()).trim()));
        });

        return { text, data: newData };
      }
      return { text: '', data: [] };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);

export const searchBySize = createAsyncThunk('/searchBySize', async ({ text, data }, thunkAPI) => {
  try {
    if (text !== '') {
      const newData = defaultToArr(data).filter(item =>
        String(item.size_name)
          .toUpperCase()
          .includes(
            String(defaultToStr(text.toUpperCase()).trim()) === '—'
              ? '0'
              : String(defaultToStr(text.toUpperCase()).trim())
          )
      );
      return { text, data: newData };
    }
    return { text: '', data: [] };
  } catch (error) {
    return thunkAPI.rejectWithValue('Error');
  }
});
export const changeCostPrice = createAsyncThunk(
  '/changeCostPrice',
  async ({ index, number }, thunkAPI) => {
    try {
      if (regex.test(number)) {
        const newNumber = replace('.', ',', String(replace(',', '.', replace(' ', '', number))));
        return {
          index,
          number: formatNumber(newNumber)
        };
      }
      if (isNaN(number) || number === 'undefined' || number == undefined) {
        return { index, number: 0 };
      }
      if (prop(0, number) === '-') {
        return { index: undefined, number: undefined };
      }
      if (number == 0) {
        return { index, number: 0 };
      }
      if (trim(number) === '' || length(String(number)) > 15) {
        return { index: null, number: null };
      }
      // if (
      // 	!isNil(index) &&
      // 	index !== undefined &&
      // 	(trim(number) !== "" || regex.test(number))
      // ) {
      // 	return {
      // 		index,
      // 		number: replace(
      // 			".",
      // 			",",
      // 			String(parseFloat(replace(",", ".", replace(" ", "", number))))
      // 		),
      // 	}
      // }
      return { index: null, number: null };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
// ! Articule
export const getArticuleData = createAsyncThunk(
  '/getArticuleData',
  async ({ wb_api_key_id, dispatch, report_id }, thunkAPI) => {
    try {
      const product = await WBAPI.getAnalyticsProductCost({
        identifier: 'product_id',
        wb_api_key_ids: wb_api_key_id,
        report_id
      });
      const productData = product.map(item => ({
        ...item,
        style: {
          background: 'white'
        },
        cost: {
          cost: isNil(item.cost) ? 0 : replace('.', ',', String(item.cost)),
          index: nanoid(),
          type: 'product_id'
        },
        product: {
          image: item.image,
          name: item.name,
          product_id: item.product_id
        }
      }));

      return productData;
    } catch (error) {
      if (error?.response?.data?.non_field_errors?.[0]?.code === 'wb_parse_error') {
        dispatch(mackWbParseError());
        return thunkAPI.rejectWithValue(
          error?.response?.data?.non_field_errors?.[0]?.code ?? 'Error'
        );
      }
      return thunkAPI.rejectWithValue(
        error?.response?.data?.non_field_errors?.[0]?.code ?? 'Error'
      );
    }
  }
);
export const clearDataNotLoaded = createAsyncThunk(
  '/clearDataNotLoaded',
  async (payload, thunkAPI) => {
    return false;
  }
);

export const clearWbParseError = createAsyncThunk(
  '/clearWbParseError',
  async (payload, thunkAPI) => {
    return false;
  }
);
export const mackDataNotLoaded = createAsyncThunk(
  '/mackDataNotLoaded',
  async (payload, thunkAPI) => {
    return true;
  }
);

export const mackWbParseError = createAsyncThunk('/mackWbParseError', async (payload, thunkAPI) => {
  return true;
});

export const saveArticuleData = createAsyncThunk(
  '/saveArticuleData',
  async ({ wb_api_key_id, dispatch, data, report_id }, thunkAPI) => {
    try {
      const patchData = await WBAPI.postAnalyticsProductCost({
        wb_api_key_id: wb_api_key_id,
        report_id,
        identifier: 'product_id',
        data: data
          .filter(({ code }) => (code ? code === 'success' : true))
          .map(({ identifier, cost }) => ({
            identifier,
            cost: parseFloat(replace(',', '.', String(cost.cost)))
          }))
      });
      dispatch(getBarcodeData({ wb_api_key_id, report_id }));
      const getPatchStatuses = propOr([], 'data', patchData).map(item => ({
        code: item.code,
        identifier: item.data.identifier
      }));

      const productData = data.map(item => {
        const { code } = getPatchStatuses.find(({ identifier }) => item.identifier === identifier);
        if (code === 'success') {
          return {
            ...item,
            style: rowGreenCollor
          };
        }
        if (errorArray.includes(code)) {
          return {
            ...item,
            style: rowRedCollor
          };
        }

        return {
          ...item,
          style: rowDefaultCollor
        };
      });

      if (!patchData.success) {
        return thunkAPI.rejectWithValue(productData);
      } else {
        dispatch(
          getSaccess({
            title: 'Данные были успешно сохранены!'
          })
        );
        return productData;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
export const postSearchArticuleArticule = createAsyncThunk(
  '/postSearchArticuleArticule',
  async ({ text, data }, thunkAPI) => {
    try {
      if (text === '' || regex.test(text)) {
        const newData = defaultToArr(data).filter(item =>
          String(item.identifier).includes(String(defaultToStr(text).trim()))
        );

        return { text, data: newData };
      }
      return { text: '', data: [] };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
export const searchArticuleByTitle = createAsyncThunk(
  '/searchArticuleByTitle',
  async ({ text, data }, thunkAPI) => {
    try {
      if (text !== '') {
        const newData = defaultToArr(data).filter(item =>
          String(item.name)
            .toUpperCase()
            .includes(String(defaultToStr(text.toUpperCase()).trim()))
        );
        return { text, data: newData };
      }
      return { text: '', data: [] };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
export const changeProductCostPrice = createAsyncThunk(
  '/changeProductCostPrice',
  async ({ index, number }, thunkAPI) => {
    try {
      if (regex.test(number)) {
        const newNumber = replace('.', ',', String(replace(',', '.', replace(' ', '', number))));
        return {
          index,
          number: formatNumber(newNumber)
        };
      }
      if (isNaN(number) || number === 'undefined' || number == undefined) {
        return { index, number: 0 };
      }
      if (prop(0, number) === '-') {
        return { index: undefined, number: undefined };
      }
      if (number == 0) {
        return { index, number: 0 };
      }
      if (Number(number) < 0) {
        return { index, number: 0 };
      }

      if (trim(number) === '' || length(String(number)) > 15) {
        return { index: null, number: null };
      }
      return { index: null, number: null };
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
// ! Error actions
export const clearError = createAsyncThunk('/clearError', async () => false);
export const getSaccess = createAsyncThunk('/getSaccess', async prop => prop);

// ! Undisable articule save button
export const unDisableSaverButton = createAsyncThunk(
  '/unDisableSaverButton',
  async ({ identifier }) => identifier
);

// ! Set tab
export const setTab = createAsyncThunk('/setTab', async ({ tab }) => {
  return tab;
});

// ! import Excel
export const importExcel = createAsyncThunk(
  '/importExcel',
  async (
    {
      wb_api_key_id,
      file,
      articuleData: data,
      barcodeData: storeBarcodeData,
      report_id,
      dispatch,
      ref
    },
    thunkAPI
  ) => {
    try {
      let formData = new FormData();
      formData.append('file', file);
      formData.append('wb_api_key_id', wb_api_key_id);
      if (report_id) {
        formData.append('report_id', report_id);
      }
      const patchData = await WBAPI.postExcelAnalyticsProductCost({
        formData,
        report_id
      });

      if (prop('identifier', patchData) === 'barcode') {
        dispatch(setTab({ tab: 'По баркодам' }));
        const getPatchStatuses = propOr([], 'data', patchData).map(item => ({
          code: prop('code', item),
          cost: propOr(0, 'cost', prop('data', item)),
          identifier: prop('identifier', prop('data', item))
        }));

        const status = prop('success', patchData)
          ? false
          : defaultToArr(getPatchStatuses).some(({ code }) => errorArray.includes(code));

        const errorStatuses = status
          ? defaultToArr(getPatchStatuses).filter(({ code }) => errorArray.includes(code))
          : [];

        const errorStatus = propOr(
          false,
          'code',
          defaultToArr(errorStatuses).find(({ code }) => errorArray.includes(code))
        );

        let newArr = [];
        const barcodeData = defaultToArr(storeBarcodeData).map(item => {
          const object = defaultToArr(getPatchStatuses).find(
            ({ identifier }) => prop('identifier', item) === identifier
          );

          const cost =
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === undefined ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === 'undefined' ||
            isNil(defaultToZero(replace('.', ',', String(prop('cost', object))))) ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === null ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === 'null'
              ? 0
              : defaultToZero(replace('.', ',', String(prop('cost', object))));

          if (errorArray.includes(prop('code', object))) {
            newArr = [
              {
                ...item,
                cost: {
                  ...item.cost,
                  color: 0,
                  cost: !cost || cost === 'undefined' ? item.cost.cost : cost
                },
                style: rowRedCollor
              },
              ...newArr
            ];
            return {
              ...item,
              cost: {
                ...item.cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost
              },
              style: rowRedCollor
            };
          }

          if (prop('code', object) === 'success') {
            newArr = [
              ...newArr,
              {
                ...item,
                cost: {
                  ...item.cost,
                  color: 2,
                  cost
                },
                style: rowGreenCollor
              }
            ];
            return {
              ...item,
              cost: {
                ...item.cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost
              },
              style: rowGreenCollor
            };
          }

          newArr = [
            {
              ...item,
              cost: {
                ...item.cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost,
                color: 1
              },
              style: rowDefaultCollor
            },
            ...newArr
          ];
          return {
            ...item,
            cost: {
              ...item.cost,
              cost: !cost || cost === 'undefined' ? item.cost.cost : cost
            },
            style: rowDefaultCollor
          };
        });

        if (!barcodeData.some(({ style }) => equals(style, rowRedCollor))) {
          dispatch(saveBarcodeData({ wb_api_key_id, data: barcodeData, report_id, dispatch }));
        }
        ref.current.value = null;
        return {
          data: newArr
            .sort((a, b) => a.cost.color - b.cost.color)
            .map(item => {
              return {
                ...item,
                cost: {
                  ...item.cost
                }
              };
            }),
          errorStatuses,
          disabledSaveButton: Boolean(errorStatus),
          identifier: prop('identifier', patchData),
          error: errorStatus
        };
      } else {
        dispatch(setTab({ tab: 'По артикулам' }));
        const getPatchStatuses = propOr([], 'data', patchData).map(item => ({
          code: prop('code', item),
          cost: prop('cost', prop('data', item)),
          identifier: prop('identifier', prop('data', item))
        }));
        const status = prop('success', patchData)
          ? false
          : defaultToArr(getPatchStatuses).some(({ code }) => errorArray.includes(code));

        const errorStatuses = status
          ? defaultToArr(getPatchStatuses).filter(({ code }) => errorArray.includes(code))
          : [];

        const errorStatus = propOr(
          false,
          'code',
          defaultToArr(errorStatuses).find(({ code }) => errorArray.includes(code))
        );

        let newArr = [];

        const productData = defaultToArr(data).map(item => {
          const object = defaultToArr(getPatchStatuses).find(
            ({ identifier }) => prop('identifier', item) === identifier
          );
          const cost =
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === undefined ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === 'undefined' ||
            isNil(defaultToZero(replace('.', ',', String(prop('cost', object))))) ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === null ||
            defaultToZero(replace('.', ',', String(prop('cost', object)))) === 'null'
              ? 0
              : defaultToZero(replace('.', ',', String(prop('cost', object))));

          if (errorArray.includes(prop('code', object))) {
            newArr = [
              {
                ...item,
                cost: {
                  ...item.cost,
                  color: 0,
                  cost: !cost || cost === 'undefined' ? item.cost.cost : cost
                },
                style: rowRedCollor
              },
              ...newArr
            ];
            return {
              ...item,
              cost: {
                ...item.cost,
                // cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost
              },
              style: rowRedCollor
            };
          }

          if (prop('code', object) === 'success') {
            newArr = [
              ...newArr,
              {
                ...item,
                cost: {
                  ...item.cost,
                  color: 2,
                  // cost,
                  cost: !cost || cost === 'undefined' ? item.cost.cost : cost
                },
                style: rowGreenCollor
              }
            ];
            return {
              ...item,
              cost: {
                ...item.cost,
                // cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost
              },
              style: rowGreenCollor
            };
          }
          newArr = [
            {
              ...item,
              cost: {
                ...item.cost,
                // cost,
                cost: !cost || cost === 'undefined' ? item.cost.cost : cost,
                color: 1
              },
              style: rowDefaultCollor
            },
            ...newArr
          ];

          return {
            ...item,
            cost: {
              ...item.cost,
              // cost,
              cost: !cost || cost === 'undefined' ? item.cost.cost : cost
            },
            style: rowDefaultCollor
          };
        });
        if (!errorStatus) {
          dispatch(saveArticuleData({ wb_api_key_id, data: productData, report_id, dispatch }));
        }
        ref.current.value = null;
        return {
          data: newArr
            .sort((a, b) => a.cost.color - b.cost.color)
            .map(item => {
              return {
                ...item,
                cost: {
                  ...item.cost
                }
              };
            }),
          // errorStatuses,
          errorArticuleStatuses: errorStatuses,
          disabledSaveButton: Boolean(errorStatus),
          error: errorStatus
        };
      }
    } catch (error) {
      return thunkAPI.rejectWithValue('Error');
    }
  }
);
