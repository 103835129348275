import React from "react"

import classnames from "classnames"
import {Link} from "react-router-dom"

import styles from "./index.module.scss"

const LinkPure = props => {
	const {to, children, className, classes = {}, ...rest} = props

	return (
		<Link
			className={classnames(styles.root, className, classes.root)}
			to={to}
			{...rest}
		>
			{children}
		</Link>
	)
}

export default LinkPure
