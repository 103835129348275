import React from "react"

import Tab from "@mui/material/Tab"

import styles from "./index.module.scss"

const Tab_ = props => {
	return (
		<Tab
			{...props}
			classes={styles}
		/>
	)
}

export default Tab_
