import {createSlice} from "@reduxjs/toolkit"

import { setAverts } from "./actions"

const initialState = {   
	adverts: []
}

export const productAdvertsSlice = createSlice({
	name: "productAdvertsSlice",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(setAverts.fulfilled, (state, {payload}) => {
			state.adverts = payload
		})
	}
})

export default productAdvertsSlice.reducer
