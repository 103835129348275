import { createSlice } from "@reduxjs/toolkit"

import { setParams, setWords } from "./actions"


const initialState = {
	words: "",
	params: {}
}

export const trendRequests = createSlice({
	name: "trend-requests",
	initialState,
	reducers: {},
	extraReducers: builder => {
		// !------------setWords
		builder.addCase(setWords.fulfilled, (state, { payload }) => {
			state.words = payload
		})
		builder.addCase(setParams.fulfilled, (state, { payload }) => {
			state.params = payload
		})

	},
})

export default trendRequests.reducer
