import { createAsyncThunk } from "@reduxjs/toolkit"

export const checkSeller = createAsyncThunk(
	"/checkSeller",
	async (data) => data
)
export const checkSellers = createAsyncThunk(
	"/checkSellers",
	async (data) => data
)
export const setTabSeller = createAsyncThunk(
	"/setTabSeller",
	async (tab) => tab
)
