import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M6.10132 17.7568C6.10132 18.1155 6.37462 18.4054 6.7129 18.4054H17.6833C18.0215 18.4054 18.2948 18.1155 18.2948 17.7568V13.0338C18.2948 9.46216 15.5656 6.56757 12.1981 6.56757C8.83053 6.56757 6.10132 9.46216 6.10132 13.0338V17.7568ZM7.47739 13.0338C7.47739 10.2689 9.59119 8.02703 12.1981 8.02703C14.805 8.02703 16.9188 10.2689 16.9188 13.0338V16.9459H10.134V13.4797C10.134 13.3682 10.048 13.277 9.94285 13.277H9.10192C8.9968 13.277 8.9108 13.3682 8.9108 13.4797V16.9459H7.47739V13.0338ZM6.5581 7.91554L7.31494 7.11284C7.37418 7.05 7.37418 6.94662 7.31494 6.88378L6.01722 5.50743C5.98849 5.47725 5.94968 5.46032 5.90924 5.46032C5.8688 5.46032 5.82999 5.47725 5.80126 5.50743L5.04442 6.31014C5.01596 6.34061 5 6.38177 5 6.42466C5 6.46755 5.01596 6.50871 5.04442 6.53919L6.34213 7.91554C6.40138 7.97838 6.49694 7.97838 6.5581 7.91554ZM19.3556 6.31014L18.5987 5.50743C18.57 5.47725 18.5312 5.46032 18.4907 5.46032C18.4503 5.46032 18.4115 5.47725 18.3828 5.50743L17.085 6.88378C17.0566 6.91426 17.0406 6.95542 17.0406 6.99831C17.0406 7.0412 17.0566 7.08236 17.085 7.11284L17.8419 7.91554C17.9011 7.97838 17.9986 7.97838 18.0579 7.91554L19.3556 6.53919C19.4148 6.47432 19.4148 6.37297 19.3556 6.31014ZM18.314 19.7027H6.0822C5.74392 19.7027 5.47062 19.9926 5.47062 20.3514V20.8378C5.47062 20.927 5.53942 21 5.62351 21H18.7726C18.8567 21 18.9255 20.927 18.9255 20.8378V20.3514C18.9255 19.9926 18.6522 19.7027 18.314 19.7027ZM11.6629 5.27027H12.7332C12.8173 5.27027 12.8861 5.1973 12.8861 5.10811V3.16216C12.8861 3.07297 12.8173 3 12.7332 3H11.6629C11.5788 3 11.51 3.07297 11.51 3.16216V5.10811C11.51 5.1973 11.5788 5.27027 11.6629 5.27027Z" />
		<path d="M6.10132 17.7568C6.10132 18.1155 6.37462 18.4054 6.7129 18.4054H17.6833C18.0215 18.4054 18.2948 18.1155 18.2948 17.7568V13.0338C18.2948 9.46216 15.5656 6.56757 12.1981 6.56757C8.83053 6.56757 6.10132 9.46216 6.10132 13.0338V17.7568ZM7.47739 13.0338C7.47739 10.2689 9.59119 8.02703 12.1981 8.02703C14.805 8.02703 16.9188 10.2689 16.9188 13.0338V16.9459H10.134V13.4797C10.134 13.3682 10.048 13.277 9.94285 13.277H9.10192C8.9968 13.277 8.9108 13.3682 8.9108 13.4797V16.9459H7.47739V13.0338ZM6.5581 7.91554L7.31494 7.11284C7.37418 7.05 7.37418 6.94662 7.31494 6.88378L6.01722 5.50743C5.98849 5.47725 5.94968 5.46032 5.90924 5.46032C5.8688 5.46032 5.82999 5.47725 5.80126 5.50743L5.04442 6.31014C5.01596 6.34061 5 6.38177 5 6.42466C5 6.46755 5.01596 6.50871 5.04442 6.53919L6.34213 7.91554C6.40138 7.97838 6.49694 7.97838 6.5581 7.91554ZM19.3556 6.31014L18.5987 5.50743C18.57 5.47725 18.5312 5.46032 18.4907 5.46032C18.4503 5.46032 18.4115 5.47725 18.3828 5.50743L17.085 6.88378C17.0566 6.91426 17.0406 6.95542 17.0406 6.99831C17.0406 7.0412 17.0566 7.08236 17.085 7.11284L17.8419 7.91554C17.9011 7.97838 17.9986 7.97838 18.0579 7.91554L19.3556 6.53919C19.4148 6.47432 19.4148 6.37297 19.3556 6.31014ZM18.314 19.7027H6.0822C5.74392 19.7027 5.47062 19.9926 5.47062 20.3514V20.8378C5.47062 20.927 5.53942 21 5.62351 21H18.7726C18.8567 21 18.9255 20.927 18.9255 20.8378V20.3514C18.9255 19.9926 18.6522 19.7027 18.314 19.7027ZM11.6629 5.27027H12.7332C12.8173 5.27027 12.8861 5.1973 12.8861 5.10811V3.16216C12.8861 3.07297 12.8173 3 12.7332 3H11.6629C11.5788 3 11.51 3.07297 11.51 3.16216V5.10811C11.51 5.1973 11.5788 5.27027 11.6629 5.27027Z" />

	</Template>
))
