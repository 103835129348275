import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M11.7669 14.3859C11.7648 14.3859 11.7627 14.3859 11.7606 14.3859C11.3784 14.3825 11.071 14.0696 11.0744 13.6869L11.0775 13.3404C11.0775 13.3207 11.0786 13.3006 11.0807 13.2809C11.1766 12.2731 11.8451 11.6555 12.3828 11.1591C12.5649 10.9907 12.7373 10.8317 12.8841 10.6658C13.0631 10.4638 13.3227 10.0502 13.0496 9.55103C12.7349 8.9746 11.9663 8.81144 11.3691 8.94827C10.7452 9.09099 10.5153 9.62412 10.4336 9.92897C10.3346 10.2986 9.95478 10.5179 9.58537 10.4191C9.21596 10.3201 8.99681 9.94005 9.09548 9.57043C9.37141 8.53915 10.0874 7.82035 11.0595 7.5976C12.3679 7.29865 13.6856 7.82762 14.2641 8.88591C14.7457 9.76719 14.6138 10.8012 13.9196 11.5848C13.7268 11.8024 13.5208 11.9929 13.3214 12.1768C12.8245 12.6358 12.514 12.9428 12.4614 13.3858L12.4586 13.6986C12.4558 14.08 12.1463 14.3859 11.7669 14.3859Z" />
		<path d="M11.7665 16.463C11.5865 16.463 11.4064 16.3903 11.2784 16.2621C11.1468 16.1339 11.0741 15.9538 11.0741 15.7702C11.0741 15.5901 11.1468 15.4099 11.2784 15.2817C11.5345 15.0254 11.9985 15.0254 12.2547 15.2817C12.3862 15.4099 12.4589 15.5901 12.4589 15.7702C12.4589 15.9538 12.3862 16.1305 12.2581 16.2621C12.1266 16.3903 11.95 16.463 11.7665 16.463Z" />
		<path d="M11.9753 21C11.1876 21 10.4069 20.8975 9.65461 20.6948C6.41268 19.8222 3.87425 17.1351 3.18771 13.8486C2.48801 10.5005 3.77939 6.98099 6.47811 4.88207C8.03815 3.66858 10.0057 3 12.0186 3C13.4121 3 14.7959 3.32424 16.0208 3.93705C19.0456 5.45122 21 8.61467 21 11.9967C21 14.7562 19.6986 17.416 17.5188 19.1114C15.9533 20.3293 13.9843 21 11.9753 21ZM12.0189 4.38565C10.3124 4.38565 8.64644 4.95031 7.32841 5.97604C5.04445 7.75245 3.95111 10.7313 4.54348 13.5649C5.12442 16.3449 7.27198 18.6184 10.0147 19.3566C10.6496 19.5274 11.3095 19.6143 11.9753 19.6143H11.9756C13.6783 19.6143 15.345 19.0473 16.6692 18.0174C18.5142 16.5825 19.6155 14.3319 19.6155 11.9967C19.6155 9.13499 17.9616 6.4579 15.4017 5.17652C14.368 4.65932 13.1984 4.38565 12.0189 4.38565Z" />
	</Template>
))
