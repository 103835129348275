import { configureStore } from "@reduxjs/toolkit"
import { combineReducers } from "redux"
// import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from "redux-persist"
import persistReducer from "redux-persist/es/persistReducer"
import storage from "redux-persist/lib/storage"
import { WBapi } from "root/services/WBapi"

import analysisBySubjectSlice from "./analysisBySubject/analysisBySubjectSlice"
import analyticsSlice from "./analyticsSlice/analyticsSlice"
import articlesComparisonReducer from "./articlesComparison/articlesComparisonSlice"
import { articuleCompasitionReducer } from "./articlesComparisonOld/reducer"
import bloggersSearchSlice from "./bloggersSearchSlice/bloggersSearchSlice"
import brandAnalyzer from "./brandAnalyzer/brandAnalyzerSlice"
import { brandCardReducer } from "./brandCard/reducer"
import { categoryCardReducer } from "./categoryCard/reducer"
import { chatGpt } from "./chatGpt/reducer"
import { economicLogicSliceReducer } from "./economicalLogic/economicLogicSlice"
import { financialLogicSliceReducer } from "./financialLogic/financialLogicSlice"
import pvzSlice from "./geoPVZ/geoPVZSlice"
import groupingReducer from "./grouping/groupingSlice"
import { grouping } from "./groupingOld/reducer"
import { keyCompasitionReducer } from "./keyComposition/reducer"
import keysCheckProducts from "./keysCheckProducts/keysCheckProducts"
// import { keystat } from "./keystat/reducer"
// import { keystatSubjects } from "./keystat/subjects"
import keystatReducer from "./keystatSlice/keystatSlice"
import { keyWordTable } from "./keyWordTable/reducer"
import loadFilter from "./loadFilter/loadFilter"
import marksReducer from "./marks/marksSlice"
import brandsSlice from "./monitoring/brands/brandsSlice"
import categoriesSlice from "./monitoring/category/categoriesSlice"
import keywordsSlice from "./monitoring/keywords/keywordsSlice"
import monitoringSlice from "./monitoring/monitoringSlice"
import productsSlice from "./monitoring/products/productsSlice"
import sellersSlice from "./monitoring/sellers/sellersSlice"
import sppReducer from "./monitoring/spp/sppSlice"
import partialPurchaseSlice from "./partialPurchase/partialPurchaseSlice"
import paymentHistoryReducer from "./paymentHistory/paymentHistorySlice"
import { priceSegmentation } from "./priceSegmentation/reducer"
import productAdvertsSlice from "./productAdvertsSlice/productAdvertsSlice"
import productAnalyzer from "./productAnalyzer/productAnalyzerSlice"
import { productCardReducer } from "./productCard/reducer"
import productDynamicsReducer from "./productDynamics/productDynamicsSlice"
import profileSlice from "./profile/profileSlice"
import { searchAnalyticsReducer } from "./searchAnalytics/reducer"
import searchAnalytics from "./searchAnalyticsSlice/searchAnalyticsSlice"
import searchTopsAnalysisSlice from "./searchTopsAnalysis/searchTopsAnalysisSlice"
import { sellerCardReducer } from "./sellerCard/reducer"
import seoAISlice from "./seoAI/seoAISlice"
import settingsColumns from "./settingsColumns/settingsColumnsSlice"
import { settingsDebriefingReducer } from "./settingsDebriefing/settingsDebriefingSlice"
import tariffsReducer from "./tariffs/tariffsSlice"
import monitoringTrafficsSlice from "./traffics/tariffsSlice";
import trendRequests from "./trendRequestsSlice/trendRequestsSlice"
import userReducer from "./user/userSlice"
import userTariffReducer from "./userTariff/userTariff"

const rootReducer = combineReducers({
	user: userReducer,
	analysisBySubject: analysisBySubjectSlice,
	productAdverts: productAdvertsSlice,
	partialPurchase: partialPurchaseSlice,
	// keystat,
	profile: profileSlice,
	keystatSlice: keystatReducer,
	seoAI: seoAISlice,
	trendRequestsSlice: trendRequests,
	analyticsSlice,
	searchAnalytics,
	bloggersSearchSlice,
	// keystatSubjects,
	chatGpt,
	grouping,
	productCard: productCardReducer,
	articuleCompasition: articuleCompasitionReducer,
	priceSegmentation,
	userTariff: userTariffReducer,
	tariffs: tariffsReducer,
	productAnalyzer,
	keyWordTable,
	searchAnalyticsReducer,
	keyCompasitionReducer,
	brandAnalyzer,
	sellerCard: sellerCardReducer,
	categoryCard: categoryCardReducer,
	brandCard: brandCardReducer,
	productDynamics: productDynamicsReducer,
	loadFilter,
	settingsColumns,
	keysCheckProducts,
	spp: sppReducer,
	marks: marksReducer,
	monitoring: monitoringSlice,
	monitoringProducts: productsSlice,
	monitoringTraffics: monitoringTrafficsSlice,
	monitoringBrands: brandsSlice,
	monitoringSellers: sellersSlice,
	monitoringCategories: categoriesSlice,
	monitoringKeywords: keywordsSlice,
	groupingSlice: groupingReducer,
	articlesComparison: articlesComparisonReducer,
	paymentHistory: paymentHistoryReducer,
	financeLogic: financialLogicSliceReducer,
	settingsDebriefing: settingsDebriefingReducer,
	economicLogic: economicLogicSliceReducer,
	pvz: pvzSlice,
	searchTopsAnalysis: searchTopsAnalysisSlice,
	[WBapi.reducerPath]: WBapi.reducer,
})

const persistConfig = {
	key: "WILDBOX",
	storage,
	whitelist: ["settingsColumns"],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const setupStore = () => {
	return configureStore({
		reducer: persistedReducer,
		middleware: getDefaultMiddleware =>
			getDefaultMiddleware({
				serializableCheck: false,
			}).concat(WBapi.middleware),
	})
}
