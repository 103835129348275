import { createSlice } from "@reduxjs/toolkit"

import { getPVZ } from "./actions"



const initialState = {
	productPVZ: false,
}

export const pvzSlice = createSlice({
	name: "PVZ",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(getPVZ.fulfilled, (state, { payload }) => {
			state.productPVZ = payload
		})
	},
})

export default pvzSlice.reducer
