import { createAsyncThunk } from "@reduxjs/toolkit"

import API from "root/js/api"

const WBAPI = new API()

export const getPaymentHistory = createAsyncThunk("/getPaymentHistory", async (_, thunkAPI) => {
	try {
		const response = await WBAPI.getPaymentHistory()
		localStorage.setItem("paymentHistory", JSON.stringify(response))
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error getting payment history...")
	}
})
