const initialState = {
	selectedPeriodTotal: {
		avrProceedsOnProductWithOrders: 0,
		avrProductWithOrders: 0,
		loading: false,
	},
}

export const categoryCardReducer = (state = initialState, action) => {
	switch (action.type) {
		case "SELECTED_PERIOD_TOTAL_LOADING":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: true,
				},
			}
		case "SELECTED_PERIOD_TOTAL":
			return {
				...state,
				selectedPeriodTotal: {
					...state.selectedPeriodTotal,
					loading: false,
					avrProceedsOnProductWithOrders: action.avrProceedsOnProductWithOrders,
					avrProductWithOrders: action.avrProductWithOrders,
					// vendor_codes: action.vendor_codes,
					// proceeds: action.proceeds,
					// product_with_orders: action.product_with_orders,
					// articlesAmountWithSalesForPeriod: action.articlesAmountWithSalesForPeriod,
					// action.vendor_codes / action.product_with_orders,
				},
			}

		default:
			return state
	}
}
