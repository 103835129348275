import { createAsyncThunk } from "@reduxjs/toolkit"

import API from "root/js/api"

import { defaultToArr } from "root/js/utils"


const WBAPI = new API()
export const GetFinancialLogicKey = createAsyncThunk("get/key", async thunkAPI => {
	try {
		const keys = await WBAPI.getCompanyApiKey()

		return defaultToArr(keys)
	} catch (e) {
		return thunkAPI.rejectWithValue("Error fetching key")
	}
})

export const GetFinancialLogicBrand = createAsyncThunk(
	"get/brand",
	async ({ keys, dateTo, dateFrom, categoryId, subjectsId }, thunkAPI) => {
		const key = keys.join()
		const category = categoryId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.getBrandFinancialLogic({
				key,
				dateTo,
				dateFrom,
				subject,
				category,
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching brand list")
		}
	}
)

export const GetFinancialLogicCategories = createAsyncThunk(
	"get/categories",
	async ({ keys, dateTo, dateFrom, subjectsId, brandIds }, thunkAPI) => {
		const key = keys.join()
		const subject = subjectsId.join()
		const brand = brandIds.join()
		try {
			const response = await WBAPI.getBrandFinancialCategories({
				key,
				dateFrom,
				dateTo,
				subject,
				brand,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching categories list")
		}
	}
)

export const GetProductSearch = createAsyncThunk(
	"get/product",
	async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds, articulIds }, thunkAPI) => {
		const key = keys.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const category = categoryId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.getProductSearch({
				key,
				dateFrom,
				dateTo,
				brandsId,
				aritucl,
				category,
				subject,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching categories list")
		}
	}
)

export const GetBarcodesSearch = createAsyncThunk(
	"get/barcodes",
	async (
		{ keys, text, dateTo, dateFrom, subjectsId, categoryId, brandIds, productId, articulIds },
		thunkAPI
	) => {
		const key = keys.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const category = categoryId.join()
		const subject = subjectsId.join()
		const product = productId.join()
		try {
			const response = await WBAPI.getBarcodesSearch({
				key,
				text,
				dateTo,
				dateFrom,
				brandsId,
				aritucl,
				category,
				subject,
				product,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching categories list")
		}
	}
)

export const GetSupplierArticles = createAsyncThunk(
	"get/article",
	async ({ keys, dateTo, dateFrom, subjectsId, categoryId, brandIds, productId }, thunkAPI) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()

		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.getArticle({
				key,
				dateFrom,
				dateTo,
				subject,
				producd,
				brandsId,
				ids,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching categories list")
		}
	}
)

export const GetSubjects = createAsyncThunk(
	"get/subject",
	async ({ keys, dateTo, dateFrom, categoryId, brandIds }, thunkAPI) => {
		const key = keys.join()
		const category = categoryId.join()
		const brand = brandIds.join()
		try {
			const response = await WBAPI.getSubjects({ key, dateFrom, dateTo, category, brand })

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching categories list")
		}
	}
)

export const GetKeyIndicatorsWidgets = createAsyncThunk(
	"get/indicators/widgets",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.getKeyIndicatorsWidgets({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const keyIndicatorsOtherWbStructure = createAsyncThunk(
	"/other/wb",
	async ({ keys, dateFrom, dateTo }, thunkAPI) => {
		const key = keys.join()

		try {
			const response = await WBAPI.keyIndicatorsOtherWBStructure({ key, dateFrom, dateTo })

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const KeyIndicatorsDynamic = createAsyncThunk(
	"get/dynamic",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const article = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const ONE_DAY = 24 * 60 * 60 * 1000
			const prevDateTo = new Date(dateFrom - ONE_DAY)
			const prevDateFrom = new Date(prevDateTo - (dateTo - dateFrom))

			const currentRequest = WBAPI.keyIndicatorsDynamic({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				article,
				barcod,
				producd,
				subject,
			})
			const prevRequest = WBAPI.keyIndicatorsDynamic({
				key,
				dateFrom: prevDateFrom,
				dateTo: prevDateTo,
				ids,
				brandsId,
				article,
				barcod,
				producd,
				subject,
			})
			const [currentResponse, prevResponse] = await Promise.all([currentRequest, prevRequest])
			const respToRespByDates = resp =>
				resp.reduce((acc, i) => {
					acc[i.date] = i
					return acc
				}, {})

			const curRespByDates = respToRespByDates(currentResponse)
			const prevRespByDates = respToRespByDates(prevResponse)

			const dateToDateKey = dt =>
				`${dt.getFullYear()}-${("0" + (dt.getMonth() + 1)).slice(-2)}-${(
					"0" + dt.getDate()
				).slice(-2)}`
			const daysCount = (dateTo - dateFrom) / ONE_DAY

			const data = Array.from({ length: daysCount }).map((_, i) => {
				const curDate = new Date(dateFrom.getTime() + ONE_DAY * i)
				const prevDate = new Date(prevDateFrom.getTime() + ONE_DAY * i)

				const curDateKey = dateToDateKey(curDate)
				const prevDateKey = dateToDateKey(prevDate)

				return {
					currentDate: curDateKey,
					// prevDate:prevDateKey,
					current: curRespByDates[curDateKey],
					prev: prevRespByDates[prevDateKey],
				}
			})

			return data
		} catch (e) {
			console.log(e.message)
			return thunkAPI.rejectWithValue("Error fetching dynamic list")
		}
	}
)

export const KeyIndicatorsServiceStructrueWB = createAsyncThunk(
	"get/WB",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.keyIndicatorsServiceStructrueWB(
				{
					key,
					dateFrom,
					dateTo,
					ids,
					brandsId,
					aritucl,
					barcod,
					producd,
					subject
				}
			)

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching dynamic list")
		}
	}
)

export const KeyIndicatorsTable = createAsyncThunk(
	"table",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			value,
			sort,
			limit,
			offset,
			subjectsId,
			q,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()

		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.keyIndicatorsTable({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				value,
				sort,
				limit,
				offset,
				subject,
				q,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching dynamic list")
		}
	}
)

export const CommisionWidgets = createAsyncThunk(
	"commision/widget",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()

		const producd = productId.join()
		const subject = subjectsId.join()

		try {
			const response = await WBAPI.commisionWidgets({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching dynamic list")
		}
	}
)

export const CommisionDiagrams = createAsyncThunk(
	"commision/diagram",
	async (
		{ keys, dateFrom, dateTo, productId, categoryId, brandIds, articulIds, barcodeIds, subjectsId },
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()

		const producd = productId.join()
		const subject = subjectsId.join()

		try {
			const response = await WBAPI.commisionDiagram(
				{
					key,
					dateFrom,
					dateTo,
					ids,
					brandsId,
					aritucl,
					barcod,
					producd,
					subject
				}
			)

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching dynamic list")
		}
	}
)

export const CommisionTable = createAsyncThunk(
	"commision/table",
	async (
		{
			keys,
			limit,
			offset,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			value,
			subjectsId,
			sort,
			q,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const subject = subjectsId.join()
		const producd = productId.join()

		try {
			const response = await WBAPI.commisionTable({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				value,
				sort,
				limit,
				offset,
				subject,
				q,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)

export const RiteReturnsWidgets = createAsyncThunk(
	"riter/widgets",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			subjectsId,
			productId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.riterReturnsWidgets({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,

				subject,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)

export const RiteReturnDiagrama = createAsyncThunk(
	"diagrama",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			value,
			subjectsId,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.riterReturnsDiagram({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)
export const RiterReturnDynamicProduct = createAsyncThunk(
	"product/dynamic",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()

		try {
			const response = await WBAPI.riterReturnsDiagramProduct({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)

export const RiterReturnOffices = createAsyncThunk(
	"offices",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()

		try {
			const response = await WBAPI.riteReturnOffices({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)

export const RiterReturnTables = createAsyncThunk(
	"table/return",
	async (
		{
			keys,
			limit,
			offset,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			value,
			subjectsId,
			sort,
			q,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const subject = subjectsId.join()
		const producd = productId.join()

		try {
			const response = await WBAPI.riteReturnTables({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				value,
				sort,
				limit,
				offset,
				subject,
				q,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)

export const PenaltyAdditionalsWidgets = createAsyncThunk(
	"getPenaltyWidgets",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			typeNames
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		const type_names = JSON.stringify(typeNames)
		try {
			const response = await WBAPI.penaltyAdditionalsWidgets({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				type_names
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyAdditionalLineDiagram = createAsyncThunk(
	"line/diagram",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.penaltyAdditionalsDiagramLine({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyAdditionalsLinesDynamic = createAsyncThunk(
	"dynamic/penalty",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
			type_name,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.penaltyAdditionalDynamic({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
				type_name,
			})
			return { response, type_name }
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyTypes = createAsyncThunk(
	"dynamic/penalty/types",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.penaltyTypes({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyOffices = createAsyncThunk(
	"offices/penalty",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
			typeNames
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		const type_names = JSON.stringify(typeNames)
		try {
			const response = await WBAPI.penaltyOffices({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
				type_names
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyDiagramProducts = createAsyncThunk(
	"diagram/penalty/products",
	async (
		{
			keys,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			subjectsId,
			value,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()
		try {
			const response = await WBAPI.penaltyProducts({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
			})
			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgets list")
		}
	}
)

export const PenaltyAdditionalsPenaltyTypes = createAsyncThunk(
	"penalty/additional/reason",
	async ({
		keys,
		dateFrom,
		dateTo,
		categoryId,
		brandIds,
		articulIds,
		barcodeIds,
		productId,
		subjectsId,
		value,
		typeNames,
	}) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const producd = productId.join()
		const subject = subjectsId.join()

		const promises = typeNames.map(elem => {
			return WBAPI.penaltyAdditionalsPenaltyTypes({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				subject,
				value,
				type_name: elem,
			});
		});

		const res = await Promise.all(promises)


		return res




	}
)

export const PenaltyTables = createAsyncThunk(
	"penalty/table",
	async (
		{
			keys,
			limit,
			offset,
			dateFrom,
			dateTo,
			categoryId,
			brandIds,
			articulIds,
			barcodeIds,
			productId,
			value,
			subjectsId,
			sort,
			q,
		},
		thunkAPI
	) => {
		const key = keys.join()
		const ids = categoryId.join()
		const brandsId = brandIds.join()
		const aritucl = JSON.stringify(articulIds)
		const barcod = barcodeIds.join()
		const subject = subjectsId.join()
		const producd = productId.join()

		try {
			const response = await WBAPI.penaltyTable({
				key,
				dateFrom,
				dateTo,
				ids,
				brandsId,
				aritucl,
				barcod,
				producd,
				value,
				sort,
				limit,
				offset,
				subject,
				q,
			})

			return response
		} catch (e) {
			return thunkAPI.rejectWithValue("Error fetching widgest list")
		}
	}
)
