import { createAsyncThunk } from "@reduxjs/toolkit"

import API from "root/js/api"

const WBAPI = new API()

const extra_fields =
	"price,subject,url,product_id,proceeds,proceeds_dynamic,orders,orders_dynamic,price,price_dynamic,quantity,quantity_dynamic,in_stock_days,out_of_stock_days,in_stock_percent,in_stock_orders_avg,in_stock_proceeds,lost_proceeds,lost_orders,discount,discount_dynamic,orders_failed,orders_failed_dynamic,proceeds_failed,proceeds_failed_dynamic,old_price,old_price_dynamic,sales,reviews,rating_dynamic,reviews_dynamic,rating,last_price,sales_speed"

export const getComparingArticle = createAsyncThunk(
	"/getComparingArticle",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getProductsDynamic({
				product_ids: reqData.id,
				extra_fields,
				period: reqData.period,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparing article...")
		}
	}
)

export const getComparingArticles = createAsyncThunk(
	"/getComparingArticles",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getProductsDynamic({
				product_ids: reqData.ids,
				extra_fields,
				period: reqData.period,
				limit: 50,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparing articles...")
		}
	}
)

export const getComparingCategories = createAsyncThunk(
	"/getComparingCategories",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getParsersCategoriesCompare({
				period: 1,
				limit: 50,
				offset: reqData.offset,
				source_product_id: reqData.sourceId,
				target_product_id: reqData.targetId,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparing categories...")
		}
	}
)

export const getTargetWarehouses = createAsyncThunk(
	"/getTargetWarehouses",
	async (targetId, thunkAPI) => {
		try {
			const response = await WBAPI.getWarehousesDynamic({
				period: 28,
				offset: 0,
				limit: 100,
				product_ids: targetId,
				extra_fields: "quantity,product_id",
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting target warehouses...")
		}
	}
)

export const getKeywordsComparison = createAsyncThunk(
	"/getKeywordsComparison",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getCheckingKeywords({
				period: 1,
				limit: 50,
				is_in: reqData.is_in,
				offset: reqData.offset,
				ordering: reqData.ordering,
				product_id: reqData.sourceId,
				product_id_in: reqData.targetId,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparison keywords...")
		}
	}
)

export const getAllKeywordsComparison = createAsyncThunk(
	"/getAllKeywordsComparison",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getCheckingKeywords({
				period: 1,
				limit: reqData.limit,
				is_in: reqData.is_in,
				offset: reqData.offset,
				ordering: reqData.ordering,
				product_id: reqData.sourceId,
				product_id_in: reqData.targetId,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting all comparison keywords...")
		}
	}
)

export const exportKeywordsComparison = createAsyncThunk(
	"/articleExportExcel",
	async (reqData, thunkAPI) => {
		try {
			const response = await WBAPI.getCheckingKeywordsExport({
				period: 1,
				offset: 0,
				limit: 10000,
				is_in: reqData.is_in,
				ordering: reqData.ordering,
				product_id: reqData.targetId,
				product_id_in: reqData.sourceId,
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error exporting file...")
		}
	}
)

export const getComparingWarehouses = createAsyncThunk(
	"/getComparingWarehouses",
	async (sourceId, thunkAPI) => {
		try {
			const response = await WBAPI.getWarehousesDynamic({
				period: 28,
				offset: 0,
				limit: 100,
				product_ids: sourceId,
				extra_fields: "quantity,product_id",
			})
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparing warehouses...")
		}
	}
)

export const getTargetCard = createAsyncThunk("/getTargetCard", async (productId, thunkAPI) => {
	try {
		const response = await WBAPI.getProductCardByProduct_ids({ id: productId })
		return response
	} catch (err) {
		return thunkAPI.rejectWithValue("Error getting target card...")
	}
})

export const getComparingCard = createAsyncThunk(
	"/getComparingCard",
	async (productId, thunkAPI) => {
		try {
			const response = await WBAPI.getProductCardByProduct_ids({ id: productId })
			return response
		} catch (err) {
			return thunkAPI.rejectWithValue("Error getting comparing card...")
		}
	}
)
