import { createAsyncThunk } from "@reduxjs/toolkit"
import { isEmpty } from "ramda"

import API from "root/js/api"

import { dateToTimestamp } from "root/js/utils"

const WBAPI = new API()

export const getKeystat = createAsyncThunk(
	"/getKeystat",
	async (
		{
			date_from,
			date_to,
			subjects,
			keyword_ids,
			subject_ids,
			search,
			stop_words,
			limit,
			offset,
			extra_fields,
		},
		thunkAPI
	) => {
		try {
			const response = await WBAPI.getProductKeywordsStatByData({
				date_from: "2022-05-01",
				subject_ids,
				search,
				subjects,
				keyword_ids,
				stop_words,
				date_to,
				limit,
				offset,
				ordering: "-frequency",
				extra_fields,
			})

			return response
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)
export const getPageChange = createAsyncThunk(
	"/getPageChange",
	async (
		{
			date_from,
			date_to,
			subjects,
			keyword_ids,
			search,
			stop_words,
			limit,
			subject_ids,
			offset,
			extra_fields,
			page
		},
		thunkAPI
	) => {
		try {
			const response = await WBAPI.getProductKeywordsStatByData({
				date_from: "2022-05-01",
				search,
				subject_ids,
				subjects,
				keyword_ids,
				stop_words,
				date_to,
				limit,
				offset,
				ordering: "-frequency",
				extra_fields,
			})

			return { response, page }
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)
export const getNewKeystatMonth = createAsyncThunk(
	"/getNewKeystatMonth",
	async (
		{
			date_from,
			subjects,
			date_to,
			keyword_ids,
			search,
			stop_words,
			limit,
			offset,
			subject_ids,
			extra_fields,
		},
		thunkAPI
	) => {
		try {
			const new_date_to = dateToTimestamp(
				new Date(new Date(date_to).setDate(new Date(date_to).getDate() - 1))
			)
			const response = await WBAPI.getProductKeywordsStatByData({
				date_from: "2022-05-01",
				subject_ids,
				search,
				keyword_ids,
				subjects,
				stop_words,
				date_to: new_date_to,
				limit,
				ordering: "-frequency",
				offset,
				extra_fields,
			})

			return {
				...response,
				date_to,
			}
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)
export const installKeystat = createAsyncThunk(
	"/getKeystat",
	async (
		{
			period,
			subject_ids,
			subjects,
			keyword_ids,
			search,
			stop_words,
			limit,
			offset,
			extra_fields,
		},
		thunkAPI
	) => {
		try {
			const response = await WBAPI.getProductKeywordsStatExport({
				period,
				search,
				keyword_ids,
				subject_ids,
				subjects,
				stop_words,
				limit,
				offset,
				extra_fields,
			})
			return response
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)

export const setWord = createAsyncThunk("/setWord", async ({ text }, thunkAPI) => {
	try {
		return text
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const setWords = createAsyncThunk(
	"/setWords",
	async (
		{
			date_from,
			date_to,
			subject_ids,
			subjects,
			dispatch,
			stop_words,
			limit,
			offset,
			extra_fields,
			array,
			page = 1
		},
		thunkAPI
	) => {
		try {
			if (!isEmpty(array)) {
				dispatch(
					getKeystat({
						date_from: "2022-05-01",
						subjects,
						date_to,
						subject_ids,
						search: array.join(";"),
						stop_words,
						limit,
						offset,
						extra_fields,
					})
				)
			}
			return { array, page }
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)

export const removeWord = createAsyncThunk("/removeWord", async ({ text }, thunkAPI) => {
	try {
		return text
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const checkAllCheckboxes = createAsyncThunk(
	"/checkAllCheckboxes",
	async (boolean, thunkAPI) => {
		try {
			return boolean
		} catch (error) {
			return thunkAPI.rejectWithValue("Error")
		}
	}
)
export const checkCheckbox = createAsyncThunk("/checkCheckbox", async (id, thunkAPI) => {
	try {
		return id
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})

export const submitStopWords = createAsyncThunk("/submitStopWords", async (data, thunkAPI) => {
	try {
		return data
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const removeStopWords = createAsyncThunk("/removeStopWords", async (data, thunkAPI) => {
	try {
		return data
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
export const submitSubject = createAsyncThunk("/submitSubject", async (data, thunkAPI) => {
	try {
		return data
	} catch (error) {
		return thunkAPI.rejectWithValue("Error")
	}
})
