import React, {useState} from "react"

import TextField from "@mui/material/TextField"
import classnames from "classnames"
import MaskedInput, {conformToMask} from "react-text-mask"


import styles from "./index.module.scss"

const TextMaskCustom = props => {
	const {inputRef, onFocus, onBlur, onChange, maskprops, ...other} = props
	const [showMask, setShowMask] = useState(false)

	const emptyConformedValue = conformToMask("", maskprops.mask).conformedValue
	const getFirstUnfilled = value => {
		let firstUnfilled = value.indexOf("_")
		firstUnfilled === -1 && (firstUnfilled = emptyConformedValue.length)
		return firstUnfilled
	}
	const setCursor = (el, pos) => {
		el.setSelectionRange(pos, pos)
	}

	return (
		<MaskedInput
			{...other}
			{...maskprops}
			guide
			showMask={showMask}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			onChange={e => {
				e.persist()
				onChange(e)
				const cursorPositin = e.target.selectionStart
				if (cursorPositin === 0) {
					setCursor(e.target, getFirstUnfilled(emptyConformedValue))
				}
			}}
			onFocus={e => {
				e.persist()
				setShowMask(true)
				setTimeout(() => {
					setCursor(e.target, getFirstUnfilled(e.target.value))
				}, 1)
				onFocus(e)
			}}
			onBlur={e => {
				e.persist()
				if (e.target.value === emptyConformedValue) {
					setShowMask(false)
					e.target.value = ""
				}
				onBlur(e)
				onChange(e)
			}}
			onMouseDown={e => {
				e.persist()
				setTimeout(() => {
					setCursor(e.target, getFirstUnfilled(e.target.value))
				}, 1)
			}}
		/>
	)
}

const TextField_ = props => {
	const {InputProps = {}, maskProps, className, classes = {}, ...rest} = props

	return (
		<TextField
			className={classnames(styles.root, className, classes.root)}
			InputProps={Object.assign(
				{
					classes: {
						root: styles.InputRoot,
						notchedOutline: styles.notchedOutline,
						focused: styles.focused,
						error: styles.error,
					},
					inputComponent: maskProps ? TextMaskCustom : "input",
				},
				InputProps
			)}
			InputLabelProps={{
				classes: {
					root: styles.labelRoot,
					focused: styles.labelFocused,
					shrink: styles.labelShrink,
				},
			}}
			FormHelperTextProps={{
				classes: {
					root: styles.helperTextRoot,
				},
			}}
			inputProps={{
				className: styles.inputRoot,
				maskprops: maskProps ? maskProps : null,
			}}
			fullWidth
			variant="outlined"
			{...rest}
		/>
	)
}

export default TextField_
