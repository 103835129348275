import { createApi } from "@reduxjs/toolkit/dist/query/react"

import API from "root/js/api"

import apiFunctions from "./apiFunctions"

const WBAPI = new API()

const WBAPIBaseQuery = () => async ({ apiMethod, params }) => {
	try {
		const data = await WBAPI[apiMethod](params)
		return { data }
	} catch (error) {
		console.log({ error })
		return {
			error,
		}
	}
}

export const WBapi = createApi({
	reducerPath: "WBAPI",
	baseQuery: WBAPIBaseQuery(),
	tagTypes: ["UserData"],
	endpoints: build => ({
		getUserData: build.query({
			query: ({ params }) => ({ apiMethod: apiFunctions.getUserData, params }),
			providesTags: result => ["UserData"],
		}),
		getUserDataById: build.query({
			query: ({ params }) => ({ apiMethod: apiFunctions.getUserDataById, params }),
		}),
		saveUserData: build.mutation({
			query: ({ params }) => ({ apiMethod: apiFunctions.saveUserData, params }),
			invalidatesTags: ["UserData"],
		}),
		patchUserData: build.mutation({
			query: ({ params }) => ({ apiMethod: apiFunctions.patchUserData, params }),
			invalidatesTags: ["UserData"],
		}),
		deleteUserData: build.mutation({
			query: ({ params }) => ({ apiMethod: apiFunctions.deleteUserData, params }),
			invalidatesTags: ["UserData"],
		}),
		getParsersSellers: build.query({
			query: ({ params }) => ({ apiMethod: apiFunctions.getParsersSellers, params }),
			keepUnusedDataFor: 600,
		}),
		getParsersBrands: build.query({
			// query: ({params}) => ({apiMethod: apiFunctions.getParsersBrands, params}),
			query: ({ params }) => {
				const obj = { ...params }
				if (!params?.date_from && !params?.date_to && !params?.period) {
					obj["period"] = 7
				}
				return ({
					apiMethod: apiFunctions.getBrandsDynamic,
					params: {
						...obj
					},
				})
			},
			keepUnusedDataFor: 600,
		}),
		getParsersCategories: build.query({
			query: ({ params }) => ({ apiMethod: apiFunctions.getParsersBrands, params }),
			// query: ({params}) => ({apiMethod: apiFunctions.getBrandsDynamic, params}),
			keepUnusedDataFor: 600,
		}),
		getPositionsKeywords: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductKeywordsByProductId,
				params
			}),
		}),
		getPositionsMyKeywords: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFavouritesDynamic,
				params
			}),
			providesTags: result => ["PositionsMyKeywords"],
		}),
		deleteKeywordsToProduct: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.deleteKeywordsToProduct,
				params
			}),
			invalidatesTags: ["PositionsMyKeywords"],
		}),
		deleteAllGeoKeywordsToProduct: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.deleteProductKeywordPositions,
				params
			}),
			invalidatesTags: ["PositionsMyKeywords"],
		}),
		addKeywordsToProduct: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.addKeywordsToProduct,
				params
			}),
			invalidatesTags: ["PositionsMyKeywords"],
		}),
		getGeo: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductKeywordGeolocationsByArticule,
				params
			}),
			providesTags: result => ["PositionsMyKeywords"],
		}),

		// !
		getMyKeywordsHeader: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFavouritesDynamicTop,
				params
			}),
			providesTags: result => ["PositionsMyKeywords"],
		}),
		// !--
		getKeywordsHeader: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getTopKeywordsDynamic,
				params
			}),
		}),


		// ! GEO LOCATION BY ARTICULE SIZES
		getSizesByArticuleLocations: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSizesBySizeLocations,
				params
			}),
			invalidatesTags: () => ["GeoSizeLocations"],
		}),

		getAllGeolocations: build.query({
			providesTags: () => ["GeoLocations"],
			query: ({ params }) => ({
				apiMethod: apiFunctions.getGeolocations,
				params
			}),
		}),
		getProductFrequencySubjects: build.query({
			providesTags: () => ["ProductFrequencySubjects"],
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFrequencySubjectIds,
				params
			}),
		}),

		searchProductFrequencySubjects: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFrequencySubjectIds,
				params
			}),
			invalidatesTags: () => ["ProductFrequencySubjects"],
		}),


		getProductFrequencyBrands: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFrequencyBrands,
				params
			}),
			invalidatesTags: () => ["ProductFrequencyBrands"],
		}),

		getProductFrequencySellers: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductFrequencySellers,
				params
			}),
			invalidatesTags: () => ["ProductFrequencySellers"],
		}),

		getParsersSubjects: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSubjectIds,
				params
			}),
			invalidatesTags: () => ["getSubjectIds"],
		}),

		getDinamicKeywordsFrazes: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getDinamicKeywordsFrazes,
				params
			}),
			providesTags: () => ["getDinamicKeywordsFrazes"],
		}),

		getSupplier: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSupplierArticleAdvertsUsageHistory,
				params
			}),
			providesTags: () => ["getSupplier"],
		}),

		getAnalyticsPricesSubjects: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getAnalyticsPricesSubjects,
				params
			}),
			providesTags: () => ["getAnalyticsPricesSubjects"],
		}),

		setSupplier: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSupplierArticleAdvertsUsageHistory,
				params
			}),
			invalidatesTags: () => ["getSupplier"],
		}),
		getProductsDynamic: build.query({
			query: ({ params }) => {
				console.log(params);
				return ({
					apiMethod: apiFunctions.getProductsDynamic,
					params
				})
			},
			providesTags: () => ["getProductsDynamic"],
		}),

		getCompanyWB: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getCompanyWB,
				params
			}),
			providesTags: () => ["getCompanyWB"],
		}),
		getSppAnalyticsPrices: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSppAnalyticsPrices,
				params
			}),
			providesTags: () => ["getSppAnalyticsPrices"],
		}),
		setSppAnalyticsPrices: build.mutation({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSppAnalyticsPrices,
				params
			}),
			invalidatesTags: () => ["getSppAnalyticsPrices"],
		}),

		getBrands: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getBrands,
				params
			}),
			providesTags: () => ["getBrands"],
		}),
		getCategories: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getCategories,
				params
			}),
			providesTags: () => ["getCategories"],
		}),

		getGroupsKeywordsCluster: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getGroupsKeywordsCluster,
				params
			}),
			providesTags: () => ["getGroupsKeywordsCluster"],
		}),

		getKeywordsStatSubjects: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getKeywordsStatSubjects,
				params
			}),
			providesTags: () => ["getKeywordsStatSubjects"],
			keepUnusedDataFor: 600,
		}),
		getKeywordsSummarySubjects: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getKeywordsSummarySubjects,
				params
			}),
			providesTags: () => ["getKeywordsSummarySubjects"],
			keepUnusedDataFor: 600,
		}),
		getProductsDynamicAnalysis: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductsDynamicAnalysis,
				params
			}),
			providesTags: () => ["getProductsDynamicAnalysis"],
		}),
		getGroupedProductDynamic: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getGroupedProductDynamic,
				params
			}),
			providesTags: () => ["getGroupedProductDynamic"],
		}),
		getPriceSteps: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getPriceSteps,
				params
			}),
			providesTags: () => ["getPriceSteps"],
		}),
		getProductsTrendGrouped: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductsTrendGrouped,
				params
			}),
			providesTags: () => ["getProductsTrendGrouped"],
		}),
		getWareHouseLogistic: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getWareHouseLogistic,
				params
			}),
			providesTags: () => ["getWareHouseLogistic"],
		}),
		getProductsTrend: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductsTrend,
				params
			}),
			providesTags: () => ["getProductsTrend"],
		}),
		getGroupedBrandsTrend: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getGroupedBrandsTrend,
				params
			}),
			providesTags: () => ["getGroupedBrandsTrend"],
		}),
		getBrandsTrend: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getBrandsTrend,
				params
			}),
			providesTags: () => ["getBrandsTrend"],
		}),
		getSellersDynamic: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSellersDynamic,
				params
			}),
			providesTags: () => ["getSellersDynamic"],
		}),
		getGeneralCategoriesDynamic: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getGeneralCategoriesDynamic,
				params
			}),
			providesTags: () => ["getGeneralCategoriesDynamic"],
		}),
		getParsersCollectionsProducts: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getParsersCollectionsProducts,
				params
			}),
			providesTags: () => ["getParsersCollectionsProducts"],
		}),
		getExactParsersCollectionsKeywords: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getExactParsersCollectionsKeywords,
				params
			}),
			providesTags: () => ["getExactParsersCollectionsKeywords"],
		}),
		getProductKeywordsStat: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getProductKeywordsStat,
				params
			}),
			providesTags: () => ["getProductKeywordsStat"],
		}),
		getSellersTrend: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getSellersTrend,
				params
			}),
			providesTags: () => ["getSellersTrend"],
		}),
		getCategoriesTrend: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getCategoriesTrend,
				params
			}),
			providesTags: () => ["getCategoriesTrend"],
		}),
		getBrandsDynamic: build.query({
			query: (params) => ({
				apiMethod: apiFunctions.getBrandsDynamic,
				params
			}),
			providesTags: () => ["getBrandsDynamic"],
		}),
		getKeywordStatByPeriod: build.query({
			query: (params) => ({
				apiMethod: apiFunctions.getKeywordStatByPeriod,
				params
			}),
			providesTags: () => ["getKeywordStatByPeriod"],
		}),
		getKeywordsStatByIdDaily: build.query({
			query: (params) => ({
				apiMethod: apiFunctions.getKeywordsStatByIdDaily,
				params
			}),
			providesTags: () => ["getKeywordsStatByIdDaily"],
		}),
		getMonitoringTraffics: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getMonitoringTraffics,
				params
			}),
			providesTags: () => ["getMonitoringTraffics"],
		}),
		getMonitoringTrafficsPublicationDynamic: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getMonitoringTrafficsPublicationDynamic,
				params
			}),
			providesTags: () => ["getMonitoringTrafficsPublicationDynamic"],
		}),
		getMonitoringTrafficsLimits: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getMonitoringTrafficsLimits,
				params
			}),
			providesTags: () => ["getMonitoringTrafficsLimits"],
		}),
		getTrafficsTariffPlan: build.query({
			query: ({ params }) => ({
				apiMethod: apiFunctions.getTrafficsTariffPlan,
				params
			}),
			providesTags: () => ["getTrafficsTariffPlan"],
		}),
	}),
})
