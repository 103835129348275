import {createSlice, current} from "@reduxjs/toolkit"
import {equals, isEmpty, length, prop, trim} from "ramda"

import {
	changeCostPrice,
	changeProductCostPrice,
	clearDataNotLoaded,
	clearError,
	clearWbParseError,
	getArticuleData,
	getBarcodeData,
	getSaccess,
	importExcel,
	mackDataNotLoaded,
	mackWbParseError,
	postSearchArticule,
	postSearchArticuleArticule,
	postSearchBarcode,
	saveArticuleData,
	saveBarcodeData,
	searchArticuleByTitle,
	searchBySize,
	searchByTitle,
	setTab,
	unDisableSaverButton,
} from "./actions"

const initialState = {
	barcodeError: false,
	tab: "По артикулам",
	successMessage: {},
	errorBarcodeStatuses: [],
	disabledSaveButton: {
		articule: false,
		barcode: false,
	},

	isBarcodeLoading: true,
	searchBarcode: "",
	searchBarcodeTitle: "",
	searchSize: "",
	searchArticule: "",
	barcodeData: [],
	barcodeLastData: [],
	barcodeFullData: [],
	searchBarcodeData: [],
	errorArticuleStatuses: [],
	zeroCostBarcodes: false,

	articuleData: [],
	articuleLastData: [],
	articuleFullData: [],
	isArticuleLoading: true,
	zeroCostArticules: false,
	articuleError: false,
	searchArticuleData: [],
	searchArticuleTitle: "",
	searchArticuleArticule: "",
	articuleBrandFilters: [],
	barcodeBrandFilters: [],

	dataNotLoaded: false,
	wbParseError:false,
}

export const analyticsSlice = createSlice({
	name: "analyticsSlice",
	initialState,
	reducers: {
		setZeroCostArticules(state, {payload}) {
			state.zeroCostArticules = payload
			if (!isEmpty(state.articuleBrandFilters)) {
				const filteredArticuleData = current(state).articuleFullData.filter(art =>
					state.articuleBrandFilters.includes(art.brand_name)
				)

				if (payload) {
					state.articuleData = filteredArticuleData.filter(({cost}) => +cost.cost === 0)
					state.articuleLastData = filteredArticuleData.filter(
						({cost}) => +cost.cost === 0
					)
				} else {
					state.articuleData = filteredArticuleData
					state.articuleLastData = filteredArticuleData
				}
			} else {
				if (payload) {
					state.articuleData = current(state).articuleFullData.filter(
						({cost}) => +cost.cost === 0
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
					state.articuleLastData = current(state).articuleFullData.filter(
						({cost}) => +cost.cost === 0
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
				} else {
					state.articuleData = current(state).articuleFullData
					state.articuleLastData = current(state).articuleFullData
				}
			}
		},

		setZeroCostBarcodes(state, {payload}) {
			state.zeroCostBarcodes = payload
			if (!isEmpty(state.barcodeBrandFilters)) {
				const filteredBarcodeData = current(state).barcodeFullData.filter(bcode =>
					state.barcodeBrandFilters.includes(bcode.brand_name)
				)

				if (payload) {
					state.barcodeData = filteredBarcodeData.filter(({cost}) => +cost.cost === 0)
					state.barcodeLastData = filteredBarcodeData.filter(({cost}) => +cost.cost === 0)
				} else {
					state.barcodeData = filteredBarcodeData
					state.barcodeLastData = filteredBarcodeData
				}
			} else {
				if (payload) {
					state.barcodeData = current(state).barcodeFullData.filter(
						({cost}) => +cost.cost === 0
					)
					state.barcodeLastData = current(state).barcodeFullData.filter(
						({cost}) => +cost.cost === 0
					)
				} else {
					state.barcodeData = current(state).barcodeFullData
					state.barcodeLastData = current(state).barcodeFullData
				}
			}
		},
		setBrandFilterArticules(state, {payload}) {
			state.articuleBrandFilters = payload
		},
		setBrandFilterBarcodes(state, {payload}) {
			state.barcodeBrandFilters = payload
		},
		setBrandFilterArticulesData(state, {payload}) {
			if (current(state).zeroCostArticules) {
				const zeroArticuleData = current(state).articuleFullData.filter(
					({cost}) => +cost.cost === 0
				)
				if (!isEmpty(payload)) {
					state.articuleData = zeroArticuleData.filter(art =>
						payload.includes(art.brand_name)
					)
					state.articuleLastData = zeroArticuleData.filter(art =>
						payload.includes(art.brand_name)
					)
				} else {
					state.articuleData = zeroArticuleData
					state.articuleLastData = zeroArticuleData
				}
			} else {
				if (!isEmpty(payload)) {
					state.articuleData = current(state).articuleFullData.filter(art =>
						payload.includes(art.brand_name)
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
					state.articuleLastData = current(state).articuleFullData.filter(art =>
						payload.includes(art.brand_name)
					)
					// .map((item, index) => ({
					// 	...item,
					// 	cost: {
					// 		...item.cost,
					// 		index,
					// 	},
					// }))
				} else {
					state.articuleData = current(state).articuleFullData
					state.articuleLastData = current(state).articuleFullData
				}
			}
		},
		setBrandFilterBarcodesData(state, {payload}) {
			if (current(state).zeroCostBarcodes) {
				const zeroBarcodeData = current(state).barcodeFullData.filter(
					({cost}) => +cost.cost === 0
				)
				if (!isEmpty(payload)) {
					state.barcodeData = zeroBarcodeData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
					state.barcodeLastData = zeroBarcodeData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
				} else {
					state.barcodeData = zeroBarcodeData
					state.barcodeLastData = zeroBarcodeData
				}
			} else {
				if (!isEmpty(payload)) {
					state.barcodeData = current(state).barcodeFullData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
					state.barcodeLastData = current(state).barcodeFullData.filter(bcode =>
						payload.includes(bcode.brand_name)
					)
				} else {
					state.barcodeData = current(state).barcodeFullData
					state.barcodeLastData = current(state).barcodeFullData
				}
			}
		},
	},
	extraReducers: builder => {
		// ! Success message
		builder.addCase(getSaccess.fulfilled, (state, {payload}) => {
			state.successMessage = payload
		})
		// ! Set tab
		builder.addCase(setTab.fulfilled, (state, {payload}) => {
			state.tab = payload
		})

		// ! Import Excel
		builder.addCase(importExcel.pending, state => {
			state.isArticuleLoading = true
			state.isLoading = true
		})
		builder.addCase(importExcel.fulfilled, (state, {payload}) => {
			if (prop("identifier", payload) === "barcode") {
				state.disabledSaveButton.barcode = payload.disabledSaveButton
				state.barcodeData = payload.data
				state.errorBarcodeStatuses = payload.errorStatuses
			} else {
				state.disabledSaveButton.articule = payload.disabledSaveButton
				state.articuleData = payload.data
				state.errorArticuleStatuses = payload.errorArticuleStatuses
			}
			state.barcodeError = payload.error
			state.isArticuleLoading = false
			state.isBarcodeLoading = false
		})
		builder.addCase(importExcel.rejected, state => {
			state.isArticuleLoading = false
			state.isBarcodeLoading = false
			state.barcodeError = true
		})

		// ! Articule
		builder.addCase(getArticuleData.pending, state => {
			state.isArticuleLoading = true
		})
		builder.addCase(getArticuleData.fulfilled, (state, {payload}) => {
			state.dataNotLoaded = false
			state.articuleData = payload
			state.articuleLastData = payload
			state.articuleFullData = payload
			state.isArticuleLoading = false
		})
		builder.addCase(getArticuleData.rejected, (state, {payload}) => {
			state.dataNotLoaded = equals(payload, "data_not_loaded")
			state.isArticuleLoading = false
			state.barcodeError = true
		})
		builder.addCase(clearDataNotLoaded.fulfilled, state => {
			state.dataNotLoaded = false
		})
		builder.addCase(mackDataNotLoaded.fulfilled, state => {
			state.dataNotLoaded = true
		})
		builder.addCase(mackWbParseError.fulfilled, state => {
					state.wbParseError = true
				})
		builder.addCase(clearWbParseError.fulfilled, state => {
						state.wbParseError = false
		})
		builder.addCase(changeProductCostPrice.fulfilled, (state, {payload}) => {
			state.articuleData = state.articuleData.map(item => {
				if (item.cost.index === payload.index) {
					return {
						...item,
						cost: {
							...item.cost,
							cost: payload.number,
						},
					}
				}
				return item
			})
		})

		builder.addCase(saveArticuleData.pending, state => {
			state.isArticuleLoading = true
		})
		builder.addCase(saveArticuleData.fulfilled, (state, {payload}) => {
			state.articuleData = payload
			state.articuleLastData = payload
			if (length(current(state).articuleFullData) === length(payload)) {
				state.articuleFullData = payload
			} else {
				state.articuleFullData = current(state).articuleFullData.map(
					obj => payload.find(o => o.identifier === obj.identifier) || obj
				)
				// .map((item, idx) => ({
				// 	...item,
				// 	cost: {
				// 		...item.cost,
				// 		index: idx,
				// 	},
				// }))
			}
			state.isArticuleLoading = false
			state.errorArticuleStatuses = []
			state.errorBarcodeStatuses = []
		})
		builder.addCase(saveArticuleData.rejected, (state, {payload = []}) => {
			state.articuleData = payload
			state.articuleLastData = payload
			state.isArticuleLoading = false
			state.barcodeError = true
		})
		builder.addCase(postSearchArticuleArticule.fulfilled, (state, {payload}) => {
			state.searchArticuleArticule = payload.text
			state.searchArticuleData = trim(payload.text) === "" ? [] : payload.data
		})
		builder.addCase(searchArticuleByTitle.fulfilled, (state, {payload}) => {
			state.searchArticuleTitle = payload.text
			state.searchArticuleData = trim(payload.text) === "" ? [] : payload.data
		})

		builder.addCase(unDisableSaverButton.fulfilled, (state, {payload}) => {
			if (payload === "barcode") {
				state.disabledSaveButton.barcode = false
			} else {
				state.disabledSaveButton.articule = false
			}
		})

		// ! Barcode
		builder.addCase(getBarcodeData.pending, state => {
			state.isBarcodeLoading = true
		})
		builder.addCase(getBarcodeData.fulfilled, (state, {payload}) => {
			state.dataNotLoaded = false
			state.barcodeData = payload
			state.barcodeLastData = payload
			state.barcodeFullData = payload
			state.isBarcodeLoading = false
		})
		builder.addCase(getBarcodeData.rejected, (state, {payload}) => {
			state.dataNotLoaded = equals(payload, "data_not_loaded")
			state.isBarcodeLoading = false
			state.barcodeError = true
		})
		builder.addCase(saveBarcodeData.pending, state => {
			state.isBarcodeLoading = true
		})
		builder.addCase(saveBarcodeData.fulfilled, (state, {payload}) => {
			state.barcodeData = payload
			state.barcodeLastData = payload
			state.isBarcodeLoading = false
			if (length(current(state).barcodeFullData) === length(payload)) {
				state.barcodeFullData = payload
			} else {
				state.barcodeFullData = current(state).barcodeFullData.map(
					obj => payload.find(o => o.identifier === obj.identifier) || obj
				)
			}
			state.errorArticuleStatuses = []
			state.errorBarcodeStatuses = []
		})
		builder.addCase(saveBarcodeData.rejected, (state, {payload = []}) => {
			state.barcodeData = payload
			state.barcodeLastData = payload
			state.isBarcodeLoading = false
			state.barcodeError = true
		})

		builder.addCase(postSearchBarcode.fulfilled, (state, {payload}) => {
			state.searchBarcode = payload.text
			state.searchBarcodeData = trim(payload.text) === "" ? [] : payload.data
		})
		builder.addCase(searchByTitle.fulfilled, (state, {payload}) => {
			state.searchBarcodeTitle = payload.text
			state.searchBarcodeData = trim(payload.text) === "" ? [] : payload.data
		})
		builder.addCase(searchBySize.fulfilled, (state, {payload}) => {
			state.searchSize = payload.text
			state.searchBarcodeData = trim(payload.text) === "" ? [] : payload.data
		})
		builder.addCase(postSearchArticule.fulfilled, (state, {payload}) => {
			state.searchArticule = payload.text
			state.searchBarcodeData = trim(payload.text) === "" ? [] : payload.data
		})
		builder.addCase(changeCostPrice.fulfilled, (state, {payload}) => {
			state.barcodeData = state.barcodeData.map(item => {
				if (item.cost.index === payload.index) {
					return {
						...item,
						cost: {
							...item.cost,
							cost: payload.number,
						},
					}
				}
				return item
			})
		})
		// ! Error actions
		builder.addCase(clearError.fulfilled, (state, {payload}) => {
			state.barcodeError = payload
			state.articuleError = payload
			state.errorBarcodeStatuses = []
			state.errorArticuleStatuses = []
		})
	},
})

export const {
	setZeroCostArticules,
	setZeroCostBarcodes,
	setBrandFilterArticules,
	setBrandFilterArticulesData,
	setBrandFilterBarcodesData,
	setBrandFilterBarcodes,
} = analyticsSlice.actions

export default analyticsSlice.reducer
