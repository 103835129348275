import { createAsyncThunk } from "@reduxjs/toolkit"

export const checkCategory = createAsyncThunk(
	"/checkCategory",
	async (data) => data
)
export const checkCategories = createAsyncThunk(
	"/checkCategories",
	async (data) => data
)
export const setTabCategory = createAsyncThunk(
	"/setTabCategory",
	async (tab) => tab
)
