import { createAsyncThunk } from "@reduxjs/toolkit"

export const checkProduct = createAsyncThunk(
	"/checkProduct",
	async (data) => data
)
export const checkProducts = createAsyncThunk(
	"/checkProducts",
	async (data) => data
)
