import React, {forwardRef} from "react"

import {  propOr } from "ramda"

// import Template from "./utils/Template"

// eslint-disable-next-line react/display-name
export default forwardRef((props/*, ref*/) => (
	<svg
		width="18"
		height="14.73"
		viewBox="0 0 14 12"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.4363 1.10656C13.0186 0.630996 12.3404 0.631295 11.9221 1.10656L5.10712 8.85582L2.07816 5.41177C1.65991 4.9362 0.981935 4.9362 0.563686 5.41177C0.145438 5.88734 0.145438 6.65823 0.563686 7.1338L4.34973 11.4387C4.55872 11.6763 4.83276 11.7955 5.10683 11.7955C5.3809 11.7955 5.65521 11.6766 5.8642 11.4387L13.4363 2.82856C13.8546 2.35332 13.8546 1.5821 13.4363 1.10656Z"
			fill={propOr("#0FB682", "color", props) }
		/>
	</svg>
))
