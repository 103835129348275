import {createSlice} from "@reduxjs/toolkit"

const initialState = {
	productAnalyzer: null,
	brandAnalyzer: null,
	sellerAnalyzer: null,
}

const settingsColumnsSlice = createSlice({
	name: "settingsColumns",
	initialState,
	reducers: {
		setSettingsProductAnalyzer(state, {payload}) {
			state.productAnalyzer = payload.settings
			state.brandAnalyzer = payload.brandSettings
			state.sellerAnalyzer = payload.sellerSettings
		},
	},
})

export const {setSettingsProductAnalyzer} = settingsColumnsSlice.actions

export default settingsColumnsSlice.reducer
