import {createSlice} from "@reduxjs/toolkit"

import {deleteMark, getMarks, getSeasonalityMarks, setMark} from "./actions"

const initialState = {
	marksLoading: false,
	marks: [],
	marksHolidays: [],
	marksHighDemand: [],
	marksSeasons: [],
	marksError: null,

	// seasonality
	seasonalityMarksLoading: false,
	seasonalityMarks: [],
	seasonalityHolidays: [],
	seasonalityHighDemand: [],
	seasonalitySeasons: [],
	seasonalityError: null,

	setMarkLoading: false,
	setMarkSuccess: false,
	setMarkError: null,

	deleteMarkLoading: false,
	deleteMarkSuccess: false,
	deleteMarkError: null,
}

const marks = createSlice({
	name: "marks",
	initialState,
	reducers: {},
	extraReducers: builder => {
		// get for seasonality
		builder.addCase(getSeasonalityMarks.pending, state => {
			state.seasonalityMarksLoading = true
		})
		builder.addCase(getSeasonalityMarks.fulfilled, (state, {payload}) => {
			state.seasonalityMarksLoading = false
			state.seasonalityMarks = payload.user_marks
			state.seasonalityHolidays = payload.holidays
			state.seasonalitySeasons = payload?.seasons.map(item => {
				const marksSeasonsStroke = "#" + Math.floor(Math.random() * 16777215).toString(16)
				return {
					...item,
					stroke: marksSeasonsStroke,
				}
			})
			state.seasonalityHighDemand = payload?.high_demand.map(item => {
				const seasonalityHighDemandStroke =
					"#" + Math.floor(Math.random() * 16777215).toString(16)
				return {
					...item,
					stroke_: seasonalityHighDemandStroke,
				}
			})
			state.seasonalityError = null
		})
		builder.addCase(getSeasonalityMarks.rejected, state => {
			state.seasonalityMarksLoading = false
			state.seasonalityError = "Seasonality marks error..."
		})

		// get marks
		builder.addCase(getMarks.pending, state => {
			state.marksLoading = true
		})
		builder.addCase(getMarks.fulfilled, (state, {payload}) => {
			// const {dateFrom, dateTo} = payload
			state.marksLoading = false
			state.marks = payload.user_marks
			state.marksHolidays = payload.holidays
			// propOr([], "seasons", clone(payload))
			state.marksSeasons = payload?.seasons.map(item => {
				const marksSeasonsStroke = "#" + Math.floor(Math.random() * 16777215).toString(16)
				return {
					...item,
					stroke: marksSeasonsStroke,
					start_date: item.start_date,
					end_date: item.end_date,
					// start_date:
					// 	new Date(dateFrom) >= new Date(item.start_date)
					// 		? dateFrom
					// 		: item.start_date,
					// end_date:
					// 	new Date(propOr(dateToTimestamp(new Date()), "end_date", item))
					// 	>=
					// 	new Date(dateTo)
					// 		? dateTo
					// 		: prop("end_date", item),
				}
			})
			// propOr([], "high_demand", clone(payload))
			state.marksHighDemand = payload?.high_demand.map(item => {
				const marksHighDemandStroke =
					"#" + Math.floor(Math.random() * 16777215).toString(16)
				return {
					...item,
					stroke_: marksHighDemandStroke,
					start_date: item.start_date,
					end_date: item.end_date,
					// start_date:
					// 	new Date(dateFrom) >= new Date(item.start_date)
					// 		? dateFrom
					// 		: item.start_date,
					// end_date:
					// 	new Date(propOr(dateToTimestamp(new Date()), "end_date", item)) >=
					// 	new Date(dateTo)
					// 		? dateTo
					// 		: prop("end_date", item),
				}
			})
			state.marksError = null
		})
		builder.addCase(getMarks.rejected, state => {
			state.marksLoading = false
			state.marksError = "Marks error..."
		})

		// set mark
		builder.addCase(setMark.pending, state => {
			state.setMarkSuccess = false
			state.setMarkLoading = true
		})
		builder.addCase(setMark.fulfilled, (state, {payload}) => {
			state.setMarkLoading = false
			state.setMarkSuccess = true
			state.setMarkError = null
		})
		builder.addCase(setMark.rejected, state => {
			state.setMarkLoading = false
			state.setMarkError = "Set mark error..."
		})

		// delete mark
		builder.addCase(deleteMark.pending, state => {
			state.deleteMarkSuccess = false
			state.deleteMarkLoading = true
		})
		builder.addCase(deleteMark.fulfilled, (state, {payload}) => {
			state.deleteMarkLoading = false
			state.deleteMarkSuccess = true
			state.deleteMarkError = null
		})
		builder.addCase(deleteMark.rejected, state => {
			state.deleteMarkLoading = false
			state.deleteMarkError = "Delete mark error..."
		})
	},
})

export const marksActions = {
	...marks.actions,
	getMarks,
	setMark,
	deleteMark,
	getSeasonalityMarks,
}

export default marks.reducer
