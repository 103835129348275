const initialState = {
	wb_sales_vol: false,
}

export const productCardReducer = (state = initialState, action) => {
	switch (action.type) {
		case "PRODUCT_WB_SALES_VOL":
			return {
				...state,
				wb_sales_vol: true,
			}

		default:
			return state
	}
}
