import { createAsyncThunk } from "@reduxjs/toolkit"

export const setWords = createAsyncThunk(
	"/setWords",
	async ({ value = "" }) => value
)
export const setParams = createAsyncThunk(
	"/setParams",
	async (data) => data
)
