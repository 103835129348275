import ColorHash from "color-hash";
import { isNil, prop } from "ramda";

import request, { postFile } from "./request";
import { capitalizeFirstLetter, deletePropFromObject, minmax, sortStrings } from "./utils";

const colorHash = new ColorHash({ saturation: 1 });

class API {
  constructor() {
    this.token = window.localStorage.getItem("token") || window.sessionStorage.getItem("token");
    this.user = window.localStorage.getItem("user") || window.sessionStorage.getItem("user")

    /*this.token &&
      request.interceptors.request.use(config => {
        config.headers.authorization = `Token ${this.token}`
        return config
      })*/
    this.token && (request.defaults.headers.common.Authorization = `Token ${this.token}`);
    if (typeof this.user === "string") {
      const { id, companyId } = JSON.parse(this.user)
      if (id && companyId) {
        request.defaults.headers.common.UserID = id
        request.defaults.headers.common.CompanyID = companyId
      }
    }

    const collectionSections = [
      "keywords",
      "brands",
      "categories",
      "products",
      "sellers",
      "categories",
      "product_keyword"
    ];

    collectionSections.forEach(sectionName => {
      this[`getParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        limit,
        offset,
        ordering
      } = {}) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/`,
          method: "get",
          params: {
            limit,
            offset,
            ordering
          }
        });
        return response.data;
      };

      this[`createParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        name,
        items
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/`,
          method: "post",
          data: {
            name,
            items
          }
        });
        return response.data;
      };

      this[`getExactParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        id,
        limit,
        offset = 0
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/${sectionName === "product_keyword" ? "products/" : ""
            }`,
          method: "get",
          params: {
            limit,
            offset
          }
        });
        return response.data;
      };
      // ! dependencies
      // "node-sass": "^4.14.1",
      // "react-side-effect": "^2.1.1",
      // "@testing-library/jest-dom": "^4.2.4",
      // "@testing-library/react": "^9.5.0",
      // "@testing-library/user-event": "^7.2.1",
      // "dayjs": "^1.11.7",
      // ! devDependencies
      // "@svgr/webpack": "^5.5.0",
      // "use-reducer-logger": "^1.0.2"

      this[`getExactParsersCollectionsProductKeyword${capitalizeFirstLetter(sectionName)}`] =
        async ({ id, limit, offset = 0, product_ids }) => {
          const response = await request({
            url: `/parsers/collections/${sectionName}/${id}/`,
            method: "get",
            params: {
              product_ids,
              limit,
              offset
            }
          });
          return response.data;
        };

      this[`updateParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        id,
        name,
        items
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/`,
          method: "patch",
          data: {
            name,
            items
          }
        });
        return response.data;
      };

      this[`deleteParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        id,
        product_ids
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/`,
          method: "delete",
          params: {
            product_ids
          }
        });
        return response.data;
      };

      this[`deleteAllItemsInParsersCollection${capitalizeFirstLetter(sectionName)}`] = async ({
        id
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/clear_items/`,
          method: "post"
        });
        return response.data;
      };

      this[`deleteItemsInParsersCollection${capitalizeFirstLetter(sectionName)}`] = async ({
        id,
        items
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/remove_items/`,
          method: "post",
          data: {
            items
          }
        });
        return response.data;
      };

      this[`addToParsersCollections${capitalizeFirstLetter(sectionName)}`] = async ({
        id,
        items
      }) => {
        const response = await request({
          url: `/parsers/collections/${sectionName}/${id}/add_items/`,
          method: "post",
          data: {
            items
          }
        });
        return response.data;
      };
    });
  }

  async isAuth() {
    const storedToken =
      window.localStorage.getItem("token") || window.sessionStorage.getItem("token");
    const isLsTookenExists = Boolean(storedToken);
    !this.token && isLsTookenExists && (this.token = storedToken);
    if (isLsTookenExists) {
      try {
        await this.getProfile();
        return true;
      } catch (err) {
        console.log(err);
        if (prop("status", prop("response", err)) === 401) {
          this.resetToken();
          return false;
        } else {
          return true;
        }
      }
    } else {
      this.resetToken();
      return false;
    }
  }

  getToken() {
    return this.token;
  }

  resetToken() {
    window.localStorage.removeItem("token");
    window.sessionStorage.removeItem("token");
    this.token = null;
    request.defaults.headers.common.Authorization = undefined;
  }

  setToken({ token, isSession }) {
    window[isSession ? "sessionStorage" : "localStorage"].setItem("token", token);
    window[isSession ? "localStorage" : "sessionStorage"].removeItem("token");
    this.token = token;
    request.defaults.headers.common.Authorization = `Token ${this.token}`;
  }

  async signIn({ phone, password }) {
    const response = await request({
      url: "/auth/login/",
      method: "post",
      data: {
        phone,
        password
      }
    });

    return response.data;
  }

  async signUp({ code, email, password, phone, name, social_login_id }) {
    const response = await request({
      url: "/auth/registration/",
      method: "post",
      data: {
        email,
        password,
        phone,
        name,
        code,
        social_login_id
      }
    });

    return response.data;
  }
  async getBrandFinancialLogic({ key, brand_ids, dateFrom, dateTo, category, subject }) {
    const response = await request({
      url: "/api/analytics/brands/by-rr-dates/",
      method: "get",
      params: {
        brand_ids,
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        subject_id__in: subject,
        category_id__in: category
      }
    });
    return response.data;
  }
  async getBrandFinancialCategories({ key, dateFrom, dateTo, subject, brand }) {
    const response = await request({
      url: "/analytics/categories/by-rr-dates/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        subject_id__in: subject,
        brand_id__in: brand
      }
    });
    return response.data;
  }

  async getProductSearch({ key, dateTo, dateFrom, brandsId, aritucl, category, subject }) {
    const response = await request({
      url: "/analytics/product-ids/search/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,

        subject_id__in: subject,
        brand_id__in: brandsId,
        category_id__in: category,

        supplier_article__in: aritucl
      }
    });
    return response.data;
  }

  async getBarcodesSearch({
    key,
    text = "",
    dateTo,
    dateFrom,
    brandsId,
    aritucl,
    category,
    subject,
    product
  }) {
    const response = await request({
      url: "/analytics/barcodes/search/",
      method: "get",
      params: {
        wb_api_key_ids: key,

        q: text,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: category,
        brand_id__in: brandsId,
        product_id__in: product,
        subject_id__in: subject,
        supplier_article__in: aritucl
      }
    });
    return response.data;
  }
  async getArticle({ key, text = "", dateTo, dateFrom, subject, producd, brandsId, ids }) {
    const response = await request({
      url: "/api/analytics/supplier-articles/search/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        q: text,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async getKeyIndicatorsWidgets({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    subject
  }) {
    const response = await request({
      url: "/analytics/key_indicators_widgets/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async keyIndicatorsDynamic({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject
  }) {
    const response = await request({
      url: "/analytics/key_indicators_dynamic/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async keyIndicatorsServiceStructrueWB({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject
  }) {
    const response = await request({
      url: "/analytics/key_indicators_wb_services_structure/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async keyIndicatorsTable({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    value,
    sort,
    limit,
    offset,
    subject,
    q
  }) {
    const response = await request({
      url: "/analytics/key_indicators_table/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        group_by: value,
        order_by: sort,
        limit,
        offset,
        subject_id__in: subject,
        q
      }
    });
    return response.data;
  }
  async keyIndicatorsTableDownload({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    group_by,
    subject
  }) {
    const response = await request({
      url: "/analytics/key_indicators_table/export/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        group_by,
        date_from: dateFrom,
        date_to: dateTo,
        subject_id__in: subject
      }
    });
    return response.data;
  }
  async commisionWidgets({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    subject
  }) {
    const response = await request({
      url: "/analytics/commission_widgets/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async commisionDiagram({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    subject
  }) {
    const response = await request({
      url: "/analytics/commission_dynamic/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async commisionTable({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    value,
    sort,
    limit,
    offset,
    subject,
    q
  }) {
    const response = await request({
      url: "/analytics/commission_table/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        group_by: value,
        order_by: sort,
        subject_id__in: subject,
        limit,
        offset,
        q
      }
    });
    return response.data;
  }

  async commisionTableDownload({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    subject,
    group_by
  }) {
    const response = await request({
      url: "/analytics/commission_table/export/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        group_by,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async riterReturnsWidgets({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    subject,
    producd
  }) {
    const response = await request({
      url: "analytics/ride_returns_widgets/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject
      }
    });
    return response.data;
  }

  async riterReturnsDiagramProduct({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value
  }) {
    const response = await request({
      url: "analytics/ride_returns_top_products/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        value
      }
    });
    return response.data;
  }

  async riterReturnsDiagram({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value
  }) {
    const response = await request({
      url: "analytics/ride_returns_dynamic/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        value
      }
    });
    return response.data;
  }

  async riteReturnOffices({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    value,
    subject
  }) {
    const response = await request({
      url: "/analytics/ride_returns_offices/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        value
      }
    });
    return response.data;
  }

  async riteReturnTables({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    value,
    sort,
    limit,
    subject,
    offset,
    q
  }) {
    const response = await request({
      url: "/analytics/ride_returns_table/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        group_by: value,
        order_by: sort,
        subject_id__in: subject,
        limit,
        offset,
        q
      }
    });
    return response.data;
  }
  async downloadReturnTables({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    group_by
  }) {
    const response = await request({
      url: "/analytics/ride_returns_table/export/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        group_by
      }
    });
    return response.data;
  }

  async penaltyAdditionalsWidgets({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    group_by
  }) {
    const response = await request({
      url: "analytics/penalty_additionals_widgets/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        group_by
      }
    });
    return response.data;
  }

  async penaltyTypes({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value
  }) {
    const response = await request({
      url: "analytics/penalty_additionals_penalty_types/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        value
      }
    });
    return response.data;
  }

  async penaltyAdditionalsPenaltyTypes({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value,
    type_name
  }) {
    const response = await request({
      url: "/analytics/penalty_additionals_penalty_types_products/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        type_name,
        value
      }
    });
    return response.data;
  }

  async penaltyOffices({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value
  }) {
    const response = await request({
      url: "analytics/penalty_additionals_offices/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,

        value
      }
    });
    return response.data;
  }

  async penaltyProducts({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value
  }) {
    const response = await request({
      url: "analytics/penalty_additionals_top_products/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,

        value
      }
    });
    return response.data;
  }

  async penaltyAdditionalDynamic({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    value,
    type_name
  }) {
    const response = await request({
      url: "analytics/penalty_additionals_dynamic/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        type_name,
        value
      }
    });
    return response.data;
  }
  async penaltyTable({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    aritucl,
    barcod,
    producd,
    value,
    sort,
    limit,
    subject,
    offset,
    q
  }) {
    const response = await request({
      url: "/analytics/penalty_additionals_table/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: aritucl,
        barcode__in: barcod,
        product_id__in: producd,
        group_by: value,
        order_by: sort,
        subject_id__in: subject,
        limit,
        offset,
        q
      }
    });
    return response.data;
  }

  async downloadPenaltyTable({
    key,
    dateFrom,
    dateTo,
    ids,
    brandsId,
    article,
    barcod,
    producd,
    subject,
    group_by
  }) {
    const response = await request({
      url: "/analytics/penalty_additionals_table/export/",

      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: ids,
        brand_id__in: brandsId,
        supplier_article__in: article,
        barcode__in: barcod,
        product_id__in: producd,
        subject_id__in: subject,
        group_by
      }
    });
    return response.data;
  }

  async getCompanyWB() {
    const response = await request({
      url: "/company/wb_cabinets/",
      method: "get"
    });
    return response.data;
  }
  async getMonitoringTraffics({ search, limit, offset }) {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/",
      method: "get",
      params: {
        search,
        limit,
        offset
      }
    });
    return response.data;
  }
  async postMonitoringTraffics({ data }) {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/",
      method: "post",
      data
    });
    return response.data;
  }
  async getMonitoringTrafficsLimits() {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/limits/",
      method: "get",
    });
    return response.data;
  }
  async getMonitoringTrafficspaymentUrl(data) {
    const response = await request({
      url: "/company/v2/purchase/feature/",
      method: "post",
      data
    });
    return response.data;
  }


  async exportMonitoringTraffics({
    supplier_article,
    date_from,
    date_to,
    source,
  }) {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/export/",
      method: "get",
      params: {
        source,
        supplier_article,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async updateMonitoringTraffics({ data }) {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/bulk_update/",
      method: "post",
      data
    });
    return response.data;
  }

  async getMonitoringTrafficsPublicationDynamic({
    supplier_articles,
    supplier_article,
    date_to,
    date_from,
    limit,
    offset
  }) {
    const response = await request({
      url: "/monitoring/supplier_article_advert_monitoring/publication_dynamic/",
      method: "get",
      params: {
        supplier_articles,
        supplier_article,
        date_to,
        date_from,
        limit,
        offset
      }
    });
    return response.data;
  }

  async createWBCabinets({ name, isShared }) {
    const response = await request({
      url: "/company/wb_cabinets/",
      method: "post",
      data: {
        name,
        isShared
      }
    });
    return response.data;
  }
  async updateWBCabinet({ ID, name, is_shared }) {
    const response = await request({
      url: `/company/wb_cabinets/${ID}/`,
      method: "patch",
      data: {
        name,
        is_shared
      }
    });
    return response.data;
  }

  async deleteWbCabinet({ ID }) {
    const response = await request({
      url: `/company/wb_cabinets/${ID}/`,
      method: "delete"
    });

    return response.data;
  }

  async createWBtokens({ CABINET_ID, token, type }) {
    const response = await request({
      url: `/company/wb_cabinets/${CABINET_ID}/tokens/`,
      method: "post",
      data: {
        token,
        type
      }
    })
    return response.data
  }
  async updateWBCabinetToken({ CABINET_ID, ID, token }) {
    const response = await request({
      url: `/company/wb_cabinets/${CABINET_ID}/tokens/${ID}/`,
      method: "patch",
      data: {
        token
      }
    })
    return response.data
  }
  async deleteWBCabinetToken({ CABINET_ID, ID, token }) {
    const response = await request({
      url: `/company/wb_cabinets/${CABINET_ID}/tokens/${ID}/`,
      method: "delete",
      data: {
        token
      }
    })
    return response.data
  }

  async getWBtokens({ CABINET_ID }) {
    const response = await request({
      url: `/company/wb_cabinets/${CABINET_ID}/tokens/`,
      method: "get",
    })
    return response.data
  }

  async sendSms({ phone }) {
    const response = await request({
      url: "/auth/registration/send_sms/",
      method: "post",
      data: {
        phone
      }
    });

    return response.data;
  }

  async refreshToken() {
    const response = await request({
      url: "/auth/profile/refresh_token/",
      method: "post"
    });

    return response.data;
  }

  async resendConfirmation({ email }) {
    const response = await request({
      url: "/auth/resend/",
      method: "post",
      data: {
        email
      }
    });

    return response.data;
  }

  async socialSignUp({ code, provider, redirect_url }) {
    const response = await request({
      url: "/auth/social/signup/",
      method: "post",
      data: {
        code,
        provider,
        redirect_url
      }
    });

    return response.data;
  }

  async socialSignIn({ code, provider, redirect_url }) {
    const response = await request({
      url: "/auth/social/login/",
      method: "post",
      data: {
        code,
        provider,
        redirect_url
      }
    });

    return response.data;
  }

  async resetPassword({ email, key, password }) {
    const response = await request({
      url: "/auth/reset/",
      method: "post",
      data: {
        email,
        key,
        password
      }
    });

    return response.data;
  }

  async confirmResetPassword({ key, password }) {
    const response = await request({
      url: "/auth/confirm_reset/",
      method: "post",
      data: {
        key,
        password
      }
    });

    return response.data;
  }

  async getBrandInfo({ id }) {
    const response = await request({
      url: `/brands/${id}/info/`,
      method: "get"
    });
    return response.data;
  }

  async getBrandCategories({ id }) {
    const response = await request({
      url: `/categories/tree?brand_ids=${id}/`,
      method: "get"
    });
    return response.data;
  }

  async getBrandGraph({ id, brand_ids, category_ids, gtype, date_from, date_to }) {
    const response = await request({
      url: `/brands/${id}/graph/`,
      method: "post",
      data: {
        brand_ids: [...brand_ids, id],
        category_ids: category_ids,
        gtype: gtype,
        date_from: date_from,
        date_to: date_to
      }
    });
    return response.data;
  }

  async searchBrands({ name }) {
    const response = await request({
      url: `/brands/search/`,
      method: "post",
      data: {
        name: name
      }
    });
    return response.data;
  }

  async searchCategories({ brand_ids, name }) {
    const response = await request({
      url: `/categories/search/`,
      method: "post",
      data: {
        name: name,
        brand_ids: brand_ids,
        with_parents: true
      }
    });
    return response.data;
  }

  async getBrandSaledByCategories({ id, category_ids, date_from, date_to, parent_id }) {
    const response = await request({
      url: `/brands/${id}/structure/categories/`,
      method: "post",
      data: {
        category_ids: category_ids,
        date_from: date_from,
        date_to: date_to,
        parent_id: parent_id
      }
    });
    return response.data;
  }

  async getBrandSaledByOther({ id, gtype, category_ids, date_from, date_to }) {
    const response = await request({
      url: `/brands/${id}/structure/other/`,
      method: "post",
      data: {
        category_ids: category_ids,
        date_from: date_from,
        date_to: date_to,
        gtype: gtype
      }
    });
    return response.data;
  }

  async getBrandProducts({ id, category_ids, date_from, date_to, page, per_page }) {
    const response = await request({
      url: `/brands/${id}/products/`,
      method: "post",
      data: {
        category_ids: category_ids,
        date_from: date_from,
        date_to: date_to,
        page: page,
        per_page: per_page
      }
    });
    return response.data;
  }

  async getBrandStocks({ id, category_ids, brand_ids }) {
    const response = await request({
      url: `/brands/${id}/stocks/`,
      method: "post",
      data: {
        category_ids: category_ids,
        brand_ids: [...brand_ids, id]
      }
    });
    return response.data;
  }
  // api/parsers/geolocations/{geolocation_id}/pickup/dynamic/
  async getProductCategoryGeoPVZ({
    geolocation_id = 7,
    date_from,
    date_to,
    period,
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/geolocations/${geolocation_id}/pickup/dynamic/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getProductInfo({ id }) {
    const response = await request({
      url: `/parsers/products/${id}/`,
      method: "get"
    });
    let colorNameRepeats = {};
    if (response.data.color_items && response.data.color_items.length > 0) {
      response.data.color_items.forEach(item => {
        const itemName = capitalizeFirstLetter(item.name);
        item.name = itemName;
        const repeats = colorNameRepeats[itemName];
        if (repeats !== undefined) {
          //already exist
          item.name = `${itemName} ${repeats + 2}`;
          colorNameRepeats[itemName] = repeats + 1;
        } else {
          colorNameRepeats[itemName] = 0;
        }

        if (item.color) {
          if (item.color.every(c => c >= 245)) {
            item.color = [200, 200, 200];
          }
          item.color = `rgb(${item.color[0]}, ${item.color[1]}, ${item.color[2]})`;
        } else {
          item.color = colorHash.hex(item.name.split("").reverse().join(""));
        }
      });
      response.data.color_items.sort((a, b) => sortStrings(a.name, b.name));
    } else if (response.data.color_items.length === 0) {
      response.data.color_items = [
        {
          name: id,
          id: id,
          color: colorHash.hex(String(id).split("").reverse().join(""))
        }
      ];
    }
    return response.data;
  }
  async getProductInfoByProduct_ids({ id }) {
    const response = await request({
      url: `/parsers/products/${id}/`,
      method: "get"
    });
    return { ...response.data, imgs: response.data.images };
  }
  async getProductCardByProduct_ids({ id }) {
    const response = await request({
      url: `/parsers/products/${id}/card/`,
      method: "get"
    });

    return response.data;
  }
  async getProductCardByProductIds_inKeywords({ id, object }) {
    const response = await request({
      url: `/parsers/products/${id}/card/in_keywords/`,
      method: "post",
      data: object
    });

    return response;
  }
  async getGrouping({ object, limit, offset }) {
    const response = await request({
      url: "/parsers/keywords/grouping/",
      method: "post",
      data: object,
      params: {
        limit,
        offset,
        extra_fields: "name"
      }
    });

    return response.data;
  }
  async getGroupingKeywords({ body, limit, offset, extra_fields }) {
    const response = await request({
      url: "/parsers/keywords/grouping/keywords/",
      method: "post",
      data: body,
      params: {
        limit,
        offset,
        extra_fields
      }
    });

    return response.data;
  }
  async getGroupingWordNames({ body, limit }) {
    const response = await request({
      url: "/parsers/keywords/grouping/words/",
      method: "post",
      data: body,
      params: {
        limit,
        offset: 0,
        extra_fields: "name"
      }
    });

    return response.data;
  }
  async exportGroupingExcel({ object }) {
    const response = await request({
      url: "/parsers/keywords/grouping/export/",
      method: "post",
      data: object
    });

    return response.data;
  }
  async getIdsByWords(searchReq) {
    const response = await request({
      url: `/wb_dynamic/keywords_ids/`,
      method: "post",
      data: {
        keywords: [searchReq]
      }
    });

    return response;
  }

  async getProductGraph({ id, colors, gtype, date_from, date_to }) {
    const response = await request({
      url: `/products/${id}/graphs/`,
      method: "post",
      data: {
        colors: [...colors, id],
        gtype: gtype,
        date_from: date_from,
        date_to: date_to
      }
    });
    return response.data;
  }

  async getProductPositions({ id, geo, date_from, date_to }) {
    const response = await request({
      url: `/products/${id}/positions/`,
      method: "post",
      data: {
        geo: geo,
        date_from: date_from,
        date_to: date_to
      }
    });
    return response.data;
  }

  async getProductSales({ id, gtype, vtype, date_from, date_to, colors }) {
    const response = await request({
      url: `/products/${id}/structure/sales/`,
      method: "post",
      data: {
        colors: [...colors, id],
        vtype: vtype,
        gtype: gtype,
        date_from: date_from,
        date_to: date_to
      }
    });
    return response.data;
  }

  async getProductQuanity({ id, gtype, colors }) {
    const response = await request({
      url: `/products/${id}/structure/quantity/`,
      method: "post",
      data: {
        colors: [...colors, id],
        gtype: gtype
      }
    });
    return response.data;
  }

  async getRegions() {
    const response = await request({
      url: `/regions/`,
      method: "get"
    });
    return response.data;
  }

  async exportReportFilter({
    orders_share__lt,
    sale_percent__gt,
    available_for_sale__gt,
    category_ids,
    brand_ids,
    limit,
    offset
  }) {
    const response = await request({
      url: `/analytics/items/filter/export/`,
      method: "GET",
      params: {
        orders_share__lt,
        sale_percent__gt,
        available_for_sale__gt,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getIncomePlanReportByProduct({
    report_id,
    ordering,
    search,
    offset,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    status
  }) {
    const response = await request({
      url: `/analytics/income_plan/report_by_product/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        search,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        status
      }
    });
    return response.data;
  }
  async exportIncomePlanReportByProduct({
    report_id,
    ordering,
    offset,
    status,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/income_plan/report_by_product/export/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        status,
        wb_api_key_ids,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }
  async getIncomePlanReportByBarcode({
    report_id,
    ordering,
    offset,
    search,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    status,
    period
  }) {
    const response = await request({
      url: `/analytics/income_plan/report_by_barcode/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        status,
        ordering,
        search,
        date_from,
        limit,
        date_to,
        period,
        offset
      }
    });
    return response.data;
  }
  async exportIncomePlanReportByBarcode({
    report_id,
    ordering,
    offset,
    limit,
    status,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/income_plan/report_by_barcode/export/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        status,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }

  async getUnitReportByProduct({
    report_id,
    ordering,
    offset,
    search,
    limit,
    wb_api_key_ids,
    date_from,
    brand_id__in,
    category_id__in,
    date_to,
    period
  }) {
    const response = await request({
      url: `/analytics/unit/report_by_product/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        brand_id__in,
        category_id__in,
        wb_api_key_ids,
        ordering,
        search,
        date_from,
        limit,
        date_to,
        period,
        offset
      }
    });
    return response.data;
  }
  async exportUnitReportByProduct({
    report_id,
    ordering,
    offset,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/unit/report_by_product/export/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }
  async getUnitReportByBarcode({
    report_id,
    ordering,
    search,
    offset,
    limit,
    wb_api_key_ids,
    brand_id__in,
    category_id__in,
    date_from,
    date_to,
    period
  }) {
    const response = await request({
      url: `/analytics/unit/report_by_barcode/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        brand_id__in,
        category_id__in,
        search,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset
      }
    });
    return response.data;
  }
  async exportUnitReportByBarcode({
    report_id,
    ordering,
    offset,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/unit/report_by_barcode/export/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }
  async getReportByProduct({
    report_id,
    ordering,
    offset,
    limit,
    wb_api_key_ids,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/report_by_product/`,
      method: "GET",
      params: {
        report_id__in: report_id,
        wb_api_key_ids,
        ordering,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }
  async exportReportByProduct({
    offset,
    ordering,
    limit,
    wb_api_key_ids,
    report_id,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/report_by_product/export/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        ordering,
        report_id__in: report_id,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }
  async getAbcReportByProduct({
    wb_api_key_ids,
    report_id,
    ordering,
    date_from,
    brand_id__in,
    category_id__in,
    date_to,
    period,
    search,
    limit,
    offset,
    proceeds_abc,
    orders_abc,
    profit_abc
  }) {
    const response = await request({
      url: `/analytics/abc/report_by_product/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        report_id__in: report_id,
        date_from,
        brand_id__in,
        category_id__in,
        ordering,
        date_to,
        period,
        search,
        limit,
        offset,
        proceeds_abc,
        orders_abc,
        profit_abc
      }
    });
    return response.data;
  }
  async exportAbcReportByProduct({
    wb_api_key_ids,
    report_id,
    ordering,
    date_from,
    date_to,
    period,
    search,
    limit,
    offset,
    proceeds_abc,
    orders_abc,
    profit_abc
  }) {
    const response = await request({
      url: `/analytics/abc/report_by_product/export/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        report_id__in: report_id,
        date_from,
        ordering,
        date_to,
        period,
        search,
        limit,
        offset,
        proceeds_abc,
        orders_abc,
        profit_abc
      }
    });
    return response.data;
  }

  async getSizesBySizeLocations({ product_id, geolocation_ids, limit, offset }) {
    const response = await request({
      url: `/parsers/products/${product_id}/availability/`,
      method: "GET",
      params: {
        product_id,
        geolocation_ids,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getAbcReportByBarcode({
    wb_api_key_ids,
    report_id,
    date_from,
    ordering,
    date_to,
    period,
    search,
    limit,
    brand_id__in,
    category_id__in,
    offset,
    proceeds_abc,
    orders_abc,
    profit_abc
  }) {
    const response = await request({
      url: `/analytics/abc/report_by_barcode/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        report_id__in: report_id,
        ordering,
        date_from,
        brand_id__in,
        category_id__in,
        date_to,
        period,
        search,
        limit,
        offset,
        proceeds_abc,
        orders_abc,
        profit_abc
      }
    });
    return response.data;
  }
  async exportAbcReportByBarcode({
    wb_api_key_ids,
    report_id,
    date_from,
    ordering,
    date_to,
    period,
    search,
    limit,
    offset,
    proceeds_abc,
    orders_abc,
    profit_abc
  }) {
    const response = await request({
      url: `/analytics/abc/report_by_barcode/export/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        report_id__in: report_id,
        ordering,
        date_from,
        date_to,
        period,
        search,
        limit,
        offset,
        proceeds_abc,
        orders_abc,
        profit_abc
      }
    });
    return response.data;
  }

  async getReportByBarcode({
    offset,
    search,
    limit,
    ordering,
    wb_api_key_ids,
    report_id,
    date_from,
    date_to,
    period
  }) {
    const response = await request({
      url: `/analytics/report_by_barcode/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        report_id__in: report_id,
        ordering,
        search,
        date_from,
        date_to,
        period,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getKeywordsCluster({ search, limit, offset }) {
    const response = await request({
      url: `/parsers/keywords_cluster/analyze/`,
      method: "GET",
      params: {
        // keywords: search,
        search,
        limit,
        offset
      }
    });
    return response.data;
  }
  async exportKeywordsCluster({ search, limit, offset }) {
    const response = await request({
      url: `/parsers/keywords_cluster/export/`,
      method: "GET",
      params: {
        search,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsWords({ wb_api_key_ids, advert_id, limit, offset }) {
    const response = await request({
      url: `/analytics/adverts/${advert_id}/words/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsAdverts({ wb_api_key_ids, advert_id, limit, offset, status, ordering, type }) {
    const response = await request({
      url: `/analytics/adverts/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        advert_id,
        limit,
        offset,
        status__in: status,
        ordering,
        type__in: type
      }
    });
    return response.data;
  }
  async updateAnalyticsAdverts({ wb_api_key_ids }) {
    const response = await request({
      url: `/analytics/adverts/update_list/`,
      method: "POST",
      params: {
        wb_api_key_ids
      }
    });
    return response.data;
  }
  async getAnalyticsAdvertsUpdateWords({ wb_api_key_ids, advert_id, limit, offset }) {
    const response = await request({
      url: `/analytics/adverts/${advert_id}/update_words/`,
      method: "post",
      params: {
        wb_api_key_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getGroupsKeywordsCluster({
    search,
    limit,
    offset,
    stop_clusters,
    stop_keywords,
    stop_search,
    clusters
  }) {
    const keywords = search ? search : undefined
    const response = await request({
      url: `/parsers/keywords_cluster/groups/`,
      method: "POST",
      params: {
        limit,
        offset
      },
      data: {
        clusters,
        stop_search,
        stop_keywords,
        keywords,
        stop_clusters
      }
    });
    return response.data;
  }
  async exportGroupsKeywordsCluster({
    search,
    clusters,
    cluster,
    limit,
    offset,
    s_search,
    stop_clusters,
    stop_search,
    export_groups
  }) {
    const response = await request({
      // /api/parsers/keywords_cluster/export?keywords=платье&clusters=платье&limit=50&offset=0
      url: `/parsers/keywords_cluster/export/`,
      method: "POST",
      params: {
        limit,
        offset,
        export_groups
      },
      data: {
        search: s_search,
        keywords: search,
        cluster,
        clusters,
        stop_clusters,
        stop_keywords: stop_search
      }
    });
    return response.data;
  }

  async exportReportByBarcode({
    offset,
    ordering,
    limit,
    wb_api_key_ids,
    report_id,
    date_from,
    date_to,
    period,
    search
  }) {
    const response = await request({
      url: `/analytics/report_by_barcode/export/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        ordering,
        report_id__in: report_id,
        date_from,
        limit,
        date_to,
        period,
        offset,
        search
      }
    });
    return response.data;
  }

  async getReportSummary({ wb_api_key_ids, search, limit, offset, date_from, date_to, period }) {
    const response = await request({
      url: `/analytics/report_summary/`,
      method: "GET",
      params: {
        wb_api_key_ids,
        date_from,
        date_to,
        search,
        period,
        limit,
        offset
      }
    });
    return response.data;
  }

  async postAnalyticsReportCharge({ report_id, income, other, additional, storage }) {
    const response = await request({
      url: `/analytics/report_charge/`,
      method: "post",
      data: {
        report_id,
        income,
        other,
        additional,
        storage
      }
      // params: {
      // 	wb_api_key_ids,
      // 	date_from,
      // 	date_to,
      // 	period,
      // },
    });
    return response.data;
  }
  async getAnalyticsReportChargeByid({ report_id, wb_api_key_ids }) {
    const response = await request({
      url: `/analytics/report_charge/`,
      method: "get",
      params: {
        report_id,
        wb_api_key_ids
      }
    });
    return response.data;
  }
  async postAnalyticsReportChargeByid({ report_id, income, other, storage, tax }) {
    const response = await request({
      url: `/analytics/report_charge/`,
      method: "post",
      data: {
        report_id,
        income,
        other,
        storage,
        tax
      }
    });
    return response.data;
  }

  async getFilteredItems({
    limit = 10,
    offset = 0,
    // item_ids,
    warehouse_ids,
    brand_ids,
    orders_share__gt,
    orders_share__lt,
    sale_percent__lt,
    sale_percent__gt,
    available_for_sale__gt,
    available_for_sale__lt
  }) {
    const response = await request({
      url: `/analytics/items/filter/`,
      method: "GET",
      params: {
        limit,
        offset,
        warehouse_ids,
        brand_ids,
        orders_share__gt,
        orders_share__lt,
        sale_percent__lt,
        sale_percent__gt,
        available_for_sale__lt,
        available_for_sale__gt
      }
    });
    return response.data;
  }

  async getKeywordSummary({
    limit = 25,
    offset = 0,
    name__icontains,

    days30__exact,
    days30__range,
    days30__lt,
    days30__lte,
    days30__gt,
    days30__gte,

    products__exact,
    products__range,
    products__lt,
    products__lte,
    products__gt,
    products__gte,

    diff_frequency30__exact,
    diff_frequency30__range,
    diff_frequency30__lt,
    diff_frequency30__lte,
    diff_frequency30__gt,
    diff_frequency30__gte,

    diff_percent30__exact,
    diff_percent30__range,
    diff_percent30__lt,
    diff_percent30__lte,
    diff_percent30__gt,
    diff_percent30__gte,

    diff_percent60__exact,
    diff_percent60__range,
    diff_percent60__lt,
    diff_percent60__lte,
    diff_percent60__gt,
    diff_percent60__gte,

    diff_percent90__exact,
    diff_percent90__range,
    diff_percent90__lt,
    diff_percent90__lte,
    diff_percent90__gt,
    diff_percent90__gte,

    frequency_per_product30__gte,
    frequency_per_product30__lte,

    subjects_id__in,

    ordering = "-days30",
    create_date
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_summary/`,
      method: "GET",
      params: {
        limit,
        offset,
        name__icontains,
        days30__exact,
        days30__range,
        days30__lt,
        days30__lte,
        days30__gt,
        days30__gte,

        products__exact,
        products__range,
        products__lt,
        products__lte,
        products__gt,
        products__gte,

        diff_frequency30__exact,
        diff_frequency30__range,
        diff_frequency30__lt,
        diff_frequency30__lte,
        diff_frequency30__gt,
        diff_frequency30__gte,

        diff_percent30__exact,
        diff_percent30__range,
        diff_percent30__lt,
        diff_percent30__lte,
        diff_percent30__gt,
        diff_percent30__gte,

        diff_percent60__exact,
        diff_percent60__range,
        diff_percent60__lt,
        diff_percent60__lte,
        diff_percent60__gt,
        diff_percent60__gte,

        diff_percent90__exact,
        diff_percent90__range,
        diff_percent90__lt,
        diff_percent90__lte,
        diff_percent90__gt,
        diff_percent90__gte,

        frequency_per_product30__gte,
        frequency_per_product30__lte,

        subject_ids: subjects_id__in,

        ordering,
        create_date
      }
    });
    return response.data;
  }
  async getKeywordSummaryExport({
    limit = 25,
    offset = 0,
    name__icontains,
    create_date,
    days30__exact,
    days30__range,
    days30__lt,
    days30__lte,
    days30__gt,
    days30__gte,
    frequency_per_product30__gte,
    frequency_per_product30__lte,
    subject_ids,
    frequency_per_product30__gt,
    frequency_per_product30__lt,

    products__exact,
    products__range,
    products__lt,
    products__lte,
    products__gt,
    products__gte,

    diff_frequency30__exact,
    diff_frequency30__range,
    diff_frequency30__lt,
    diff_frequency30__lte,
    diff_frequency30__gt,
    diff_frequency30__gte,

    diff_percent30__exact,
    diff_percent30__range,
    diff_percent30__lt,
    diff_percent30__lte,
    diff_percent30__gt,
    diff_percent30__gte,

    diff_percent60__exact,
    diff_percent60__range,
    diff_percent60__lt,
    diff_percent60__lte,
    diff_percent60__gt,
    diff_percent60__gte,

    diff_percent90__exact,
    diff_percent90__range,
    diff_percent90__lt,
    diff_percent90__lte,
    diff_percent90__gt,
    diff_percent90__gte,
    ordering = "-days30"
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_summary/export/`,
      method: "GET",
      params: {
        frequency_per_product30__gte,
        frequency_per_product30__lte,

        frequency_per_product30__gt,
        frequency_per_product30__lt,
        subject_ids,
        limit,
        offset,
        name__icontains,
        create_date,
        days30__exact,
        days30__range,
        days30__lt,
        days30__lte,
        days30__gt,
        days30__gte,

        products__exact,
        products__range,
        products__lt,
        products__lte,
        products__gt,
        products__gte,

        diff_frequency30__exact,
        diff_frequency30__range,
        diff_frequency30__lt,
        diff_frequency30__lte,
        diff_frequency30__gt,
        diff_frequency30__gte,

        diff_percent30__exact,
        diff_percent30__range,
        diff_percent30__lt,
        diff_percent30__lte,
        diff_percent30__gt,
        diff_percent30__gte,

        diff_percent60__exact,
        diff_percent60__range,
        diff_percent60__lt,
        diff_percent60__lte,
        diff_percent60__gt,
        diff_percent60__gte,

        diff_percent90__exact,
        diff_percent90__range,
        diff_percent90__lt,
        diff_percent90__lte,
        diff_percent90__gt,
        diff_percent90__gte,
        ordering
      }
    });
    return response.data;
  }
  async getKeywordSummaryToMonitoring({
    limit,
    offset,
    name__icontains,
    collection_id,
    create_date,
    days30__exact,
    days30__range,
    days30__lt,
    days30__lte,
    days30__gt,
    days30__gte,

    products__exact,
    products__range,
    products__lt,
    products__lte,
    products__gt,
    products__gte,

    diff_frequency30__exact,
    diff_frequency30__range,
    diff_frequency30__lt,
    diff_frequency30__lte,
    diff_frequency30__gt,
    diff_frequency30__gte,

    diff_percent30__exact,
    diff_percent30__range,
    diff_percent30__lt,
    diff_percent30__lte,
    diff_percent30__gt,
    diff_percent30__gte,

    diff_percent60__exact,
    diff_percent60__range,
    diff_percent60__lt,
    diff_percent60__lte,
    diff_percent60__gt,
    diff_percent60__gte,

    diff_percent90__exact,
    diff_percent90__range,
    diff_percent90__lt,
    diff_percent90__lte,
    diff_percent90__gt,
    diff_percent90__gte,
    ordering = "-days30"
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_summary/to_monitoring/`,
      method: "post",
      params: {
        limit,
        offset,
        name__icontains,
        create_date,
        days30__exact,
        days30__range,
        collection_id,
        days30__lt,
        days30__lte,
        days30__gt,
        days30__gte,

        products__exact,
        products__range,
        products__lt,
        products__lte,
        products__gt,
        products__gte,

        diff_frequency30__exact,
        diff_frequency30__range,
        diff_frequency30__lt,
        diff_frequency30__lte,
        diff_frequency30__gt,
        diff_frequency30__gte,

        diff_percent30__exact,
        diff_percent30__range,
        diff_percent30__lt,
        diff_percent30__lte,
        diff_percent30__gt,
        diff_percent30__gte,

        diff_percent60__exact,
        diff_percent60__range,
        diff_percent60__lt,
        diff_percent60__lte,
        diff_percent60__gt,
        diff_percent60__gte,

        diff_percent90__exact,
        diff_percent90__range,
        diff_percent90__lt,
        diff_percent90__lte,
        diff_percent90__gt,
        diff_percent90__gte,
        ordering
      }
    });
    return response.data;
  }

  async getBrandsList() {
    const response = await request({
      url: "/analytics/brands/",
      method: "GET"
    });
    return response.data;
  }

  async getSummary() {
    const response = await request({
      url: "/analytics/summary/",
      method: "GET"
    });
    return response.data;
  }

  async postSettingAbcAnalitics(data) {
    const response = await request({
      url: "/analytics/settings/",
      method: "post",
      data
    });
    return response.data;
  }

  async getOrders({ brand_ids, warehouse_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/summary/orders/",
      method: "GET",
      params: {
        brand_ids,
        warehouse_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getSales({ brand_ids, warehouse_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/summary/sales/",
      method: "GET",
      params: {
        brand_ids,
        warehouse_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getReturns({ brand_ids, warehouse_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/summary/returns/",
      method: "GET",
      params: {
        brand_ids,
        warehouse_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getItemsPopular({ brand_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/items/popular/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getItemsOld({ brand_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/items/old/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getItemsRansom({ brand_ids, item_ids, ransom__lt, ransom__gt, offset, limit }) {
    const response = await request({
      url: "/analytics/items/ransom/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        ransom__lt,
        ransom__gt,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getBrandActual({ id, brand_id__in, extra_fields }) {
    const response = await request({
      url: `/parsers/brands/${id ? id + "/" : ""}`,
      method: "GET",
      params: {
        brand_id__in,
        is_actual: false,
        extra_fields
      }
    });
    return response.data;
  }

  async getSellerActual({ id, seller_id__in }) {
    const response = await request({
      url: `/parsers/sellers/${id ? id : ""}`,
      method: "GET",
      params: {
        seller_id__in,
        is_actual: false
      }
    });
    return response.data;
  }

  async getCategoriesActual({ id, category_id__in, extra_fields }) {
    const response = await request({
      url: `/parsers/categories/${id ? id : ""}`,
      method: "GET",
      params: {
        category_id__in,
        is_actual: false,
        extra_fields
      }
    });
    return response.data;
  }

  async getItemsLost({ brand_ids, item_ids, offset, limit }) {
    const response = await request({
      url: "/analytics/items/lost/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getExportPopularItems({ brand_ids, item_ids, limit, offset }) {
    const response = await request({
      url: "/analytics/items/popular/export/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getExportOldItems({ brand_ids, item_ids, limit, offset }) {
    const response = await request({
      url: "/analytics/items/old/export/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getExportRansomItems({ brand_ids, item_ids, ransom__lt, ransom__gt, limit, offset }) {
    const response = await request({
      url: "/analytics/items/ransom/export/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        ransom__lt,
        ransom__gt,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getExportLostItems({ brand_ids, item_ids, limit, offset }) {
    const response = await request({
      url: "/analytics/items/lost/export/",
      method: "GET",
      params: {
        brand_ids,
        item_ids,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getParsersBrands({
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    name__in,
    period,
    likes__gt,
    likes__lt,
    incomes_30,
    orders_sum,
    orders_volume,
    quantity_sum_per_day,
    orders_sum_per_day,
    orders_avg_per_day,
    supplier,
    categories,
    ransom,
    reviews_sum,
    quantity_sum,
    discount_avg,
    price_avg,
    date_from, // за период YYYY_MM_DD
    date_to,
    wb,
    is_new,
    limit,
    offset,
    search,
    name,
    warehouse_id__in,
    ordering
  }) {
    const response = await request({
      url: "/parsers/brands/",
      method: "get",
      params: {
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        name__in,
        period,
        likes__lt,
        likes__gt,
        orders_sum,
        orders_volume,
        quantity_sum_per_day,
        orders_sum_per_day,
        orders_avg_per_day,
        supplier,
        categories,
        ransom,
        reviews_sum,
        quantity_sum,
        discount_avg,
        price_avg,
        date_to,
        date_from,
        is_new,
        wb,
        name,
        warehouse_id__in,
        incomes_30,
        search,
        limit,
        offset,
        ordering,
        actual: true
      }
    });
    return response.data;
  }

  async getBrandsDynamic({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    product_ids,
    wb_search,
    search,
    wb_catalog_url,
    alias,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sellers",
      "ransom",
      "ransom_dynamic",
      "vendor_codes",
      "rating",
      "reviews",
      "vendor_codes_dynamic",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent"
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/brands/",
      method: "get",
      params: {
        brand_ids,
        collection_id,
        product_ids,
        seller_ids,
        category_ids,
        wb_search,
        search,
        wb_catalog_url,
        alias,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });

    return response.data;
  }
  async getCategoryBrands({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    wb_search,
    search,
    wb_catalog_url,
    alias,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sellers",
      "ransom",
      "ransom_dynamic",
      "vendor_codes",
      "rating",
      "reviews",
      "vendor_codes_dynamic",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent"
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/category_brands/",
      method: "get",
      params: {
        brand_ids,
        collection_id,
        seller_ids,
        category_ids,
        wb_search,
        search,
        wb_catalog_url,
        alias,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }
  async exportCategoryBrands({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    wb_search,
    search,
    wb_catalog_url,
    alias,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sellers",
      "ransom",
      "ransom_dynamic",
      "vendor_codes",
      "rating",
      "reviews",
      "vendor_codes_dynamic",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent"
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/category_brands/export/",
      method: "get",
      params: {
        brand_ids,
        collection_id,
        seller_ids,
        category_ids,
        wb_search,
        search,
        wb_catalog_url,
        alias,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async exportBrandsDynamic({
    brand_ids,
    seller_ids,
    category_ids,
    wb_search,
    search,
    wb_catalog_url,
    collection_id,
    product_ids,
    alias,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "ransom",
      "ransom_dynamic"
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/brands/export/",
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        category_ids,
        collection_id,
        product_ids,
        wb_search,
        search,
        wb_catalog_url,
        alias,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async exportBrandsDynamicById({
    id,
    seller_ids,
    category_ids,
    wb_search,
    search,
    wb_catalog_url,
    alias,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "ransom",
      "ransom_dynamic"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/brands/${id}/export/`,
      method: "get",
      params: {
        brand_ids: id,
        seller_ids,
        category_ids,
        wb_search,
        search,
        wb_catalog_url,
        alias,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async getGeneralCategoriesDynamic({
    product_ids,
    locf_dynamic,
    brand_ids,
    full_name,
    seller_ids,
    category_ids,
    collection_id,
    parent_id,
    down_parent_id,
    up_parent_id,
    level,
    wb_search,
    search,
    name, // new search!
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    has_child,
    extra_fields = [
      // "vendor_codes",
      // "vendor_codes_dynamic",
      // "brands_count",
      // "brands_count_dynamic",
      // "sellers_count",
      // "sellers_count_dynamic",
      // "price",
      // "price_dynamic",
      // "old_price",
      // "discount",
      // "discount_dynamic",
      // "proceeds",
      // "proceeds_dynamic",
      // "orders",
      // "orders_dynamic",
      // "ransom",
      // "sales_proceeds",
      // "sales_proceeds_dynamic",
      // "sales",
      // "sales_dynamic",
      // "quantity",
      // "quantity_dynamic",
      // "in_stock_percent",
      // "in_stock_days",
      // "out_of_stock_days",
      // "in_stock_orders_avg",
      // "in_stock_proceeds",
      // "lost_proceeds",
      // "product_with_orders",
      // "product_with_orders_dynamic",
      "product_with_orders_and_quantity",
      // "product_with_orders_and_quantity_dynamic",
      // "brands_with_orders",
      // "brands_with_orders_dynamic",
      // "sellers_with_orders",
      // "sellers_with_orders_dynamic",
      "category_name",
      "vendor_codes_dynamic",
      "brands_with_orders_dynamic",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity_dynamic",
      "brands_count_dynamic",
      "sellers_count_dynamic",
      "sellers_with_orders_dynamic",
      "price_dynamic",
      "discount_dynamic",
      "proceeds_dynamic",
      "orders_dynamic",
      "quantity_dynamic",
      "sales_dynamic",
      "sales_proceeds_dynamic",
      "vendor_codes",
      "brands_with_orders",
      "product_with_orders",
      "brands_count",
      "sellers_count",
      "sellers_with_orders",
      "price",
      "discount",
      "proceeds",
      "orders",
      "quantity",
      "lost_proceeds",
      "sales",
      "sales_percent",
      "sales_proceeds"
      // "in_stock_orders_avg",
      // "in_stock_proceeds",
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/general_categories/",
      method: "get",
      params: {
        product_ids,
        locf_dynamic,
        full_name,
        brand_ids,
        seller_ids,
        collection_id,
        category_ids,
        parent_id,
        down_parent_id,
        up_parent_id,
        level,
        wb_search,
        search,
        name,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        has_child,
        extra_fields
      }
    });
    return response.data;
  }

  async getPriceStepsForBrand({
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    price__gte,
    price__lte,
    price__gt = 1,
    price__lt = 1,
    chunks = 1,
    extra_fields = [
      "category_name",
      "vendor_codes_dynamic",
      "brands_with_orders_dynamic",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity_dynamic",
      "brands_count_dynamic",
      "sellers_count_dynamic",
      "sellers_with_orders_dynamic",
      "price_dynamic",
      "discount_dynamic",
      "proceeds_dynamic",
      "orders_dynamic",
      "quantity_dynamic",
      "sales_dynamic",
      "sales_proceeds_dynamic",
      "vendor_codes",
      "brands_with_orders",
      "product_with_orders",
      "brands_count",
      "sellers_count",
      "sellers_with_orders",
      "price",
      "discount",
      "proceeds",
      "orders",
      "quantity",
      "lost_proceeds",
      "sales",
      "sales_percent",
      "sales_proceeds"
      // "in_stock_orders_avg",
      // "in_stock_proceeds",
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/price_steps_dynamic/",
      method: "get",
      params: {
        product_ids,
        brand_ids,
        seller_ids,
        category_ids,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        price__gte,
        price__lte,
        price__gt,
        price__lt,
        chunks,
        extra_fields
      }
    });
    return response.data;
  }
  async getPriceStepsForSeach({
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    wb_search,
    price__gte,
    price__lte,
    // price__gt = 1,
    // price__lt = 1,
    chunks = 1,
    city,
    extra_fields = [
      "category_name",
      "vendor_codes_dynamic",
      "brands_with_orders_dynamic",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity_dynamic",
      "brands_count_dynamic",
      "sellers_count_dynamic",
      "sellers_with_orders_dynamic",
      "price_dynamic",
      "discount_dynamic",
      "proceeds_dynamic",
      "orders_dynamic",
      "quantity_dynamic",
      "sales_dynamic",
      "sales_proceeds_dynamic",
      "vendor_codes",
      "brands_with_orders",
      "product_with_orders",
      "brands_count",
      "sellers_count",
      "sellers_with_orders",
      "price",
      "discount",
      "proceeds",
      "orders",
      "quantity",
      "lost_proceeds",
      "sales",
      "sales_percent",
      "sales_proceeds"
      // "in_stock_orders_avg",
      // "in_stock_proceeds",
    ].join()
  }) {
    const response = await request({
      url: "/api/wb_dynamic/price_steps_search/",
      method: "get",
      params: {
        price__gte,
        price__lte,
        // price__gt,
        // price__lt,
        chunks,
        wb_search,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async getPriceSteps({
    product_collection_id,
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    price__gte,
    price__lte,
    price__gt,
    price__lt,
    chunks = 1,
    extra_fields = [
      "category_name",
      "vendor_codes_dynamic",
      "brands_with_orders_dynamic",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity_dynamic",
      "brands_count_dynamic",
      "sellers_count_dynamic",
      "sellers_with_orders_dynamic",
      "price_dynamic",
      "discount_dynamic",
      "proceeds_dynamic",
      "orders_dynamic",
      "quantity_dynamic",
      "sales_dynamic",
      "sales_proceeds_dynamic",
      "vendor_codes",
      "brands_with_orders",
      "product_with_orders",
      "brands_count",
      "sellers_count",
      "sellers_with_orders",
      "price",
      "discount",
      "proceeds",
      "orders",
      "quantity",
      "lost_proceeds",
      "sales",
      "sales_percent",
      "sales_proceeds"
      // "in_stock_orders_avg",
      // "in_stock_proceeds",
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/price_steps/",
      method: "get",
      params: {
        product_collection_id,
        product_ids,
        brand_ids,
        seller_ids,
        category_ids,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        price__gte,
        price__lte,
        price__gt,
        price__lt,
        chunks,
        extra_fields
      }
    });
    return response.data;
  }
  async getProductComparison({
    productId,
    date_from,
    date_to,
    created_date_from,
    created_date_to,
    offset,
    date,
    limit,
    as_html
  }) {
    const response = await request({
      url: `/wb_dynamic/product_comparison/${productId}/${date ? date : ""}${created_date_from ? created_date_from + "/" : ""
        }${created_date_to ? created_date_to : ""}`,
      method: "get",
      params: {
        date_from,
        date_to,
        offset,
        limit,
        as_html
      }
    });
    return response.data;
  }

  async exportGeneralCategoriesDynamic({
    category_ids,
    parent_id,
    collection_id,
    product_ids,
    level,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    search,
    has_child,
    extra_fields = [
      "brands_count",
      "category_id",
      "create_date",
      "discount",
      "discount_avg",
      "discount_sum",
      "full_name",
      "group_count",
      "has_child",
      "id",
      "income",
      "income_failed",
      "level",
      "name",
      "old_price_avg",
      "old_price_sum",
      "orders",
      "orders_failed",
      "orders_failed_summary",
      "orders_summary",
      "parent_id",
      "price_avg",
      "price_sum",
      "proceeds",
      "proceeds_failed",
      "proceeds_failed_summary",
      "proceeds_summary",
      // "product_with_orders",
      // "product_with_orders_and_quantity",
      "promo_discount_avg",
      "promo_discount_sum",
      "quantity",
      "ransom_summary",
      "sales_proceeds_summary",
      "sales_summary",
      "sellers_count",
      "url_type",
      "vendor_codes",
      "product_with_orders",
      // "product_with_orders_dynamic",
      "product_with_orders_and_quantity",
      // "product_with_orders_and_quantity_dynamic",
      "brands_with_orders",
      // "brands_with_orders_dynamic",
      "sellers_with_orders"
      // "sellers_with_orders_dynamic",
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/general_categories/export/",
      method: "get",
      params: {
        category_ids,
        collection_id,
        parent_id,
        product_ids,
        level,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        search,
        has_child,
        extra_fields
      }
    });
    return response.data;
  }

  async exportGeneralCategoriesDynamicById({
    id,
    category_ids,
    parent_id,
    level,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity_dynamic",
      "brands_with_orders_dynamic",
      "sellers_with_orders_dynamic"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/general_categories/${id}/export/`,
      method: "get",
      params: {
        category_ids,
        parent_id,
        level,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async getProducts({
    id__in,
    brand_id__in,

    discount__gte, // Общий % скидки без учета СПП
    discount__lte,

    price__gte, // Цена реализации
    price__lte,

    date_from, // за период YYYY-MM-DD
    date_to,

    rating__gte, //Рейтинг
    rating__lte,

    reviews_sum__gte, //{min,max} Количество отзывов
    reviews_sum__lte,

    orders_volume__gte, //{min,max} объем входящих заказов
    orders_volume__lte,

    orders_sum__gte, //{min,max} кол-во заказаных товаров
    orders_sum__lte,

    ransom__gte, //{min,max} Доля выкупа
    ransom__lte,

    orders_sum_per_day__gte, //{min,max} Заказов в день при наличии
    orders_sum_per_day__lte,

    orders_avg_per_day__gte, //{min,max} Скорость заказов в день при наличии
    orders_avg_per_day__lte,

    days_on_site__gte, //{min,max} Дней на сайте
    days_on_site__lte,

    out_of_stock_30_percent__gte, //{min,max} % времени не в наличии за последние 30 дней
    out_of_stock_30_percent__lte,

    quantity_sum__gte, //{min,max} Товарные остатки
    quantity_sum__lte,

    tag_link,

    limit,
    offset
  }) {
    const response = await request({
      url: "parsers/products/",
      method: "get",
      params: {
        id__in,
        brand_id__in,

        discount__gte, // Общий % скидки без учета СПП
        discount__lte,

        price__gte, // Цена реализации
        price__lte,

        date_from, // За период YYYY-MM-DD
        date_to,

        rating__gte, // Рейтинг
        rating__lte,

        ...Object.fromEntries(
          [
            ["reviews_sum", minmax(reviews_sum__gte, reviews_sum__lte)],
            ["orders_volume", minmax(orders_volume__gte, orders_volume__lte)],
            ["orders_sum", minmax(orders_sum__gte, orders_sum__lte)],
            ["ransom", minmax(ransom__gte, ransom__lte)],
            ["orders_sum_per_day", minmax(orders_sum_per_day__gte, orders_sum_per_day__lte)],
            ["orders_avg_per_day", minmax(orders_avg_per_day__gte, orders_avg_per_day__lte)],
            ["days_on_site_day", minmax(days_on_site__gte, days_on_site__lte)],
            [
              "out_of_stock_30_percent_day",
              minmax(out_of_stock_30_percent__gte, out_of_stock_30_percent__lte)
            ],
            ["quantity_sum", minmax(quantity_sum__gte, quantity_sum__lte)]
          ].filter(pair => pair[1] !== null)
        ),

        tag_link,

        limit,
        offset
      }
    });
    return response.data;
  }

  async getProfile() {
    const response = await request({
      url: "/auth/profile/",
      method: "get"
    });
    return response.data;
  }

  async updateProfile({ email, phone, name, old_password, new_password1, new_password2 }) {
    const response = await request({
      url: "/auth/profile/",
      method: "patch",
      data: {
        email,
        phone,
        name,
        old_password,
        new_password1,
        new_password2
      }
    });
    return response.data;
  }

  async getApiKeys() {
    const response = await request({
      url: "/company/api_keys/",
      method: "get"
    });
    return response.data;
  }

  async createApiKey({ name, api_key, ad_key, is_new }) {
    const response = await request({
      url: "/company/api_keys/",
      method: "post",
      data: {
        name,
        api_key,
        is_new,
        ad_key
      }
    });
    return response.data;
  }

  async updateApiKey({ name, api_key, ad_key, is_new, id }) {
    const response = await request({
      url: `/company/api_keys/${id}/`,
      method: "patch",
      data: {
        name,
        ad_key,
        api_key,
        is_new
      }
    });
    return response.data;
  }

  async deleteApiKey({ id }) {
    const response = await request({
      url: `/company/api_keys/${id}/`,
      method: "delete"
    });
    return response.data;
  }

  async getCompanyUsers() {
    const response = await request({
      url: "/company/users/",
      method: "get"
    });
    return response.data;
  }
  async postDemoTariff() {
    const response = await request({
      url: "/company/users/activate_demo/",
      method: "POST"
    });
    return response.data;
  }

  async createCompanyUser({ email, password, phone, name, role, api_keys, is_active }) {
    const response = await request({
      url: `/company/users/`,
      method: "post",
      data: {
        email,
        password,
        phone,
        name,
        role,
        api_keys,
        is_active
      }
    });
    return response.data;
  }

  async updateCompanyUser({ id, email, password, phone, name, role, api_keys, is_active }) {
    const response = await request({
      url: `/company/users/${id}/`,
      method: "patch",
      data: {
        email,
        password,
        phone,
        name,
        role,
        api_keys,
        is_active
      }
    });
    return response.data;
  }

  async deleteCompanyUser({ id }) {
    const response = await request({
      url: `/company/users/${id}/`,
      method: "delete"
    });
    return response.data;
  }

  async getOperationReport({ date_from, date_to, brand_ids, category_ids }) {
    const response = await request({
      url: `/analytics/operation_report/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids
      }
    });
    return response.data;
  }
  async getTopKeywordsDynamic({
    date_from,
    keyword_ids,
    period,
    date_to,
    product_keyword_collection_id,
    product_id
  }) {
    const response = await request({
      url: `wb_dynamic/products/${product_id}/top_keywords/`,
      method: "GET",
      params: {
        period,
        date_from,
        date_to,
        keyword_ids,
        product_keyword_collection_id
      }
    });
    return response.data;
  }
  async getMonitoringTopKeywordsDynamic({
    date_from,
    keyword_ids,
    date_to,
    product_keyword_collection_id,
    product_id,
    geolocation_id
  }) {
    const response = await request({
      // url: `wb_dynamic/products/${product_id}/top_keywords/`,
      url: `parsers/collections/product_keyword/${product_keyword_collection_id}/dynamic/top/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        product_id,
        keyword_ids,
        geolocation_id
        // product_keyword_collection_id,
      }
    });
    return response.data;
  }

  async getProductMonitoring({
    limit,
    offset,
    period,
    ordering,
    product_keyword_collection_id,
    date_from,
    date_to,
    product_id,
    extra_fields
  }) {
    const response = await request({
      url: `wb_dynamic/products/${product_id}/keywords/`,
      method: "GET",
      params: {
        period,
        ordering,
        product_keyword_collection_id,
        date_from,
        date_to,
        limit,
        offset,
        extra_fields
      }
    });
    return response.data;
  }
  async getMyPartialPurchase() {
    const response = await request({
      url: `/profile/partial_purchase_state/`,
      method: "GET"
      // params: {},
    });
    return response.data;
  }
  async payPartialPurchase({ url_key }) {
    const response = await request({
      url: `/partial_purchase/`,
      method: "POST",
      data: {
        url_key
      }
    });
    return response.data;
  }

  async getPartialPurchase({ token }) {
    const response = await request({
      url: `/partial_purchase/${token}/`,
      method: "GET"
      // params: {},
    });
    return response.data;
  }
  //
  async postKeywordProductMonitoring({ collection_id, date_from, date_to, product_id }) {
    const response = await request({
      url: `wb_dynamic/products/${product_id}/keywords/`,
      method: "POST",
      data: {
        product_keyword_collection_id: collection_id,
        collection_id,
        date_from,
        date_to
      }
    });
    return response.data;
  }
  async postKeywordProductMonitoring2({
    collection_id,
    date_from,
    date_to,
    product_id,
    extra_fields,
    geolocation_id,
    ordering,
    period
  }) {
    const response = await request({
      url: `wb_dynamic/products/${product_id}/keywords/to_monitoring/`,
      method: "POST",
      params: {
        ordering,
        geolocation_id,
        product_keyword_collection_id: collection_id,
        collection_id,
        date_from,
        date_to,
        extra_fields,
        period
      }
    });
    return response.data;
  }

  async getReportByDates({ date_from, date_to, brand_ids, category_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/operation_report/by_dates/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportByDates({ date_from, date_to, brand_ids, category_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/operation_report/by_dates/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportByItems({ date_from, date_to, brand_ids, category_ids, qtype, offset, limit }) {
    const response = await request({
      url: `/analytics/operation_report/by_items/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids,
        qtype,
        offset,
        limit
      }
    });
    return response.data;
  }

  async getOperationReportPaidIncome({ date_from, date_to, brand_ids, category_ids }) {
    const response = await request({
      url: `/analytics/paid_income/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids
      }
    });
    return response.data;
  }
  async getSppAnalyticsPrices({
    limit,
    offset,
    product_id,
    subject_id,
    price__gte,
    price__lte,
    discount__gte,
    discount__lte,
    wb_api_key_ids,
    available_to_monitor,
    price_with_discount__gte,
    price_with_discount__lte,
    create_date_gte,
    create_date_lte,
    create_date__gte,
    create_date__lte,
  }) {
    const response = await request({
      url: `/analytics/prices/`,
      method: "GET",
      params: {
        limit,
        offset,
        product_id,
        subject_id,
        price__gte,
        price__lte,
        discount__gte,
        discount__lte,
        wb_api_key_ids,
        available_to_monitor,
        price_with_discount__gte,
        price_with_discount__lte,
        create_date__gte: create_date_gte ?? create_date__gte,
        create_date__lte: create_date_lte ?? create_date__lte,
      }
    });
    return response.data;
  }


  async getReportByItems({ date_from, date_to, qtype, brand_ids, category_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/operation_report/by_items/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        qtype,
        brand_ids,
        category_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getCategories({ wb_api_key_ids }) {
    const response = await request({
      url: `/analytics/categories/`,
      method: "get",
      params: {
        wb_api_key_ids
      }
    });
    return response.data;
  }

  async getBrands({ wb_api_key_ids }) {
    const response = await request({
      url: `/analytics/brands/`,
      method: "get",
      params: {
        wb_api_key_ids
      }
    });
    return response.data;
  }

  async exportReportByCategory({ date_from, date_to, brand_ids, category_ids }) {
    const response = await request({
      url: `/analytics/orders/by_category/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        brand_ids,
        category_ids
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersByCategory({ date_from, date_to, category_ids, brand_ids }) {
    const response = await request({
      url: `/analytics/orders/by_category/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids
      }
    });
    return response.data;
  }

  async getExternalCategoriesTreeById({ id }) {
    const response = await request({
      url: `/analytics/external_categories/${id}/tree/`,
      method: "get"
    });
    return response.data;
  }

  async getItemsFilter({
    limit, //limit
    offset, //offset
    date_gt,
    date_lt,
    ids, //ids
    external_ids, //wb id
    warehouse_ids, //склады
    brand_ids, //бренды
    category_ids, //категории
    external_category_ids, //wb категории
    color_ids, //Группы цветов
    size_ids, //размеры
    date__gt, //дата больше чем
    date__lt, //дата меньше чем
    is_new, //показать только новые
    can_end_up__gt, //могут закончится больше чем
    can_end_up__lt, //могут закончится меньше чем
    days_on_site__gt, //дней на сайте больше чем
    days_on_site__lt, //дней на сайте меньше чем
    turnover__gt, //оборачиваемость больше чем
    turnover__lt, //оборачиваемость меньше чем
    price_with_discount__lt, //цена со скидкой меньше чем
    price_with_discount__gt, //цена со скидкой больше чем
    discount__lt, //скидка меньше чем
    discount__gt, //скидка больше чем
    orders_sum__gt, //Объем входящих заказов больше чем
    orders_sum__lt, //Объем входящих заказов меньше чем
    orders_amount__lt, //Количество заказанных товаров меньше чем
    orders_amount__gt, //Количество заказанных товаров больше чем
    sale_percent__lt, //процент выкупаемости меньше чем
    sale_percent__gt, //процент выкупаемости больше чем
    sale_amount__lt, //Количество выкупленных товаров меньше чем
    sale_amount__gt, //Количество выкупленных товаров больше чем
    sale_sum__gt, //Объем выкупленных заказов больше чем
    sale_sum__lt, //Объем выкупленных заказов меньше чем
    return_percent__lt, //процент возврата меньше чем
    return_percent__gt, //процент возврата больше чем
    percent_of_val_income__lt, //процент валовой прибыли меньше чем
    percent_of_val_income__gt, //процент валовой прибыли больше чем
    logistics_outcome__lt, //расходы на логистику меньше чем
    logistics_outcome__gt, //расходы на логистику больше чем
    storage_outcome__lt, //Расходы на хранение меньше чем
    storage_outcome__gt, //Расходы на хранение больше чем
    reviews_count__lt, //кол-во отзывов меньше чем
    reviews_count__gt, //кол-во отзывов больше чем
    rating__lt, //рейтинг меньше чем
    rating__gt, //рейтинг больше чем
    available_for_sale__lt, //доступно для продажи меньше чем
    available_for_sale__gt, //доступно для продажи больше чем
    orders_share__lt, //доля заказов меньше чем
    orders_share__gt, //доля заказов больше чем
    orders_speed_in_stock__lt, //Скорость заказанных товаров при наличии меньше чем
    orders_speed_in_stock__gt, //Скорость заказанных товаров при наличии больше чем
    sales_speed_in_stock__lt, //Скорость выкупленных товаров при наличии меньше чем
    sales_speed_in_stock__gt //Скорость выкупленных товаров при наличии больше чем
  }) {
    const response = await request({
      url: "/analytics/items/filter/",
      method: "get",
      params: {
        limit,
        offset,
        date_gt,
        date_lt,
        ids,
        external_ids,
        warehouse_ids,
        brand_ids,
        category_ids,
        external_category_ids,
        color_ids,
        size_ids,
        date__gt,
        date__lt,
        is_new,
        can_end_up__gt,
        can_end_up__lt,
        days_on_site__gt,
        days_on_site__lt,
        turnover__gt,
        turnover__lt,
        price_with_discount__lt,
        price_with_discount__gt,
        discount__lt,
        discount__gt,
        orders_sum__gt,
        orders_sum__lt,
        orders_amount__lt,
        orders_amount__gt,
        sale_percent__lt,
        sale_percent__gt,
        sale_amount__lt,
        sale_amount__gt,
        sale_sum__gt,
        sale_sum__lt,
        return_percent__lt,
        return_percent__gt,
        percent_of_val_income__lt,
        percent_of_val_income__gt,
        logistics_outcome__lt,
        logistics_outcome__gt,
        storage_outcome__lt,
        storage_outcome__gt,
        reviews_count__lt,
        reviews_count__gt,
        rating__lt,
        rating__gt,
        available_for_sale__lt,
        available_for_sale__gt,
        orders_share__lt,
        orders_share__gt,
        orders_speed_in_stock__lt,
        orders_speed_in_stock__gt,
        sales_speed_in_stock__lt,
        sales_speed_in_stock__gt
      }
    });
    return response.data;
  }

  async getAnalyticsSavedFilters({ search, limit, offset }) {
    const response = await request({
      url: "/analytics/items/filter/saved/",
      method: "get",
      params: {
        search,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportOrdersBySize({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_size/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }
  async saveAnalyticsFilter({ name, data }) {
    const response = await request({
      url: "/analytics/items/filter/saved/",
      method: "put",
      data: {
        name,
        data
      }
    });
    return response.data;
  }

  async renameAnalyticsSavedFilters({ id, name }) {
    const response = await request({
      url: `/analytics/items/filter/saved/${id}/`,
      method: "post",
      data: {
        name
      }
    });
    return response.data;
  }

  async deleteAnalyticsSavedFilters({ id }) {
    const response = await request({
      url: `/analytics/items/filter/saved/${id}/`,
      method: "delete"
    });
    return response.data;
  }

  async getCompanyApiKey() {
    const response = await request({
      url: "/analytics/api_keys/with-rr-info/",
      method: "get"
    });
    return response.data;
  }

  async tableEconomic({ dateTo, dateFrom, joinKeys, group_by, sort, q, limit, offset }) {
    const response = await request({
      url: "/analytics/unit_economy_table/",
      method: "get",
      params: {
        wb_api_key_ids: joinKeys,
        date_from: dateFrom,
        date_to: dateTo,
        group_by,
        limit,
        offset,
        q,
        order_by: sort
      }
    });
    return response.data;
  }

  async downloadEconomicTable({
    key,
    dateFrom,
    dateTo,

    group_by
  }) {
    const response = await request({
      url: "/analytics/unit_economy_table/export/",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo,
        group_by
      }
    });

    return response.data;
  }

  async getSubjects({ key, text = "", dateTo, dateFrom, category, brand }) {
    const response = await request({
      url: "/analytics/subjects/by-rr-dates/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        q: text,
        date_from: dateFrom,
        date_to: dateTo,
        category_id__in: category,
        brand_id__in: brand
      }
    });
    return response.data;
  }

  async keyIndicatorsOtherWBStructure({ key, dateFrom, dateTo }) {
    const response = await request({
      url: "/analytics/key_indicators_wb_services_structure/with_charges/",
      method: "get",
      params: {
        wb_api_key_ids: key,
        date_from: dateFrom,
        date_to: dateTo
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersBySize({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_size/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportOrdersByWarehouse({
    date_from,
    date_to,
    category_ids,
    brand_ids,
    limit,
    offset
  }) {
    const response = await request({
      url: `/analytics/orders/by_warehouse/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersByWarehouse({
    date_from,
    date_to,
    category_ids,
    brand_ids,
    limit,
    offset
  }) {
    const response = await request({
      url: `/analytics/orders/by_warehouse/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportOrdersByRegion({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_region/export/`,
      method: "GET",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersByRegion({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_region/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersBy_Warehouse({
    date_from,
    date_to,
    category_ids,
    brand_ids,
    limit,
    offset
  }) {
    const response = await request({
      url: `/analytics/orders/by_warehouse/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersByColors({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_color/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAnalyticsOrdersBy_Region({
    date_from,
    date_to,
    category_ids,
    brand_ids,
    limit,
    offset
  }) {
    const response = await request({
      url: `/analytics/orders/by_region/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportReportOrdersByColors({ date_from, date_to, category_ids, brand_ids, limit, offset }) {
    const response = await request({
      url: `/analytics/orders/by_color/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        category_ids,
        brand_ids,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getColors() {
    const response = await request({
      url: `/analytics/colors/`,
      method: "get"
    });
    return response.data;
  }

  async getCatalogs() {
    const response = await request({
      url: `/analytics/catalogs/`,
      method: "get"
    });
    return response.data;
  }

  async getProductById({ id, date_from, date_to, period }) {
    const response = await request({
      url: `/parsers/products/${id}/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async getAnalyticsPricesSubjects() {
    const response = await request({
      url: `/analytics/prices/subjects/`,
      method: "get",
    });
    return response.data;
  }

  async getSubjectIds({
    search,
    limit,
    offset,
    brand_ids,
    seller_ids,
    subject_ids,
    ordering,
    date_on_site__gte,
    date_on_site__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales_lte
  }) {
    const response = await request({
      url: `/parsers/subjects/`,
      method: "get",
      params: {
        search,
        limit,
        offset,
        brand_ids,
        seller_ids,
        subject_ids,
        ordering,
        date_on_site__gte,
        date_on_site__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales_lte
      }
    });
    return response.data;
  }
  async getProductFrequencySubjectIds({
    search,
    limit = 100,
    offset = 0,
    period = 7,
    brand_ids,
    seller_ids,
    subject_ids,
    ordering,
    date_on_site__gte,

    date_on_site__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales_lte,
    price_avg__lte,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte,
    price_avg__gte,
    ...resp
  }) {
    const response = await request({
      url: `/parsers/product_frequency/subjects/`,
      method: "get",
      params: {
        search,
        limit,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        offset,
        period,
        brand_ids,
        seller_ids,
        subject_ids,
        ordering,
        date_on_site__gte,
        date_on_site__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales_lte,
        ...resp,
        percent_diff_frequency__direction: undefined,
        percent_diff_orders__direction: undefined,
        percent_diff_price__direction: undefined
      }
    });
    return response.data;
  }

  async getProductRelative({ id, date_from, date_to, period }) {
    const response = await request({
      url: `/parsers/products/${id}/relative/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async getProductColorDynamic({
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset,
    ordering
  }) {
    const response = await request({
      url: "/wb_dynamic/product_colors/",
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset,
        ordering
      }
    });
    return response.data;
  }

  async exportProductColorDynamic({
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset
  }) {
    const response = await request({
      url: "/wb_dynamic/product_colors/export/",
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSimilarProducts({
    product_ids,
    ordering,
    date_from,
    date_to,
    extra_fields,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/product_similar/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        ordering,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSimilarProductsScope({ id, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/parsers/products/${id}/similar/scope/`,
      method: "get",
      params: {
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportSimilarProducts({ product_ids, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/wb_dynamic/product_similar/export/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }
  async productRecGoods({ product_ids, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/wb_dynamic/product_rec_goods/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }
  async exportProductRecGoods({ product_ids, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/wb_dynamic/product_rec_goods/export/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async productWaterfallCardRec({ product_ids, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/wb_dynamic/product_waterfall_card_rec/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }
  async exportProductWaterfallCardRec({
    product_ids,
    date_from,
    date_to,
    extra_fields,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/product_waterfall_card_rec/export/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getAlsoBuyProducts({
    product_ids,
    date_from,
    date_to,
    extra_fields,
    limit,
    offset,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/product_also_buy/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset,
        ordering
      }
    });
    return response.data;
  }

  async getAlsoBuyProductsScope({ id, date_from, date_to, extra_fields, limit, offset, ordering }) {
    const response = await request({
      url: `/parsers/products/${id}/also_buy/scope/`,
      method: "get",
      params: {
        date_from,
        date_to,
        extra_fields,
        limit,
        offset,
        ordering
      }
    });
    return response.data;
  }

  async exportAlsoBuyProducts({ product_ids, date_from, date_to, extra_fields, limit, offset }) {
    const response = await request({
      url: `/wb_dynamic/product_also_buy/export/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSearchTagsProducts({ id, date_from, date_to }) {
    const response = await request({
      url: `/parsers/products/${id}/search_tags/`,
      method: "get",
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  }

  // async getTagLinkProducts({ id__in, limit, offset, tag_link }) {
  // 	const response = await request({
  // 		url: `/parsers/products/`,
  // 		method: "get",
  // 		params: {
  // 			tag_link,
  // 			limit,
  // 			offset,
  // 			id__in,
  // 		},
  // 	})
  // 	return response.data
  // }

  // async getTagLinkProductsNext({ limit, offset, tag_link }) {
  // 	const response = await request({
  // 		url: `/parsers/products/`,
  // 		method: "get",
  // 		params: {
  // 			limit,
  // 			offset,
  // 			tag_link,
  // 		},
  // 	})
  // 	return response.data
  // }

  // async getProducts({ id__in }) {
  // 	const response = await request({
  // 		url: `/parsers/products/`,
  // 		method: "get",
  // 		params: {
  // 			id__in,
  // 		},
  // 	})
  // 	return response.data
  // }

  async getBrandCard({ brandId, date_from, date_to, period, orders_volume }) {
    const response = await request({
      url: `/parsers/brands/${brandId}/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period,
        orders_volume
      }
    });
    return response.data;
  }

  async getBrandCategoriesSeo({ brandId, period, date_from, date_to }) {
    const response = await request({
      url: `/parsers/brands_seo/${brandId}/categories/`,
      method: "get",
      params: {
        period,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getBrandWarehousesDynamic({ brand_ids, period, date_from, date_to }) {
    const response = await request({
      url: `/parsers/warehouses_dynamic/`,
      method: "get",
      params: {
        brand_ids,
        period,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getParsersWarehouses({ search, limit, offset }) {
    const response = await request({
      url: "/parsers/warehouses/",
      method: "get",
      params: {
        search,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getBrandWarehousesById({ warehouse_id, period, date_from, date_to }) {
    const response = await request({
      url: `/parsers/warehouses/${warehouse_id}/`,
      method: "get",
      params: {
        period,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async searchBrandsByName({
    //deprecated
    products__id__in,
    products__id,
    products__id__isnull,
    products__name__in,
    products__name,
    products__name__isnull,
    name__in,
    name,
    likes__in,
    likes,
    likes__range,
    likes__gte,
    likes__lte,
    likes__gt,
    likes__lt,
    likes__isnull,
    warehouse_id__in,
    search,
    limit,
    offset,
    date_from,
    date_to,
    extra_fields
  }) {
    const response = await request({
      url: "/parsers/brands/search/",
      method: "get",
      params: {
        products__id__in,
        products__id,
        products__id__isnull,
        products__name__in,
        products__name,
        products__name__isnull,
        name__in,
        name,
        likes__in,
        likes,
        likes__range,
        likes__gte,
        likes__lte,
        likes__gt,
        likes__lt,
        likes__isnull,
        warehouse_id__in,
        search,
        limit,
        offset,
        date_from,
        date_to,
        extra_fields
      }
    });
    return response.data;
  }

  async getCategoriesDynamic({
    brand_ids,
    parent_id,
    seller_ids,
    category_ids,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      "brands_count",
      "brands_count_dynamic",
      "sellers_count",
      "sellers_count_dynamic",
      "price",
      "price_dynamic",
      "old_price",
      "discount",
      "discount_dynamic",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "ransom",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sales",
      "sales_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_percent",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "product_with_orders",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity",
      "product_with_orders_and_quantity_dynamic",
      "brands_with_orders",
      "brands_with_orders_dynamic",
      "sellers_with_orders",
      "sellers_with_orders_dynamic"
    ].join(),
    level,
    has_child,
    period,
    ordering,
    limit,
    offset
  }) {
    const response = await request({
      url: "/wb_dynamic/categories/",
      method: "get",
      params: {
        brand_ids,
        parent_id,
        seller_ids,
        category_ids,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        extra_fields,
        level,
        has_child,
        period,
        ordering,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getCategoriesTrend({
    collection_id,
    period,
    date_from,
    date_to,
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    parent_id,
    down_parent_id,
    up_parent_id,
    wb_search,
    wb_catalog_url,
    city,
    level,
    limit,
    offset,
    ordering,
    extra_fields,
    predict_trand
  }) {
    const a = await request({
      url: `/parsers/categories/${(category_ids !== 0 && category_ids !== "0"
        && !isNil(category_ids) && category_ids !== undefined)
        ? `?category_id__in=${category_ids}`
        : ""}`,
      method: "get"
    });
    const dateFrom2 = a.data.results[0].create_date;

    if (new Date(dateFrom2) > new Date(date_from)) {
      date_from = dateFrom2.slice(0, 10);
    }

    const response = await request({
      url: "/wb_dynamic/categories_trend/",
      method: "get",
      params: {
        collection_id,
        product_ids,
        period,
        date_from,
        date_to,
        brand_ids,
        seller_ids,
        category_ids,
        parent_id,
        down_parent_id,
        up_parent_id,
        wb_search,
        wb_catalog_url,
        city,
        level,
        limit,
        offset,
        ordering,
        locf_dynamic: false,
        extra_fields,
        predict_trand
      }
    });
    return response.data;
  }

  async getBrandsTrend({
    period,
    date_from,
    date_to,
    brand_ids,
    collection_id,
    limit,
    offset,
    ordering,
    extra_fields,
    predict_trand,
  }) {
    const response = await request({
      url: "/wb_dynamic/brands_trend/",
      method: "get",
      params: {
        period,
        collection_id,
        date_from,
        date_to,
        brand_ids,
        limit,
        offset,
        ordering,
        locf_dynamic: false,
        extra_fields,
        predict_trand,
      }
    });

    return response.data;
  }
  async getGroupedBrandsTrend({
    period,
    date_from,
    date_to,
    brand_ids,
    collection_id,
    limit,
    offset,
    ordering,
    extra_fields
  }) {
    const response = await request({
      url: "/wb_dynamic/brands_trend/grouped/",
      method: "get",
      params: {
        period,
        collection_id,
        date_from,
        date_to,
        brand_ids,
        limit,
        offset,
        ordering,
        locf_dynamic: false,
        extra_fields
      }
    });

    return response.data;
  }

  async getSellersTrend({
    period,
    date_from,
    date_to,
    seller_ids,
    collection_id,
    limit,
    offset,
    ordering,
    extra_fields,
    predict_trand,
  }) {
    const response = await request({
      url: "/wb_dynamic/sellers_trend/",
      method: "get",
      params: {
        period,
        date_from,
        collection_id,
        date_to,
        seller_ids,
        limit,
        offset,
        ordering,
        locf_dynamic: false,
        extra_fields,
        predict_trand,
      }
    });

    return response.data;
  }

  async getProductsTrend({
    period,
    date_from,
    date_to,
    product_ids,
    collection_id,
    limit,
    offset,
    ordering,
    extra_fields,
    predict_trand,
  }) {
    const response = await request({
      url: "/wb_dynamic/products_trend/",
      method: "get",
      params: {
        period,
        date_from,
        date_to,
        product_ids,
        collection_id,
        limit,
        offset,
        ordering,
        locf_dynamic: false,
        extra_fields,
        predict_trand
      }
    });

    return response.data;
  }

  async exportCategoriesDynamic({
    brand_ids,
    parent_id,
    seller_ids,
    category_ids,
    collection_id,
    product_ids,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      "brands_count",
      "brands_count_dynamic",
      "sellers_count",
      "sellers_count_dynamic",
      "price",
      "price_dynamic",
      "old_price",
      "discount",
      "discount_dynamic",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "ransom",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sales",
      "sales_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_percent",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "product_with_orders",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity",
      "product_with_orders_and_quantity_dynamic",
      "brands_with_orders",
      "brands_with_orders_dynamic",
      "sellers_with_orders",
      "sellers_with_orders_dynamic"
    ].join(),
    level,
    has_child,
    period,
    ordering,
    limit,
    offset
  }) {
    const response = await request({
      url: "/wb_dynamic/categories/export/",
      method: "get",
      params: {
        brand_ids,
        parent_id,
        seller_ids,
        category_ids,
        collection_id,
        product_ids,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        extra_fields,
        level,
        has_child,
        period,
        ordering,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportCategoriesDynamicById({
    id,
    brand_ids,
    parent_id,
    seller_ids,
    category_ids,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      "brands_count",
      "brands_count_dynamic",
      "sellers_count",
      "sellers_count_dynamic",
      "price",
      "price_dynamic",
      "old_price",
      "discount",
      "discount_dynamic",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "ransom",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "sales",
      "sales_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_percent",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "product_with_orders",
      "product_with_orders_dynamic",
      "product_with_orders_and_quantity",
      "product_with_orders_and_quantity_dynamic",
      "brands_with_orders",
      "brands_with_orders_dynamic",
      "sellers_with_orders",
      "sellers_with_orders_dynamic"
    ].join(),
    level,
    has_child,
    period,
    ordering,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/categories/${id}/export/`,
      method: "get",
      params: {
        brand_ids,
        parent_id,
        seller_ids,
        category_ids,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        extra_fields,
        level,
        has_child,
        period,
        ordering,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getCategoriesDynamicById({
    id,
    brand_ids,
    parent_id,
    seller_ids,
    category_ids,
    wb_search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    extra_fields,
    level,
    has_child,
    period,
    ordering,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/categories/${id}/`,
      method: "get",
      params: {
        id,
        brand_ids,
        parent_id,
        seller_ids,
        category_ids,
        wb_search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        extra_fields,
        level,
        has_child,
        period,
        ordering,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getParsersCategories({
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    parent_id,
    search,
    level,
    has_child,
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/categories/`,
      method: "get",
      params: {
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        parent_id,
        search,
        level,
        has_child,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getParsersCategoriesCompare({
    source_product_id,
    period,
    target_product_id,
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/categories_compare/`,
      method: "get",
      params: {
        period,
        source_product_id,
        target_product_id,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getParsersCategoriesExact({
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    id
  }) {
    const response = await request({
      url: `/parsers/categories/${id}/`,
      method: "get",
      params: {
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in
      }
    });
    return response.data;
  }

  async getParsersCategoriesSearch({
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    search,
    is_deleted = false,
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/categories/search/`,
      method: "get",
      params: {
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        search,
        is_deleted,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getProductPositionDynamic({ id, period, date_from, date_to, limit, offset, extra_fields }) {
    const response = await request({
      url: `/parsers/products/${id}/position_dynamic/`,
      method: "get",
      params: {
        period,
        date_from,
        date_to,
        limit,
        offset,
        extra_fields
      }
    });
    return response.data;
  }
  async exportProductPositionDynamic({
    id,
    period,
    date_from,
    date_to,
    limit,
    offset,
    extra_fields
  }) {
    const response = await request({
      url: `/parsers/products/${id}/position_dynamic/export/`,
      method: "get",
      params: {
        period,
        date_from,
        date_to,
        limit,
        offset,
        extra_fields
      }
    });
    return response.data;
  }

  async getAnalyticsCollections() {
    const response = await request({
      url: "/analytics/collections/",
      method: "get"
    });
    return response.data;
  }

  async getAnalyticsExactCollection({ id }) {
    const response = await request({
      url: `/analytics/collections/${id}/`,
      method: "get"
    });
    return response.data;
  }

  async createAnalyticsCollection({ name, items }) {
    const response = await request({
      url: `/analytics/collections/`,
      method: "post",
      data: {
        name,
        items // list of int | id своих товаров
      }
    });
    return response.data;
  }

  async deleteAnalyticsCollection({ id }) {
    const response = await request({
      url: `/analytics/collections/${id}/`,
      method: "DELETE"
    });
    return response.data;
  }

  async removeProductsAnalyticsCollection({ id, items }) {
    const response = await request({
      url: `/analytics/collections/${id}/remove_items/`,
      method: "POST",
      data: {
        items
      }
    });
    return response.data;
  }

  async removeAllProductsAnalyticsCollection({ id }) {
    const response = await request({
      url: `/analytics/collections/${id}/clear_items/`,
      method: "POST"
    });
    return response.data;
  }

  async addProductsAnalyticsCollection({ id, items }) {
    const response = await request({
      url: `/analytics/collections/${id}/add_items/`,
      method: "POST",
      data: {
        items
      }
    });
    return response.data;
  }
  // TODO ????
  async updateAnalyticsCollection({ id, name, items }) {
    const response = await request({
      url: `/analytics/collections/${id}/`,
      method: "PATCH",
      data: {
        name,
        items
      }
    });
    return response.data;
  }

  async getProductsColorSizeDynamic({
    // id,
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset,
    product_ids,
    size_ids
  }) {
    const response = await request({
      url: `/wb_dynamic/product_color_sizes/`,
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset,
        product_ids,
        size_ids
      }
    });
    return response.data;
  }
  async getOrderAndSales({
    wb_api_key_ids,
    ordering,
    section,
    brand_name__icontains,
    limit,
    offset,
    date_from,
    date_to,
    period
  }) {
    const response = await request({
      url: `/analytics/order_and_sales/`,
      method: "get",
      params: {
        brand_name__icontains,
        section,
        ordering,
        wb_api_key_ids,
        date_from,
        date_to,
        period,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportProductsColorSizeDynamic({
    // id,
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/product_color_sizes/export/`,
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getProductsColorWarehouseDynamic({
    // id,
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset,
    warehouse_ids,
    product_ids
  }) {
    const response = await request({
      url: `/wb_dynamic/product_color_warehouses/`,
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset,
        warehouse_ids,
        product_ids
      }
    });
    return response.data;
  }

  async exportProductsColorWarehouseDynamic({
    // id,
    item,
    date_from,
    date_to,
    period,
    extra_fields,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/product_color_warehouses/export/`,
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportbrandsWarehouseDynamic({
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/warehouses/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }
  async getWarehousesDynamic({
    // id,
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      // url: `/parsers/brands/${id}/warehouse_dynamic/`,
      url: `/wb_dynamic/warehouses/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }
  async exportWarehousesDynamic({
    // id,
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      // url: `/parsers/brands/${id}/warehouse_dynamic/`,
      url: `/wb_dynamic/warehouses/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  // ------ Динамика товаров --------
  async getProductsDynamic({
    short_price_sigment,
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    city,
    count,
    wb_search,
    wb_catalog_url,
    collection_id,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    wb_adsearch,
    pages_max,
    price__gte,
    price__lte,
    price__gt,
    price__lt,
    in_visual_similar,
    also_buy,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "sales_percent",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "lost_proceeds_dynamic",
      "lost_orders",
      "lost_orders_dynamic",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      // "old_price",
      // "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      // "ransom",
      // "ransom_dynamic",
      "reviews",
      "rating_dynamic",
      "reviews_dynamic",
      "rating",
      "last_price"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/products/`,
      method: "get",
      params: {
        short_price_sigment,
        product_ids,
        brand_ids,
        collection_id,
        count,
        city,
        seller_ids,
        category_ids,
        wb_search,
        wb_catalog_url,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        wb_adsearch,
        pages_max,
        in_visual_similar,
        also_buy,
        extra_fields,
        price__gte,
        price__lte,
        price__gt,
        price__lt
      }
    });
    return response.data;
  }

  async getProductsDynamicToMonitoring({
    short_price_sigment,
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    city,
    wb_search,
    wb_catalog_url,
    collection_id,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    wb_adsearch,
    pages_max,
    price__gte,
    price__lte,
    price__gt,
    price__lt,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "sales_percent",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "lost_proceeds_dynamic",
      "lost_orders",
      "lost_orders_dynamic",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      // "old_price",
      // "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      // "ransom",
      // "ransom_dynamic",
      "reviews",
      "rating_dynamic",
      "reviews_dynamic",
      "rating",
      "last_price"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/products/to_monitoring/`,
      method: "get",
      params: {
        short_price_sigment,
        product_ids,
        brand_ids,
        collection_id,
        city,
        seller_ids,
        category_ids,
        wb_search,
        wb_catalog_url,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        wb_adsearch,
        pages_max,
        extra_fields,
        price__gte,
        price__lte,
        price__gt,
        price__lt
      }
    });
    return response.data;
  }
  async getKeyWordCPM({ keyword, extra_fields, auto_adv, product_id, category_id, city }) {
    const response = await request({
      url: `/monitoring/position_cpm/`,
      method: "get",
      params: {
        city,
        auto_adv,
        keyword,
        product_id,
        category_id,
        extra_fields
      }
    });
    return response.data;
  }
  async exportKeyWordCPM({ keyword, extra_fields, auto_adv, product_id, category_id, city }) {
    const response = await request({
      url: `/monitoring/position_cpm/export/`,
      method: "get",
      params: {
        city,
        auto_adv,
        keyword,
        product_id,
        category_id,
        extra_fields
      }
    });
    return response.data;
  }
  async getMonitoringCPP({ limit = 10000, offset }) {
    const response = await request({
      url: `/monitoring/wb_user_product_spp_history/`,
      method: "get",
      params: {
        limit,
        offset
      }
    });
    return response.data;
  }
  async getCPP() {
    const response = await request({
      url: `/monitoring/wb_user_disabled_notification/`,
      method: "get"
    });
    return response.data;
  }
  async postCPP({
    spp_increase = false,
    analytics = false,
    spp_decrease = false,
    spp_same = false,
    blogger_monitoring_is_changed = false,
    blogger_monitoring_is_not_changed = false
  }) {
    const response = await request({
      url: `/monitoring/wb_user_disabled_notification/`,
      method: "post",
      data: {
        spp_increase,
        spp_decrease,
        spp_same,
        analytics,
        blogger_monitoring_is_changed,
        blogger_monitoring_is_not_changed
      }
    });
    return response.data;
  }
  async postMonitoringCPP({
    product_id,
    is_decreased = true,
    is_increased = true,
    is_unchanged = true
  }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/`,
      method: "post",
      data: {
        product_id,
        is_decreased,
        is_increased,
        is_unchanged
      }
    });
    return response.data;
  }
  async getMonitoringCheckBoxes({ product_id }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/${product_id}`,
      method: "GET"
    });
    return response.data;
  }
  async deleteMonitoringCPP({ product_id }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/${product_id}/`,
      method: "delete"
    });
    return response.data;
  }

  async exportProductsDynamic({
    product_ids,
    brand_ids,
    seller_ids,
    category_ids,
    wb_search,
    city,
    short_price_sigment = false,
    wb_catalog_url,
    collection_id,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    price__gte,
    price__lte,
    extra_fields = [
      "proceeds",
      "orders",
      "price",
      "quantity",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "orders_failed",
      "proceeds_failed",
      "old_price",
      "sales",
      "sales_proceeds"
    ].join()
  }) {
    const response = await request({
      url: "/wb_dynamic/products/export/",
      method: "get",
      params: {
        product_ids,
        brand_ids,
        short_price_sigment,
        seller_ids,
        category_ids,
        collection_id,
        city,
        wb_search,
        wb_catalog_url,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        price__gte,
        price__lte,
        extra_fields
      }
    });
    return response.data;
  }

  async exportProductsDynamicById({
    product_ids,
    brand_ids,
    seller_ids,
    wb_search,
    wb_catalog_url,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "ransom",
      "ransom_dynamic"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_ids}/export/`,
      method: "get",
      params: {
        product_ids,
        brand_ids,
        seller_ids,
        wb_search,
        wb_catalog_url,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  // async getParsersCollections() {
  // 	const response = await request({
  // 		url: "parsers/collections/",
  // 		method: "get",
  // 	})
  // 	return response.data
  // }

  async getParsersCollections({ limit, offset }) {
    const response = await request({
      url: "parsers/collections/",
      method: "get",
      params: {
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSupplierArticleAdverts({ supplier_article }) {
    const response = await request({
      url: "parsers/supplier_article_adverts/run_parser/",
      method: "get",
      params: {
        supplier_article
      }
    });
    return response.data;
  }
  async getSupplierArticleAdvertsUsageHistory({
    supplier_article,
    product_id,
    supplier_article_ids,
    product_ids,
    offset = 0,
    limit = 1000,
    ordering
  }) {
    const response = await request({
      url: "parsers/supplier_article_adverts/usage_history/",
      method: "get",
      params: {
        supplier_article,
        product_id,
        supplier_article_ids,
        product_ids,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }
  async exportSupplierArticleAdvertsUsageHistory({
    supplier_article,
    run_id,
    source
  }) {
    const response = await request({
      url: "parsers/supplier_article_adverts/export/",
      method: "get",
      params: {
        supplier_article,
        run_id,
        source
      }
    });
    return response.data;
  }

  async createParsersCollection({ name, items }) {
    const response = await request({
      url: `/parsers/collections/`,
      method: "post",
      data: {
        name,
        items // list of int | id своих товаров
      }
    });
    return response.data;
  }

  async deleteParsersCollection({ id }) {
    const response = await request({
      url: `/parsers/collections/${id}/`,
      method: "delete"
    });
    return response.data;
  }

  async addProductsToParsersCollection({ id, items }) {
    const response = await request({
      url: `/parsers/collections/${id}/add_items/`,
      method: "post",
      data: {
        items
      }
    });
    return response.data;
  }

  async getBrandsSummary({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    limit,
    offset,
    ordering,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/brands_summary/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_percent__gte,
        sales_percent__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        limit,
        offset,
        ordering,
        month_width
      }
    });
    return response.data;
  }
  async exportBrandAnalyzer({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    limit,
    offset,
    ordering,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/brands_summary/export/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_percent__gte,
        sales_percent__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        limit,
        offset,
        ordering,
        month_width
      }
    });
    return response.data;
  }
  async getBrandsSummaryCount({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/brands_summary/count/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_percent__gte,
        sales_percent__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        month_width
      }
    });
    return response.data;
  }

  async exportBrandsSummary({
    brand_id__in,
    seller_id__in,
    category_id__in,
    warehouse_id__in,
    is_new,
    is_fbs,
    likes__gte,
    likes__lte,
    quantity__gte,
    quantity__lte,
    sellers__gte,
    sellers__lte,
    products__gte,
    products__lte,
    orders__gte,
    orders__lte,
    position__gte,
    position__lte,
    reviews__gte,
    reviews__lte,
    proceeds__gte,
    proceeds__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    price__gte,
    price__lte,
    discount__gte,
    discount__lte,
    ransom__gte,
    ransom__lte,
    // sales_percent__get,
    // sales_percent__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    incomes__gte,
    incomes__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_product_count_percent__gte,
    dynamic_product_count_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_reviews_percent__gte,
    dynamic_reviews_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    limit,
    offset,
    ordering
  }) {
    const response = await request({
      url: `/parsers/brands_summary/export/`,
      method: "get",
      params: {
        brand_id__in,
        seller_id__in,
        category_id__in,
        warehouse_id__in,
        is_new,
        is_fbs,
        likes__gte,
        likes__lte,
        quantity__gte,
        quantity__lte,
        sellers__gte,
        sellers__lte,
        products__gte,
        products__lte,
        orders__gte,
        orders__lte,
        position__gte,
        position__lte,
        reviews__gte,
        reviews__lte,
        proceeds__gte,
        proceeds__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        price__gte,
        price__lte,
        discount__gte,
        discount__lte,
        ransom__gte,
        ransom__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        incomes__gte,
        incomes__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_product_count_percent__gte,
        dynamic_product_count_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_reviews_percent__gte,
        dynamic_reviews_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        limit,
        offset,
        ordering
      }
    });
    return response.data;
  }

  async getProductsSummary({
    on_site_date__gte,
    on_site_date__lte,
    period,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    date_from,
    date_to,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    limit,
    offset,
    ordering,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/products_summary/`,
      method: "get",
      params: {
        period,
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        sales_percent__gte,
        sales_percent__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        limit,
        offset,
        ordering,
        month_width
      }
    });
    return response.data;
  }
  async exportProductsSummary({
    on_site_date__gte,
    on_site_date__lte,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    date_from,
    date_to,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    limit,
    offset,
    ordering,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/products_summary/export/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        sales_percent__gte,
        sales_percent__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        limit,
        offset,
        ordering,
        month_width
      }
    });
    return response.data;
  }

  async getProductsSummaryCount({
    on_site_date__gte,
    on_site_date__lte,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    period,
    sales_proceeds__lte,
    date_from,
    date_to,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    sellers_with_orders__gte,
    sellers_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/products_summary/count/`,
      method: "get",
      params: {
        period,
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        sales_percent__gte,
        sales_percent__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        sellers_with_orders__gte,
        sellers_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        month_width
      }
    });
    return response.data;
  }
  async getProductsFrequencyCount({
    period,
    brand_ids,
    seller_ids,
    subject_ids,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte,
    limit,
    offset,
    price_avg__lte,
    price_avg__gte,
    brand_id__in__gte,
    brand_id__in__lte,
    seller_id__in__gte,
    seller_id__in__lte,
    date_on_site__lte,
    date_on_site__gte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales__lte,
    search
  }) {
    const response = await request({
      url: `/parsers/product_frequency/count/`,
      method: "get",
      params: {
        period,
        search,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        brand_ids,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        seller_ids,
        subject_ids,
        // price_avg__lte,
        // price_avg__gte,
        limit,
        offset,
        date_on_site__lte,
        date_on_site__gte,
        brand_id__in__gte,
        brand_id__in__lte,
        seller_id__in__gte,
        seller_id__in__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales__lte
      }
    });
    return response.data;
  }
  async getProductsFrequency({
    period,
    ordering,
    brand_ids,
    seller_ids,
    subject_ids,
    date_on_site__lte,
    date_on_site__gte,
    price_avg__lte,
    price_avg__gte,
    limit,
    offset,
    brand_id__in__gte,
    brand_id__in,
    seller_id__in,
    brand_id__in__lte,
    seller_id__in__gte,
    seller_id__in__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales__lte,
    search
  }) {
    const response = await request({
      url: `/parsers/product_frequency/`,
      method: "get",
      params: {
        period,
        search,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        ordering,
        brand_ids,
        seller_ids,
        subject_ids,
        date_on_site__lte,
        date_on_site__gte,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        // price_avg__lte,
        // price_avg__gte,
        limit,
        offset,
        brand_id__in__gte,
        brand_id__in,
        seller_id__in,
        brand_id__in__lte,
        seller_id__in__gte,
        seller_id__in__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales__lte
      }
    });
    return response.data;
  }
  async exportProductsFrequency({
    period,
    ordering,
    brand_ids,
    seller_ids,
    subject_ids,
    date_on_site__lte,
    date_on_site__gte,
    price_avg__lte,
    price_avg__gte,
    limit,
    offset,
    brand_id__in__gte,
    brand_id__in__lte,
    seller_id__in__gte,
    seller_id__in__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales__lte,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte,
    search
  }) {
    const response = await request({
      url: `/parsers/product_frequency/export/`,
      method: "get",
      params: {
        period,
        search,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        ordering,
        brand_ids,
        seller_ids,
        subject_ids,
        date_on_site__lte,
        date_on_site__gte,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        // price_avg__lte,
        // price_avg__gte,
        limit,
        offset,
        brand_id__in__gte,
        brand_id__in__lte,
        seller_id__in__gte,
        seller_id__in__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales__lte
      }
    });
    return response.data;
  }
  // async exportProductsFrequency({
  //   period,
  //   ordering,
  //   brand_ids,
  //   seller_ids,
  //   subject_ids,
  //   date_on_site__lte,
  //   date_on_site__gte,
  //   price_avg__lte,
  //   price_avg__gte,
  //   limit,
  //   offset,
  //   brand_id__in__gte,
  //   brand_id__in__lte,
  //   seller_id__in__gte,
  //   seller_id__in__lte,
  //   frequency__gte,
  //   frequency__lte,
  //   percent_diff_frequency__gte,
  //   percent_diff_frequency__lte,
  //   proceeds__gte,
  //   proceeds__lte,
  //   percent_diff_proceeds__gte,
  //   percent_diff_proceeds__lte,
  //   orders__gte,
  //   orders__lte,
  //   percent_diff_orders__gte,
  //   percent_diff_orders__lte,
  //   lost_proceeds__gte,
  //   lost_proceeds__lte,
  //   percent_diff_lost_proceeds__gte,
  //   percent_diff_lost_proceeds__lte,
  //   price__gte,
  //   price__lte,
  //   percent_diff_price__gte,
  //   percent_diff_price__lte,
  //   percent_sales__gte,
  //   percent_sales__lte,
  //   search,
  // }) {
  //   const response = await request({
  //     url: `/parsers/product_frequency/export/`,
  //     method: "get",
  //     params: {
  //       period,
  //       search,
  //       ordering,
  //       brand_ids,
  //       seller_ids,
  //       subject_ids,
  //       date_on_site__lte,
  //       date_on_site__gte,
  //       price_avg__lte,
  //       price_avg__gte,
  //       limit,
  //       offset,
  //       brand_id__in__gte,
  //       brand_id__in__lte,
  //       seller_id__in__gte,
  //       seller_id__in__lte,
  //       frequency__gte,
  //       frequency__lte,
  //       percent_diff_frequency__gte,
  //       percent_diff_frequency__lte,
  //       proceeds__gte,
  //       proceeds__lte,
  //       percent_diff_proceeds__gte,
  //       percent_diff_proceeds__lte,
  //       orders__gte,
  //       orders__lte,
  //       percent_diff_orders__gte,
  //       percent_diff_orders__lte,
  //       lost_proceeds__gte,
  //       lost_proceeds__lte,
  //       percent_diff_lost_proceeds__gte,
  //       percent_diff_lost_proceeds__lte,
  //       price__gte,
  //       price__lte,
  //       percent_diff_price__gte,
  //       percent_diff_price__lte,
  //       percent_sales__gte,
  //       percent_sales__lte,
  //     }
  //   });
  //   return response.data;
  // }

  async getUserData({ type, ordering, limit, offset }) {
    const response = await request({
      url: "company/user_data/",
      method: "get",
      params: {
        type,
        ordering,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getUserDataById({ id }) {
    const response = await request({
      url: `/company/user_data/${id}/`,
      method: "get"
    });
    return response.data;
  }

  async saveUserData({ data, type }) {
    const response = await request({
      url: "company/user_data/",
      method: "post",
      data: {
        data,
        type
      }
    });
    return response.data;
  }

  async getSupplierArticleAdvertsLimits() {
    const response = await request({
      url: "parsers/supplier_article_adverts/limits/",
      method: "get"
    });
    return response.data;
  }

  async patchUserData({ id, data }) {
    const response = await request({
      url: `/company/user_data/${id}/`,
      method: "patch",
      data: {
        data
      }
    });
    return response.data;
  }

  async deleteUserData({ id }) {
    const response = await request({
      url: `/company/user_data/${id}/`,
      method: "delete"
    });
    return response.data;
  }
  async getProductFrequencyBrands({
    date,
    period = 7,
    brand_ids,
    seller_ids,
    subject_ids,
    limit = 25,
    offset = 0,
    ordering,
    date_on_site__gte,
    date_on_site__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales_lte,
    price_avg__lte,
    price_avg__gte,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte,
    search
  }) {
    const response = await request({
      url: `/parsers/product_frequency/brands/`,
      method: "get",
      params: {
        date,
        search,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        period,
        // price_avg__lte,
        // price_avg__gte,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        brand_ids,
        seller_ids,
        subject_ids,
        limit,
        offset,
        ordering,
        date_on_site__gte,
        date_on_site__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales_lte
      }
    });
    return response.data;
  }
  async getProductFrequencySellers({
    date,
    period = 7,
    brand_ids,
    search,
    seller_ids,
    subject_ids,
    limit = 25,
    offset = 0,
    ordering,
    date_on_site__gte,
    date_on_site__lte,
    frequency__gte,
    frequency__lte,
    percent_diff_frequency__gte,
    percent_diff_frequency__lte,
    proceeds__gte,
    proceeds__lte,
    percent_diff_proceeds__gte,
    percent_diff_proceeds__lte,
    orders__gte,
    orders__lte,
    percent_diff_orders__gte,
    percent_diff_orders__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    percent_diff_lost_proceeds__gte,
    percent_diff_lost_proceeds__lte,
    price__gte,
    price__lte,
    percent_diff_price__gte,
    percent_diff_price__lte,
    percent_sales__gte,
    percent_sales_lte,
    price_avg__lte,
    price_avg__gte,
    percent_diff_price_avg__gte,
    percent_diff_price_avg__lte
  }) {
    const response = await request({
      url: `/parsers/product_frequency/sellers/`,
      method: "get",
      params: {
        date,
        percent_diff_price_avg__gte,
        percent_diff_price_avg__lte,
        period,
        brand_ids,
        seller_ids,
        search,
        price_avg__lte: price_avg__lte ? price_avg__lte : price__lte,
        price_avg__gte: price_avg__gte ? price_avg__gte : price__gte,
        subject_ids,
        // price_avg__lte,
        // price_avg__gte,
        limit,
        offset,
        ordering,
        date_on_site__gte,
        date_on_site__lte,
        frequency__gte,
        frequency__lte,
        percent_diff_frequency__gte,
        percent_diff_frequency__lte,
        proceeds__gte,
        proceeds__lte,
        percent_diff_proceeds__gte,
        percent_diff_proceeds__lte,
        orders__gte,
        orders__lte,
        percent_diff_orders__gte,
        percent_diff_orders__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        percent_diff_lost_proceeds__gte,
        percent_diff_lost_proceeds__lte,
        price__gte,
        price__lte,
        percent_diff_price__gte,
        percent_diff_price__lte,
        percent_sales__gte,
        percent_sales_lte
      }
    });
    return response.data;
  }

  async getBrandColorGroupDynamic({
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/brand_colors/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async getBrandSizeDynamic({
    brand_ids,
    product_ids,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering,
    period
  }) {
    const response = await request({
      url: `/wb_dynamic/sizes/`,
      method: "get",
      params: {
        brand_ids,
        product_ids,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering,
        period
      }
    });
    return response.data;
  }
  async getCheckingKeywords({ product_id, period, is_in, product_id_in, ordering, offset, limit }) {
    const response = await request({
      url: `/parsers/checking_keywords/`,
      method: "get",
      params: {
        product_id,
        product_id_in,
        is_in,
        ordering,
        period,
        offset,
        limit
      }
    });
    return response.data;
  }
  async getCheckingKeywordsExport({
    product_id,
    product_id_in,
    ordering,
    period,
    offset,
    limit,
    is_in
  }) {
    const response = await request({
      url: `/parsers/checking_keywords/export/`,
      method: "get",
      params: {
        product_id,
        product_id_in,
        is_in,
        ordering,
        period,
        offset,
        limit
      }
    });
    return response.data;
  }
  async postCheckedWordsMonitoring({
    product_id,
    collection_id,
    period,
    is_in,
    product_id_in,
    ordering,
    offset,
    limit
  }) {
    const response = await request({
      url: `/parsers/checking_keywords/to_monitoring/`,
      method: "post",
      params: {
        product_id,
        collection_id,
        product_id_in,
        is_in,
        ordering,
        period,
        offset,
        limit
      }
    });
    return response.data;
  }

  async exportBrandColorGroupDynamic({
    brand_ids,
    seller_id,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/brand_colors/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_id,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async exportBrandSizeDynamic({
    brand_ids,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/sizes/export/`,
      method: "get",
      params: {
        brand_ids,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }
  async deleteProductKeywordPositions({
    id,
    product_ids,
    // collectionId,
    geolocation_ids
  }) {
    const response = await request({
      url: `/parsers/collections/product_keyword/${id}/clear_items/`,
      method: "post",
      params: {
        product_ids,
        // collectionId,
        geolocation_ids
      }
    });
    return response.data;
  }

  async exportWarehouseDynamic({
    brand_ids,
    product_ids,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/warehouses/export/`,
      method: "get",
      params: {
        brand_ids,
        product_ids,
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async getParsersSellers({
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    is_new,
    search,
    limit,
    offset,
    ordering
  }) {
    const response = await request({
      url: "/parsers/sellers/",
      method: "get",
      params: {
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        is_new,
        search,
        limit,
        offset,
        ordering
        // actual:true
      }
    });
    return response.data;
  }

  async getSellerById({ id, date_from, date_to, period }) {
    const response = await request({
      url: `/parsers/sellers/${id}/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async getAnalyticsProductCost({ wb_api_key_ids, identifier, report_id }) {
    const response = await request({
      url: `/analytics/${report_id ? "report_product_cost" : "product_cost"}/`,
      method: "get",
      params: {
        wb_api_key_ids,
        identifier,
        report_id
      }
    });
    return response.data;
  }
  async exportAnalyticsProductCost({ identifier, wb_api_key_ids, report_id, is_empty }) {
    const response = await request({
      url: `/analytics/${report_id ? "report_product_cost" : "product_cost"}/export/`,
      method: "get",
      params: {
        identifier,
        wb_api_key_ids,
        report_id,
        is_empty
      }
    });
    return response.data;
  }
  async postAnalyticsProductCost({ wb_api_key_id, identifier, data, report_id }) {
    const response = await request({
      url: `/analytics/${report_id ? "report_product_cost" : "product_cost"}/`,
      method: "post",
      data: {
        wb_api_key_id,
        identifier,
        report_id,
        data
      }
    });
    return response.data;
  }

  async postExcelAnalyticsProductCost({ formData, report_id }) {
    const token = window.localStorage.getItem("token") || window.sessionStorage.getItem("token");
    const response = await postFile({
      url: `/analytics/${"product_cost_validator"
        // report_id ? "report_product_cost" : "product_cost_validator"
        }/${report_id ? "?report_id=" + report_id : ""}`,
      data: formData,
      method: "post",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Token ${token}`
      }
    });
    return response.data;
  }

  async getIncomeTaxRate({ wb_api_key_id, report_id }) {
    const response = await request({
      url: `/analytics/settings/`,
      method: "get",
      params: {
        wb_api_key_ids: wb_api_key_id,
        report_id
      }
    });
    return response.data;
  }
  async postIncomeTaxRate({ wb_api_key_id, tax }) {
    const response = await request({
      url: `/analytics/settings/`,
      method: "post",
      data: {
        wb_api_key_ids: wb_api_key_id,
        tax
      }
    });
    return response.data;
  }

  async getSellersSummary({
    on_site_date__gte,
    on_site_date__lte,

    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    brands__gte,
    brands__lte,
    limit,
    offset,
    ordering,

    brands_with_orders__gte,
    brands_with_orders__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers_summary/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales_percent__gte,
        sales_percent__lte,
        brands__gte,
        brands__lte,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        limit,
        offset,
        ordering,
        brands_with_orders__gte,
        brands_with_orders__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        month_width
      }
    });
    return response.data;
  }
  async exportSellerAnalyzer({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    brands__gte,
    brands__lte,
    limit,
    offset,
    ordering,
    brands_with_orders__gte,
    brands_with_orders__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    month_width
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers_summary/export/`,
      method: "get",
      params: {
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales_percent__gte,
        sales_percent__lte,
        brands__gte,
        brands__lte,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        limit,
        offset,
        ordering,
        brands_with_orders__gte,
        brands_with_orders__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        month_width
      }
    });
    return response.data;
  }
  async getSellersSummaryCount({
    brands_with_orders__gte,
    brands_with_orders__lte,
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    brand_id__in,
    category_id__in,
    seller_id__in,
    product_id__in,
    orders__gte,
    orders__lte,
    proceeds__gte,
    proceeds__lte,
    quantity__gte,
    quantity__lte,
    returns__gte,
    returns__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    incomes__gte,
    incomes__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__gte,
    sales_percent__lte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    price__gte,
    price__lte,
    old_price__gte,
    old_price__lte,
    discount__gte,
    discount__lte,
    basic_discount__gte,
    basic_discount__lte,
    promo_discount__gte,
    promo_discount__lte,
    orders_failed__gte,
    orders_failed__lte,
    proceeds_failed__gte,
    proceeds_failed__lte,
    vendor_codes__gte,
    vendor_codes__lte,
    position__gte,
    position__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    sellers__gte,
    sellers__lte,
    reviews__gte,
    reviews__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    brands__gte,
    brands__lte,
    avg_orders_per_article_with_orders__gte,
    avg_orders_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_proceeds_per_article_with_orders__lte,
    products_with_orders__gte,
    products_with_orders__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article__lte,
    avg_percent_articles_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    month_width
    // sales_percent__gte,
    // sales_percent__lte,
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers_summary/count/`,
      method: "get",
      params: {
        brands_with_orders__gte,
        brands_with_orders__lte,
        brands__gte,
        brands__lte,
        date_from,
        date_to,
        on_site_date__gte,
        on_site_date__lte,
        sales_percent__gte,
        sales_percent__lte,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        brand_id__in,
        category_id__in,
        seller_id__in,
        product_id__in,
        orders__gte,
        orders__lte,
        proceeds__gte,
        proceeds__lte,
        quantity__gte,
        quantity__lte,
        returns__gte,
        returns__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        incomes__gte,
        incomes__lte,
        ransom__gte,
        ransom__lte,
        price__gte,
        price__lte,
        old_price__gte,
        old_price__lte,
        discount__gte,
        discount__lte,
        basic_discount__gte,
        basic_discount__lte,
        promo_discount__gte,
        promo_discount__lte,
        orders_failed__gte,
        orders_failed__lte,
        proceeds_failed__gte,
        proceeds_failed__lte,
        vendor_codes__gte,
        vendor_codes__lte,
        position__gte,
        position__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        sellers__gte,
        sellers__lte,
        reviews__gte,
        reviews__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        avg_orders_per_article_with_orders__gte,
        avg_orders_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_proceeds_per_article_with_orders__lte,
        products_with_orders__gte,
        products_with_orders__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article__lte,
        avg_percent_articles_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        // sales_percent__gte,
        // sales_percent__lte,
        month_width
      }
    });
    return response.data;
  }

  async getSellerColorGroupDynamic({
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/seller_colors/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }
  async exportSellerColorGroupDynamic({
    brand_ids,
    seller_ids,
    product_ids,
    date_from,
    date_to,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/seller_colors/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        product_ids,
        date_from,
        date_to,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async getCategoriesSummary({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    level,
    parent_id,
    down_parent_id,
    up_parent_id,
    sellers__lte,
    sellers__gte,
    sellers_with_orders__lte,
    sellers_with_orders__gte,
    avg_percent_sellers_with_orders__lte,
    avg_percent_sellers_with_orders__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    brands__lte,
    brands__gte,
    brands_with_orders__lte,
    brands_with_orders__gte,
    avg_percent_brands_with_orders__lte,
    avg_percent_brands_with_orders__gte,
    products_with_orders__lte,
    products_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article_with_orders__lte,
    avg_orders_per_article_with_orders__gte,
    vendor_codes__lte,
    vendor_codes__gte,
    avg_proceeds__lte,
    avg_proceeds__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    brand_id__in,
    brand_id,
    category_id__in,
    category_id,
    seller_id__in,
    seller_id,
    product_id__in,
    product_id,
    orders__lte,
    orders__gte,
    proceeds__lte,
    proceeds__gte,
    quantity__lte,
    quantity__gte,
    returns__lte,
    returns__gte,
    lost_proceeds__lte,
    lost_proceeds__gte,
    incomes__lte,
    incomes__gte,
    ransom__lte,
    ransom__gte,
    sales_percent__gte,
    sales_percent__lte,
    price__lte,
    price__gte,
    old_price__lte,
    old_price__gte,
    discount__lte,
    discount__gte,
    basic_discount__lte,
    basic_discount__gte,
    promo_discount__lte,
    promo_discount__gte,
    orders_failed__lte,
    orders_failed__gte,
    incomes_failed__lte,
    incomes_failed__gte,
    proceeds_failed__lte,
    proceeds_failed__gte,
    position__lte,
    position__gte,
    returns_percent__lte,
    returns_percent__gte,
    reviews,
    // reviewsо,
    lost_proceeds_share,
    dynamic_proceeds_percent__lte,
    dynamic_proceeds_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_price_percent__lte,
    dynamic_price_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_quantity_percent__lte,
    dynamic_quantity_percent__gte,
    limit,
    offset,
    ordering,
    in_stock_orders_avg__gte,
    in_stock_orders_avg__lte,
    in_stock_proceeds__gte,
    in_stock_proceeds__lte,
    month_width,
    has_child
  }) {
    const response = await request({
      url: `/wb_dynamic/categories_summary/`,
      method: "get",
      params: {
        sales_percent__gte,
        sales_percent__lte,
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        level,
        parent_id,
        down_parent_id,
        up_parent_id,
        sellers__lte,
        sellers__gte,
        sellers_with_orders__lte,
        sellers_with_orders__gte,
        avg_percent_sellers_with_orders__lte,
        avg_percent_sellers_with_orders__gte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        brands__lte,
        brands__gte,
        brands_with_orders__lte,
        brands_with_orders__gte,
        avg_percent_brands_with_orders__lte,
        avg_percent_brands_with_orders__gte,
        products_with_orders__lte,
        products_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article_with_orders__lte,
        avg_orders_per_article_with_orders__gte,
        vendor_codes__lte,
        vendor_codes__gte,
        avg_proceeds__lte,
        avg_proceeds__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        brand_id__in,
        brand_id,
        category_id__in,
        category_id,
        seller_id__in,
        seller_id,
        product_id__in,
        product_id,
        orders__lte,
        orders__gte,
        proceeds__lte,
        proceeds__gte,
        quantity__lte,
        quantity__gte,
        returns__lte,
        returns__gte,
        lost_proceeds__lte,
        lost_proceeds__gte,
        incomes__lte,
        incomes__gte,
        ransom__lte,
        ransom__gte,
        price__lte,
        price__gte,
        old_price__lte,
        old_price__gte,
        discount__lte,
        discount__gte,
        basic_discount__lte,
        basic_discount__gte,
        promo_discount__lte,
        promo_discount__gte,
        orders_failed__lte,
        orders_failed__gte,
        incomes_failed__lte,
        incomes_failed__gte,
        proceeds_failed__lte,
        proceeds_failed__gte,
        position__lte,
        position__gte,
        returns_percent__lte,
        returns_percent__gte,
        reviews,
        // reviewsо,
        lost_proceeds_share,
        dynamic_proceeds_percent__lte,
        dynamic_proceeds_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_price_percent__lte,
        dynamic_price_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_quantity_percent__lte,
        dynamic_quantity_percent__gte,
        limit,
        offset,
        ordering,
        in_stock_orders_avg__gte,
        in_stock_orders_avg__lte,
        in_stock_proceeds__gte,
        in_stock_proceeds__lte,
        month_width,
        has_child
      }
    });
    return response.data;
  }
  async exportCategoriesSummary({
    on_site_date__gte,
    on_site_date__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    level,
    parent_id,
    down_parent_id,
    up_parent_id,
    sellers__lte,
    sellers__gte,
    sellers_with_orders__lte,
    sellers_with_orders__gte,
    avg_percent_sellers_with_orders__lte,
    avg_percent_sellers_with_orders__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    brands__lte,
    brands__gte,
    brands_with_orders__lte,
    brands_with_orders__gte,
    avg_percent_brands_with_orders__lte,
    avg_percent_brands_with_orders__gte,
    products_with_orders__lte,
    products_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article_with_orders__lte,
    avg_orders_per_article_with_orders__gte,
    vendor_codes__lte,
    vendor_codes__gte,
    avg_proceeds__lte,
    avg_proceeds__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    brand_id__in,
    brand_id,
    category_id__in,
    category_id,
    seller_id__in,
    seller_id,
    product_id__in,
    product_id,
    orders__lte,
    orders__gte,
    proceeds__lte,
    proceeds__gte,
    quantity__lte,
    quantity__gte,
    returns__lte,
    returns__gte,
    lost_proceeds__lte,
    lost_proceeds__gte,
    incomes__lte,
    incomes__gte,
    ransom__lte,
    ransom__gte,
    sales_percent__gte,
    sales_percent__lte,
    price__lte,
    price__gte,
    old_price__lte,
    old_price__gte,
    discount__lte,
    discount__gte,
    basic_discount__lte,
    basic_discount__gte,
    promo_discount__lte,
    promo_discount__gte,
    orders_failed__lte,
    orders_failed__gte,
    incomes_failed__lte,
    incomes_failed__gte,
    proceeds_failed__lte,
    proceeds_failed__gte,
    position__lte,
    position__gte,
    returns_percent__lte,
    returns_percent__gte,
    reviews,
    reviewsо,
    lost_proceeds_share,
    dynamic_proceeds_percent__lte,
    dynamic_proceeds_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_price_percent__lte,
    dynamic_price_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_quantity_percent__lte,
    dynamic_quantity_percent__gte,
    limit,
    offset,
    ordering,
    in_stock_orders_avg__gte,
    in_stock_orders_avg__lte,
    in_stock_proceeds__gte,
    in_stock_proceeds__lte,
    month_width,
    has_child
  }) {
    const response = await request({
      url: `/wb_dynamic/categories_summary/export/`,
      method: "get",
      params: {
        sales_percent__gte,
        sales_percent__lte,
        on_site_date__gte,
        on_site_date__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        level,
        parent_id,
        down_parent_id,
        up_parent_id,
        sellers__lte,
        sellers__gte,
        sellers_with_orders__lte,
        sellers_with_orders__gte,
        avg_percent_sellers_with_orders__lte,
        avg_percent_sellers_with_orders__gte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        brands__lte,
        brands__gte,
        brands_with_orders__lte,
        brands_with_orders__gte,
        avg_percent_brands_with_orders__lte,
        avg_percent_brands_with_orders__gte,
        products_with_orders__lte,
        products_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article_with_orders__lte,
        avg_orders_per_article_with_orders__gte,
        vendor_codes__lte,
        vendor_codes__gte,
        avg_proceeds__lte,
        avg_proceeds__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        brand_id__in,
        brand_id,
        category_id__in,
        category_id,
        seller_id__in,
        seller_id,
        product_id__in,
        product_id,
        orders__lte,
        orders__gte,
        proceeds__lte,
        proceeds__gte,
        quantity__lte,
        quantity__gte,
        returns__lte,
        returns__gte,
        lost_proceeds__lte,
        lost_proceeds__gte,
        incomes__lte,
        incomes__gte,
        ransom__lte,
        ransom__gte,
        price__lte,
        price__gte,
        old_price__lte,
        old_price__gte,
        discount__lte,
        discount__gte,
        basic_discount__lte,
        basic_discount__gte,
        promo_discount__lte,
        promo_discount__gte,
        orders_failed__lte,
        orders_failed__gte,
        incomes_failed__lte,
        incomes_failed__gte,
        proceeds_failed__lte,
        proceeds_failed__gte,
        position__lte,
        position__gte,
        returns_percent__lte,
        returns_percent__gte,
        reviews,
        reviewsо,
        lost_proceeds_share,
        dynamic_proceeds_percent__lte,
        dynamic_proceeds_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_price_percent__lte,
        dynamic_price_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_quantity_percent__lte,
        dynamic_quantity_percent__gte,
        limit,
        offset,
        ordering,
        in_stock_orders_avg__gte,
        in_stock_orders_avg__lte,
        in_stock_proceeds__gte,
        in_stock_proceeds__lte,
        month_width,
        has_child
      }
    });
    return response.data;
  }
  async getCategoriesSummaryCount({
    on_site_date__gte,
    on_site_date__lte,
    in_stock_orders_avg__gte,
    in_stock_orders_avg__lte,
    in_stock_proceeds__gte,
    in_stock_proceeds__lte,
    date_from,
    date_to,
    sales__gte,
    sales__lte,
    sales_proceeds__gte,
    sales_proceeds__lte,
    level,
    parent_id,
    down_parent_id,
    up_parent_id,
    sellers__lte,
    sellers__gte,
    sellers_with_orders__lte,
    sellers_with_orders__gte,
    avg_percent_sellers_with_orders__lte,
    avg_percent_sellers_with_orders__gte,
    dynamic_sales_percent__lte,
    dynamic_sales_percent__gte,
    brands__lte,
    brands__gte,
    brands_with_orders__lte,
    brands_with_orders__gte,
    avg_percent_brands_with_orders__lte,
    avg_percent_brands_with_orders__gte,
    products_with_orders__lte,
    products_with_orders__gte,
    avg_percent_articles_with_orders__lte,
    avg_percent_articles_with_orders__gte,
    avg_proceeds_per_article__lte,
    avg_proceeds_per_article__gte,
    avg_proceeds_per_article_with_orders__lte,
    avg_proceeds_per_article_with_orders__gte,
    avg_orders_per_article__lte,
    avg_orders_per_article__gte,
    avg_orders_per_article_with_orders__lte,
    avg_orders_per_article_with_orders__gte,
    vendor_codes__lte,
    vendor_codes__gte,
    avg_proceeds__lte,
    avg_proceeds__gte,
    dynamic_vendor_codes_percent__lte,
    dynamic_vendor_codes_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    brand_id__in,
    brand_id,
    category_id__in,
    category_id,
    seller_id__in,
    seller_id,
    product_id__in,
    product_id,
    orders__lte,
    orders__gte,
    proceeds__lte,
    proceeds__gte,
    quantity__lte,
    quantity__gte,
    returns__lte,
    returns__gte,
    lost_proceeds__lte,
    lost_proceeds__gte,
    incomes__lte,
    incomes__gte,
    ransom__lte,
    ransom__gte,
    price__lte,
    price__gte,
    old_price__lte,
    old_price__gte,
    discount__lte,
    discount__gte,
    basic_discount__lte,
    basic_discount__gte,
    promo_discount__lte,
    promo_discount__gte,
    orders_failed__lte,
    orders_failed__gte,
    incomes_failed__lte,
    incomes_failed__gte,
    proceeds_failed__lte,
    proceeds_failed__gte,
    position__lte,
    position__gte,
    returns_percent__lte,
    returns_percent__gte,
    reviews,
    // reviewsо,
    lost_proceeds_share,
    dynamic_proceeds_percent__lte,
    dynamic_proceeds_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_price_percent__lte,
    dynamic_price_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_quantity_percent__lte,
    dynamic_quantity_percent__gte,
    sales_percent__gte,
    sales_percent__lte,
    month_width,
    has_child,
  }) {
    const response = await request({
      url: `/wb_dynamic/categories_summary/count/`,
      method: "get",
      params: {
        sales_percent__lte,
        sales_percent__gte,
        on_site_date__gte,
        on_site_date__lte,
        in_stock_orders_avg__gte,
        in_stock_orders_avg__lte,
        in_stock_proceeds__gte,
        in_stock_proceeds__lte,
        date_from,
        date_to,
        sales__gte,
        sales__lte,
        sales_proceeds__gte,
        sales_proceeds__lte,
        level,
        parent_id,
        down_parent_id,
        up_parent_id,
        sellers__lte,
        sellers__gte,
        sellers_with_orders__lte,
        sellers_with_orders__gte,
        avg_percent_sellers_with_orders__lte,
        avg_percent_sellers_with_orders__gte,
        dynamic_sales_percent__lte,
        dynamic_sales_percent__gte,
        brands__lte,
        brands__gte,
        brands_with_orders__lte,
        brands_with_orders__gte,
        avg_percent_brands_with_orders__lte,
        avg_percent_brands_with_orders__gte,
        products_with_orders__lte,
        products_with_orders__gte,
        avg_percent_articles_with_orders__lte,
        avg_percent_articles_with_orders__gte,
        avg_proceeds_per_article__lte,
        avg_proceeds_per_article__gte,
        avg_proceeds_per_article_with_orders__lte,
        avg_proceeds_per_article_with_orders__gte,
        avg_orders_per_article__lte,
        avg_orders_per_article__gte,
        avg_orders_per_article_with_orders__lte,
        avg_orders_per_article_with_orders__gte,
        vendor_codes__lte,
        vendor_codes__gte,
        avg_proceeds__lte,
        avg_proceeds__gte,
        dynamic_vendor_codes_percent__lte,
        dynamic_vendor_codes_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        brand_id__in,
        brand_id,
        category_id__in,
        category_id,
        seller_id__in,
        seller_id,
        product_id__in,
        product_id,
        orders__lte,
        orders__gte,
        proceeds__lte,
        proceeds__gte,
        quantity__lte,
        quantity__gte,
        returns__lte,
        returns__gte,
        lost_proceeds__lte,
        lost_proceeds__gte,
        incomes__lte,
        incomes__gte,
        ransom__lte,
        ransom__gte,
        price__lte,
        price__gte,
        old_price__lte,
        old_price__gte,
        discount__lte,
        discount__gte,
        basic_discount__lte,
        basic_discount__gte,
        promo_discount__lte,
        promo_discount__gte,
        orders_failed__lte,
        orders_failed__gte,
        incomes_failed__lte,
        incomes_failed__gte,
        proceeds_failed__lte,
        proceeds_failed__gte,
        position__lte,
        position__gte,
        returns_percent__lte,
        returns_percent__gte,
        reviews,
        lost_proceeds_share,
        dynamic_proceeds_percent__lte,
        dynamic_proceeds_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_price_percent__lte,
        dynamic_price_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_quantity_percent__lte,
        dynamic_quantity_percent__gte,
        month_width,
        has_child
      }
    });
    return response.data;
  }

  async getSellersSummaryScope({
    seller_id__in,
    brand_id__in,
    category_id__in,
    is_new,
    brands__gte,
    brands__lte,
    products__gte,
    products__lte,
    orders__gte,
    orders__lte,
    incomes__gte,
    incomes__lte,
    reviews__gte,
    reviews__lte,
    proceeds__gte,
    proceeds__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    position__gte,
    position__lte,
    likes__gte,
    likes__lte,
    quantity__gte,
    quantity__lte,
    price__gte,
    price__lte,
    discount__gte,
    discount__lte,
    ransom__gte,
    ransom__lte,
    // sales_percent__get,
    // sales_percent__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_product_count_percent__gte,
    dynamic_product_count_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_reviews_percent__gte,
    dynamic_reviews_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte
  }) {
    const response = await request({
      url: `/parsers/sellers_summary/scope/`,
      method: "get",
      params: {
        seller_id__in,
        brand_id__in,
        category_id__in,
        is_new,
        brands__gte,
        brands__lte,
        products__gte,
        products__lte,
        orders__gte,
        orders__lte,
        incomes__gte,
        incomes__lte,
        reviews__gte,
        reviews__lte,
        proceeds__gte,
        proceeds__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        position__gte,
        position__lte,
        likes__gte,
        likes__lte,
        quantity__gte,
        quantity__lte,
        price__gte,
        price__lte,
        discount__gte,
        discount__lte,
        ransom__gte,
        ransom__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_product_count_percent__gte,
        dynamic_product_count_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_reviews_percent__gte,
        dynamic_reviews_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte
      }
    });
    return response.data;
  }

  async getExactSellersSummary({ id }) {
    const response = await request({
      url: `/parsers/sellers_summary/${id}/`,
      method: "get"
    });
    return response.data;
  }

  async exportSellersSummary({
    seller_id__in,
    brand_id__in,
    category_id__in,
    is_new,
    ordering,
    brands__gte,
    brands__lte,
    products__gte,
    products__lte,
    orders__gte,
    orders__lte,
    incomes__gte,
    incomes__lte,
    reviews__gte,
    reviews__lte,
    proceeds__gte,
    proceeds__lte,
    avg_proceeds__gte,
    avg_proceeds__lte,
    position__gte,
    position__lte,
    likes__gte,
    likes__lte,
    quantity__gte,
    quantity__lte,
    price__gte,
    price__lte,
    discount__gte,
    discount__lte,
    ransom__gte,
    ransom__lte,
    sales_percent__get,
    sales_percent__lte,
    lost_proceeds__gte,
    lost_proceeds__lte,
    lost_proceeds_share__gte,
    lost_proceeds_share__lte,
    dynamic_proceeds_percent__gte,
    dynamic_proceeds_percent__lte,
    dynamic_product_count_percent__gte,
    dynamic_product_count_percent__lte,
    dynamic_orders_percent__gte,
    dynamic_orders_percent__lte,
    dynamic_price_percent__gte,
    dynamic_price_percent__lte,
    dynamic_avg_proceeds_percent__gte,
    dynamic_avg_proceeds_percent__lte,
    dynamic_lost_proceeds_percent__gte,
    dynamic_lost_proceeds_percent__lte,
    dynamic_reviews_percent__gte,
    dynamic_reviews_percent__lte,
    dynamic_quantity_percent__gte,
    dynamic_quantity_percent__lte,
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/sellers_summary/export/`,
      method: "get",
      params: {
        sales_percent__get,
        sales_percent__lte,
        seller_id__in,
        brand_id__in,
        category_id__in,
        is_new,
        ordering,
        brands__gte,
        brands__lte,
        products__gte,
        products__lte,
        orders__gte,
        orders__lte,
        incomes__gte,
        incomes__lte,
        reviews__gte,
        reviews__lte,
        proceeds__gte,
        proceeds__lte,
        avg_proceeds__gte,
        avg_proceeds__lte,
        position__gte,
        position__lte,
        likes__gte,
        likes__lte,
        quantity__gte,
        quantity__lte,
        price__gte,
        price__lte,
        discount__gte,
        discount__lte,
        ransom__gte,
        ransom__lte,
        lost_proceeds__gte,
        lost_proceeds__lte,
        lost_proceeds_share__gte,
        lost_proceeds_share__lte,
        dynamic_proceeds_percent__gte,
        dynamic_proceeds_percent__lte,
        dynamic_product_count_percent__gte,
        dynamic_product_count_percent__lte,
        dynamic_orders_percent__gte,
        dynamic_orders_percent__lte,
        dynamic_price_percent__gte,
        dynamic_price_percent__lte,
        dynamic_avg_proceeds_percent__gte,
        dynamic_avg_proceeds_percent__lte,
        dynamic_lost_proceeds_percent__gte,
        dynamic_lost_proceeds_percent__lte,
        dynamic_reviews_percent__gte,
        dynamic_reviews_percent__lte,
        dynamic_quantity_percent__gte,
        dynamic_quantity_percent__lte,
        limit,
        offset
      }
    });
    return response.data;
  }

  async exportSellerDynamic({
    seller_ids,
    brand_ids,
    search,
    wb_search,
    wb_catalog_url,
    period,
    date_from,
    date_to,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      //"rating",
      //"rating_dynamic",
      //"reviews",
      //"reviews_dynamic",
      "categories",
      "discount_price",
      "price",
      "orders",
      "quantity",
      "sales",
      "ransom",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_dynamic",
      "orders_proceeds_dynamic",
      "quantity_dynamic",
      "price_dynamic",
      "sales_dynamic",
      "proceeds_dynamic",
      "ransom_dynamic"
    ].join(),
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/sellers_dynamic/export/`,
      method: "get",
      params: {
        seller_ids,
        brand_ids,
        search,
        wb_search,
        wb_catalog_url,
        period,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSellersDynamic({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    product_ids,
    wb_search,
    search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      // "old_price",
      // "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      // "ransom",
      // "ransom_dynamic",
      "vendor_codes",
      "vendor_codes_dynamic",
      "reviews",
      "rating",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent",
      // "sales_percent_dynamic",
      "categories"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        category_ids,
        collection_id,
        product_ids,
        wb_search,
        search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }
  async getCategorySellers({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    wb_search,
    search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      // "old_price",
      // "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      // "ransom",
      // "ransom_dynamic",
      "vendor_codes",
      "vendor_codes_dynamic",
      "reviews",
      "rating",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent",
      // "sales_percent_dynamic",
      "categories"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/category_sellers/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        category_ids,
        collection_id,
        wb_search,
        search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }
  async exportCategorySellers({
    brand_ids,
    seller_ids,
    category_ids,
    collection_id,
    wb_search,
    search,
    wb_catalog_url,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      // "old_price",
      // "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      // "ransom",
      // "ransom_dynamic",
      "vendor_codes",
      "vendor_codes_dynamic",
      "reviews",
      "rating",
      "rating_dynamic",
      "reviews_dynamic",
      "sales_percent",
      // "sales_percent_dynamic",
      "categories"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/category_sellers/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        category_ids,
        collection_id,
        wb_search,
        search,
        wb_catalog_url,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async exportSellersDynamic({
    brand_ids,
    seller_ids,
    category_ids,
    wb_search,
    search,
    wb_catalog_url,
    collection_id,
    product_ids,
    city,
    date_from,
    date_to,
    period,
    limit,
    offset,
    ordering,
    extra_fields = [
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "discount_dynamic",
      "orders_failed",
      "orders_failed_dynamic",
      "proceeds_failed",
      "proceeds_failed_dynamic",
      "old_price",
      "old_price_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "ransom",
      "ransom_dynamic",
      "vendor_codes",
      "vendor_codes_dynamic",
      "reviews",
      "rating",
      "rating_dynamic",
      "reviews_dynamic"
    ].join()
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers/export/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        category_ids,
        wb_search,
        search,
        wb_catalog_url,
        collection_id,
        product_ids,
        city,
        date_from,
        date_to,
        period,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }

  async exportSellerDynamicById({
    id = "",
    seller_ids,
    extra_fields,
    period,
    date_from,
    date_to,
    limit,
    offset
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers/${id}/export/`,
      method: "get",
      params: {
        seller_ids,
        extra_fields,
        period,
        date_from,
        date_to,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getSellerCategoriesDynamic({
    category_id,
    seller_id,
    category_ids,
    brand_ids,
    level,
    has_child,
    date_from,
    date_to,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      //"rating",
      //"rating_dynamic",
      //"reviews",
      //"reviews_dynamic",
      //"categories",
      "proceeds",
      "orders",
      "price",
      "quantity",
      "sales",
      "ransom",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "discount",
      "orders_share",
      "proceeds_failed",
      "old_price",
      "sales_proceeds",
      "discount_dynamic",
      "orders_dynamic",
      "orders_proceeds_dynamic",
      "quantity_dynamic",
      "price_dynamic",
      "sales_dynamic",
      "sales_proceeds_dynamic",
      "proceeds_dynamic",
      "ransom_dynamic"
    ].join(),
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/sellers/${seller_id}/categories_dynamic/${category_id ? category_id : ""}/`,
      method: "get",
      params: {
        category_ids,
        brand_ids,
        level,
        has_child,
        date_from,
        date_to,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }

  async getProductsDynamicScope({
    date_from,
    date_to,
    product_ids,
    brand_ids,
    seller_ids,
    period
  }) {
    const response = await request({
      url: `/parsers/products_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        brand_ids,
        seller_ids,
        period
      }
    });
    return response.data;
  }

  async getBrandDynamicScope({ brand_ids, ordering, seller_ids, date_from, date_to, period }) {
    const response = await request({
      url: `/parsers/brands_dynamic/scope/`,
      method: "get",
      params: {
        brand_ids,
        seller_ids,
        date_from,
        date_to,
        period,
        ordering
      }
    });
    return response.data;
  }

  async getProductDynamicScope({ product_ids, date_from, date_to, period }) {
    const response = await request({
      url: `/parsers/products_dynamic/scope/`,
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async getCategoriesDynamicScope({ brand_ids, date_from, date_to }) {
    const response = await request({
      url: `/parsers/categories_dynamic/scope/`,
      method: "get",
      params: {
        brand_ids,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getBrandColorGroupDynamicScope({ brand_ids, seller_ids, date_from, date_to, id }) {
    const response = await request({
      url: `/parsers/brands/${id}/color_group_dynamic/scope/`,
      method: "get",
      params: {
        seller_ids,
        brand_ids,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getBrandSizeDynamicScope({ brand_ids, date_from, date_to, id }) {
    const response = await request({
      url: `/parsers/brands/${id}/size_dynamic/scope/`,
      method: "get",
      params: {
        brand_ids,
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getProductHistoryDynamic({ date_from, date_to, product_ids, period }) {
    const response = await request({
      url: `/parsers/product_history_dynamic/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        period
      }
    });
    return response.data;
  }

  async getProductColorDynamicScope({ id, date_from, date_to }) {
    const response = await request({
      url: `/parsers/products/${id}/color_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getProductsColorSizeDynamicScope({ id, date_from, date_to }) {
    const response = await request({
      url: `/parsers/products/${id}/color_size_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getProductsColorWarehouseDynamicScope({ id, date_from, date_to }) {
    const response = await request({
      url: `/parsers/products/${id}/color_warehouse_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async getProductPositionDynamicScope({ id, date_from, date_to }) {
    const response = await request({
      url: `/parsers/products/${id}/position_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to
      }
    });
    return response.data;
  }

  async searchProductById({ product_ids, date_from, date_to, period }) {
    const response = await request({
      url: "/parsers/products_dynamic/",
      method: "get",
      params: {
        product_ids,
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async SearchBrandByAlias({ brand_ids, date_from, date_to, period, search, alias }) {
    const response = await request({
      url: "parsers/brands_dynamic/",
      method: "get",
      params: {
        brand_ids,
        alias,
        search,
        date_from,
        date_to,
        period
      }
    });
    return response.data;
  }

  async searchSellersByName({ seller_ids, search, period }) {
    const response = await request({
      url: `/parsers/sellers_dynamic/`,
      method: "get",
      params: {
        seller_ids,
        search,
        period
      }
    });
    return response.data;
  }
  async getPartnersStatus() {
    const response = await request({
      url: `/partners/`,
      method: "get"
    });
    return response.data;
  }
  async getPartnerAwards({ purchase__date__gte, purchase__date__lte, purchase__date__lt }) {
    const response = await request({
      url: `/partner_awards/`,
      method: "get",
      params: {
        purchase__date__gte,
        purchase__date__lte,
        purchase__date__lt
      }
    });
    return response.data;
  }
  async getPartnerAwardsDynamic({
    create_date__gte,
    create_date__lte,
    purchase__date__gte,
    purchase__date__lt,
    purchase__date__lte
  }) {
    const response = await request({
      url: `/partner_awards_dynamic/`,
      method: "get",
      params: {
        create_date__gte,
        create_date__lte,
        purchase__date__gte,
        purchase__date__lt,
        purchase__date__lte
      }
    });
    return response.data;
  }
  async getProductWarehouseDynamic({
    item,
    // id,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/product_warehouses/`,
      method: "get",
      params: {
        item,
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async getProductWarehouseDynamicScope({
    id,
    date_from,
    date_to,
    period,
    extra_fields,
    offset,
    limit,
    ordering
  }) {
    const response = await request({
      url: `/parsers/products/${id}/warehouse_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to,
        period,
        extra_fields,
        offset,
        limit,
        ordering
      }
    });
    return response.data;
  }

  async exportSellerCategoriesDynamic({
    date_from,
    date_to,
    id,
    category_ids,
    extra_fields = [
      "vendor_codes",
      "vendor_codes_dynamic",
      "rating",
      "reviews",
      "categories",
      "sellers",
      "proceeds",
      "proceeds_dynamic",
      "orders",
      "orders_dynamic",
      "price",
      "price_dynamic",
      "quantity",
      "quantity_dynamic",
      "sales",
      "sales_dynamic",
      "sales_proceeds",
      "sales_proceeds_dynamic",
      "ransom",
      "ransom_dynamic",
      "discount",
      "discount_dynamic",
      "in_stock_days",
      "out_of_stock_days",
      "in_stock_percent",
      "in_stock_orders_avg",
      "in_stock_proceeds",
      "lost_proceeds",
      "orders_share",
      "old_price"
    ].join(),
    limit,
    offset
  }) {
    const response = await request({
      url: `/parsers/sellers/${id}/categories_dynamic/export/`,
      params: {
        date_from,
        date_to,
        category_ids,
        extra_fields,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getSellersDynamicScope({ date_from, date_to, brand_ids, seller_ids, period }) {
    const response = await request({
      url: `/parsers/sellers_dynamic/scope/`,
      method: "get",
      params: {
        date_from,
        date_to,
        seller_ids,
        period,
        brand_ids
      }
    });
    return response.data;
  }

  async getKeywords({ keyword }) {
    const response = await request({
      url: `/monitoring/keyword_collector/`,
      method: "get",
      params: {
        keyword
      }
    });

    return response.data;
  }

  async exportKeywords({ keyword }) {
    const response = await request({
      url: `/monitoring/keyword_collector/export/`,
      method: "get",
      params: {
        keyword
      }
    });
    return response.data;
  }

  async getPoritions({ product_id, phrase, pages_max /*limit, offset, ordering*/ }) {
    const response = await request({
      url: `/monitoring/positions/`,
      method: "get",
      params: {
        product_id,
        phrase,
        pages_max
      }
    });
    return response.data;
  }

  async getBanners() {
    const response = await request({
      url: "/banners/",
      method: "get"
    });

    return response.data;
  }

  async getCompanyTariff() {
    const response = await request({
      url: "/company/tariff_plan/",
      method: "get"
    });

    return response.data;
  }

  async getProductKeywords({
    date_from,
    date_to,
    product_ids,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/product_keywords/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering
      },
      timeout: 50000
    });
    return response.data;
  }
  async getProductKeywordsByProductId({
    date_from,
    date_to,
    product_id,
    keyword_ids,
    extra_fields,
    limit,
    search,
    period,
    offset,
    ordering,
    is_advert
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_id}/keywords/`,
      method: "get",
      params: {
        date_from,
        date_to,
        keyword_ids,
        extra_fields,
        search,
        limit,
        period,
        offset,
        ordering,
        is_advert
      },
      timeout: 50000
    });
    return response.data;
  }
  async getProductDynamic({
    product_id,
    date_from,
    date_to,
    limit,
    offset,
    ordering,
    extra_fields,
    period
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_id}/`,
      method: "get",
      params: {
        period,
        date_from,
        date_to,
        limit,
        offset,
        ordering,
        extra_fields
      }
    });
    return response.data;
  }
  async addProductKeywordsByProductIdToMonitoring({
    date_from,
    date_to,
    product_id,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering,
    collection_id,
    geolocation_id
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_id}/keywords/to_monitoring/`,
      method: "post",
      data: {
        date_from,
        geolocation_id,
        // product_ids: product_id,
        date_to,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering,
        collection_id
      },
      timeout: 50000
    });
    return response.data;
  }
  async postToMonitoringProductKeywords({
    date_from,
    date_to,
    product_ids,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering,
    collection_id
  }) {
    const response = await request({
      url: `/wb_dynamic/product_keywords/to_monitoring/`,
      method: "post",
      params: {
        collection_id,
        date_from,
        date_to,
        product_ids,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering
      }
    });
    return response.data;
  }
  async becomepPartner() {
    const response = await request({
      url: `/company/convert_to_partner/`,
      method: "post"
    });
    return response.data;
  }
  async exportProductKeywords({
    date_from,
    date_to,
    product_ids,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering,
    is_advert = 0
  }) {
    const response = await request({
      url: `/wb_dynamic/product_keywords/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering,
        is_advert
      }
    });
    return response.data;
  }
  async exportProductKeywordsArticule({
    date_from,
    date_to,
    product_ids,
    product_id,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering,
    is_advert
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_ids ?? product_id}/keywords/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        product_id,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering,
        is_advert
      }
    });
    return response.data;
  }

  async getDinamicKeywordsFrazes({
    wb_search,
    subject_ids,
    brand_ids,
    category_ids,
    stop_product_ids,
    products__gte,
    products__lte,
    seller_ids,
    city,
    period = 7,
    limit,
    product_ids,
    offset,
    ordering,
    extra_fields,
    frequency__gte,
    frequency__lte,
    percent_products__gte,
    percent_products__lte
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords/`,
      method: "get",
      params: {
        seller_ids,
        subject_ids,
        brand_ids,
        category_ids,
        stop_product_ids,
        products__gte,
        products__lte,
        wb_search,
        city,
        product_ids,
        period,
        limit,
        offset,
        ordering,
        extra_fields,
        frequency__gte,
        frequency__lte,
        percent_products__gte,
        percent_products__lte
      }
    });
    return response.data;
  }
  async getWbParsers({ keyword_ids__in, keyword_ids, limit, offset }) {
    const response = await request({
      url: `/parsers/keywords/`,
      method: "get",
      params: {
        keyword_ids__in,
        keyword_ids,
        limit,
        offset
      }
    });
    return response.data;
  }
  async getDinamicKeywordsFrazesCount({
    wb_search,
    brand_ids,
    subject_ids,
    category_ids,
    stop_product_ids,
    products__gte,
    products__lte,
    seller_ids,
    city,
    period = 7,
    limit,
    product_ids,
    offset,
    ordering,
    extra_fields,
    frequency__gte,
    frequency__lte,
    percent_products__gte,
    percent_products__lte
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords/count/`,
      method: "get",
      params: {
        seller_ids,
        brand_ids,
        subject_ids,
        category_ids,
        stop_product_ids,
        products__gte,
        products__lte,
        wb_search,
        city,
        product_ids,
        period,
        limit,
        offset,
        ordering,
        extra_fields,
        frequency__gte,
        frequency__lte,
        percent_products__gte,
        percent_products__lte
      }
    });
    return response.data;
  }
  async addAllMonitoringDinamicKeywordsFrazes({
    wb_search,
    brand_ids,
    category_ids,
    stop_product_ids,
    date_from,
    date_to,
    seller_ids,
    city,
    subject_ids,
    collection_id,
    period,
    limit,
    product_ids,
    offset,
    ordering,
    extra_fields,
    products__gte,
    products__lte,
    frequency__gte,
    frequency__lte,
    percent_products__gte,
    percent_products__lte
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords/to_monitoring/`,
      method: "post",
      params: {
        seller_ids,
        brand_ids,
        category_ids,
        stop_product_ids,
        subject_ids,
        date_from,
        date_to,
        collection_id,
        wb_search,
        city,
        product_ids,
        period,
        limit,
        offset,
        ordering,
        extra_fields,
        products__gte,
        products__lte,
        frequency__gte,
        frequency__lte,
        percent_products__gte,
        percent_products__lte
      }
    });
    return response.data;
  }

  async exportKeywordsFrazes({
    wb_search,
    brand_ids,
    subject_ids,
    category_ids,
    stop_product_ids,
    seller_ids,
    city,
    period,
    limit,
    product_ids,
    offset,
    ordering,
    extra_fields,
    frequency__gte,
    frequency__lte,
    products__gte,
    products__lte,
    percent_products__gte,
    percent_products__lte
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords/export/`,
      method: "get",
      params: {
        seller_ids,
        subject_ids,
        brand_ids,
        category_ids,
        stop_product_ids,
        wb_search,
        city,
        product_ids,
        period,
        limit,
        offset,
        ordering,
        extra_fields,
        frequency__gte,
        frequency__lte,
        products__gte,
        products__lte,
        percent_products__gte,
        percent_products__lte
      }
    });
    return response.data;
  }

  async getProductKeywordsStatByData({
    keyword_ids,
    stop_subjects,
    stop_words,
    date_from,
    subjects,
    subject_ids,
    date_to,
    search,
    ordering,
    period,
    limit,
    offset,
    extra_fields,
    collection_id
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/analyze/`,
      method: "post",
      data: {
        collection_id,
        stop_words,
        subject_ids,
        stop_subjects,
        subjects,
        keyword_ids,
        date_from,
        date_to,
        search,
        period,
        extra_fields
      },
      params: {
        limit,
        ordering,
        offset
      }
    });
    return response.data;
  }
  async getProductKeywordsStat({
    keyword_ids,
    stop_words,
    date_from,
    date_to,
    search,
    ordering,
    period,
    limit,
    offset,
    extra_fields,
    collection_id
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/`,
      method: "get",
      params: {
        collection_id,
        stop_words,
        keyword_ids,
        date_from,
        date_to,
        search,
        ordering,
        period,
        limit,
        offset,
        extra_fields
      }
    });
    return response.data;
  }
  async getKeywordsStatSubjects({
    keyword_ids,
    stop_words,
    date_from,
    date_to,
    search,
    subjects,
    ordering,
    period,
    limit,
    offset,
    extra_fields,
    collection_id,
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/subjects/`,
      method: "get",
      params: {
        collection_id,
        keyword_ids,
        ordering,
        date_from,
        subjects,
        date_to,
        search,
        period,
        limit,
        offset,
        extra_fields,
        stop_words,
      }
    });
    return response.data;
  }
  async postProductKeywordsStat({
    keyword_ids,
    stop_words,
    date_from,
    date_to,
    search,
    ordering,
    period,
    limit,
    offset,
    extra_fields,
    collection_id
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/?limit=${limit}&offset=${offset}`,
      method: "post",
      params: {
        collection_id,
        stop_words,
        keyword_ids,
        date_from,
        date_to,
        search,
        ordering,
        period,
        // limit,
        // offset,
        extra_fields
      }
    });
    return response.data;
  }
  async postAllToMonitoring({
    keyword_ids,
    date_from,
    date_to,
    search,
    ordering,
    stop_words,
    period,
    limit,
    offset,
    extra_fields,
    collection_id,
    subject_ids
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/to_monitoring/`,
      method: "post",
      params: {
        collection_id
      },
      data: {
        subject_ids,
        keyword_ids,
        date_from,
        stop_words,
        date_to,
        search,
        ordering,
        period,
        limit,
        offset,
        extra_fields
      }
    });
    return response.data;
  }
  async getProductKeywordsStatExport({
    keyword_ids,
    date_from,
    stop_words,
    date_to,
    search,
    ordering,
    subject_ids,
    period,
    limit,
    subjects,
    offset,
    extra_fields,
    collection_id
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/export/`,
      method: "post",
      params: {
        collection_id,
        limit,
        offset
      },
      data: {
        keyword_ids,
        subjects,
        stop_words,
        subject_ids,
        date_from,
        date_to,
        search,
        ordering,
        period,

        extra_fields
      }
    });
    return response.data;
  }

  // async getNewProductKeywords({
  // 	keyword_ids,
  // 	date_from,
  // 	date_to,
  // 	search,
  // 	ordering,
  // 	period,
  // 	limit,
  // 	offset,
  // 	extra_fields,
  // }) {
  // 	const response = await request({
  // 		url: `/wb_dynamic/keywords/`,
  // 		method: "get",
  // 		params: {
  // 			keyword_ids,
  // 			date_from,
  // 			date_to,
  // 			search,
  // 			ordering,
  // 			period,
  // 			limit,
  // 			offset,
  // 			extra_fields,
  // 		},
  // 	})
  // 	return response.data
  // }

  async getTariffs({
    promo_code
  }) {
    const response = await request({
      url: "/tariff/plans/",
      method: "get",
      params: {
        promo_code
      }
    });

    return response.data;
  }
  async getTrafficsTariffPlan(params) {
    const response = await request({
      url: "/tariff/tariff_plan_feature/",
      method: "get",
      params
    });

    return response.data;
  }
  async getTrafficsTariffPlanPurchaseCalc(data) {
    const response = await request({
      url: "/company/v2/purchase_calc/feature/",
      method: "post",
      data
    });

    return response.data;
  }



  async getTransactions({ limit, offset }) {
    const response = await request({
      url: "/billing/transactions/",
      method: "get",
      params: {
        limit,
        offset
      }
    });

    return response.data;
  }

  async getTransactionsById({ id }) {
    const response = await request({
      url: `/billing/transactions/${id}/`,
      method: "get"
    });
    return response.data;
  }

  async getPaymentFormData({ sum }) {
    const response = await request({
      url: "/billing/refill/",
      method: "post",
      data: {
        sum
      }
    });
    return response.data;
  }

  async buyTariff({ plan }) {
    const response = await request({
      url: "/company/purchase/",
      method: "post",
      data: {
        plan
      }
    });
    return response.data;
  }

  async getPromocodeInfo({ promocode }) {
    const response = await request({
      url: `/promo_code_checker/?promo_code=${promocode}`,
      method: "get"
    });

    return response.data;
  }
  async getPromocodes({ limit, ordering, offset }) {
    const response = await request({
      url: `/promo_codes/`,
      method: "get",
      params: {
        limit,
        ordering,
        offset
      }
    });

    return response.data;
  }

  async buyTariffv2({ plan, period, promocode }) {
    const response = await request({
      url: "/company/v2/purchase/",
      method: "post",
      data: deletePropFromObject(
        {
          plan,
          period,
          promo_code: promocode
        },
        promocode ? "" : "promo_code"
      )
    });
    return response.data;
  }

  async calcTariffPurchase({ plan, period, promocode }) {
    const response = await request({
      url: "/company/v2/purchase_calc/",
      method: "post",
      data: deletePropFromObject(
        {
          plan,
          period,
          promo_code: promocode
        },
        promocode ? "" : "promo_code"
      )
    });
    return response.data;
  }

  async exportReviews({ id, stars }) {
    const response = await request({
      url: `/parsers/products/${id}/feedbacks/export/?mark__in=${stars}`,
      method: "get"
    });
    return response.data;
  }
  async createChatGptNewChat({ name }) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/`,
      method: "post",
      data: {
        name
      }
    });
    return response.data;
  }
  async getChatGptChats({ limit, offset, ordering }) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/`,
      method: "get",
      params: {
        limit,
        offset,
        ordering
      }
    });
    return response.data;
  }

  async deleteChatGptChat({ id }) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/${id}/`,
      method: "delete"
    });
    return response.data;
  }
  async editChatGptChat({ id, data }) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/${id}/`,
      method: "patch",
      data
    });
    return response.data;
  }
  async sendMessageToChatGPT(id, message) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/${id}/text/`,
      method: "post",
      data: {
        text: message
      }
    });
    return response.data;
  }
  async getMessageToChatGPT(id, textId) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/${id}/text/${textId}/`,
      method: "get"
    });
    return response.data;
  }
  async getMessagesByChayId(id) {
    const response = await request({
      url: `/monitoring/chatgpt_chat/${id}/text/`,
      method: "get"
    });
    return response.data;
  }
  async getChatLimit() {
    const response = await request({
      url: `/monitoring/chatgpt_chat/limits/`,
      method: "get"
    });
    return response.data;
  }
  async getKeywordStatByPeriod({
    date_from,
    date_to, id,
    extra_fields = "dynamic",
    keywords,
    predict_trand
  }) {
    const response = await request({
      url: "/wb_dynamic/keywords_stat/",
      method: "get",
      params: {
        date_from,
        date_to,
        keywords,
        keyword_ids: id,
        extra_fields,
        predict_trand
      }
    });

    return response.data;
  }

  async getSPPList({ dateFrom, dateTo, ordering, limit, offset, product_id }) {
    const response = await request({
      url: `/monitoring/wb_user_product_spp_history/`,
      method: "get",
      params: {
        limit,
        offset,
        ordering,
        product_id,
        create_date__gte: dateFrom,
        create_date__lte: dateTo
      }
    });
    return response.data;
  }

  async getSPPNotifications({ limit, offset, product_id }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/`,
      method: "get",
      params: {
        limit,
        offset,
        product_id
      }
    });
    return response.data;
  }

  async getSPPItemNotification({ id }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/`,
      method: "get",
      params: {
        product_id: id
      }
    });
    return response.data;
  }

  async setSPPList({ list: spp_notifications = {} }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/`,
      method: "post",
      data: {
        ...spp_notifications
        // spp_notifications
      }
    });
    return response.data;
  }

  async setSPPListBulkUpdate({ list: spp_notifications = {} }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/bulk_update/`,
      method: "post",
      data: spp_notifications
    });
    return response.data;
  }

  async deleteSPPItems({ ids }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/delete`,
      method: "delete",
      data: {
        product_ids: ids
      }
    });
    return response.data;
  }
  async deleteSPPItemsBulkDelete({ ids }) {
    const response = await request({
      url: `/monitoring/spp_user_notification/bulk_delete/`,
      method: "delete",
      data: {
        product_ids: ids
      }
    });
    return response.data;
  }


  async getMarks({ type, itemId, dateFrom, dateTo }) {
    const dynamicKey = () => {
      if (type === "products") return "product_id";
      if (type === "brands") return "brand_id";
      if (type === "sellers") return "seller_id";
      if (type === "categories") return "category_id";
    };
    const response = await request({
      url: `/monitoring/marks/${type}/`,
      method: "get",
      params: {
        [dynamicKey()]: itemId,
        end_date__gte: dateFrom,
        start_date__lte: dateTo,
        limit: 1000,
        offset: 0
      }
    });
    return response.data;
  }

  async setMark({ type, item }) {
    const response = await request({
      url: `/monitoring/marks/${type}/`,
      method: "post",
      data: item
    });
    return response.data;
  }

  async deleteMark({ type, deleteId }) {
    const response = await request({
      url: `/monitoring/marks/${type}/${deleteId}/`,
      method: "delete"
    });
    return response.data;
  }

  async exportKeywordsStat({ date_from, date_to, keyword_ids, extra_fields }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        keyword_ids,
        extra_fields
      }
    });
    return response.data;
  }

  async getAnalyticsWarehouses({
    api_key_ids,
    order_date__lte,
    order_date__gte,
    // group_by,
    ordering,
    offset,
    limit
  }) {
    const response = await request({
      url: "/analytics/products/warehouses",
      method: "get",
      params: {
        offset,
        limit,
        ordering,
        api_key_ids,
        order_date__gte,
        order_date__lte
        // group_by,
      }
    });
    return response.data;
  }

  async getAnalyticsProducts({
    group_by,
    offset,
    limit,
    ordering,
    api_key_id,
    order_date__gte,
    order_date__lte
  }) {
    const response = await request({
      url: "/analytics/products/",
      method: "get",
      params: {
        offset,
        limit,
        ordering,
        api_key_id,
        order_date__gte,
        order_date__lte,
        group_by
      }
    });
    return response.data;
  }

  async getPaymentHistory() {
    const response = await request({
      url: "/company/purchase_history/",
      method: "get"
    });
    return response.data;
  }

  async addKeywordsToProduct({ collectionId, items }) {
    const response = await request({
      url: `parsers/collections/product_keyword/${collectionId}/add_items/`,
      method: "post",
      data: {
        items
      }
    });
    return response.data;
  }
  async deleteKeywordsToProduct({ collectionId, geolocation_id, items }) {
    const response = await request({
      url: `parsers/collections/product_keyword/${collectionId}/remove_items/`,
      method: "post",
      data: {
        items
      },
      params: {
        geolocation_id
      }
    });
    return response.data;
  }

  async getProductKeywordGeolocations({ limit, offset }) {
    const response = await request({
      url: "/parsers/collections/product_keyword/geolocations/",
      method: "get",
      params: {
        limit,
        offset
      }
    });
    return response.data;
  }
  async getProductKeywordGeolocationsByArticule({ collection_id, product_ids }) {
    const response = await request({
      url: `/parsers/collections/product_keyword/${collection_id}/geolocations/`,
      method: "get",
      params: {
        product_ids
      }
    });
    return response.data;
  }

  async getProductFavouritesDynamic({
    collection_id,
    product_id,
    geolocation_id,
    date_from,
    date_to,
    limit,
    offset,
    search,
    ordering,
    period,
    is_advert,
    extra_fields
  }) {
    const response = await request({
      url: `/parsers/collections/product_keyword/${collection_id}/dynamic/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_id,
        geolocation_id,
        limit,
        offset,
        search,
        ordering,
        period,
        is_advert,
        extra_fields
      }
    });
    return response.data;
  }

  async getProductFavouritesDynamicTop({
    collection_id,
    product_id,
    geolocation_id,
    date_from,
    date_to,
    search,
    is_advert
  }) {
    const response = await request({
      url: `/parsers/collections/product_keyword/${collection_id}/dynamic/top/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_id,
        geolocation_id,
        search,
        is_advert
      }
    });
    return response.data;
  }

  async exportProductFavouritesDynamic({
    collection_id,
    product_id,
    geolocation_id,
    date_from,
    date_to,
    limit,
    offset,
    search,
    ordering,
    period,
    is_advert,
    extra_fields
  }) {
    const response = await request({
      url: `/parsers/collections/product_keyword/${collection_id}/dynamic/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_id,
        geolocation_id,
        limit,
        offset,
        search,
        ordering,
        period,
        is_advert,
        extra_fields
      }
    });
    return response.data;
  }

  async getKeywordsSummarySubjects({
    limit = 1000,
    offset = 0,
    diff_percent30__gte,
    diff_percent30__lte,
    diff_percent60__gte,
    diff_percent60__lte,
    diff_percent90__gte,
    diff_percent90__lte,
    create_date,
    days30__gte,
    search,
    subjects,
    days30__lte,
    products__gte,
    products__lte,
    frequency_per_product30__gte,
    frequency_per_product30__lte,
  }) {
    const response = await request({
      url: `wb_dynamic/keywords_summary/subjects/`,
      method: "get",
      params: {
        limit,
        search,
        offset,
        create_date,
        subjects,
        diff_percent30__gte,
        diff_percent30__lte,
        diff_percent60__gte,
        diff_percent60__lte,
        diff_percent90__gte,
        diff_percent90__lte,
        days30__gte,
        days30__lte,
        products__gte,
        products__lte,
        frequency_per_product30__gte,
        frequency_per_product30__lte,
      }
    });
    return response;
  }
  async getBrandsByRating({
    // period = 30,
    ordering = "rating_position",
    limit = 10,
    offset = 0,
    month_width = true,
    category_ids,
    date_from = "2023-12-01",
    date_to = "2023-12-31",
    rating_position__lte = 1000,
  }) {
    const response = await request({
      url: `/api/wb_dynamic/brands_summary/`,
      method: "get",
      params: {
        // period,
        ordering,
        limit,
        month_width,
        category_ids,
        offset,
        date_from,
        date_to,
        rating_position__lte,
      }
    })
    return response
  }

  async getSellersByRating({
    // period = 30,
    ordering = "rating_position",
    limit = 10,
    offset = 0,
    month_width = true,
    category_ids,
    date_from = "2023-12-01",
    date_to = "2023-12-31",
    rating_position__lte = 1000,
  }) {
    const response = await request({
      url: `/api/wb_dynamic/sellers_summary/`,
      method: "get",
      params: {
        // period,
        ordering,
        limit,
        month_width,
        category_ids,
        offset,
        date_from,
        date_to,
        rating_position__lte,
      }
    })
    return response
  }
  async getDynamicProductsKeywords({ productId, keyword_ids, limit, offset, date_from, date_to, ordering }) {
    const response = await request({
      url: `/wb_dynamic/products/${productId}/keywords/`,
      method: "get",
      params: {
        keyword_ids,
        date_from,
        date_to,
        limit,
        offset: 0,
        ordering,
      }
    });
    return response.data;
  }
  async getProductByClusters({
    limit,
    offset,
    product_id,
    period,
    date_from,
    date_to,
    keyword_ids,
    search,
    is_advert,
    extra_fields,
    ordering,
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_id}/clusters/`,
      method: "get",
      params: {
        limit,
        offset,
        period,
        date_from,
        date_to,
        keyword_ids,
        search,
        is_advert,
        extra_fields,
        ordering,
      },
      timeout: 50000
    });
    return response.data;
  }
  async getProductByClustersExport({
    date_from,
    date_to,
    product_ids,
    product_id,
    keyword_ids,
    extra_fields,
    limit,
    period,
    offset,
    ordering,
    is_advert
  }) {
    const response = await request({
      url: `/wb_dynamic/products/${product_ids ?? product_id}/clusters/export/`,
      method: "get",
      params: {
        date_from,
        date_to,
        product_ids,
        product_id,
        keyword_ids,
        extra_fields,
        limit,
        period,
        offset,
        ordering,
        is_advert
      }
    });
    return response.data;
  }
  async getKeywordsStatByIdDaily({
    keyword_id,
    date_from,
    date_to,
    period,
    predict_trand
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/${keyword_id}/daily_dynamic/`,
      method: "get",
      params: {
        date_from,
        date_to,
        predict_trand,
        period,
      }
    })
    return response
  }
  async getGroupedProductDynamic({
    collection_id,
    brand_ids,
    product_ids,
    extra_fields,
    date_from,
    date_to,
  }) {
    const response = await request({
      url: `/wb_dynamic/products/grouped/`,
      method: "get",
      params: {
        collection_id,
        brand_ids,
        product_ids,
        extra_fields,
        date_from,
        date_to,
      }
    })
    return response
  }
  async getGroupedBrandsDynamic({
    collection_id,
    extra_fields,
    date_from,
    date_to,
  }) {
    const response = await request({
      url: `/wb_dynamic/brands/grouped/`,
      method: "get",
      params: {
        collection_id,
        extra_fields,
        date_from,
        date_to,
      }
    })
    return response
  }
  async getGroupedSellersDynamic({
    collection_id,
    extra_fields,
    date_from,
    date_to,
  }) {
    const response = await request({
      url: `/wb_dynamic/sellers/grouped/`,
      method: "get",
      params: {
        collection_id,
        extra_fields,
        date_from,
        date_to,
      }
    })
    return response
  }
  async getGroupedCategoriesDynamic({
    collection_id,
    extra_fields,
    date_from,
    date_to,
  }) {
    const response = await request({
      url: `/wb_dynamic/general_categories/grouped/`,
      method: "get",
      params: {
        collection_id,
        extra_fields,
        date_from,
        date_to,
      }
    })
    return response
  }
  async getGroupedKeywordsDynamic({
    collection_id,
    extra_fields,
    date_from,
    date_to,
  }) {
    const response = await request({
      url: `/wb_dynamic/keywords_stat/grouped/`,
      method: "get",
      params: {
        collection_id,
        extra_fields,
        date_from,
        date_to,
      }
    })
    return response
  }
  async getProductsTrendGrouped({
    collection_id,
    extra_fields,
    date_from,
    date_to,
    period
  }) {
    const response = await request({
      url: `/wb_dynamic/products_trend/grouped/`,
      method: "get",
      params: {
        collection_id,
        extra_fields,
        date_from,
        date_to,
        period
      }
    })
    return response
  }
  async getProductsDynamicAnalysis({
    collection_id,
    product_ids,
    seller_ids,
    category_ids,
    wb_adsearch,
    price__lte,
    price__gte,
    short_price_sigment,
    extra_fields,
    date_from,
    date_to,
    period,
    limit = 1000,
    ordering
  }) {
    const response = await request({
      url: `/wb_dynamic/products/analyze/`,
      method: "get",
      params: {
        collection_id,
        product_ids,
        seller_ids,
        category_ids,
        wb_adsearch,
        price__lte,
        price__gte,
        short_price_sigment,
        extra_fields,
        date_from,
        date_to,
        period,
        limit,
        ordering
      }
    })
    return response
  }
  async getWareHouseLogistic({
    keyword,
    city,
    with_products,
  }) {
    const response = await request({
      url: `/monitoring/warehouse_logistic/`,
      method: "get",
      params: {
        keyword,
        city,
        with_products,
      }
    })
    return response
  }
}

export default API;
