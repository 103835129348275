import React, { Fragment, useLayoutEffect, useState } from 'react'

import ClearIcon from "@mui/icons-material/Clear";
import PaymentsIcon from '@mui/icons-material/Payments';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box, Dialog, IconButton, Paper, Typography } from '@mui/material'
import { isEmpty, isNil } from 'ramda'
import { useSelector } from 'react-redux'

import TextField from "root/components/TextField"

import { useActions } from 'root/hooks/useActions'

import Button from '../Button';
import PageTitle from '../PageTitle';
import Tab_ from '../Tab';
import TabPanel from '../TabPanel';
import Tabs_ from '../Tabs';
import Tooltip from '../Tooltip';

import styles from "./index.module.scss";

import SuccessArrow from "root/icons/SuccessArrow";
import TooltipQuestionIcon from "root/icons/TooltipQuestion";

const tariffs = [
    {
        title: "Демо-доступ на 2 дня",
        id: "demo",
        icon: ReceiptIcon,
    },
    {
        title: "Тариф «Профессиональный» на 3 дня",
        id: "tariff",
        icon: PaymentsIcon,
    }
]

const PromoAccess = ({ long = false }) => {
    const { getPaymentHistory } = useActions()
    const [showTariffs, setShowTariffs] = useState(false)
    const userTariff = useSelector(store => store.userTariff)

    const localStoragePaymentHistory = JSON.parse(localStorage.getItem("paymentHistory"))
    const { paymentHistory: { purchases = [] } = { purchases: [] } } = useSelector(store => store.paymentHistory)
    const profile = useSelector(store => store.profile)

    const [modalOpen, setModalOpen] = useState(false)
    const [dialogInfo, setDialogInfo] = useState("")
    const [helperModalOpen, setHelperModalOpen] = useState({
        isOpen: false
    })
    const [selected, setSelected] = useState(tariffs[0].id)
    const [tab, setTab] = useState(1)
    const [apiToken, setApiToken] = useState("")
    const exist = false

    const onSetToken = (e) => {
        setApiToken(e.target.value)
    }
    const onSubmitToken = () => {
        try {
            setDialogInfo("success")
        } catch (error) {
            setDialogInfo("exist")
        }
        setModalOpen(false)
        setHelperModalOpen(state => ({ ...state, isOpen: true }));
    }

    const onOrderCall = () => {
        setDialogInfo("call")
        setHelperModalOpen(state => ({ ...state, isOpen: true }));
    }

    const onPay = () => {
        console.log("Pay");
    }

    useLayoutEffect(() => {
        if (userTariff?.isLoading) return
        if (!isEmpty(purchases)) {
            return setShowTariffs(false)
        }
        if (isNil(userTariff?.id) && isEmpty(purchases)) {
            setShowTariffs(true)

            if (!localStoragePaymentHistory?.purchases?.[0]) {
                getPaymentHistory()
            }
        } else {
            setShowTariffs(false)
        }
    }, [userTariff?.id, userTariff?.isLoading])


    if (showTariffs) {
        return (
            <Fragment>
                <Box
                    ml={long ? 30 : 9}
                    className={styles.root}
                    onClick={() => setModalOpen(true)}
                >
                    <Box component={"span"}>Специальное предложение для новых пользователей!</Box>
                    <Box
                        component={"span"}
                        className={styles.more}
                    >
                        подробнее...
                    </Box>
                </Box>
                <Dialog
                    open={modalOpen}
                    fullWidth
                    onClose={() => setModalOpen(false)}
                >
                    <Paper elevation={0}>
                        <Box p={2}>
                            <Box mb={2}>
                                <PageTitle>Специальное предложение для новых пользователей!</PageTitle>
                                <Box
                                    style={{
                                        position: "absolute",
                                        right: "0",
                                        top: "0"
                                    }}>
                                    <IconButton
                                        onClick={() => setModalOpen(false)}
                                    >
                                        <ClearIcon className={styles.clearIcon} />
                                    </IconButton>
                                </Box>
                            </Box>

                            <Box
                                mb={2}
                                className={styles.cards}
                            >
                                {tariffs.map((item, i) => (
                                    <Box
                                        key={i}
                                        className={styles.card}
                                        onClick={() => setSelected(item.id)}
                                    >
                                        <Box
                                            className={styles.icon}
                                        >
                                            <item.icon />
                                        </Box>
                                        <Box
                                            className={styles.title}
                                        >
                                            {item.title}
                                        </Box>
                                        <div className={styles.item}>
                                            <div className={styles.flexBox}>
                                                <div className={styles.itemContent}>
                                                    <div
                                                        className={styles.nameWrapper}
                                                        data-active={item.id == selected}
                                                    >
                                                        <div className={styles.radio} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Box>
                                ))}
                            </Box>

                            <TabPanel
                                value={selected}
                                index={"demo"}
                            >
                                <Box>
                                    <Box mb={1}>
                                        <PageTitle>Демо-доступ к возможностям сервиса</PageTitle>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography variant="subtitle2">
                                            Без функции «поиск трафика». Ограничение лимита запросов 1000 шт. в день.
                                        </Typography>
                                    </Box>
                                    <Box mb={3}>
                                        Нам необходимо убедиться, что вы реальный пользователь.
                                        <br />
                                        Добавьте API-токен из личного кабинета WB или закажите звонок менеджера
                                    </Box>
                                    <Box>
                                        <Tabs_ value={tab} onChange={(_, v) => setTab(v)}>
                                            <Tab_ value={1} label={"Добавить токен"} />
                                            <Tab_ value={2} label={"Заказать звонок"} />
                                        </Tabs_>
                                        <TabPanel
                                            value={tab}
                                            index={1}
                                        >
                                            <Box mt={2}>
                                                <Typography variant="subtitle2">
                                                    <Box component={"span"}>
                                                        <Box component={"span"}>
                                                            Загрузить свой API-токен статистики на чтение.
                                                        </Box>
                                                        <Box component={"span"}>
                                                            <Tooltip
                                                                title={" "}>
                                                                <TooltipQuestionIcon style={{ cursor: "pointer" }} />
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>
                                                </Typography>
                                                <Box
                                                    display={"flex"}
                                                    gap={"1rem"}
                                                    mb={3}
                                                >
                                                    <Box
                                                        flex={3}
                                                        position={"relative"}
                                                    >
                                                        <TextField
                                                            placeholder="Введите API-токен"
                                                            value={apiToken}
                                                            onChange={onSetToken}
                                                        />
                                                        <Box
                                                            position={"absolute"}
                                                            bottom={"-26px"}
                                                            right={0}
                                                            color={"#cf3aa0"}
                                                        >
                                                            Как добавить токен?
                                                        </Box>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Button
                                                            variant="filled"
                                                            onClick={onSubmitToken}
                                                        >
                                                            Добавить токен
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TabPanel>
                                        <TabPanel
                                            value={tab}
                                            index={2}
                                        >
                                            <Box mt={2}>
                                                <Box>
                                                    Менеджер свяжется с вами в течениие 24 часов, с 10:00 до 19:00
                                                </Box>
                                                <Box
                                                    display={"flex"}
                                                    gap={"1rem"}
                                                    my={1}
                                                    alignItems={"center"}
                                                >
                                                    <Box
                                                        display={"flex"}
                                                        style={{
                                                            placeContent: "end"
                                                        }}
                                                        flex={3}
                                                    >
                                                        <PageTitle>{profile?.phone ?? "—"}</PageTitle>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <Button
                                                            variant="filled"
                                                            onClick={onOrderCall}
                                                        >
                                                            Заказать звонок
                                                        </Button>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </TabPanel>
                                    </Box>
                                </Box>
                            </TabPanel>
                            <TabPanel
                                value={selected}
                                index={"tariff"}
                            >
                                <Box>

                                    <Box mb={1}>
                                        <PageTitle>Специальное предложение</PageTitle>
                                    </Box>
                                    <Box mb={3}>
                                        <Typography variant="subtitle2">
                                            Только для новых пользователей Wildbox
                                        </Typography>
                                    </Box>
                                    <Box display={"flex"}>


                                        <Box
                                            flex={1}
                                            display={"grid"}
                                        >
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <SuccessArrow /><b> Поиск трафика</b>
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <SuccessArrow /> Тариф Профессиональный
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <SuccessArrow /> 3 дня доступа
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <SuccessArrow /> до 3 пользователей
                                            </Box>
                                            <Box
                                                display="flex"
                                                alignItems="center"
                                                gap={1}
                                            >
                                                <SuccessArrow /> Данные с марта 2022 года
                                            </Box>
                                        </Box>

                                        <Box
                                            flex={1}
                                            display={"flex"}
                                            flexDirection={"column"}
                                            style={{
                                                placeSelf: "flex-end"
                                            }}
                                        >
                                            <Box
                                                display="flex"
                                                flexDirection="row-reverse"
                                                mb={2}
                                            >
                                                <PageTitle>3500 руб.</PageTitle>
                                            </Box>
                                            <Button
                                                variant="filled"
                                                onClick={onPay}
                                            >
                                                Оплатить
                                            </Button>

                                        </Box>
                                    </Box>
                                </Box>
                            </TabPanel>
                        </Box>
                    </Paper>
                </Dialog>


                <Dialog
                    open={helperModalOpen.isOpen}
                    onClose={() => setHelperModalOpen(state => ({
                        ...state,
                        isOpen: false
                    }))}
                >
                    <TabPanel
                        value={dialogInfo}
                        index={"success"}
                    >
                        <Box
                            p={2}
                            textAlign={"center"}
                        >
                            <Box>
                                <PageTitle>Токен успешно добавлен</PageTitle>
                            </Box>
                            <Box
                                maxWidth={360}
                                my={2}
                            >
                                <Typography variant="subtitle2">
                                    Доступ ко всем возможностям сервиса будет активирован в течение 15 минут.
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Button
                                    variant="filled"
                                    onClick={() => {
                                        setDialogInfo("")
                                        setHelperModalOpen({
                                            success: false,
                                            exist: false,
                                            orderCall: false,
                                            pay: false
                                        })
                                    }}
                                >
                                    Продолжить
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>
                    <TabPanel
                        value={dialogInfo}
                        index={"exist"}
                    >
                        <Box
                            p={2}
                            textAlign={"center"}
                        >
                            <Box>
                                <PageTitle>Токен уже используется</PageTitle>
                            </Box>
                            <Box
                                maxWidth={450}
                                my={2}
                            >
                                <Typography variant="subtitle2">
                                    <Box component={"span"} color={"#cf3aa0"}>
                                        Для вашего кабинета уже использован демо-доступ
                                    </Box>
                                </Typography>
                                <Typography variant="subtitle2">
                                    <Box component={"span"} color={"#cf3aa0"}>
                                        Ранее API-токен вашего кабинета уже загружали в систему для получения демо-доступа. Вы можете заказать звонок от менеждера или приобрести тариф, на странице «Оплата сервиса»
                                    </Box>
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Button
                                    variant="filled"
                                    onClick={() => {
                                        setDialogInfo("")
                                        setHelperModalOpen(state => ({ ...state, isOpen: false }))
                                    }}
                                >
                                    Закрыть
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>

                    <TabPanel
                        value={dialogInfo}
                        index={"call"}
                    >
                        <Box
                            p={2}
                            textAlign={"center"}
                        >
                            <Box>
                                <PageTitle>Звонок заказан</PageTitle>
                            </Box>
                            <Box
                                maxWidth={360}
                                my={2}
                            >
                                <Typography variant="subtitle2">
                                    Наш менеджер свяжется с вами для дальнейшей верификации в течение 24 часов.
                                </Typography>
                            </Box>
                            <Box
                                display={"flex"}
                                justifyContent={"center"}
                            >
                                <Button
                                    variant="filled"
                                    onClick={() => {
                                        setDialogInfo("")
                                        setHelperModalOpen(state => ({ ...state, isOpen: false }))
                                    }}
                                >
                                    Продолжить
                                </Button>
                            </Box>
                        </Box>
                    </TabPanel>
                </Dialog>
            </Fragment >
        )
    }
    return null
}

export default PromoAccess