import * as React from 'react';

import { StyledEngineProvider } from '@mui/material/styles';

export const GlobalCssPriority = ({children}) => {
    return (
        <StyledEngineProvider injectFirst>
            {children}
        </StyledEngineProvider>
    );
}
