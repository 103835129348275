import React, {forwardRef} from "react"

import Template from "./utils/Template"

export default forwardRef((props, ref) => (
	<Template
		ref={ref}
		tags={["none"]}
		{...props}
	>
		<path d="M10.0631 14.3657L9.7653 18.3332C10.1913 18.3332 10.3758 18.1599 10.5971 17.9517L12.5944 16.1434L16.733 19.0146C17.492 19.4154 18.0267 19.2043 18.2315 18.3531L20.9481 6.29415L20.9488 6.29344C21.1896 5.2305 20.5431 4.81484 19.8035 5.0756L3.83565 10.8671C2.74588 11.2678 2.76238 11.8433 3.6504 12.1041L7.73275 13.307L17.2152 7.68606C17.6615 7.40612 18.0672 7.56101 17.7335 7.84096L10.0631 14.3657Z" />
	</Template>
))
