import React from "react"

import Button from "../Button"
import LinkPure from "../LinkPure"

import styles from "./index.module.scss"

const NoTariffDialog = () => {
	return (
		<div className={styles.root}>
			<div className={styles.box}>
				<div className={styles.title}>Ваш тариф закончился</div>
				<div className={styles.subtitle}>Оплатите тариф, чтобы пользоваться сервисом</div>
				<LinkPure to="/dashboard/tariff">
					<Button variant="filled">Перейти к оплате</Button>
				</LinkPure>
			</div>
		</div>
	)
}

export default NoTariffDialog
