import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as R from "ramda";

import API from "root/js/api";

import { formatDateTimezone } from "../../js/utils";
import { fetchProductDynamic } from "../productDynamics/productDynamicsSlice";
import { getUserTariff } from "../userTariff/userTariff";

const WBAPI = new API();

export const checkTariffExpired = createAsyncThunk("user/checkTariffExpired", async () => {
  // try {
  // 	const result = await WBAPI.getCompanyTariff()
  //
  // 	if(result.tariff_expiration === null || !result.tariff_plan || formatDateTimezone(new Date(result.tariff_expiration)) < formatDateTimezone(new Date())) {
  // 		return true
  // 	}
  // 	return equals([], result)
  // } catch (error) {
  // 	console.log(error)
  // }
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    expiredTariff: false,
    error: "",
    isDateLoading: true
  },
  reducers: {
    setExpiredTariff(state, action) {
      state.expiredTariff = action.payload.expired;
    },
    setError(state, action) {
      state.error = action.payload;
    },
    setIsDateLoading(state, { payload }) {
      state.isDateLoading = payload;
    }
  },
  extraReducers: {
    [fetchProductDynamic.rejected]: (state, { payload }) => {
      const error = payload.error;
      const responseStatus = R.path(["response", "status"], error);

      if (responseStatus === 401) {
        WBAPI.resetToken();
      } else if (responseStatus === 429) {
        state.error = "throttled";
      } else if (responseStatus === 404) {
        state.error = "not_found";
      } else if (
        responseStatus === 500 ||
        responseStatus === 502 ||
        error.code === "ECONNABORTED"
      ) {
        state.error = "internal_server_error";
      }
    },
    // [checkTariffExpired.fulfilled]: (state, action) => {
    // 	state.expiredTariff = action.payload
    // },
    [getUserTariff.fulfilled]: (state, { payload }) => {
      state.expiredTariff =
        payload.tariffExpiration === null ||
        payload.id === null ||
        formatDateTimezone(new Date(payload.tariffExpiration)) < formatDateTimezone(new Date());
    },
    [getUserTariff.rejected]: (state, { payload }) => {
      const error = payload.error;
      const responseStatus = R.path(["response", "status"], error);

      if (responseStatus === 401) {
        WBAPI.resetToken();
      } else if (responseStatus === 429) {
        state.error = "throttled";
      } else if (responseStatus === 403) {
        state.expiredTariff = true;
      } else if (
        responseStatus === 500 ||
        responseStatus === 502 ||
        error.code === "ECONNABORTED"
      ) {
        state.error = "internal_server_error";
      }
    }
  }
});

export const { setExpiredTariff, setError, setIsDateLoading } = userSlice.actions;

export default userSlice.reducer;
