import * as React from 'react';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

const cache = createCache({
    key: 'css',
    prepend: true,
});

export const PlainCssPriority = ({children}) =>  {
    return (
        <CacheProvider value={cache}>
            {children}
        </CacheProvider>
    );
}
