import React from "react";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenOutlinedIcon from "@mui/icons-material/MenuOpenOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Hidden from "@mui/material/Hidden";
import IconButton from "@mui/material/IconButton";
import classnames from "classnames";
import { useHistory } from "react-router-dom";

import API from "root/js/api";

import logo from "../../img/logo/logo-with-black-text.svg";
import LinkPure from "../LinkPure";

// import ExitToAppIcon from "./Icon/Exit.svg"
// import {ReactComponent as ExitToAppOutlinedIcon} from "./Icon/Exit.svg"
import { ReactComponent as PersonOutlineOutlinedIcon } from "./Icon/Avatar.svg";

import styles from "./index.module.scss";

const WBAPI = new API();

const Header = props => {
  const history = useHistory();

  const { onOpenNotifications, isDrawerOpen, drawerToggle, className, ...rest } = props;

  const logout = () => {
    WBAPI.resetToken();
    history.push("/signin");
  };

  return (
    <>
      {/*<Hidden xlDown>*/}
      {/*	{" "}*/}
      {/*	/!*smDown*!/*/}
      {/*	<div*/}
      {/*		{...rest}*/}
      {/*		className={classnames(styles.desktop, className)}*/}
      {/*		data-is-drawer-open={isDrawerOpen}*/}
      {/*	>*/}
      {/*		<Box*/}
      {/*			pr={2}*/}
      {/*			pl={1}*/}
      {/*			className={styles.iconWrapper}*/}
      {/*		>*/}
      {/*			<IconButton*/}
      {/*				className={styles.iconButton}*/}
      {/*				onClick={drawerToggle}*/}
      {/*				disableRipple*/}
      {/*				disableFocusRipple*/}
      {/*			>*/}
      {/*				{isDrawerOpen ? (*/}
      {/*					<MenuOpenOutlinedIcon className={styles.drawerIcon} />*/}
      {/*				) : (*/}
      {/*					<MenuIcon />*/}
      {/*				)}*/}
      {/*			</IconButton>*/}
      {/*		</Box>*/}

      {/*		<div className={styles.grow} />*/}

      {/*		<div className={styles.badgeWrapper}>*/}
      {/*			<IconButton*/}
      {/*				className={styles.iconButton}*/}
      {/*				onClick={onOpenNotifications}*/}
      {/*			>*/}
      {/*				<Badge*/}
      {/*					badgeContent={12}*/}
      {/*					color="error"*/}
      {/*					classes={{badge: styles.badge}}*/}
      {/*				>*/}
      {/*					<NotificationsNoneOutlinedIcon />*/}
      {/*				</Badge>*/}
      {/*			</IconButton>*/}
      {/*		</div>*/}

      {/*		<LinkPure to="/">*/}
      {/*			<div className={styles.userWrapper}>*/}
      {/*				<PersonOutlineOutlinedIcon className={styles.icon} />*/}
      {/*			</div>*/}
      {/*		</LinkPure>*/}

      {/*		<div className={styles.exitWrapper}>*/}
      {/*			<IconButton*/}
      {/*				className={styles.iconButton}*/}
      {/*				onClick={logout}*/}
      {/*			>*/}
      {/*				<ExitToAppIcon className={styles.icon} />*/}
      {/*			</IconButton>*/}
      {/*		</div>*/}
      {/*	</div>*/}
      {/*</Hidden>*/}
      <Hidden mdUp>
        <div {...rest} className={classnames(styles.mobile, className)}>
          <IconButton className={styles.iconButton} onClick={drawerToggle}>
            <MenuOutlinedIcon className={styles.drawerIcon} />
          </IconButton>
          <LinkPure to="/">
            <img alt="logo" className={styles.text} src={logo} />
          </LinkPure>
          <LinkPure to="/cabinet?tab=0">
            <IconButton className={styles.iconButton}>
              <PersonOutlineOutlinedIcon />
            </IconButton>
          </LinkPure>
        </div>
      </Hidden>
    </>
  );
};

export default Header;
