import { createSlice } from "@reduxjs/toolkit"

import { checkProduct, checkProducts } from "./actions"

const initialState = {
	productsCheckBoxes: [],
	fullIds: []
}

const products = createSlice({
	name: "monitoring products",
	initialState,
	reducers: {},
	extraReducers: builder => {
		builder.addCase(checkProducts.fulfilled, (state, { payload }) => {
			state.fullIds = payload
		})
		builder.addCase(checkProduct.fulfilled, (state, { payload }) => {
			state.productsCheckBoxes = payload
		})
	},
})

export const productsActions = {
	...products.actions,
	checkProduct,
	checkProducts
}

export default products.reducer
